import { ISideBarLink } from '../sidebar.data'
import { BiCategoryAlt } from 'react-icons/bi'
import { TbReport, TbReportAnalytics } from 'react-icons/tb'
import { AiOutlineInteraction, AiOutlineFundProjectionScreen } from 'react-icons/ai'
import { appRoutes } from '../../../constants/app-route'

export const laboratoryActivitySubmenus: ISideBarLink[] = [
    {
        app: '01',
        code: 'D00',
        description: 'Laboratory Activities',
        route: appRoutes.LABORATORY_ACTIVITY.LABORATORY_ACTIVITY_BATCH_SUMMARY,
        dropDown: false,
        dropDownMenus: [],
        parent: '',
        icon: <BiCategoryAlt />,
    },
    {
        app: '01',
        code: 'D01',
        description: 'Milestone Projections',
        route: appRoutes.LABORATORY_ACTIVITY.LABORATORY_MILESTONE_PROJECTION,
        dropDown: false,
        dropDownMenus: [],
        parent: '',
        icon: <BiCategoryAlt />,
    },
    {
        app: '01',
        parent: '',
        code: 'D02',
        description: 'Milestone Achievements',
        route: appRoutes.LABORATORY_ACTIVITY.LABORATORY_MILESTONE_ACHIEVEMENT,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReportAnalytics />,
    },
    {
        app: '01',
        parent: '',
        code: 'D03',
        description: 'BO Program Mgt',
        route: appRoutes.LABORATORY_ACTIVITY.LABORATORY_PROGRAM_MANAGEMENT_BO,
        dropDown: false,
        dropDownMenus: [],
        icon: <AiOutlineInteraction />,
    },
    {
        app: '01',
        parent: '',
        code: 'D04',
        description: 'DHS Program Mgt',
        route: appRoutes.LABORATORY_ACTIVITY.LABORATORY_PROGRAM_MANAGEMENT_DHS,
        dropDown: false,
        dropDownMenus: [],
        icon: <AiOutlineFundProjectionScreen />,
    },
    {
        app: '01',
        parent: '',
        code: 'D05',
        description: 'MVAN Program Mgt',
        route: appRoutes.LABORATORY_ACTIVITY.LABORATORY_PROGRAM_MANAGEMENT_MVAN,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReport />,
    },
     // LAB
     {
        app: '01',
        parent: 'D',
        code: 'D06',
        description: 'Lab Regional Modifications',
        route: appRoutes.LABORATORY_ACTIVITY.LAB_REGIONAL_MODIFICATION,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReport />,
    },
    {
        app: '01',
        parent: 'D',
        code: 'D07',
        description: 'Lab Zone Site Modifications',
        route: appRoutes.LABORATORY_ACTIVITY.LAB_ZONE_SITE_MODIFICATION,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReport />,
    },
    {
        app: '01',
        parent: 'D',
        code: 'D08',
        description: 'Lab Zone Above Site Modifications',
        route: appRoutes.LABORATORY_ACTIVITY.LAB_ZONE_ABOVE_SITE_MODIFICATION,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReport />,
    },
]
