import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IIncludeRunner {
    user: string
    runner: string
}

export const emptyIncludeRunner: IIncludeRunner = {
    user: '',
    runner: '',
}

export interface IIncludeRunnerState extends IBaseState {
    readonly includeRunners: IIncludeRunner[]
    readonly includeRunner: IIncludeRunner
}

export interface IIncludeRunnerResponse extends IResponseBase {
    data: IIncludeRunner
}
