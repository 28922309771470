import React from 'react'
import { Table } from 'antd'
import { IBranch } from '../../../models/common/branch.model'
import { branchesTableColumns } from './branch-column.component'
import { useBranch } from '../../../hooks/common/branch.hook'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'

const BranchTable = () => {
    const { branches, setBranch } = useBranch()
    const router = useHistory()
    // const route = use
    const handleRowClick = (branch: IBranch) => {
        setBranch(branch)
        router.push(`/configurations/branches/${slugify(branch.description)}`)
    }
    return (
        <>
            {branches && branches.length ? (
                <Table<IBranch>
                    dataSource={branches}
                    columns={branchesTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    onRow={(record: IBranch) => {
                        return {
                            onClick: (e) => {
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                <NoContent title="No data for branch" />
            )}
        </>
    )
}

export default BranchTable
