import { authRequests } from '../..'
import { IUser, IUserResponse } from '../../../models/shared/user.model'

const userService = {
    list: (): Promise<IUser[]> => authRequests.get('/api/users'),
    details: (code: string): Promise<IUser> => authRequests.get(`/users/${code}`),
    create: (user: IUser): Promise<IUserResponse> =>
        authRequests.post('/manage/register', user),
    update: (user: IUser): Promise<IUserResponse> =>
        authRequests.put('/users', user),
    search: (searchQuery: string): Promise<IUser[]> =>
        authRequests.get(`/users/search/${searchQuery}`),
}

export default userService
