import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IActivityPlanOptions {
    copYear: string
    component: string
    project: string
}

export interface IActivityPlan {
    tenant: string
    code: string
    copYear: string
    component: string
    project: string
    description: string
    intervention: string
    costCategory: string
    approach: string
    objective: string
    budgetCode: string
    budgetBalance: number
    activityType: string
    createdOn: Date
}

export interface IActivityPlanState extends IBaseState {
    readonly activityPlans: IActivityPlan[]
    readonly activityPlan: IActivityPlan
}

export const emptyActivityPlan: IActivityPlan = {
    tenant: '',
    code: '',
    copYear: '',
    component: '',
    project: '',
    description: '',
    intervention: '',
    costCategory: '',
    approach: '',
    objective: '',
    budgetCode: '',
    budgetBalance: 0,
    activityType: '',
    createdOn: new Date(),
}

export interface IActivityPlanResponse extends IResponseBase {
    data: IActivityPlan
}

export type ActivityPlanParamType = {
    tenant: string
    copYear: string
    component: string
    project: string
}
