import { InfoCircleTwoTone } from '@ant-design/icons'
import { Menu, MenuProps, Space } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const AppHeaderNotificationMenus: React.FC = () => {
    const items: MenuProps['items'] = [
        {
            label: (
                <>
                    <Link to="/">
                        <Space size="middle" align="start">
                            <InfoCircleTwoTone
                                twoToneColor="#52c41a"
                                style={{ fontSize: '1.5rem' }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <h3>Notification title</h3>
                                <p style={{ flex: 1 }}>
                                    Lorem ipsum dolor sit, amet consectetur
                                </p>
                            </div>
                        </Space>
                    </Link>
                </>
            ),
            key: 'notification-key',
        },
        {
            label: <Link to="/">mark all as read</Link>,
            key: 'mark-as-read',
        },
    ]

    return <Menu style={{ width: 340 }} items={items} />
}

export default AppHeaderNotificationMenus
