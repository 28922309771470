import { ISideBarLink } from '../sidebar.data'
import { BiCategoryAlt } from 'react-icons/bi'
import { TbReport, TbReportAnalytics } from 'react-icons/tb'
import { AiOutlineInteraction, AiOutlineFundProjectionScreen } from 'react-icons/ai'
import { appRoutes } from '../../../constants/app-route'

export const activitySubmenus: ISideBarLink[] = [
    {
        app: '01',
        code: 'C00',
        description: 'Site Activities',
        route: appRoutes.MAIN_ACTIVITY.ACTIVITY_BATCH_SUMMARY,
        dropDown: false,
        dropDownMenus: [],
        parent: '',
        icon: <BiCategoryAlt />,
    },
    {
        app: '01',
        code: 'C01',
        description: 'Milestone Projections',
        route: appRoutes.MAIN_ACTIVITY.MILESTONE_PROJECTION,
        dropDown: false,
        dropDownMenus: [],
        parent: '',
        icon: <BiCategoryAlt />,
    },
    {
        app: '01',
        parent: '',
        code: 'C02',
        description: 'Milestone Achievements',
        route: appRoutes.MAIN_ACTIVITY.MILESTONE_ACHIEVEMENT,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReportAnalytics />,
    },
    {
        app: '01',
        parent: '',
        code: 'C03',
        description: 'BO Program Mgt',
        route: appRoutes.MAIN_ACTIVITY.PROGRAM_MANAGEMENT_BO,
        dropDown: false,
        dropDownMenus: [],
        icon: <AiOutlineInteraction />,
    },
    {
        app: '01',
        parent: '',
        code: 'C04',
        description: 'DHS Program Mgt',
        route: appRoutes.MAIN_ACTIVITY.PROGRAM_MANAGEMENT_DHS,
        dropDown: false,
        dropDownMenus: [],
        icon: <AiOutlineFundProjectionScreen />,
    },
    {
        app: '01',
        parent: '',
        code: 'C05',
        description: 'MVAN Program Mgt',
        route: appRoutes.MAIN_ACTIVITY.PROGRAM_MANAGEMENT_MVAN,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReport />,
    },
    // HIV
    {
        app: '01',
        parent: 'C',
        code: 'C06',
        description: 'HIV Regional Modifications',
        route: appRoutes.MAIN_ACTIVITY.HIV_REGIONAL_MODIFICATION,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReport />,
    },
    {
        app: '01',
        parent: 'C',
        code: 'C07',
        description: 'HIV Zone Site Modifications',
        route: appRoutes.MAIN_ACTIVITY.HIV_ZONE_SITE_MODIFICATION,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReport />,
    },
    {
        app: '01',
        parent: 'C',
        code: 'C08',
        description: 'HIV Zone Above Site Modifications',
        route: appRoutes.MAIN_ACTIVITY.HIV_ZONE_ABOVE_SITE_MODIFICATION,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReport />,
    },
   
]
