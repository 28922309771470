import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as locationActions from '../../../redux/common/location/location.slice'
import { ILocation } from '../../../models/common/location.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import locationService from '../../../services/common/location/location.service'

const useLocation = () => {
    const locations = useSelector<rootState, ILocation[]>(
        (state) => state.location.locations
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.location.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.location.initialFetch
    )
    const location = useSelector<rootState, ILocation>(
        (state) => state.location.location
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.location.updateMode
    )

    const dispatch = useDispatch()

    const loadlocations = useCallback(() => {
        if (initialFetch) {
            dispatch(locationActions.fetchlocationsAsync())
        }
    }, [dispatch, initialFetch])

    const addlocation = async (location: ILocation) => {
        return await locationService
            .create(location)
            .then((locationResponse) => {
                dispatch(locationActions.addlocationSuccess(locationResponse.data))
                return true
            })
            .catch((error) => {
                // dispatch(locationActions.)
                return false
            })
    }

    const setLocation = (location: ILocation) => {
        dispatch(locationActions.setActivelocation(location))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(locationActions.setlocationUpdateMode(updateMode))
    }

    const editlocation = async (location: ILocation) => {
        return await locationService
            .update(location)
            .then((locationResponse) => {
                dispatch(locationActions.editlocationSuccess(locationResponse.data))
                setLocation(location)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveLocation = async (location: ILocation, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addlocation(location)
            : await editlocation(location)
    }

    useEffect(() => {
        loadlocations()
    }, [location, locations, isLoading, initialFetch, loadlocations])

    return {
        location,
        locations,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addlocation,
        editlocation,
        saveLocation,
        setLocation,
    }
}

export { useLocation }
