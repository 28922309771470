import { Card, Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useBranch } from '../../../hooks/common/branch.hook'

const BranchDetailComponent: React.FC = () => {
    const { branch } = useBranch()

    return (
        <Card bordered={false} size="small">
            <Row>
                <Col span={4}>
                    <StyledParagraph>Code: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branch.code}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Description: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branch.description}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Branch Name: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branch.branchName}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Telephone: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branch.telephone}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>StaCode: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branch.staCode}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Post Box: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branch.postBox}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Email: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branch.eMail}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Website: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branch.website}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Slogan: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branch.slogan}</StyledParagraph>
                </Col>
            </Row>
        </Card>
    )
}

export default BranchDetailComponent

const StyledParagraph = styled.p`
    margin-bottom: 0;
`
