import { apiRequests } from '../..'
import { IBranch, IBranchResponse } from '../../../models/common/branch.model'

const branchService = {
    list: (): Promise<IBranch[]> => apiRequests.get('/branches'),
    details: (code: string): Promise<IBranch> =>
        apiRequests.get(`/branches/${code}`),
    create: (branch: IBranch): Promise<IBranchResponse> =>
        apiRequests.post('/branches', branch),
    update: (branch: IBranch): Promise<IBranchResponse> =>
        apiRequests.put('/branches', branch),
}

export default branchService
