import { apiRequests } from '../'
import { IQuarter, IQuarterResponse } from '../../models/budgeting/quarter.model'

const quarterService = {
    list: (): Promise<IQuarter[]> => apiRequests.get('/quarters'),
    details: (code: string): Promise<IQuarter> =>
        apiRequests.get(`/quarters/${code}`),
    create: (quarter: IQuarter): Promise<IQuarterResponse> =>
        apiRequests.post('/quarters', quarter),
    update: (quarter: IQuarter): Promise<IQuarterResponse> =>
        apiRequests.put('/quarters', quarter),
}

export default quarterService
