import { apiRequests } from '../..'
import { IUserMenu, IUserMenuResponse } from '../../../models/common/user-menu.model'

const userMenuService = {
    list: (): Promise<IUserMenu[]> => apiRequests.get('/userMenus'),
    details: (code: string): Promise<IUserMenu> =>
        apiRequests.get(`/userMenus/${code}`),
    create: (userMenu: IUserMenu): Promise<IUserMenuResponse> =>
        apiRequests.post('/userMenus', userMenu),
    update: (userMenu: IUserMenu): Promise<IUserMenuResponse> =>
        apiRequests.put('/userMenus', userMenu),
}

export default userMenuService
