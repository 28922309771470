import { Space, Tag } from 'antd'
import React from 'react'
import { useRole } from '../../../hooks/common/role/role.hook'
import { useUserRole } from '../../../hooks/common/user-role/user-role.hook'

type Props = {
    userCode: string
}

const UserRolesRenderer: React.FunctionComponent<Props> = ({ userCode }) => {
    const { roles } = useRole()
    const { userRoles } = useUserRole()
    const match = userRoles.find((ur) => ur.usrCode === userCode)
    const currentUserRoleCodes = match ? match.roleCode.split(',') : ''
    const availableUserRoles = roles.filter((item) =>
        currentUserRoleCodes.includes(item.code)
    )
    const getRandomColors = () => {
        const colors = [
            'pink',
            'red',
            'yellow',
            'orange',
            'cyan',
            'green',
            'blue',
            'purple',
            'geekblue',
            'magenta',
            'volcano',
            'gold',
            'lime',
        ]
        const randomColorPos = Math.floor(Math.random() * (colors.length - 1))
        return colors[randomColorPos]
    }
    return (
        <Space size="small" wrap>
            {availableUserRoles.map((item) => (
                <Tag color={getRandomColors()}>{item.description}</Tag>
            ))}
        </Space>
    )
}

export default UserRolesRenderer
