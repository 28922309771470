import { Reducer } from 'redux'
import { ILanguage, LanguageActionCreators, LanguageActionTypes } from './lang.types'

export const defaultLanguage: ILanguage = {
    key: 'en',
    name: 'English',
}

export interface ILanguageState {
    key: string
}

export const INITIAL_STATE: ILanguageState = {
    key: 'en',
}

const reducer: Reducer<ILanguageState> = (
    state = INITIAL_STATE,
    action: LanguageActionCreators
): ILanguageState => {
    switch (action.type) {
        case LanguageActionTypes.CHANGE: {
            return { ...state, key: action.payload }
        }
        default:
            return state
    }
}

export { reducer as languageReducer }
