import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { rootState } from '../redux/root-reducer'
import { IConfiguration } from '../models/configuration.model'
import { setActiveConfiguration } from '../redux/configuration.slice'

const useConfiguration = () => {
    const configuration = useSelector<rootState, IConfiguration>(
        (state) => state.configuration.configuration,
        shallowEqual
    )

    const dispatch = useDispatch()

    const setConfiguration = useCallback(
        (configuration: IConfiguration) => {
            dispatch(setActiveConfiguration(configuration))
        },
        [dispatch]
    )

    return {
        configuration,
        setConfiguration,
    }
}

export { useConfiguration }
