import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ISubDivision {
    code: string
    description: string
    divisionCode: string
    createdOn: Date
}

export interface ISubDivisionState extends IBaseState {
    readonly subDivisions: ISubDivision[]
    readonly subDivision: ISubDivision
}

export const emptySubDivision: ISubDivision = {
    code: '',
    description: '',
    divisionCode: '',
    createdOn: new Date(),
}
export interface ISubDivisionResponse extends IResponseBase {
    data: ISubDivision
}
