import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { IDateGeneration } from './date-generation.model'
import { emptyServerStatusBase, IServerStatusBase } from './server-status-base.model'

export interface IServerStatus extends IServerStatusBase {
    currentDate?: IDateGeneration
}

export interface IServerStatusState extends IBaseState {
    readonly serverStatuses: IServerStatus[]
    readonly serverStatus: IServerStatus
}

export const emptyServerStatus: IServerStatus = {
    ...emptyServerStatusBase,
    // currentDate: emptyDateGeneration
}

export interface IServerStatusResponse extends IResponseBase {
    data: IServerStatus
}
