import { apiRequests } from '../'
import {
    ICopYearBudgetCode,
    ICopYearBudgetCodeResponse,
} from '../../models/budgeting/cop-year-budget-code.model'

const copYearBudgetCodeService = {
    list: (): Promise<ICopYearBudgetCode[]> =>
        apiRequests.get('/copYearBudgetCodes'),
    details: (code: string): Promise<ICopYearBudgetCode> =>
        apiRequests.get(`/copYearBudgetCodes/${code}`),
    create: (
        copyearbudgetcode: ICopYearBudgetCode
    ): Promise<ICopYearBudgetCodeResponse> =>
        apiRequests.post('/copYearBudgetCodes', copyearbudgetcode),
    update: (
        copyearbudgetcode: ICopYearBudgetCode
    ): Promise<ICopYearBudgetCodeResponse> =>
        apiRequests.put('/copYearBudgetCodes', copyearbudgetcode),
}

export default copYearBudgetCodeService
