import React from 'react'
import { Table } from 'antd'
import { ILocation } from '../../../models/common/location.model'
import { locationsTableColumns } from './location-column.component'
import { useLocation } from '../../../hooks/common/location/location.hook'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'

const LocationTable = () => {
    const { setLocation } = useLocation()
    const router = useHistory()
    // const route = use
    const handleRowClick = (location: ILocation) => {
        setLocation(location)
        router.push(`/configurations/locations/${slugify(location.description)}`)
    }
    const locationss: ILocation[] = [
        {
            tenant: '200',
            code: '101',
            description: 'Old Town',
            subDivisionCode: '',
            createdOn: new Date(),
        },
        {
            tenant: '206',
            code: '102',
            description: 'City Chemist',
            subDivisionCode: '',
            createdOn: new Date(),
        },
        {
            tenant: '205',
            code: '103',
            description: 'Mile 4',
            subDivisionCode: '',
            createdOn: new Date(),
        },
        {
            tenant: '204',
            code: '104',
            description: 'Ngeng Junction',
            subDivisionCode: '',
            createdOn: new Date(),
        },
        {
            tenant: '203',
            code: '105',
            description: 'Back Travelers',
            subDivisionCode: '',
            createdOn: new Date(),
        },
        {
            tenant: '202',
            code: '106',
            description: 'Mile 1',
            subDivisionCode: '',
            createdOn: new Date(),
        },
        {
            tenant: '201',
            code: '107',
            description: 'Ndamukong',
            subDivisionCode: '',
            createdOn: new Date(),
        },
    ]
    return (
        <>
            {
                //locations && locations.length ?
                locationss && locationss.length ? (
                    <Table<ILocation>
                        dataSource={locationss}
                        columns={locationsTableColumns}
                        size={'small'}
                        rowKey={'code'}
                        onRow={(record: ILocation) => {
                            return {
                                onClick: (e) => {
                                    handleRowClick(record)
                                },
                            }
                        }}
                    />
                ) : (
                    <NoContent title="No data for location" />
                )
            }
        </>
    )
}

export default LocationTable
