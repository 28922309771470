import { apiRequests } from '../..'
import { IRoleClaim } from '../../../models/common/role-claim.model'

const roleClaimService = {
    list: (): Promise<IRoleClaim[]> => apiRequests.get('/roleClaims'),
    getClaimsForRole: (code: string): Promise<IRoleClaim[]> =>
        apiRequests.get(`/roleMenus/${code}`),
    save: (roleMenus: IRoleClaim): Promise<IRoleClaim> =>
        apiRequests.post(`/roleMenus/`, roleMenus),
}

export default roleClaimService
