import React, { createContext, useEffect, useState } from 'react'
import { Layout, Skeleton } from 'antd'
import { MenuFoldOutlined } from '@ant-design/icons'
import './app-header-skeleton.scss'

export const HeaderContext = createContext({})

interface prop {
    handleToggleSidebar: (value: boolean) => void
}
const AppHeaderSkeleton: React.FC<prop> = ({ handleToggleSidebar }) => {
    const { Header } = Layout
    const [showMobileNavbar, setShowMobileNavbar] = useState(false)
    const [showSearch, setShowSearch] = useState(false)

    useEffect(() => {}, [showSearch, showMobileNavbar])

    return (
        <HeaderContext.Provider
            value={{
                showMobileNavbar,
                showSearch,
                setShowMobileNavbar,
                setShowSearch,
            }}
        >
            {/* mobile sidebar drawer */}
            <Header
                className="site-layout-background app-header"
                style={{ backgroundColor: '#fff' }}
            >
                <div>
                    <MenuFoldOutlined
                        onClick={() => handleToggleSidebar(true)}
                        className="sidebar__toggler"
                    />
                    <Skeleton.Button active={true} className="antd_skeleton" />
                </div>

                {/* other things */}
                <div className="antd_space">
                    {/* notifications */}
                    <Skeleton.Avatar active={true} />

                    {/* language */}
                    <Skeleton.Button active={true} />

                    {/* avatar */}
                    <Skeleton.Avatar active={true} />
                </div>
            </Header>
        </HeaderContext.Provider>
    )
}

export default AppHeaderSkeleton
