import React from 'react'
import { Table } from 'antd'
import { stationReferenceTableColumns } from './station-reference-column.component'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'
import { IStationReference } from '../../../models/common/station-reference.model'
import { useStationReference } from '../../../hooks/common/station-reference/station-reference.hook'

const StationReferenceTable: React.FC = () => {
    const { setStationReference, stationReferences } = useStationReference()
    const router = useHistory()
    // const route = use
    const handleRowClick = (stationReference: IStationReference) => {
        setStationReference(stationReference)
        router.push(
            `/configurations/station-references/${slugify(stationReference.code)}`
        )
    }
    return (
        <>
            {stationReferences && stationReferences.length ? (
                <Table<IStationReference>
                    dataSource={stationReferences}
                    columns={stationReferenceTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    onRow={(record: IStationReference) => {
                        return {
                            onClick: (e) => {
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                <NoContent title="No data for Station Reference" />
            )}
        </>
    )
}

export default StationReferenceTable
