import { Button, Modal, Space } from 'antd'
import { Form, Input, SubmitButton, FormItem } from 'formik-antd'
import { Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import { useUser } from '../../../hooks/shared/user/user.hook'
import { emptyUser } from '../../../models/shared/user.model'

interface Props {
    onCancel: () => void
    visible: boolean
    title: string
}

export const UserFormFields: React.FC<Props> = ({ onCancel, visible, title }) => {
    const { saveUser, updateMode, user } = useUser()
    const { t } = useTranslation()
    const { toastSuccess, toastError } = useToast()

    return (
        <Modal
            title={title}
            visible={visible}
            footer={null}
            maskClosable={false}
            onCancel={onCancel}
            width={'25rem'}
            className="patient-form__modal"
        >
            <Formik
                initialValues={user || emptyUser}
                // validationSchema={roomValidationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const feedback = await saveUser(values, updateMode)
                    if (feedback) {
                        toastSuccess('M0002')
                        if (updateMode === 'EDIT') {
                            //nothing doing
                        } else {
                            resetForm({
                                values: emptyUser,
                            })
                        }
                        onCancel()
                    } else {
                        toastError('M0003')
                    }
                    setSubmitting(false)
                }}
            >
                {({ handleSubmit, isSubmitting, isValid }) => (
                    <Form onFinish={handleSubmit} autoComplete="off">
                        <label>
                            <p className="mb-0">{t('common:user.form.usrName')}</p>
                            <FormItem name="userName">
                                <Input name="userName" disabled={isSubmitting} />
                            </FormItem>
                        </label>

                        <Space>
                            <SubmitButton disabled={isValid && isSubmitting}>
                                {t('common:user.form.saveBtn')}
                            </SubmitButton>

                            <Button style={{ marginLeft: '5px' }} onClick={onCancel}>
                                {t('common:user.form.cancelBtn')}
                            </Button>
                        </Space>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}
