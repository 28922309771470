import { Select, Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, SubmitButton, ResetButton, DatePicker, Input } from 'formik-antd'
import moment from 'moment'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useRunnerPeriod } from '../../../hooks/budgeting/runner-period.hook'
import { useRunner } from '../../../hooks/budgeting/runner.hook'
import { IRunner } from '../../../models/budgeting/runner.model'
import {
    FlexedDiv,
    FormLabels,
    SizedLabel,
    width100,
} from '../../profile/profile.styles'

const FormItem = Form.Item
const RunnerFormFields: React.FC = () => {
    const { t } = useTranslation()
    const { runnerPeriods } = useRunnerPeriod()
    const { runners } = useRunner()
    const { setFieldValue, values, isSubmitting } = useFormikContext<IRunner>()

    const getActiveRunnerPeriods = () => {
        const closedRunnerCodes = runners.map((item) => item.code.slice(2))
        return runnerPeriods.filter((item) => !closedRunnerCodes.includes(item.code))
    }
    getActiveRunnerPeriods()
    const handleSelectRunnerPeriod = useCallback(
        (_, option: any) => {
            const selectedRunnerPeriod = runnerPeriods.find(
                (runnerPeriod) => runnerPeriod.code === option.key
            )
            //console.log("selectedRunnerPeriod: ", selectedRunnerPeriod)
            //set form fields: setFieldValue
            if (selectedRunnerPeriod) {
                setFieldValue('code', selectedRunnerPeriod.code)
                setFieldValue('description', selectedRunnerPeriod.description)
                setFieldValue('startDate', new Date(selectedRunnerPeriod.startDate!))
                setFieldValue('endDate', new Date(selectedRunnerPeriod.endDate!))
                // new fields
                setFieldValue("milestoneProjection", selectedRunnerPeriod.milestoneProjection);
                setFieldValue("week", selectedRunnerPeriod.week);
                setFieldValue("month", selectedRunnerPeriod.month);
                setFieldValue("yearWeek", selectedRunnerPeriod.yearWeek);
                setFieldValue("copYear", selectedRunnerPeriod.copYear);
                setFieldValue("component", selectedRunnerPeriod.component);
                setFieldValue("status", selectedRunnerPeriod.status);
                setFieldValue("componentWeek", selectedRunnerPeriod.componentWeek);
            }
            console.log('selected runner period: ', selectedRunnerPeriod)
            // console.log(fields[0].startDate, fields[0].endDate)
        },
        [runnerPeriods, setFieldValue]
    )
    return (
        <>
            <Form autoComplete="off">
                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:runner.form.description')}
                        </FormLabels>
                        <FormItem
                            name="none"
                            style={{ marginBottom: '-1rem' }}
                            required={true}
                        >
                            <Select
                                style={width100}
                                disabled={isSubmitting}
                                onChange={handleSelectRunnerPeriod}
                                placeholder="Select a Runner Period"
                            >
                                {runnerPeriods && runnerPeriods.length > 0
                                    ? runnerPeriods.map(
                                          (runnerPeriod) => (
                                              <Select.Option
                                                  value={runnerPeriod.description}
                                                  key={runnerPeriod.code}
                                              >
                                                  {runnerPeriod.description}
                                              </Select.Option>
                                          )
                                      )
                                    : []}
                            </Select>
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormItem name="description" style={{ marginBottom: 5 }}>
                            <Input
                                name="description"
                                style={width100}
                                disabled={true}
                                type="hidden"
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:runner.form.startDate')}
                        </FormLabels>
                        <FormItem name="startDate" style={{ marginBottom: 5 }}>
                            <DatePicker
                                name={t('budgeting:runner.form.startDate')}
                                style={width100}
                                disabled={true}
                                value={moment(
                                    new Date(values.startDate),
                                    'DD/MM/YYYY'
                                )}
                                format={'DD/MM/YYYY'}
                            />
                        </FormItem>
                    </SizedLabel>

                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:runner.form.endDate')}
                        </FormLabels>
                        <FormItem name="endDate" style={{ marginBottom: 5 }}>
                            <DatePicker
                                name={t('budgeting:runner.form.endDate')}
                                style={width100}
                                disabled={true}
                                value={moment(
                                    new Date(values.endDate),
                                    'DD/MM/YYYY'
                                )}
                                format={'DD/MM/YYYY'}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <Space size={'small'} style={{ marginTop: 10 }}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('budgeting:runner.form.submit')}
                    </SubmitButton>
                    <ResetButton disabled={isSubmitting}>
                        {t('budgeting:runner.form.reset')}
                    </ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default RunnerFormFields
