import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as regionModel from '../../models/budgeting/region.model'
import { UpdateMode } from '../../models/update-mode.enum'
import regionService from '../../services/budgeting/region.service'

export const initialState: regionModel.IRegionState = {
    regions: [],
    errors: '',
    region: regionModel.emptyRegion,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchRegionsAsync = createAsyncThunk<regionModel.IRegion[], void>(
    'region/fetchregionsAsync',
    async (_, thunkApi) => {
        try {
            return await regionService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        fetchRegionsRequest: (state) => {
            state.isLoading = true
        },
        fetchRegionsSuccess: (
            state,
            action: PayloadAction<regionModel.IRegion[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.regions = action.payload
        },
        fetchRegionsError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editRegionSuccess: (state, action: PayloadAction<regionModel.IRegion>) => {
            state.regions = state.regions.map((region) => {
                return region.code === action.payload.code ? action.payload : region
            })
            state.updateMode = UpdateMode.NONE
        },
        addRegionSuccess: (state, action: PayloadAction<regionModel.IRegion>) => {
            state.regions = [...state.regions, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveRegion: (state, action: PayloadAction<regionModel.IRegion>) => {
            state.region = action.payload
        },
        setRegionUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRegionsAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchRegionsAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.regions = action.payload
        })
        builder.addCase(fetchRegionsAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchRegionsRequest,
    fetchRegionsSuccess,
    fetchRegionsError,
    editRegionSuccess,
    addRegionSuccess,
    setActiveRegion,
    setRegionUpdateMode,
} = regionSlice.actions

const reducer = regionSlice.reducer

export { reducer as regionReducer }
