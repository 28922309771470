import { PlusOutlined } from '@ant-design/icons'
import { Col, Row, Tabs } from 'antd'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import { UserTable } from '../../../components/common/user/user-table.component'
import { NoContent } from '../../../components/shared/no-content/no-content.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useUser } from '../../../hooks/shared/user/user.hook'
import UserForm from '../../../components/common/user/user-form.component'
import { UpdateMode } from '../../../models/update-mode.enum'
import SearchInput from '../../../components/shared/search-input/search-input.component'
import { useTablePagination } from '../../../hooks/shared/pagination/pagination.hook'
import { IUser } from '../../../models/shared/user.model'
import search from '../../../utils/search'

const { TabPane } = Tabs

const UserPage: React.FC = () => {
    const { users, setUpdateMode, isSearching, loadUsersSearch } = useUser()
    const { t } = useTranslation()
    const { setContent, setShow, setTitle } = useContext<any>(AppModalContext)
    const { searchQuery, setSearchQuery } = useTablePagination()
    const [query, setQuery] = useState<string>(searchQuery)

    const resultUsers: IUser[] = users.filter((client) =>
        search(client, ['code', 'usrName', 'email'], query, false)
    )

    const handleVisibility = () => {
        setUpdateMode(UpdateMode.ADD)
        setTitle(t('common:user.userForm.title'))
        setContent(<UserForm />)
        setShow(true)
    }

    const handleChangeSearchQuery = (searchQuery: string) => {
        setQuery(searchQuery)
    }

    const handleSearch = (searchQuery: string) => {
        loadUsersSearch(searchQuery)
        setSearchQuery(searchQuery)
    }

    return (
        <>
            <PageBreadCrumbs items={[t('common:user.form.title')]} />
            {users && users.length > 0 ? (
                <>
                    <TitleBar
                        title={t('common:user.form.title')}
                        showButton={true}
                        buttonLabel={t('common:user.form.titleAdd')}
                        handleShow={handleVisibility}
                        icon={<PlusOutlined />}
                    />
                    <Row>
                        <Col md={24}>
                            <SearchInput
                                isSearching={isSearching}
                                handleChangeSearchQuery={(query) =>
                                    handleChangeSearchQuery(query)
                                }
                                handleSearch={(query) => handleSearch(query)}
                                debounceDelay={500}
                                placeHolder={t('search')}
                            />
                        </Col>
                    </Row>
                    {/* <Tabs defaultActiveKey="1" type="line" size="small">
                        <TabPane tab="Users" key="1">
                            <UserTable />
                        </TabPane>
                    </Tabs> */}

                    <UserTable
                        resultUsers={
                            resultUsers && resultUsers.length > 0
                                ? resultUsers
                                : users
                        }
                    />
                </>
            ) : (
                <>
                    <NoContent
                        title="No Data Here"
                        showButton
                        handleClick={handleVisibility}
                    />
                </>
            )}
        </>
    )
}

export default UserPage
