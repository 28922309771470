import { Menu } from 'antd'
import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import SidebarSkeleton from './sidebar.skeleton'
import { ISideBarLink, sidebarData } from './sidebar.data'
import { useInfoBar } from '../../hooks/common/info-bar/info-bar.hook'
import { useTranslation } from 'react-i18next'
import './sidebar.scss'
import type { MenuProps } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useCurrentUser } from '../../hooks/common/current-user/current-user.hook'

interface Iprops {
    collapsed: boolean
}

type MenuItem = Required<MenuProps>['items'][number]

type Item = {
    label: React.ReactNode
    key: React.Key
    icon?: React.ReactNode
    children?: MenuItem[]
}

function getItem(menuItem: Item): MenuItem {
    if (menuItem.children && menuItem.children.length > 0) {
        return {
            label: menuItem.label,
            key: menuItem.key,
            icon: menuItem.icon,
            children: menuItem.children.map((t) => getItem(t as any)),
        }
    }

    const newObj = {
        label: menuItem.label,
        key: menuItem.key,
        icon: menuItem.icon,
    }
    return {
        ...newObj,
    }
}

const Sidebar: FC<Iprops> = ({ collapsed }) => {
    const { t } = useTranslation()
    const { isLoading } = useInfoBar()
    const { userMenus } = useCurrentUser()
    const router = useHistory()

    const parentRouteCodes = userMenus
        .filter((item) => item.menCode.length === 1)
        .map((item) => item.menCode)

    parentRouteCodes.push(sidebarData.at(0)!.code as string) // Attach home route as default route to all routes

    const dropdownMenuCodes = userMenus
        .filter((item) => item.menCode.length > 1)
        .map((item) => item.menCode)

    dropdownMenuCodes.forEach((code) => {
        if (!parentRouteCodes.includes(code[0])) {
            parentRouteCodes.push(code[0])
        }
    })

    const parentRoutes = sidebarData.filter((route) =>
        parentRouteCodes.includes(route.code)
    )

    parentRoutes.map((route) => {
        if (route.dropDown && route.dropDownMenus.length > 0) {
            const filteredDropdowns = route.dropDownMenus.filter((dropdown) =>
                dropdownMenuCodes.includes(dropdown.code)
            )
            if (filteredDropdowns.length > 0) {
                route.dropDownMenus = filteredDropdowns
            }
        }
        return route
    })

    const userRoutes = parentRoutes.filter(
        (route) =>
            route.dropDown === false ||
            (route.dropDown && route.dropDownMenus.length > 0)
    )

    const translateSidebar = (menuLinks: ISideBarLink[]) => {
        menuLinks.map((menu) => {
            if (menu.dropDown && menu.dropDownMenus.length > 0) {
                menu.description = t(`common:${menu.code}`)
                return translateSidebar(menu.dropDownMenus)
            }

            menu.description = t(`common:${menu.code}`)
            return menu
        })
    }

    // translateSidebar(sidebarData)
    translateSidebar(userRoutes)

    const [current, setCurrent] = useState('H01')

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key)
        router.push(e.key)
    }

    const objMap: MenuItem[] = userRoutes.map((bar) => {
        if (bar.dropDownMenus && bar.dropDownMenus.length > 0) {
            return {
                label: bar.description,
                key: bar.route,
                icon: bar.icon,
                children: bar.dropDownMenus.map((b) => ({
                    label: b.description,
                    key: b.route,
                    icon: b.icon,
                })),
            }
        }
        return {
            label: bar.description,
            key: bar.route,
            icon: bar.icon,
        }
    })

    const items = objMap.map((bar) => {
        return getItem(bar as any) as ItemType
    })

    return (
        <>
            {isLoading ? (
                <SidebarSkeleton active={true} collapsed={collapsed} />
            ) : (
                <Menu
                    onClick={onClick}
                    mode="inline"
                    defaultOpenKeys={[current]}
                    defaultSelectedKeys={[current]}
                    items={items}
                    style={{
                        width: `${collapsed ? '58px' : '256px'}`,
                        height: '100%',
                        position: 'fixed',
                        top: 0,
                        overflowY: 'auto',
                    }}
                />
            )}
        </>
    )
}

export default Sidebar
