import { Tabs } from 'antd'
import React from 'react'
import UserDetailTabContent from './tabs/user-detail-tab-content.component'

const { TabPane } = Tabs

const UserDetailTabs: React.FunctionComponent = () => {
    const callback = (key: string) => {}

    return (
        <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Details" key="1">
                <UserDetailTabContent />
            </TabPane>
            <TabPane tab="Tab 2" key="2">
                Content of Tab Pane 2
            </TabPane>
            <TabPane tab="Tab 3" key="3">
                Content of Tab Pane 3
            </TabPane>
        </Tabs>
    )
}

export default UserDetailTabs
