export interface IBudgetBase {
    tenant: string
    copYear: string
    project: string
    region: string
    activity: string
    component: string
    amount: number
    createdOn: Date
}

export const emptyBudgetBase: IBudgetBase = {
    tenant: '',
    copYear: '',
    project: '',
    region: '',
    activity: '',
    component: '',
    amount: 0,
    createdOn: new Date(),
}
