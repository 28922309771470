import { apiRequests } from '../..'
import {
    IBranchStation,
    IBranchStationResponse,
} from '../../../models/common/branch-station.model'

const branchStationService = {
    list: (): Promise<IBranchStation[]> => apiRequests.get('/branchStations'),
    details: (code: string): Promise<IBranchStation> =>
        apiRequests.get(`/branchStations/${code}`),
    create: (branchStation: IBranchStation): Promise<IBranchStationResponse> =>
        apiRequests.post('/branchStations', branchStation),
    update: (branchStation: IBranchStation): Promise<IBranchStationResponse> =>
        apiRequests.put('/branchStations', branchStation),
}

export default branchStationService
