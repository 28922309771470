import React from 'react'
import { useBranch } from '../../../hooks/common/branch.hook'
import { IBranchStation } from '../../../models/common/branch-station.model'

interface IProp {
    branchStation: IBranchStation
}
const BranchNameRenderer: React.FC<IProp> = ({ branchStation }) => {
    const { branches } = useBranch()
    const branchName =
        branches.find((b) => b.code === branchStation.branch)?.branchName ||
        'No Match'
    return <div>{branchName}</div>
}

export default BranchNameRenderer
