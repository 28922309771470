import React, { useContext } from 'react'
import { FiEdit } from 'react-icons/fi'
import BackButton from '../../../components/common/back-button/back-button.component'
import SubDivisionDetailComponent from '../../../components/common/sub-division/sub-division-detail.component'
import SubDivisionForm from '../../../components/common/sub-division/sub-division-form.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { UpdateMode } from '../../../models/update-mode.enum'

const SubDivisionDetailsPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)

    const handleShow = () => {
        setTitle('Edit Sub Division')
        setContent(<SubDivisionForm updateMode={UpdateMode.EDIT} />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'SubDivisions', 'Details']} />
            <TitleBar
                title="Sub Division"
                subTitle="View and manage sub divisions"
                showButton
                handleShow={handleShow}
                buttonLabel="Edit Sub Division"
                icon={<FiEdit />}
            />

            <BackButton page="SubDivision" />

            {/* details */}
            <SubDivisionDetailComponent />
        </>
    )
}

export default SubDivisionDetailsPage
