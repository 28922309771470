import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as weekModel from '../../models/budgeting/week.model'
import weekService from '../../services/budgeting/week.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: weekModel.IWeekState = {
    weeks: [],
    errors: '',
    week: weekModel.emptyWeek,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchWeeksAsync = createAsyncThunk<weekModel.IWeek[], string>(
    'week/fetchweekAsync',
    async (copYear: string, thunkApi) => {
        try {
            return await weekService.list(copYear)
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const weekSlice = createSlice({
    name: 'week',
    initialState,
    reducers: {
        fetchweekRequest: (state) => {
            state.isLoading = true
        },
        fetchweekSuccess: (state, action: PayloadAction<weekModel.IWeek[]>) => {
            state.isLoading = false
            state.initialFetch = false
            state.weeks = action.payload
        },
        fetchweekError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWeeksAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchWeeksAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.weeks = action.payload
        })
        builder.addCase(fetchWeeksAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const { fetchweekRequest, fetchweekSuccess, fetchweekError } =
    weekSlice.actions

const reducer = weekSlice.reducer

export { reducer as weekReducer }
