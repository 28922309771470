import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as copYearOutlayModel from '../../models/budgeting/cop-year-outlay.model'
import copYearOutlayService from '../../services/budgeting/cop-year-outlay.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: copYearOutlayModel.ICopYearOutlayState = {
    copYearOutlays: [],
    errors: '',
    copYearOutlay: copYearOutlayModel.emptyCopYearOutlay,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchCopYearOutlayAsync = createAsyncThunk<
    copYearOutlayModel.ICopYearOutlay[],
    void
>('copYearOutlay/fetchCopYearOutlayAsync', async (_, thunkApi) => {
    try {
        return await copYearOutlayService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const copYearOutlaySlice = createSlice({
    name: 'copYearOutlay',
    initialState,
    reducers: {
        fetchCopYearOutlayRequest: (state) => {
            state.isLoading = true
        },
        fetchCopYearOutlaySuccess: (
            state,
            action: PayloadAction<copYearOutlayModel.ICopYearOutlay[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.copYearOutlays = action.payload
        },
        fetchCopYearOutlayError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editCopYearOutlaySuccess: (
            state,
            action: PayloadAction<copYearOutlayModel.ICopYearOutlay>
        ) => {
            state.copYearOutlays = state.copYearOutlays.map((copYearOutlay) => {
                return copYearOutlay.copYear === action.payload.copYear
                    ? action.payload
                    : copYearOutlay
            })
            state.updateMode = UpdateMode.NONE
        },
        addCopYearOutlaySuccess: (
            state,
            action: PayloadAction<copYearOutlayModel.ICopYearOutlay>
        ) => {
            state.copYearOutlays = [...state.copYearOutlays, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveCopYearOutlay: (
            state,
            action: PayloadAction<copYearOutlayModel.ICopYearOutlay>
        ) => {
            state.copYearOutlay = action.payload
        },
        setCopYearOutlayUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCopYearOutlayAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchCopYearOutlayAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.copYearOutlays = action.payload
        })
        builder.addCase(fetchCopYearOutlayAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchCopYearOutlayRequest,
    fetchCopYearOutlaySuccess,
    fetchCopYearOutlayError,
    editCopYearOutlaySuccess,
    addCopYearOutlaySuccess,
    setActiveCopYearOutlay,
    setCopYearOutlayUpdateMode,
} = copYearOutlaySlice.actions

const reducer = copYearOutlaySlice.reducer

export { reducer as copYearOutlayReducer }
