import React, { useEffect } from 'react'
import { FiArchive, FiX } from 'react-icons/fi'
import RunnerDetailComponent from '../../../components/budgeting/runner/runner-detail.component'
import BackButton from '../../../components/common/back-button/back-button.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { UpdateMode } from '../../../models/update-mode.enum'
import { useTranslation } from 'react-i18next'
import { Button, message, Popconfirm, Space } from 'antd'
import { useRunner } from '../../../hooks/budgeting/runner.hook'
import { RUNNER_STATUS } from '../../../constants/configurations.constant'
import { BiRefresh } from 'react-icons/bi'

const RunnerDetailsPage: React.FC = () => {
    const { t } = useTranslation()
    const { runner, saveRunner, setRunner } = useRunner()
    let runnerActionButton: React.ReactNode

    const closeRunner = async () => {
        const updatedRunner = { ...runner, status: RUNNER_STATUS.CLOSED }
        const feedback = await saveRunner(updatedRunner, UpdateMode.EDIT)
        if (feedback) {
            message.success('Runner closed successfully')
            setRunner(updatedRunner)
        } else {
            message.error('Error: could not close runner')
        }
    }

    const reopenRunner = async () => {
        const updatedRunner = { ...runner, status: RUNNER_STATUS.REOPENED }
        const feedback = await saveRunner(updatedRunner, UpdateMode.EDIT)
        if (feedback) {
            message.success('Runner reponed successfully')
            setRunner(updatedRunner)
        } else {
            message.error('Error: could not reopen runner')
        }
    }

    const handleArchiveRunner = async () => {
        const updatedRunner = { ...runner, status: RUNNER_STATUS.ARCHIVED }
        const feedback = await saveRunner(updatedRunner, UpdateMode.EDIT)
        if (feedback) {
            message.success('Runner archived successfully')
            setRunner(updatedRunner)
        } else {
            message.error('Error: could not archive runner')
        }
    }

    if (
        runner.status === RUNNER_STATUS.OPEN ||
        runner.status === RUNNER_STATUS.REOPENED
    ) {
        runnerActionButton = (
            <Popconfirm
                placement="topLeft"
                title="Are you sure you want to close this runner?"
                onConfirm={closeRunner}
                okText="Yes"
                cancelText="No"
            >
                <Button
                    type="primary"
                    className="custom__button"
                    danger
                    icon={<FiX style={{ marginRight: '4px' }} />}
                >
                    <span>Close runner</span>
                </Button>
            </Popconfirm>
        )
    }
    if (runner.status === RUNNER_STATUS.CLOSED) {
        runnerActionButton = (
            <Space>
                <Popconfirm
                    placement="topLeft"
                    title="Are you sure you want to reopen this runner?"
                    onConfirm={reopenRunner}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button className="custom__button">
                        <BiRefresh style={{ marginRight: '4px' }} />
                        <span>Reopen runner</span>
                    </Button>
                </Popconfirm>
                <Popconfirm
                    placement="topLeft"
                    title="Are you sure you want to archive this runner? This action cannot be reversed"
                    onConfirm={handleArchiveRunner}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="primary"
                        style={{
                            background: '#fec601',
                            borderColor: '#fec601',
                            color: '#181818',
                        }}
                    >
                        <FiArchive style={{ marginRight: '4px' }} /> Archive runner
                    </Button>
                </Popconfirm>
            </Space>
        )
    }

    return (
        <>
            <PageBreadCrumbs items={['Runner s', 'Details']} />
            <TitleBar
                title={t('budgeting:runner.detailPage.title')}
                subTitle={t('budgeting:runner.detailPage.subTitle')}
                showExtra
                extra={runnerActionButton}
            />

            <BackButton page="Runner" />

            {/* details */}
            <RunnerDetailComponent />
        </>
    )
}

export default RunnerDetailsPage
