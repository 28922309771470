import { Card, Col, List, Row, Tag, Typography } from 'antd'
import React from 'react'
import { FiArchive, FiCheckCircle, FiRefreshCw, FiXCircle } from 'react-icons/fi'
import { RUNNER_STATUS } from '../../../constants/configurations.constant'
import { useRunner } from '../../../hooks/budgeting/runner.hook'
import { format } from '../../../utils/format'

const RunnerDetailComponent: React.FC = () => {
    const { runner } = useRunner()
    let runnerStatus: React.ReactNode

    if (runner.status === RUNNER_STATUS.OPEN) {
        runnerStatus = (
            <Tag
                color="success"
                icon={<FiCheckCircle style={{ marginRight: '4px' }} />}
            >
                Open
            </Tag>
        )
    }
    if (runner.status === RUNNER_STATUS.CLOSED) {
        // if (true) {
        runnerStatus = (
            <Tag color="red" icon={<FiXCircle style={{ marginRight: '4px' }} />}>
                Closed
            </Tag>
        )
    }

    if (runner.status === RUNNER_STATUS.ARCHIVED) {
        runnerStatus = (
            <Tag color="orange" icon={<FiArchive style={{ marginRight: '4px' }} />}>
                Archived
            </Tag>
        )
    }
    if (runner.status === RUNNER_STATUS.REOPENED) {
        runnerStatus = (
            <Tag color="lime" icon={<FiRefreshCw style={{ marginRight: '4px' }} />}>
                Reopened
            </Tag>
        )
    }

    return (
        <Card bordered={false} size="small" bodyStyle={{ padding: 0 }}>
            <List
                size="small"
                dataSource={[
                    {
                        label: 'Code',
                        value: runner.code,
                    },
                    {
                        label: 'Description',
                        value: runner.description,
                    },
                    {
                        label: 'Start date',
                        value: format.date(runner.startDate),
                    },
                    {
                        label: 'End date',
                        value: format.date(runner.endDate),
                    },
                    {
                        label: 'Status',
                        value: runnerStatus,
                    },
                ]}
                renderItem={(item) => (
                    <List.Item>
                        <Row style={{ width: '100%' }}>
                            <Col md={4}>
                                <Typography.Text>{item.label}</Typography.Text>
                            </Col>
                            <Col md={20}>
                                <Typography.Text>{item.value}</Typography.Text>
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />
        </Card>
    )
}

export default RunnerDetailComponent
