import React, { useCallback, useState } from 'react'
import { Card, Transfer } from 'antd'
import { Formik } from 'formik'
import { Form, FormItem, Select, SubmitButton } from 'formik-antd'
import { useRole } from '../../../hooks/common/role/role.hook'
import { useUserRole } from '../../../hooks/common/user-role/user-role.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import { useUser } from '../../../hooks/shared/user/user.hook'
import { IUser } from '../../../models/shared/user.model'
import './role-management.scss'
import { useTranslation } from 'react-i18next'
import { UpdateMode } from '../../../models/update-mode.enum'
import { IUserRoleDto } from '../../../models/common/user-role.model'

const { Option } = Select

const UserRoleTab: React.FunctionComponent = () => {
    const { users } = useUser()
    const { roles } = useRole()
    const { t } = useTranslation()
    const { userRoles, addUserRole } = useUserRole()
    const { toastSuccess, toastError } = useToast()
    const { currentBranch } = useCurrentBranch()
    const [targetKeys, setTargetKeys] = useState<string[]>([])
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])
    const [selectedUser, setSelectedUser] = useState('')

    const onChange = (targetKeys: string[]) => setTargetKeys(targetKeys)

    const onSelectChange = (
        sourceSelectedKeys: string[],
        targetSelectedKeys: string[]
    ) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
    }

    const getUserRoles = (userItem: IUser) => {
        const matches = userRoles.filter((ur) => ur.usrCode === userItem.code)
        return matches.map((m) => m.roleCode)
    }

    const handleChange = useCallback(
        (userCode: string) => {
            setSelectedUser(userCode)
            const match: IUser = users.find((c) => c.code === userCode) as IUser
            setTargetKeys(getUserRoles(match))
        },
        [users, userRoles]
    )

    const onSearch = useCallback((input: string, option) => {
        return option?.props.children.props.children
            .toString()
            .toLowerCase()
            .includes(input)
    }, [])

    return (
        <>
            <Formik
                onSubmit={async (values, { setSubmitting }) => {
                    const obj: IUserRoleDto = {
                        ...values,
                        user: selectedUser,
                        roles: targetKeys,
                        tenant: currentBranch.tenant,
                    }
                    console.log('role users data: ', obj)
        
                    const feedback = await addUserRole(obj)
                    // const feedback = true

                    if (feedback) {
                        toastSuccess('M0002')
                    } else {
                        toastError('M0003')
                    }
                    setSubmitting(false)
                }}
                initialValues={{}}
            >
                {({ isSubmitting, handleSubmit }) => (
                    <Form autoComplete="off" onFinish={handleSubmit}>
                        <Card size="small" title={t('common:role.RolePrv.title')}>
                            <div className="mb-lg">
                                <label
                                    style={{ marginBottom: '1rem', width: '100%' }}
                                >
                                    <p className="mb-0">
                                        {t('common:role.RolePrv.selUser')}
                                    </p>
                                    <FormItem name="user" required={true}>
                                        <Select
                                            name="user"
                                            disabled={isSubmitting}
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                onSearch(input, option)
                                            }
                                            onChange={handleChange}
                                        >
                                            {users.map((user, index) => (
                                                <Option
                                                    value={user.code}
                                                    key={index}
                                                >
                                                    <p className="mb-0">
                                                        {user.usrName}
                                                    </p>
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                </label>
                            </div>

                            <div>
                                <Transfer
                                    className="vulnurability-transfer-list"
                                    rowKey={(record) => record.code}
                                    dataSource={roles}
                                    showSearch
                                    listStyle={{
                                        height: 300,
                                    }}
                                    operations={[
                                        t('common:role.RolePrv.addBtn'),
                                        t('common:role.RolePrv.removeBtn'),
                                    ]}
                                    operationStyle={{
                                        color: '#fff',
                                        backgroundColor: '#fff',
                                    }}
                                    titles={[
                                        t('common:role.RolePrv.allRol'),
                                        t('common:role.RolePrv.selRol'),
                                    ]}
                                    targetKeys={targetKeys}
                                    selectedKeys={selectedKeys}
                                    onChange={onChange}
                                    onSelectChange={onSelectChange}
                                    render={(item) => `${item.description}`}
                                    // filterOption={onSearch}
                                />
                            </div>
                            <SubmitButton
                                loading={isSubmitting}
                                style={{ marginTop: '1rem' }}
                            >
                                {t('common:role.form.savebtn')}
                            </SubmitButton>
                        </Card>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default UserRoleTab
