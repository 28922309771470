import React, { useEffect } from 'react'
import { FiEdit } from 'react-icons/fi'
import BackButton from '../../../components/common/back-button/back-button.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useActivityHistory } from '../../../hooks/budgeting/activity-history.hook'
import ActivityHistoryDetailTable from '../../../components/budgeting/activity-history/activity-history-table.component'
import { IConfiguration, emptyConfiguration } from '../../../models/configuration.model'
import { useConfiguration } from '../../../hooks/configuration.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'

const initialHivConfig: IConfiguration = {
    ...emptyConfiguration,
    activityType: '01',
    tranCode: '01',
    project: '01',
    activeTab: '',
}
const initialLabConfig: IConfiguration = {
    ...emptyConfiguration,
    activityType: '01',
    tranCode: '01',
    project: '02',
    activeTab: '',
}

const ActivityHistoryBatchDetailsPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const {batchLines, getBatchLines} = useActivityHistory()
    const history = useHistory();
    const batchCode = history.location.pathname.split('/').reverse().at(0)
   
    const {currentBranch} =useCurrentBranch();
    const { setConfiguration } = useConfiguration()

   useEffect(() => {
    if(currentBranch.project === '01'){
        setConfiguration(initialHivConfig)
    }
    else {
        setConfiguration(initialLabConfig)
    }
        getBatchLines(batchCode)
   }, [setConfiguration])
 
    return (
        <>
            <PageBreadCrumbs items={["Regional modifications", "Batch detail"]} />

            <TitleBar
                title={t('budgeting:activity.activitySummaryDetailPage.title')}
                subTitle={t('budgeting:activity.activitySummaryDetailPage.subTitle')}
                showButton={false}
                icon={<FiEdit />}
            />
            <BackButton page="Modifications" />
            <ActivityHistoryDetailTable activities={batchLines} />
       </>
    )
}

export default ActivityHistoryBatchDetailsPage
