import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as interventionActions from '../../redux/budgeting/intervention.slice'
import { IIntervention } from '../../models/budgeting/intervention.model'
import { UpdateMode } from '../../models/update-mode.enum'
import interventionService from '../../services/budgeting/intervention.service'
import { useBudgetCode } from './budget-code.hook'

const useIntervention = () => {
    const { getBudgetCodeDescription } = useBudgetCode()
    const interventions = useSelector<rootState, IIntervention[]>(
        (state) => state.intervention.interventions
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.intervention.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.intervention.initialFetch
    )
    const intervention = useSelector<rootState, IIntervention>(
        (state) => state.intervention.intervention
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.intervention.updateMode
    )

    const dispatch = useDispatch()

    const loadInterventions = useCallback(() => {
        if (initialFetch) {
            dispatch(interventionActions.fetchinterventionAsync())
        }
    }, [dispatch, initialFetch])

    const addIntervention = async (intervention: IIntervention) => {
        return await interventionService
            .create(intervention)
            .then((interventionResponse) => {
                if (interventionResponse.success) {
                    dispatch(
                        interventionActions.addInterventionSuccess(
                            interventionResponse.data
                        )
                    )
                } else {
                    return interventionResponse
                }
                return interventionResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setIntervention = (intervention: IIntervention) => {
        dispatch(interventionActions.setActiveIntervention(intervention))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(interventionActions.setInterventionUpdateMode(updateMode))
    }

    const editIntervention = async (intervention: IIntervention) => {
        return await interventionService
            .update(intervention)
            .then((interventionResponse) => {
                dispatch(
                    interventionActions.editInterventionSuccess(
                        interventionResponse.data
                    )
                )
                setIntervention(interventionResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const getInterventionDescription = useCallback(
        (code: string) => {
            return interventions.find((item) => item.code === code)?.description
        },
        [interventions]
    )

    const getInterventionBudgetCode = useCallback(
        (interventionCode: string) => {
            const budgetCode = interventions.find(
                (item) => item.code === interventionCode
            )?.budgetCode

            const budgetDescription = getBudgetCodeDescription(budgetCode!)
            return budgetDescription
        },
        [getBudgetCodeDescription, interventions]
    )

    const saveIntervention = async (
        intervention: IIntervention,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addIntervention(intervention)
            : await editIntervention(intervention)
    }

    useEffect(() => {
        loadInterventions()
    }, [intervention, interventions, isLoading, initialFetch, loadInterventions])

    return {
        intervention,
        interventions,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addIntervention,
        editIntervention,
        saveIntervention,
        setIntervention,
        getInterventionBudgetCode,
        getInterventionDescription,
    }
}

export { useIntervention }
