import { Reducer } from 'redux'
import { ISideBarToggleState } from '../../../models/shared/side-bar-toggle.model'
import * as ConfigurationTypes from './side-bar-toggle.types'

export const INITIAL_STATE: ISideBarToggleState = {
    sideBarToggle: ConfigurationTypes.defaultSideBarTogle,
}

const reducer: Reducer<ISideBarToggleState> = (
    state = INITIAL_STATE,
    action: ConfigurationTypes.configurationActionsCreators
): ISideBarToggleState => {
    switch (action.type) {
        case ConfigurationTypes.configurationActionTypes.SIDEBAR_TOGGLE: {
            return {
                ...state,
                sideBarToggle: {
                    ...state.sideBarToggle,
                    isOpen: !state.sideBarToggle.isOpen,
                },
            }
        }
        default:
            return state
    }
}

export { reducer as sideBarToggleReducer }
