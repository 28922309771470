export interface ITargetBase {
    tenant: string
    outlay: string
    copYear: string
    project: string
    component: string
    region: string
    target: number
    createdOn: Date
}

export const emptyTargetBase: ITargetBase = {
    tenant: '',
    outlay: '',
    copYear: '',
    project: '',
    component: '',
    region: '',
    target: 0,
    createdOn: new Date(),
}
