import { apiRequests } from '../..'
import {
    IMaritalStatus,
    IMaritalStatusResponse,
} from '../../../models/common/marital-status.model'

const maritalStatusService = {
    list: (): Promise<IMaritalStatus[]> => apiRequests.get('/maritalStatuses'),
    details: (code: string): Promise<IMaritalStatus> =>
        apiRequests.get(`/maritalStatuses/${code}`),
    create: (maritalStatus: IMaritalStatus): Promise<IMaritalStatusResponse> =>
        apiRequests.post('/maritalStatuses', maritalStatus),
    update: (maritalStatus: IMaritalStatus): Promise<IMaritalStatusResponse> =>
        apiRequests.put('/maritalStatuses', maritalStatus),
}

export default maritalStatusService
