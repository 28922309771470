
import { CommentOutlined, WechatOutlined } from '@ant-design/icons'
import { ISideBarLink } from '../sidebar.data'
import { appRoutes } from '../../../constants/app-route'

export const faqsSubmenus: ISideBarLink[] = [
        {
            app: '01',
            code: 'K00',
            description: 'Ticket',
            route: appRoutes.FAQs.TICKET,
            dropDown: false,
            dropDownMenus: [],
            icon: <WechatOutlined />,
            parent: '',
        },

        {
            app: '01',
            code: 'K01',
            description: 'Ticket Status',
            route: appRoutes.FAQs.TICKET_STATUS,
            dropDown: false,
            dropDownMenus: [],
            icon: <CommentOutlined />,
            parent: '',
        },
    
]
