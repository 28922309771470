import React from 'react'
import { Select } from 'formik-antd'

const { Option } = Select

type Props = {
    items: any[]
    IsMulti?: boolean
    parentCallBack: any
    disabled: boolean
    required: boolean
    value: string
    width100?: React.CSSProperties
    name?: string
}

const SearchableDropDown: React.FunctionComponent<Props> = ({
    items,
    IsMulti,
    parentCallBack,
    disabled,
    required,
    value,
    width100,
    name,
}) => {
    const handleChange = (value: string) => {
        parentCallBack(value)
    }

    let options: any[] = []
    items.forEach((item) => {
        options.push(
            <Option key={item.code} value={item.code}>
                {item.description}
            </Option>
        )
    })

    return IsMulti ? (
        <>
            <Select
                disabled={disabled}
                mode="multiple"
                allowClear
                showArrow
                optionFilterProp="children"
                placeholder="Please select"
                aria-required={required}
                onChange={handleChange}
                name={name!}
                style={width100}
                defaultValue={value}
            >
                {options}
            </Select>
        </>
    ) : (
        <>
            <Select
                disabled={disabled}
                showSearch
                showArrow
                optionFilterProp="children"
                placeholder="Please select"
                aria-required={required}
                onChange={handleChange}
                name={name!}
                style={width100}
                defaultValue={value}
            >
                {options}
            </Select>
        </>
    )
}

export default SearchableDropDown
