import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { emptyChartSeries, IChartSeries } from './chart-series.model'

export interface IChart {
    categories: string[]
    series: IChartSeries[]
}

export const emptyChart: IChart = {
    categories: [],
    series: [emptyChartSeries],
}

export interface IChartState extends IBaseState {
    readonly components: IChart[]
    readonly component: IChart
}

export interface IChartResponse extends IResponseBase {
    data: IChart
}
