import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as budgetCodeModel from '../../models/budgeting/budget-code.model'
import budgetCodeService from '../../services/budgeting/budget-code.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: budgetCodeModel.IBudgetCodeState = {
    budgetCodes: [],
    errors: '',
    budgetCode: budgetCodeModel.emptyBudgetCode,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchBudgetCodeAsync = createAsyncThunk<
    budgetCodeModel.IBudgetCode[],
    void
>('budgetCode/fetchbudgetCodeAsync', async (_, thunkApi) => {
    try {
        return await budgetCodeService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const budgetCodeSlice = createSlice({
    name: 'budgetCode',
    initialState,
    reducers: {
        fetchbudgetCodeRequest: (state) => {
            state.isLoading = true
        },
        fetchbudgetCodeSuccess: (
            state,
            action: PayloadAction<budgetCodeModel.IBudgetCode[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.budgetCodes = action.payload
        },
        fetchbudgetCodeError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editBudgetCodeSuccess: (
            state,
            action: PayloadAction<budgetCodeModel.IBudgetCode>
        ) => {
            state.budgetCodes = state.budgetCodes.map((budgetCode) => {
                return budgetCode.code === action.payload.code
                    ? action.payload
                    : budgetCode
            })
            state.updateMode = UpdateMode.NONE
        },
        addBudgetCodeSuccess: (
            state,
            action: PayloadAction<budgetCodeModel.IBudgetCode>
        ) => {
            state.budgetCodes = [...state.budgetCodes, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveBudgetCode: (
            state,
            action: PayloadAction<budgetCodeModel.IBudgetCode>
        ) => {
            state.budgetCode = action.payload
        },
        setBudgetCodeUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBudgetCodeAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchBudgetCodeAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.budgetCodes = action.payload
        })
        builder.addCase(fetchBudgetCodeAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchbudgetCodeRequest,
    fetchbudgetCodeSuccess,
    fetchbudgetCodeError,
    editBudgetCodeSuccess,
    addBudgetCodeSuccess,
    setActiveBudgetCode,
    setBudgetCodeUpdateMode,
} = budgetCodeSlice.actions

const reducer = budgetCodeSlice.reducer

export { reducer as budgetCodeReducer }
