import { Button, Card, Form, Select, Space } from 'antd'
import React from 'react'
import MilestoneProjectionTable from '../../components/dashboard/milestone-projection/milestone-projection-table.component'
import PageBreadCrumbs from '../../components/shared/page-breadcrumb/page-breadcrumb.component'
import './home.page.scss'
import { BiRefresh } from 'react-icons/bi'
import { BsPrinter } from 'react-icons/bs'
import { useSite } from '../../hooks/budgeting/site.hook'
import { useTranslation } from 'react-i18next'
import { useMilestone } from '../../hooks/budgeting/milestone.hook'

const { Option } = Select

const MilestonProjectionDashboardPage: React.FC = () => {
    const [form] = Form.useForm()
    const { sites } = useSite()
    const { t } = useTranslation()
    const {
        setMilestoneSite,
        handleRefresh,
        milestoneDashboard,
        isRefreshing,
        isPreviewing,
        handlePrint,
        hasPrintData,
        printData,
    } = useMilestone()

    const handleChange = (value: string) => {
        form.setFieldValue('site', value)
        console.log(`${value}`)
        setMilestoneSite(`${value}`)
    }
    const handleSubmit = (value: { site: string }) => {
        handleRefresh()
        setMilestoneSite(value.site)
    }
    console.log('isRefreshing: ', isRefreshing)

    return (
        <div>
            <PageBreadCrumbs items={[t('common:breadCrumb.dashboard')]} />
            <Card
                size="small"
                bodyStyle={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                style={{ marginBottom: '1rem' }}
            >
                <Form
                    form={form}
                    initialValues={{ site: '' }}
                    onFinish={handleSubmit}
                    style={{ marginBottom: 0 }}
                >
                    <Form.Item
                        name="site"
                        style={{ marginBottom: 0 }}
                        rules={[
                            {
                                required: true,
                                message: 'Site is required',
                            },
                        ]}
                    >
                        <Select
                            onChange={handleChange}
                            style={{ width: '25rem' }}
                            placeholder="Select site"
                            disabled={isRefreshing}
                        >
                            {sites.map((site, index) => (
                                <Option key={index} value={site.code}>
                                    {site.description}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
                <Space style={{ width: 'auto', marginLeft: 'auto' }}>
                    <Button
                        loading={isRefreshing}
                        icon={<BiRefresh style={{ marginRight: '5px' }} />}
                        type="primary"
                        onClick={() => form.submit()}
                        disabled={isRefreshing}
                    >
                        {t('budgeting:dashboard.milestoneProjection.refreshButton')}
                    </Button>
                    <Button onClick={() => form.resetFields()}>
                        {t('budgeting:dashboard.milestoneProjection.resetSelection')}
                    </Button>
                    <Button
                        icon={<BsPrinter style={{ marginRight: '5px' }} />}
                        loading={isPreviewing}
                        disabled={isPreviewing}
                        onClick={handlePrint}
                    >
                        {t(
                            'budgeting:dashboard.milestoneProjection.printPreviewButton'
                        )}
                    </Button>
                </Space>
            </Card>
            <Card
                size="small"
                title="Milestones"
                headStyle={{ textAlign: 'center' }}
            >
                {hasPrintData ? (
                    <iframe
                        src={printData}
                        typeof="application/pdf"
                        height="800px"
                        width="100%"
                        title="Report title"
                    />
                ) : (
                    <MilestoneProjectionTable
                        milestoneDashboard={milestoneDashboard}
                        isRefreshing={isRefreshing}
                    />
                )}
            </Card>
        </div>
    )
}

export default MilestonProjectionDashboardPage
