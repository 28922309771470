export interface IRunnerPeriodComponentBase {
    tenant: string
    runnerPeriod: string
    project: string
    component: string
    createdOn: Date
}

export const emptyRunnerPeriodComponentBase: IRunnerPeriodComponentBase = {
    tenant: '',
    runnerPeriod: '',
    project: '',
    component: '',
    createdOn: new Date(),
}
