import { Reducer } from 'redux'
import { emptyActiveTab, IActiveTabState } from '../../../models/shared/active-tab'
import * as ActiveTabTypes from './active-tab.types'
import { ActiveTabSetActiveTabAction } from './active-tab.types'

export const INITIAL_STATE: IActiveTabState = {
    activeTab: emptyActiveTab,
}

const reducer: Reducer<IActiveTabState, ActiveTabSetActiveTabAction> = (
    state = INITIAL_STATE,
    action: ActiveTabSetActiveTabAction
): IActiveTabState => {
    switch (action.type) {
        case ActiveTabTypes.activeTabActionTypes.SET_ACTIVE_TAB: {
            return {
                ...state,
                activeTab: { ...state.activeTab, isActive: action.payload },
            }
        }
        default:
            return state
    }
}

export { reducer as activeTabReducer }
