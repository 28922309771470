import { appRoutes } from '../../../constants/app-route'
import { IRoute } from '../../../models/common/route.model'
import MilestoneAchievementPage from '../../../pages/budgeting/milestone-achievement/milestone-achievement.page'
import MilestoneProjectPage from '../../../pages/budgeting/milestone-projection/milestone-projection.page'
import RunnerPeriodDetailsPage from '../../../pages/budgeting/runner-period/runner-period-details.page'
import RunnerPeriodPage from '../../../pages/budgeting/runner-period/runner-period.page'
import RunnerDetailsPage from '../../../pages/budgeting/runner/runner-details.page'
import RunnerPage from '../../../pages/budgeting/runner/runner.page'
import NotFoundPage from '../../../pages/common/not-found/not-found.page'
import HomePage from '../../../pages/home/home.page'
import { activityRoutes } from './activity.route'
import { authRoutes } from './auth.route'
import { configurationRoutes } from './configuation.route'
import { dashboardRoutes } from './dashboard.route'
import { faqsRoutes } from './faqs.route'
import { laboratoryActivityRoutes } from './laboratory-activity.route'
import { manageRoutes } from './manage.route'
import { modificationRoutes } from './modification.route'
import { settingsRoutes } from './settings.route'
import { siteReportRoutes } from './site-reports.route'
import { surgeActivityRoutes } from './surge-activity.route'

export const routes: IRoute[] = [
    {
        path: appRoutes.HOME,
        private: true,
        exact: true,
        component: HomePage,
    },
    /**
     * Dashboard routes
     */
    ...dashboardRoutes,

    /**
     * Authentication routes
     */
    ...authRoutes,

    /**
     * Manage routes
     */
    ...manageRoutes,

    /**
     * Configuration routes
     */
    ...configurationRoutes,

    /**
     * Activity
     */
    ...activityRoutes,

    /**
     * Surge Routes
     */
    ...surgeActivityRoutes,

    /**
     * Laboratory Routes
     */
    ...laboratoryActivityRoutes,

    /**
     * Site report routes
     */
    ...siteReportRoutes,

    // Modification routes
    ...modificationRoutes,

    ...settingsRoutes,
 
    /**
     * Runner Period
     */
    {
        path: appRoutes.RUNNER.RUNNER_PERIOD,
        private: true,
        exact: true,
        component: RunnerPeriodPage,
    },
    {
        path: appRoutes.RUNNER.RUNNER_PERIOD_DETAILS,
        private: true,
        exact: true,
        component: RunnerPeriodDetailsPage,
    },
    {
        path: appRoutes.RUNNER.RUNNERS,
        private: true,
        exact: true,
        component: RunnerPage,
    },
    {
        path: appRoutes.RUNNER.RUNNER_DETAILS,
        private: true,
        exact: true,
        component: RunnerDetailsPage,
    },

    // Milestone
    {
        path: appRoutes.MAIN_ACTIVITY.MILESTONE_PROJECTION,
        private: true,
        exact: true,
        component: MilestoneProjectPage,
    },
    {
        path: appRoutes.MAIN_ACTIVITY.MILESTONE_ACHIEVEMENT,
        private: true,
        exact: true,
        component: MilestoneAchievementPage,
    },

    ...faqsRoutes,

    {
        // default not found route
        path: '*',
        private: false,
        exact: false,
        component: NotFoundPage,
    },

    
    
]
