import { ClockCircleOutlined } from '@ant-design/icons'
import { appRoutes } from '../../../constants/app-route'
import { ISideBarLink } from '../sidebar.data'

export const siteReportSubmenus: ISideBarLink[] = [
    {
        app: '01',
        code: 'F00',
        description: 'BA Cost Category',
        route: appRoutes.SITE_REPORT.BA_COST_CATEGORY,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'F',
    },
    {
        app: '01',
        code: 'F01',
        description: 'BA Intervention',
        route: appRoutes.SITE_REPORT.BA_INTERVENTION,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'F',
    },
    {
        app: '01',
        code: 'F02',
        description: 'BA Site',
        route: appRoutes.SITE_REPORT.BA_SITE,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'F',
    },
    {
        app: '01',
        code: 'F03',
        description: 'Budget Execution',
        route: appRoutes.SITE_REPORT.BUDGET_EXECUTION,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'F',
    },
    {
        app: '01',
        code: 'F04',
        description: 'CDV ER Template',
        route: appRoutes.SITE_REPORT.CDV_ER_TEMPLATE,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'F',
    },
    {
        app: '01',
        code: 'F05',
        description: 'CDV ACC Template',
        route: appRoutes.SITE_REPORT.CDV_ACC_TEMPLATE,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'F',
    },
    {
        app: '01',
        code: 'F06',
        description: 'Costed Work Plan',
        route: appRoutes.SITE_REPORT.COSTED_WORK_PLAN,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'F',
    },
]
