import { apiRequests } from '../'
import {
    IRegionRunnerPeriod,
    IRegionRunnerPeriodResponse,
} from '../../models/budgeting/region-runner-period.model'

const regionRunnerPeriodService = {
    list: (): Promise<IRegionRunnerPeriod[]> =>
        apiRequests.get('/regionRunnerPeriods'),
    details: (code: string): Promise<IRegionRunnerPeriod> =>
        apiRequests.get(`/regionRunnerPeriods/${code}`),
    create: (
        regionRunnerPeriod: IRegionRunnerPeriod
    ): Promise<IRegionRunnerPeriodResponse> =>
        apiRequests.post('/regionRunnerPeriods', regionRunnerPeriod),
    update: (
        regionRunnerPeriod: IRegionRunnerPeriod
    ): Promise<IRegionRunnerPeriodResponse> =>
        apiRequests.put('/regionRunnerPeriods', regionRunnerPeriod),
}

export default regionRunnerPeriodService
