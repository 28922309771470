import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as runnerArchiveActions from '../../redux/budgeting/runner-history.slice'

const useRunnerArchive = () => {
    const codes = useSelector<rootState, string[]>(
        (state) => state.runnerArchive.codes
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.runnerArchive.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.runnerArchive.initialFetch
    )

    const dispatch = useDispatch()

    const loadRunnerHistories = useCallback(() => {
        if (initialFetch) {
            dispatch(runnerArchiveActions.fetchRunnerHistoriesAsync())
        }
    }, [dispatch, initialFetch])


    useEffect(() => {
        loadRunnerHistories()
    }, [isLoading, initialFetch, loadRunnerHistories])

    return {
        runnerCodes:codes,
        isLoading,
        initialFetch,
    }
}

export { useRunnerArchive }
