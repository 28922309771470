import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import {
    emptyRunnerComponentBase,
    IRunnerComponentBase,
} from './runner-component-base.model'

export interface IRunnerComponentHistory extends IRunnerComponentBase {}

export interface IRunnerComponentHistoryState extends IBaseState {
    readonly runnerComponentHistories: IRunnerComponentHistory[]
    readonly runnerComponentHistory: IRunnerComponentHistory
}

export const emptyRunnerComponentHistory: IRunnerComponentHistory =
    emptyRunnerComponentBase

export interface IRunnerComponentHistoryResponse extends IResponseBase {
    data: IRunnerComponentHistory
}
