import { ColumnsType } from 'antd/lib/table'
import { IActivity } from '../../../models/budgeting/activity.model'
import { format } from '../../../utils/format'

export const activitySummaryTableColumns: ColumnsType<IActivity> = [
    {
        title: 'Line',
        dataIndex: 'batchLine',
        key: 'batchLine',
        filtered: true,
    },
    {
        title: 'Site',
        dataIndex: 'site',
        key: 'site',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: '6rem',
        align: 'right',
        render: (_, record) => format.number(record.amount),
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
