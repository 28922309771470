import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as dailyDataModel from '../../models/budgeting/daily-data.model'
import dailyDataService from '../../services/budgeting/daily-data.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: dailyDataModel.IDailyDataState = {
    dailyDatas: [],
    errors: '',
    dailyData: dailyDataModel.emptyDailyData,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchDailyDataAsync = createAsyncThunk<
    dailyDataModel.IDailyData[],
    void
>('dailyData/fetchDailyDataAsync', async (_, thunkApi) => {
    try {
        return await dailyDataService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const dailyDataSlice = createSlice({
    name: 'dailyData',
    initialState,
    reducers: {
        fetchDailyDataRequest: (state) => {
            state.isLoading = true
        },
        fetchDailyDataSuccess: (
            state,
            action: PayloadAction<dailyDataModel.IDailyData[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.dailyDatas = action.payload
        },
        fetchDailyDataError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        setActiveDailyData: (
            state,
            action: PayloadAction<dailyDataModel.IDailyData>
        ) => {
            state.dailyData = action.payload
        },
        setDailyDataUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDailyDataAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchDailyDataAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.dailyDatas = action.payload
        })
        builder.addCase(fetchDailyDataAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchDailyDataRequest,
    fetchDailyDataSuccess,
    fetchDailyDataError,
    setActiveDailyData,
    setDailyDataUpdateMode,
} = dailyDataSlice.actions

const reducer = dailyDataSlice.reducer

export { reducer as dailyDataReducer }
