import { apiRequests } from '../..'
import { ILocation, ILocationResponse } from '../../../models/common/location.model'

const locationService = {
    list: (): Promise<ILocation[]> => apiRequests.get('/locations'),
    details: (code: string): Promise<ILocation> =>
        apiRequests.get(`/locations/${code}`),
    create: (location: ILocation): Promise<ILocationResponse> =>
        apiRequests.post('/locations', location),
    update: (location: ILocation): Promise<ILocationResponse> =>
        apiRequests.put('/locations', location),
}

export default locationService
