export const frMilestoneAchievement = {
    breadcrumb: {
        milstoneArchivement: "Réalisation d'étapes importantes",
        list: 'Liste',
    },

    mainPage: {
        title: "Réalisation d'étape",
        subTitle: 'Afficher et gérer la réalisation des jalons',
        buttonLabel: "Ajouter une réalisation d'étape",
    },

    // detailPage: {
    //     title: 'Runner detail',
    //     subTitle: 'Make changes to runner',
    //     buttonLabel: 'Edit runner',
    // },

    form: {
        title: "Entrez la réalisation de l'étape",
        modalTitleAdd: "Ajouter une nouvelle réalisation d'étape",
        modalTitleEdit: 'Modifier la réalisation du jalon',
        tenant: 'Locataire',
        runner: 'Coureur',
        region: 'Région',
        project: 'Projet',
        activity: 'Activité',
        site: 'Placer',
        budgetNote: 'Note budgétaire',
        target: 'Cible',
        achievement: 'Réalisation',
        budget: 'Budget',
        number: 'Numero',
        createdOn: 'Créé sur',
    },
}
