import { message, Modal } from 'antd'
import { useCallback } from 'react'
import {
    emptyDialogMessage,
    IDialogMessage,
} from '../../../models/common/dialog-message.model'
import { useLanguage } from '../../shared/language/language.hook'
import { useDialogMessage } from '../dialog-message/dialog-message.hook'
interface IConfirmDialog {
    title: string
    content: string
    okText?: string
    okType?: 'danger' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text'
    cancelText?: string
    onOk: () => any
}

const useToast = () => {
    const { languageKey, getKeyByValue } = useLanguage()
    const { dialogMessages } = useDialogMessage()

    const getDialogMessage = useCallback(
        (code: string): IDialogMessage => {
            const dialogMessage = dialogMessages.find(
                (d) => d.code === code && getKeyByValue(d.lid) === languageKey
            )

            if (dialogMessage !== undefined) {
                return dialogMessage
            }
            return emptyDialogMessage
        },
        [dialogMessages, getKeyByValue, languageKey]
    )

    const { confirm } = Modal

    const toastInfo = (messageCode: string) =>
        message.info(getDialogMessage(messageCode).description)
    const toastSuccess = (messageCode: string) =>
        message.success(getDialogMessage(messageCode).description)
    const toastWarning = (messageCode: string) =>
        message.warning(getDialogMessage(messageCode).description)
    const toastError = (messageCode: string) =>
        message.error(getDialogMessage(messageCode).description)

    const confirmDialog = (params: IConfirmDialog) =>
        confirm({
            title: params.title,
            content: params.content,
            okText: params.okText || 'okay',
            cancelText: params.cancelText || 'cancel',
            onCancel() {},
            onOk() {
                params.onOk()
                // onCancel()
            },
        })

    return {
        toastInfo,
        toastSuccess,
        toastWarning,
        toastError,
        confirmDialog,
    }
}

export { useToast }
