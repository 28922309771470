import { apiRequests } from '..'
import { IWeek, IWeekResponse } from '../../models/budgeting/week.model'

const weekService = {
    list: (copYear: string): Promise<IWeek[]> =>
        apiRequests.get(`/weeks/${copYear}`),
    details: (code: string): Promise<IWeek> => apiRequests.get(`/weeks/${code}`),
    create: (week: IWeek): Promise<IWeekResponse> =>
        apiRequests.post('/weeks', week),
    update: (week: IWeek): Promise<IWeekResponse> => apiRequests.put('/weeks', week),
}

export default weekService
