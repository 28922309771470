export const months = [
    {
        abbreviation: 'Jan',
        name: 'January',
        code: '0123',
    },
    {
        abbreviation: 'Feb',
        name: 'February',
        code: '0223',
    },
    {
        abbreviation: 'Mar',
        name: 'March',
        code: '0323',
    },
    {
        abbreviation: 'Apr',
        name: 'April',
        code: '0423',
    },
    {
        abbreviation: 'May',
        name: 'May',
        code: '0523',
    },
    {
        abbreviation: 'Jun',
        name: 'June',
        code: '0623',
    },
    {
        abbreviation: 'Jul',
        name: 'July',
        code: '0723',
    },
    {
        abbreviation: 'Aug',
        name: 'August',
        code: '0823',
    },
    {
        abbreviation: 'Sep',
        name: 'September',
        code: '0923',
    },
    {
        abbreviation: 'Oct',
        name: 'October',
        code: '1023',
    },
    {
        abbreviation: 'Nov',
        name: 'November',
        code: '1123',
    },
    {
        abbreviation: 'Dec',
        name: 'December',
        code: '1223',
    },
]
