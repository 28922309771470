import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ICostCategory {
    tenant: string
    code: string
    copYear: string
    description: string
    project: string
    createdOn: Date
}

export const emptyCostCategory: ICostCategory = {
    tenant: '',
    code: '',
    copYear: '',
    description: '',
    project: '',
    createdOn: new Date(),
}
export interface ICostCategoryState extends IBaseState {
    readonly costCategories: ICostCategory[]
    readonly costCategory: ICostCategory
}

export interface ICostCategoryResponse extends IResponseBase {
    data: ICostCategory
}
