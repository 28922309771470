import React from 'react'
import { Tween } from 'react-gsap'

import CBCHealthService from './logos/cbc-health-service.png'
import MOH from './logos/moh-logo.png'
import Picture1 from './logos/picture1.png'
import Picture2 from './logos/picture2.png'
import './partner-cards.scss'

const PartnerCard = () => {
    const partners = [
        {
            title: 'PEPFAR',
            imgName: Picture1,
            msg: '',
            abbr: 'P.E.P.F.A.R',
        },
        {
            title: 'Center for Disease Control (C.D.C)',
            imgName: Picture2,
            msg: '',
            abbr: 'C.D.C',
        },
        {
            title: 'M.O.H',
            imgName: MOH,
            msg: '',
            abbr: 'M.O.H',
        },
        {
            title: 'C.B.C Health Service',
            imgName: CBCHealthService,
            msg: '',
            abbr: 'C.B.C',
        },
    ]

    return (
        <div className="partner__cards">
            <Tween
                from={{ y: '-30px', opacity: 0 }}
                delay={1}
                duration={2}
                ease="back.out(1.7)"
            >
                {partners.map((partner) => (
                    <div className="partner__cards_item" key={partner.abbr}>
                        <img src={partner.imgName} alt={partner.title} />
                    </div>
                ))}
            </Tween>
        </div>
    )
}

export default PartnerCard
