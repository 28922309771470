import { BUDGET_ANALYSIS } from '../../constants/configurations.constant'
import { useBudgetAnalysisContext } from '../../contexts/reports/report.context'
import { IBudgetAnalysisOptions } from '../../models/reports/budget-analysis-options.model'
import { budgetAnalysisService } from '../../services/reports/budget-analysis.service'

export const useBudgetAnalysis = () => {
    const { budgetAnalysisType } = useBudgetAnalysisContext()

    const getBudgetAnalysisData = async (options: IBudgetAnalysisOptions) => {
        switch (budgetAnalysisType) {
            case BUDGET_ANALYSIS.SITE:
                return await budgetAnalysisService.getBudgetAnalysisData(
                    options,
                    'site'
                )
            case BUDGET_ANALYSIS.INTERVENTION:
                return await budgetAnalysisService.getBudgetAnalysisData(
                    options,
                    'intervention'
                )
            case BUDGET_ANALYSIS.COST_CATEGORY:
                return await budgetAnalysisService.getBudgetAnalysisData(
                    options,
                    'costCategory'
                )
        }
    }

    const getBudgetAnalysisReport = async (options: IBudgetAnalysisOptions) => {
        switch (budgetAnalysisType) {
            case BUDGET_ANALYSIS.SITE:
                return await budgetAnalysisService.getBudgetAnalysisSite(options)
            case BUDGET_ANALYSIS.INTERVENTION:
                return await budgetAnalysisService.getBudgetAnalysisIntervention(
                    options
                )
            case BUDGET_ANALYSIS.COST_CATEGORY:
                return await budgetAnalysisService.getBudgetAnalysisCostCategory(
                    options
                )
        }
    }

    return {
        getBudgetAnalysisData,
        getBudgetAnalysisReport,
    }
}
