import { Card, Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useDivision } from '../../../hooks/common/division/division.hook'

const DivisionDetailComponent: React.FC = () => {
    const { division } = useDivision()
    return (
        <Card bordered={false} size="small">
            <Row>
                <Col span={4}>
                    <StyledParagraph>Code: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{division.code}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Description: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{division.description}</StyledParagraph>
                </Col>
            </Row>
        </Card>
    )
}

export default DivisionDetailComponent

const StyledParagraph = styled.p`
    margin-bottom: 0;
`
