import { Button, Form, Input, Space } from 'antd'
import React, { useContext, useState } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useTranslation } from 'react-i18next'

const UserForm: React.FC = () => {
    const { setShow } = useContext<any>(AppModalContext)
    const { t } = useTranslation()

    const [saving, setSaving] = useState(false)
    const [form] = Form.useForm()

    const onFinish = (values: any) => {
        setSaving(true)
        console.log('values: ', values)
        form.submit()
        form.resetFields()
        setSaving(false)
        setShow(false)
    }

    return (
        <>
            <Form
                name="user-form"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{}}
            >
                <Form.Item
                    requiredMark={false}
                    label={t('common:user.userForm.code')}
                    name="code"
                    rules={[{ required: true, message: 'Code is required' }]}
                >
                    <Input placeholder="Code" name="code" />
                </Form.Item>
                <Form.Item
                    requiredMark={false}
                    label={t('common:user.userForm.name')}
                    name="name"
                    rules={[{ required: true, message: 'Name is required' }]}
                >
                    <Input placeholder="Name" name="name" />
                </Form.Item>
                <Form.Item
                    requiredMark={false}
                    label={t('common:user.userForm.email')}
                    name="email"
                    rules={[{ required: true, message: 'Email is required' }]}
                >
                    <Input placeholder="Email" name="email" />
                </Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit" loading={saving}>
                        {t('common:user.form.saveBtn')}
                    </Button>
                    <Button onClick={() => setShow(false)}>
                        {t('common:user.form.cancelBtn')}
                    </Button>
                </Space>
            </Form>
        </>
    )
}

export default UserForm
