import { apiRequests } from '../..'
import { IRoleUserDto, IUserRole, IUserRoleDto, IUserRoleResponse } from '../../../models/common/user-role.model'

const userRoleService = {
	list: (): Promise<IUserRole[]> => 
		apiRequests.get('/userRoles'),
	details: (code: string): Promise<IUserRole> =>
		apiRequests.get(`/userRoles/${code}`),
	create: (userRole: IUserRoleDto): Promise<IUserRoleResponse> =>
		apiRequests.post('/userRoles', userRole),
	update: (userRole: IUserRole): Promise<IUserRoleResponse> => 
		apiRequests.put('/userRoles', userRole),
	assignGroupRole: (userRole: IRoleUserDto): Promise<IUserRole> =>
		apiRequests.put("/userRoles/assignGroupRole", userRole),
}

export default userRoleService

