import { Formik } from 'formik'
import React, { useContext } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useTicket } from '../../../hooks/common/ticket/ticket.hook'
import { emptyTicket, ITicket } from '../../../models/common/ticket.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import TicketFormFields from './ticket-form-field.component'
import { message } from 'antd'
import { useCurrentUser } from '../../../hooks/common/current-user/current-user.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'

interface IProp {
    updateMode: UpdateMode
}
const TicketForm: React.FC<IProp> = ({ updateMode }) => {
    const { saveTicket, ticket} = useTicket()
    const { setShow } = useContext<any>(AppModalContext)
    const { currentUser } = useCurrentUser()
    const { currentBranch } = useCurrentBranch()
    return (
        <div>
            <Formik<ITicket>
                initialValues={ticket || emptyTicket}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const obj: ITicket = {
                        ...emptyTicket,
                        ...values,
                        userCode: currentUser.code,
                        region: currentBranch.region,
                        code: ticket.code || '01',
                    }
                    if(updateMode === UpdateMode.ADD) {
                        obj.status = '01';
                        obj.comment = 'none';
                    }
                    console.log('ticket', obj)
                    const feedback = await saveTicket(obj, updateMode)
                    if (feedback) {
                        message.success('Ticket save succesfully')
                    } else {
                        message.error('Error saving ticket')
                    }
                    resetForm();
                    setSubmitting(false)
                    setShow(false)
                }}
                
            >
                <>
                    <TicketFormFields mode={updateMode} />
                </>
            </Formik>
        </div>
    )
}
export default TicketForm
