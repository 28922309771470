import { Alert } from 'antd'
import { Formik } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppModalContext } from '../../../../contexts/common/app-modal.context'
import { useMilestone } from '../../../../hooks/budgeting/milestone.hook'
import { useRunner } from '../../../../hooks/budgeting/runner.hook'
import { useCurrentBranch } from '../../../../hooks/common/current-branch/current-branch.hook'
import { useFormErrors } from '../../../../hooks/common/form/form-error.hook'
import { useToast } from '../../../../hooks/common/toast/toast.hook'
import { useConfiguration } from '../../../../hooks/configuration.hook'
import { IMilestone } from '../../../../models/budgeting/milestone.model'
import MilestoneFormFields from './milestone-achievement-form-fields-update.component'

const MilestoneAchievementFormUpdate: React.FC = () => {
    const { toastSuccess, toastError } = useToast()
    const { addMilestoneAchievement, site, milestone } = useMilestone()
    const { runner } = useRunner()
    const { configuration } = useConfiguration()
    const { currentBranch } = useCurrentBranch()

    const { formError } = useFormErrors()
    const [hasSubmitted, setSubmitted] = useState(false)
    const { setShow } = useContext<any>(AppModalContext)

    const onClose = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSubmitted(false)
        },
        []
    )
    useEffect(() => {}, [hasSubmitted, milestone])
    return (
        <div>
            {hasSubmitted && (
                <Alert
                    message="Error Messages"
                    description={
                        <>
                            {formError.validationErrors &&
                            formError.validationErrors.length > 0 ? (
                                formError.validationErrors.map((v, index) => (
                                    <span key={index} style={{ display: 'block' }}>
                                        {v}
                                    </span>
                                ))
                            ) : (
                                <span>
                                    {JSON.stringify(
                                        formError.validationErrors,
                                        null,
                                        2
                                    )}
                                </span>
                            )}
                        </>
                    }
                    type="error"
                    closable
                    onClose={onClose}
                    style={{ marginBottom: 10 }}
                />
            )}
            <Formik<IMilestone>
                initialValues={{
                    ...milestone,
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitted(false)
                    const obj: IMilestone = {
                        ...values,
                        runner: runner.milestoneProjection,
                        site: site,
                        project: configuration.project,
                        tenant: currentBranch.tenant,
                        region: currentBranch.region,
                    }

                    console.log('submit: ', milestone)

                    const feedback = await addMilestoneAchievement(obj)
                    if (feedback) {
                        toastSuccess('M0002')
                        setShow(false)
                        resetForm()
                    } else {
                        toastError('M0003')
                        setShow(true)
                        setSubmitted(true)
                    }
                    setSubmitting(false)
                }}
            >
                <>
                    <MilestoneFormFields />
                </>
            </Formik>
        </div>
    )
}
export default MilestoneAchievementFormUpdate
