export const enSiteReport = {
    //BA cost category
    mainPage: {
        title: 'Site budget analysis by cost category',
        generateBtn: 'Generate',
        loadReportBtn: 'Preview',
        exportToExel: 'Export to Exel',
    },
    form: {
        description: 'Description',
        copYear: 'Component year',
        code: 'Code',
        region: 'Region',
        transactionCode: 'Transaction Code',
        reportType: 'Report Type',
        submit: 'Submit',
        reset: 'Reset',
        startDate: 'Start Date',
        endDate: 'End Date',
    },

    //BA intervention report
    interventionMainPage: {
        title: 'Site Intervention Report',
    },
    budgetExecutionPage: {
        title: 'Site budget execution',
    },
}
