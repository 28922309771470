import { Table } from 'antd'
import { NoContent } from '../../shared/no-content/no-content.component'
import { outlayTargetTableColumns } from './outlay-target-table.column'
import './outlay-dashboard.styles.scss'
import { IOutlayAnalysis } from '../../../models/budgeting/outlay-analysis.model'
import ExcelExportWrapper from '../../reports/excel-export-wrapper.component'

type Props = {
    title?: string
    outlayAnalysis: IOutlayAnalysis[]
}

const OutlayVsTargetTable: React.FC<Props> = ({ outlayAnalysis }) => {
    return (
        <>
            {outlayAnalysis && outlayAnalysis.length ? (
                <ExcelExportWrapper
                    data={outlayAnalysis}
                    columns={outlayTargetTableColumns}
                    sheetName="oulay-vs-target"
                >
                    <Table<IOutlayAnalysis>
                        dataSource={outlayAnalysis}
                        columns={outlayTargetTableColumns}
                        size={'small'}
                        rowKey={'periodTarget'}
                        scroll={{ x: '500' }}
                        pagination={false}
                    />
                </ExcelExportWrapper>
            ) : (
                <NoContent title="No data for Outlay vs target" showButton={false} />
            )}
        </>
    )
}

export default OutlayVsTargetTable
