export interface IRunnerBase {
    tenant: string
    copYear: string
    code: string
    region: string
    project: string
    description: string
    startDate: Date
    endDate: Date
    status: string
    component: string
    month: string
    week: string
    componentWeek: string
    yearWeek: string
    milestoneProjection: string
    createdOn: Date
}

export const emptyRunnerBase: IRunnerBase = {
    tenant: '',
    copYear: '',
    code: '',
    region: '',
    project: '',
    description: '',
    startDate: new Date(),
    endDate: new Date(),
    status: '',
    component: '',
    month: '',
    week: '',
    componentWeek: '',
    yearWeek: '',
    milestoneProjection: '',
    createdOn: new Date(),
}
