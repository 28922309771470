import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as runnerHistoryActions from '../../redux/budgeting/runner-history.slice'
import {
    emptyRunnerHistory,
    IRunnerHistory,
} from '../../models/budgeting/runner-history.model'
import { UpdateMode } from '../../models/update-mode.enum'
import runnerHistoryService from '../../services/budgeting/runner-history.service'

const useRunnerHistory = () => {
    const runnerHistories = useSelector<rootState, IRunnerHistory[]>(
        (state) => state.runnerHistory.runnerHistories
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.runnerHistory.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.runnerHistory.initialFetch
    )
    const runnerHistory = useSelector<rootState, IRunnerHistory>(
        (state) => state.runnerHistory.runnerHistory
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.runnerHistory.updateMode
    )

    const dispatch = useDispatch()

    const loadRunnerHistories = useCallback(() => {
        if (initialFetch) {
            dispatch(runnerHistoryActions.fetchRunnerHistoriesAsync())
        }
    }, [dispatch, initialFetch])

    const addRunnerHistory = async (runnerHistory: IRunnerHistory) => {
        return await runnerHistoryService
            .create(runnerHistory)
            .then((runnerHistoryResponse) => {
                if (runnerHistoryResponse.success) {
                    dispatch(
                        runnerHistoryActions.addRunnerHistorySuccess(
                            runnerHistoryResponse.data
                        )
                    )
                } else {
                    return runnerHistoryResponse
                }
                return runnerHistoryResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setRunnerHistory = (runnerHistory: IRunnerHistory) => {
        dispatch(runnerHistoryActions.setActiveRunnerHistory(runnerHistory))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(runnerHistoryActions.setRunnerHistoryUpdateMode(updateMode))
    }

    const editRunnerHistory = async (runnerHistory: IRunnerHistory) => {
        return await runnerHistoryService
            .update(runnerHistory)
            .then((runnerHistoryResponse) => {
                dispatch(
                    runnerHistoryActions.editRunnerHistorySuccess(
                        runnerHistoryResponse.data
                    )
                )
                setRunnerHistory(runnerHistoryResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveRunnerHistory = async (
        runnerHistory: IRunnerHistory,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addRunnerHistory(runnerHistory)
            : await editRunnerHistory(runnerHistory)
    }

    const getRunnerHistory = useCallback(
        (code: string): IRunnerHistory => {
            const runnerHistory = runnerHistories.find((x) => x.code === code)

            if (runnerHistory) {
                return runnerHistory
            }

            return emptyRunnerHistory
        },
        [runnerHistories]
    )

    useEffect(() => {
        loadRunnerHistories()
    }, [
        runnerHistory,
        runnerHistories,
        isLoading,
        initialFetch,
        loadRunnerHistories,
    ])

    return {
        runnerHistory,
        runnerHistories,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addRunnerHistory,
        editRunnerHistory,
        saveRunnerHistory,
        setRunnerHistory,
        getRunnerHistory,
    }
}

export { useRunnerHistory }
