import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as budgetModel from '../../models/budgeting/budget.model'
import budgetService from '../../services/budgeting/budget.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: budgetModel.IBudgetState = {
    budgets: [],
    errors: '',
    budget: budgetModel.emptyBudget,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchbudgetAsync = createAsyncThunk<budgetModel.IBudget[], void>(
    'budget/fetchbudgetAsync',
    async (_, thunkApi) => {
        try {
            return await budgetService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const budgetSlice = createSlice({
    name: 'budget',
    initialState,
    reducers: {
        fetchbudgetRequest: (state) => {
            state.isLoading = true
        },
        fetchbudgetSuccess: (
            state,
            action: PayloadAction<budgetModel.IBudget[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.budgets = action.payload
        },
        fetchbudgetError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editBudgetSuccess: (state, action: PayloadAction<budgetModel.IBudget>) => {
            state.budgets = state.budgets.map((budget) => {
                return budget.activity === action.payload.activity
                    ? action.payload
                    : budget
            })
            state.updateMode = UpdateMode.NONE
        },
        addBudgetSuccess: (state, action: PayloadAction<budgetModel.IBudget>) => {
            state.budgets = [...state.budgets, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveBudget: (state, action: PayloadAction<budgetModel.IBudget>) => {
            state.budget = action.payload
        },
        setBudgetUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchbudgetAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchbudgetAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.budgets = action.payload
        })
        builder.addCase(fetchbudgetAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchbudgetRequest,
    fetchbudgetSuccess,
    fetchbudgetError,
    editBudgetSuccess,
    addBudgetSuccess,
    setActiveBudget,
    setBudgetUpdateMode,
} = budgetSlice.actions

const reducer = budgetSlice.reducer

export { reducer as budgetReducer }
