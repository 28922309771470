import { Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton, ResetButton } from 'formik-antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useActivityPlan } from '../../../hooks/budgeting/activity-plan.hook'
import { useMilestone } from '../../../hooks/budgeting/milestone.hook'
import { useSite } from '../../../hooks/budgeting/site.hook'
import { IMilestone } from '../../../models/budgeting/milestone.model'
import {
    FlexedDiv,
    FormLabels,
    SizedLabel,
    width100,
} from '../../profile/profile.styles'

const { TextArea } = Input
const FormItem = Form.Item

interface IProp {
    index: number
}
const MilestoneAchievementFormFields: React.FC<IProp> = ({ index }) => {
    const { isSubmitting, setValues } = useFormikContext<IMilestone>()
    const { t } = useTranslation()
    const { getActivityPlan } = useActivityPlan()
    const { site } = useMilestone()
    const { sites } = useSite()
    const { milestone } = useMilestone()

    useEffect(() => {
        setValues(milestone)
    }, [milestone])

    const siteDescription =
        sites && sites.length > 0
            ? sites.find((s) => s.code === site)?.description
            : ' '

    return (
        <>
            <Form autoComplete="off" key={index}>
                <FlexedDiv style={{ display: 'none' }}>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:milestoneProjection.form.site')}
                        </FormLabels>
                        <FormItem name="site" style={{ marginBottom: 5 }}>
                            <Input
                                name="site"
                                style={width100}
                                disabled={true}
                                value={site + ' - ' + siteDescription}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:milestoneProjection.form.activity')}
                        </FormLabels>
                        <FormItem name="activity" style={{ marginBottom: 5 }}>
                            <Input
                                name="activity"
                                style={width100}
                                disabled={true}
                                value={
                                    milestone.activity +
                                        ' - ' +
                                        getActivityPlan(milestone.activity)
                                            ?.description ?? ''
                                }
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:milestoneProjection.form.budgetNote')}
                        </FormLabels>
                        <FormItem
                            name="budgetNote"
                            required={true}
                            style={{ marginBottom: 5 }}
                        >
                            <TextArea
                                name="budgetNote"
                                disabled={true}
                                required={true}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:milestoneAchievement.form.target')}
                        </FormLabels>
                        <FormItem name="target" style={{ marginBottom: 5 }}>
                            <Input name="target" style={width100} disabled={true} />
                        </FormItem>
                    </SizedLabel>

                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:milestoneAchievement.form.achievement')}
                        </FormLabels>
                        <FormItem name="achievement" style={{ marginBottom: 5 }}>
                            <Input
                                name="achievement"
                                style={width100}
                                disabled={isSubmitting}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <Space size={'small'} style={{ marginTop: 10 }}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('budgeting:runner.form.submit')}
                    </SubmitButton>
                    <ResetButton disabled={isSubmitting}>
                        {t('budgeting:runner.form.reset')}
                    </ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default MilestoneAchievementFormFields
