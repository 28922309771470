import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ITicket {
    tenant: string
    code: string
    userCode: string
    userEmail: string
    userPhoneNumber: string
    region: string
    isValidated: boolean
    isConfirmed: boolean
    status: string
    description: string
    comment: string
    createdOn: Date
}

export interface ITicketState extends IBaseState {
    readonly tickets: ITicket[]
    readonly ticket: ITicket
}

export const emptyTicket: ITicket = {
    tenant: '',
    code: '',
    userCode: '',
    userEmail: '',
    userPhoneNumber: '',
    region: '',
    isValidated: false,
    isConfirmed: false,
    status:'',
    description: '',
    comment: '',
    createdOn: new Date(),
}

export interface ITicketResponse extends IResponseBase {
    data: ITicket
}
