import { Tabs } from 'antd'
import React, { useContext, useEffect } from 'react'
import { FiActivity, FiPlus } from 'react-icons/fi'
import ActivityForm from '../../../components/budgeting/activity/activity-form.component'
import ActivityTable from '../../../components/budgeting/activity/activity-table'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useActivity } from '../../../hooks/budgeting/activity.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useConfiguration } from '../../../hooks/configuration.hook'
import { useActiveTab } from '../../../hooks/shared/active-tab/active-tab.hook'
import { IFetchActivityParams } from '../../../models/budgeting/activity.model'
import {
    emptyConfiguration,
    IConfiguration,
} from '../../../models/configuration.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import { useTranslation } from 'react-i18next'
import SpinnerComponent from '../../../components/shared/loading/spinner-component'

const { TabPane } = Tabs

const configuration: IConfiguration = {
    ...emptyConfiguration,
    activityType: '02',
    tranCode: '04',
    project: '01',
    activeTab: 'site-activities',
}

const ProgramManagementBOLaboratoryActivityPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { activeTab, setActiveTab } = useActiveTab()
    const { reloadActivities, isLoading, initialFetch, initialActivity } =
        useActivity()
    const { currentBranch } = useCurrentBranch()
    const { setConfiguration } = useConfiguration()
    const { setActivity } = useActivity()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)

    const handleOnChange = (e: any) => {
        setActiveTab(e)
        const params: IFetchActivityParams = {
            activityType: '01',
            Branch: currentBranch.code,
            region: currentBranch.region,
        }
        reloadActivities(params)
        setConfiguration(configuration)
    }

    const handleShow = () => {
        setActivity(initialActivity)
        setTitle(t('budgeting:activity.form.activityBatch'))
        setContent(<ActivityForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    useEffect(() => {
        const params: IFetchActivityParams = {
            activityType: '01',
            Branch: currentBranch.code,
            region: currentBranch.region,
        }
        reloadActivities(params)
        setConfiguration(configuration)
    }, [
        currentBranch.code,
        currentBranch.region,
        reloadActivities,
        setConfiguration,
    ])

    return (
        <>
            <PageBreadCrumbs
                items={[
                    'Program Management BO Laboratory Activity Batch Summary',
                    'List',
                ]}
            />
            <TitleBar
                title={t('budgeting:activity.mainPage.title')}
                subTitle={t('budgeting:activity.mainPage.subTitle')}
                showButton={true}
                buttonLabel={t('budgeting:activity.mainPage.buttonLabel')}
                handleShow={handleShow}
                icon={<FiPlus />}
            />

            {isLoading && initialFetch ? (
                <SpinnerComponent />
            ) : (
                <Tabs
                    defaultActiveKey={'site-activities'}
                    activeKey={activeTab.isActive}
                    onChange={handleOnChange}
                    size="small"
                    onTabClick={handleOnChange}
                >
                    {/* Site Activity */}
                    <TabPane
                        tab={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FiActivity />
                                <h6
                                    style={{ paddingLeft: '.4rem', marginBottom: 0 }}
                                >
                                    {t('budgeting:activity.tabs.siteActivity')}
                                </h6>
                            </div>
                        }
                        key="site-activities"
                    >
                        <ActivityTable showButton={true} />
                    </TabPane>

                    {/* coordination-area-activities */}
                    <TabPane
                        tab={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FiActivity />
                                <h6
                                    style={{ paddingLeft: '.4rem', marginBottom: 0 }}
                                >
                                    {t(
                                        'budgeting:activity.tabs.coordinationAreaActivity'
                                    )}
                                </h6>
                            </div>
                        }
                        key="coordination-area-activities"
                    >
                        <ActivityTable />
                    </TabPane>

                    {/* regional-activities */}
                    <TabPane
                        tab={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FiActivity />
                                <h6
                                    style={{ paddingLeft: '.4rem', marginBottom: 0 }}
                                >
                                    {t('budgeting:activity.tabs.regionalActivities')}
                                </h6>
                            </div>
                        }
                        key="regional-activities"
                    >
                        <ActivityTable />
                    </TabPane>
                </Tabs>
            )}
        </>
    )
}

export default ProgramManagementBOLaboratoryActivityPage
