import { apiRequests } from '../'
import {
    ICostCategory,
    ICostCategoryResponse,
} from '../../models/budgeting/cost-category.model'

const costCategoryService = {
    list: (): Promise<ICostCategory[]> => apiRequests.get('/costCategories'),
    details: (code: string): Promise<ICostCategory> =>
        apiRequests.get(`/costCategories/${code}`),
    create: (costcategory: ICostCategory): Promise<ICostCategoryResponse> =>
        apiRequests.post('/costCategories', costcategory),
    update: (costcategory: ICostCategory): Promise<ICostCategoryResponse> =>
        apiRequests.put('/costCategories', costcategory),
}

export default costCategoryService
