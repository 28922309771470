import { ColumnsType } from 'antd/lib/table'
import { RUNNER_STATUS_TYPE } from '../../../constants/configurations.constant'
import { IRunnerPeriod } from '../../../models/budgeting/runner-period.model'
import { format } from '../../../utils/format'

export const runnerPeriodTableColumns: ColumnsType<IRunnerPeriod> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (_, record) => format.date(record.startDate),
    },
    {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (_, record) => format.date(record.endDate),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => RUNNER_STATUS_TYPE[record.status],
    },
]
