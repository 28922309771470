import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as stationReferenceActions from '../../../redux/common/station-reference/station-reference-slice'
import { IStationReference } from '../../../models/common/station-reference.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import stationReferenceService from '../../../services/common/station-reference/station-reference-service'

const useStationReference = () => {
    const stationReferences = useSelector<rootState, IStationReference[]>(
        (state) => state.stationReference.stationReferences
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.stationReference.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.stationReference.initialFetch
    )
    const stationReference = useSelector<rootState, IStationReference>(
        (state) => state.stationReference.stationReference
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.stationReference.updateMode
    )

    const dispatch = useDispatch()

    const loadStationReferences = useCallback(() => {
        if (initialFetch) {
            dispatch(stationReferenceActions.fetchStationReferencesAsync())
        }
    }, [dispatch, initialFetch])

    const addStationReference = async (stationReference: IStationReference) => {
        return await stationReferenceService
            .create(stationReference)
            .then((stationReferenceResponse) => {
                dispatch(
                    stationReferenceActions.addStationReferenceSuccess(
                        stationReferenceResponse.data
                    )
                )
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const setStationReference = (stationReference: IStationReference) => {
        dispatch(stationReferenceActions.setActiveStationReference(stationReference))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(stationReferenceActions.setStationReferenceUpdateMode(updateMode))
    }

    const editStationReference = async (stationReference: IStationReference) => {
        return await stationReferenceService
            .update(stationReference)
            .then((stationReferenceResponse) => {
                dispatch(
                    stationReferenceActions.editStationReferenceSuccess(
                        stationReferenceResponse.data
                    )
                )
                setStationReference(stationReferenceResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveStationReference = async (
        stationReference: IStationReference,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addStationReference(stationReference)
            : await editStationReference(stationReference)
    }

    useEffect(() => {
        loadStationReferences()
    }, [
        stationReference,
        stationReferences,
        isLoading,
        initialFetch,
        loadStationReferences,
    ])

    return {
        stationReference,
        stationReferences,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addStationReference,
        editStationReference,
        saveStationReference,
        setStationReference,
    }
}

export { useStationReference }
