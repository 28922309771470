import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IMaritalStatus {
    tenant: string
    code: string
    description: string
    createdOn: Date
}

export interface IMaritalStatusState extends IBaseState {
    readonly maritalStatuses: IMaritalStatus[]
    readonly maritalStatus: IMaritalStatus
}

export const emptyMaritalStatus: IMaritalStatus = {
    tenant: '',
    code: '',
    description: '',
    createdOn: new Date(),
}
export interface IMaritalStatusResponse extends IResponseBase {
    data: IMaritalStatus
}
