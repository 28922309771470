import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import * as milestoneActions from '../../redux/budgeting/milestone.slice'
import {
    emptyMilestone,
    IMilestone,
    IMilestoneDashboard,
} from '../../models/budgeting/milestone.model'
import { UpdateMode } from '../../models/update-mode.enum'
import milestoneService from '../../services/budgeting/milestone.service'
import { useFormErrors } from '../common/form/form-error.hook'
import { useActivityPlan } from './activity-plan.hook'
import { useRunner } from './runner.hook'
import { format } from '../../utils/format'
import { useCurrentBranch } from '../common/current-branch/current-branch.hook'

const useMilestone = () => {
    const milestones = useSelector<rootState, IMilestone[]>(
        (state) => state.milestone.milestones
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.milestone.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.milestone.initialFetch
    )
    const milestone = useSelector<rootState, IMilestone>(
        (state) => state.milestone.milestone
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.milestone.updateMode
    )
    const site = useSelector<rootState, string>((state) => state.milestone.site)
    const { runner } = useRunner()

    const { setformError } = useFormErrors()
    const { activityPlans } = useActivityPlan()
    const { currentBranch } = useCurrentBranch()

    const dispatch = useDispatch()

    const loadMilestones = useCallback(() => {
        if (initialFetch) {
            dispatch(milestoneActions.fetchmilestoneAsync())
        }
    }, [dispatch, initialFetch])

    const addMilestoneProjection = async (milestone: IMilestone) => {
        return await milestoneService
            .createProjection(milestone)
            .then((milestoneResponse) => {
                dispatch(
                    milestoneActions.updateMilestoneProjectionSuccess(
                        milestoneResponse.data
                    )
                )

                return true
            })
            .catch((error) => {
                setformError({
                    message: 'Failed',
                    success: false,
                    validationErrors: error,
                })
                return false
            })
    }

    const addMilestoneAchievement = async (milestone: IMilestone) => {
        return await milestoneService
            .createAchievement(milestone)
            .then((milestoneResponse) => {
                dispatch(
                    milestoneActions.updateMilestoneAchievementSuccess(
                        milestoneResponse.data
                    )
                )

                return true
            })
            .catch((error) => {
                setformError({
                    message: 'Failed',
                    success: false,
                    validationErrors: error,
                })
                return false
            })
    }

    const setMilestone = (milestone: IMilestone) => {
        dispatch(milestoneActions.setActiveMilestone(milestone))
    }
    const setMilestoneSite = (site: string) => {
        dispatch(milestoneActions.setMilestoneSite(site))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(milestoneActions.setMilestoneUpdateMode(updateMode))
    }

    const getMilestoneProjections = useCallback(
        (site: string): IMilestone[] => {
            return activityPlans.map((a) => {
                const planMilestone = milestones
                    .filter((x) => x.runner === runner.code)
                    .find((m) => m.activity === a.code && m.site === site)
                const milestone: IMilestone = {
                    ...emptyMilestone,
                    activity: a.code,
                    runner: planMilestone?.runner ?? '',
                    region: planMilestone?.region ?? '',
                    project: planMilestone?.project ?? '',
                    site: site,
                    budgetNote: planMilestone?.budgetNote ?? '',
                    target: planMilestone?.target ?? 0,
                    achievement: planMilestone?.achievement ?? 0,
                    budget: planMilestone?.budget ?? 0,
                }
                return milestone
            })
        },
        [activityPlans, milestones, runner.code]
    )

    const getMilestoneAchievements = useCallback(
        (site: string): IMilestone[] => {
            return milestones.filter(
                (x) => x.runner === runner.milestoneProjection && x.site === site
            )
        },
        [milestones, runner.milestoneProjection]
    )

    const getTotalProjectionAmount = useCallback(
        (site: string) => {
            return format.number(
                getMilestoneProjections(site)
                    .map((journal) => journal.budget)
                    .reduce((a, b) => a + b, 0)
            )
        },
        [getMilestoneProjections]
    )

    const getTotalAchievementAmount = useCallback(
        (site: string) => {
            return format.number(
                getMilestoneProjections(site)
                    .map((journal) => journal.budget)
                    .reduce((a, b) => a + b, 0)
            )
        },
        [getMilestoneProjections]
    )

    const [isRefreshing, setRefreshing] = useState(false)
    const [isPreviewing, setPreview] = useState(false)

    const [hasPrintData, setHasPrintData] = useState(false)
    const [printData, setPrintData] = useState<string>('')
    const [milestoneDashboard, setMilestoneDashboard] = useState<
        IMilestoneDashboard[]
    >([])

    //report data generation
    const handleRefresh = useCallback(async () => {
        setRefreshing(true)
        setHasPrintData(false)

        await milestoneService
            .milestonePost({ site: site, runner: runner.milestoneProjection })
            .then((response) => {
                console.log("runner: ", runner.milestoneProjection)
                setMilestoneDashboard(response)
                setRefreshing(false)
            })
            .catch((error) => console.log('Error: ', error))
    }, [runner, site])

    //print report data
    const handlePrint = useCallback(async () => {
        // debugger
        console.log('test...', site)
        console.log({
            site: site,
            runner: runner.milestoneProjection,
            region: currentBranch.region,
        })
        await milestoneService
            .milestoneReport({
                site: site,
                runner: runner.milestoneProjection,
                region: currentBranch.region,
            })
            .then((response) => {
                setHasPrintData(true)
                setPrintData(`data:application/pdf;base64,${response}`)
                setPreview(false)
            })
            .catch((error) => console.log('Error: ', error))
    }, [runner, site])

    useEffect(() => {
        loadMilestones()
    }, [milestone, milestones, isLoading, initialFetch, loadMilestones, site])

    return {
        milestone,
        milestones,
        isLoading,
        initialFetch,
        updateMode,
        site,
        setUpdateMode,
        addMilestoneProjection,
        addMilestoneAchievement,
        setMilestone,
        setMilestoneSite,
        getMilestoneProjections,
        getMilestoneAchievements,
        getTotalProjectionAmount,
        getTotalAchievementAmount,
        handleRefresh,
        handlePrint,
        isRefreshing,
        isPreviewing,
        milestoneDashboard,
        printData,
        hasPrintData,
    }
}

export { useMilestone }
