import { Col, Row, Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton, ResetButton } from 'formik-antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IBranch } from '../../../models/common/branch.model'

const FormItem = Form.Item
const BranchFormFields: React.FC = () => {
    const { isSubmitting } = useFormikContext<IBranch>()
    const { t } = useTranslation()
    return (
        <>
            <Form autoComplete="off">
                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.description')}</p>
                    </Col>
                    <Col span={20}>
                        <FormItem name="description" required={true}>
                            <Input
                                name="description"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.branchName')}</p>
                    </Col>
                    <Col span={20}>
                        <FormItem name="branchName" required={true}>
                            <Input
                                name="branchName"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.branchShortName')}</p>
                    </Col>
                    <Col span={20}>
                        <FormItem name="branchShortName" required={true}>
                            <Input
                                name="branchShortName"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.address')}</p>
                    </Col>
                    <Col span={20}>
                        <FormItem name="address" required={true}>
                            <Input
                                name="address"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.branchType')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="branchType" required={true}>
                            <Input
                                name="branchType"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.staCode')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="staCode" required={true}>
                            <Input
                                name="staCode"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.telephone')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="telephone" required={true}>
                            <Input
                                name="telephone"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.postBox')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="postBox" required={true}>
                            <Input
                                name="postBox"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.city')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="city" required={true}>
                            <Input
                                name="city"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.branchTown')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="branchTown" required={true}>
                            <Input
                                name="branchTown"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.region')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="region" required={true}>
                            <Input
                                name="region"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.bursaryStation')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="bursaryStation" required={true}>
                            <Input name="bursaryStation" disabled={isSubmitting} />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.eMail')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="eMail" required={true}>
                            <Input
                                name="eMail"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.website')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="website" required={true}>
                            <Input name="website" disabled={isSubmitting} />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:branch.slogan')}</p>
                    </Col>
                    <Col span={20}>
                        <FormItem name="slogan" required={true}>
                            <Input name="slogan" disabled={isSubmitting} />
                        </FormItem>
                    </Col>
                </Row>

                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('common:branch.submit')}
                    </SubmitButton>
                    <ResetButton disabled={isSubmitting}>
                        {t('common:branch.reset')}
                    </ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default BranchFormFields
