import { useContext, useEffect, useState } from 'react'
import { Table } from 'antd'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'
import { useRunnerPeriod } from '../../../hooks/budgeting/runner-period.hook'
import {
    emptyRunnerPeriod,
    IRunnerPeriod,
} from '../../../models/budgeting/runner-period.model'
import { runnerPeriodTableColumns } from './runner-period-colum.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import RunnerPeriodForm from './runner-period-form.component'
import { UpdateMode } from '../../../models/update-mode.enum'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useRunner } from '../../../hooks/budgeting/runner.hook'

interface IProps {
    showButton?: boolean
}

const RunnerPeriodTable: React.FC<IProps> = ({ showButton = false }) => {
    const { setRunnerPeriod, getHistoryCodes } = useRunnerPeriod()
    const { runner } = useRunner()
    const { runnerPeriods } = useRunnerPeriod()
    const { currentBranch } = useCurrentBranch()
    const [historyCodes, setHistoryCodes] = useState<string[]>([])
    const router = useHistory()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)

    const handleRowClick = (RunnerPeriod: IRunnerPeriod) => {
        setRunnerPeriod(RunnerPeriod)
        router.push(`/runner-periods/${slugify(RunnerPeriod.description)}`)
    }

    const handleShow = () => {
        setRunnerPeriod(emptyRunnerPeriod)
        setTitle('Add New RunnerPeriod')
        setContent(<RunnerPeriodForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    useEffect(() => {
        const { region, project } = currentBranch
        getHistoryCodes({ copYear: runner.copYear, region, project }).then(
            (json) => {
                console.log('json: ', json)
                setHistoryCodes(json)
            }
        )
    }, [currentBranch, getHistoryCodes, runner.copYear])

    return (
        <>
            {runnerPeriods && runnerPeriods.length ? (
                <Table<IRunnerPeriod>
                    dataSource={runnerPeriods.filter(
                        (item) => !historyCodes.includes(item.code)
                    )}
                    columns={runnerPeriodTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    scroll={{ x: '500' }}
                    onRow={(record: IRunnerPeriod) => {
                        return {
                            onClick: (e) => {
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                <NoContent
                    title="No data for Runner Period"
                    showButton={showButton}
                    handleClick={handleShow}
                    buttonLabel="Add New Runner Period"
                />
            )}
        </>
    )
}

export default RunnerPeriodTable
