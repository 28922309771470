import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as branchStationModel from '../../../models/common/branch-station.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import branchStationService from '../../../services/common/branch-station/branch-station.service'

export const initialState: branchStationModel.IBranchStationState = {
    branchStations: [],
    errors: '',
    branchStation: branchStationModel.emptyBranchStation,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchBranchStationsAsync = createAsyncThunk(
    'branchStation/fetchBranchStationsAsync',
    async () => {
        return await branchStationService.list()
    }
)

export const branchStationSlice = createSlice({
    name: 'branchStation',
    initialState,
    reducers: {
        fetchBranchStationsRequest: (state) => {
            state.isLoading = true
        },
        fetchBranchStationsSuccess: (
            state,
            action: PayloadAction<branchStationModel.IBranchStation[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.branchStations = action.payload
        },
        fetchBranchStationsError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editBranchStationSuccess: (
            state,
            action: PayloadAction<branchStationModel.IBranchStation>
        ) => {
            state.branchStations = state.branchStations.map((branchStation) => {
                return branchStation.branch === action.payload.branch &&
                    branchStation.station === action.payload.station
                    ? action.payload
                    : branchStation
            })
            state.updateMode = UpdateMode.NONE
        },
        addBranchStationSuccess: (
            state,
            action: PayloadAction<branchStationModel.IBranchStation>
        ) => {
            state.branchStations = [...state.branchStations, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveBranchStation: (
            state,
            action: PayloadAction<branchStationModel.IBranchStation>
        ) => {
            state.branchStation = action.payload
        },
        setBranchStationUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBranchStationsAsync.pending, (_state, _action) => {
            fetchBranchStationsRequest()
            _state.isLoading = true
        })
        builder.addCase(fetchBranchStationsAsync.fulfilled, (_state, action) => {
            fetchBranchStationsSuccess(action.payload)
            _state.isLoading = false
            _state.initialFetch = false
            _state.branchStations = action.payload
        })
        builder.addCase(fetchBranchStationsAsync.rejected, (_state, action) => {
            fetchBranchStationsError(action.payload as string)
            _state.isLoading = false
            _state.errors = action.payload
        })
    },
})

export const {
    fetchBranchStationsRequest,
    fetchBranchStationsSuccess,
    fetchBranchStationsError,
    editBranchStationSuccess,
    addBranchStationSuccess,
    setActiveBranchStation,
    setBranchStationUpdateMode,
} = branchStationSlice.actions

const reducer = branchStationSlice.reducer

export { reducer as branchStationReducer }
