import { Progress } from 'antd'
import React, { useEffect, useState } from 'react'

interface Props {
    message: string
}

const Loading: React.FunctionComponent<Props> = ({ message = '' }) => {
    const [progress, setProgress] = useState(0)
    const step = 10
    useEffect(() => {
        const counter = 1000 //Math.floor(Math.random() * (400 - 100 + 1) + 100);
        setTimeout(() => {
            setProgress(progress + step)
        }, counter)
    }, [progress])
    return (
        <>
            <div style={{ width: '200px', margin: 'auto' }}>
                <Progress
                    percent={progress}
                    status="active"
                    showInfo={false}
                    size="small"
                    strokeColor={'#858383'}
                />
            </div>
        </>
    )
}
export default Loading
