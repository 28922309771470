import { Col, Row, Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton, ResetButton } from 'formik-antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ILocation } from '../../../models/common/location.model'

const FormItem = Form.Item
const LocationFormFields: React.FC = () => {
    const { isSubmitting } = useFormikContext<ILocation>()
    const { t } = useTranslation()
    return (
        <>
            <Form autoComplete="off">
                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:location.description')}</p>
                    </Col>
                    <Col span={20}>
                        <FormItem name="description" required={true}>
                            <Input
                                name="description"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={4}>
                        <p className="mb-0">
                            {t('common:location.subDivisionCode')}
                        </p>
                    </Col>
                    <Col span={20}>
                        <FormItem name="subDivisionCode" required={true}>
                            <Input
                                name="subDivision"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:location.tenant')}</p>
                    </Col>
                    <Col span={20}>
                        <FormItem name="tenant" required={true}>
                            <Input
                                name="tenant"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('common:location.submit')}
                    </SubmitButton>
                    <ResetButton disabled={isSubmitting}>
                        {t('common:location.reset')}
                    </ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default LocationFormFields
