import {
    IDialogMessage,
    IDialogMessageResponse,
} from '../../../models/common/dialog-message.model'
import { apiRequests } from '../../../services'

const dialogMessageService = {
    list: (): Promise<IDialogMessage[]> => apiRequests.get('/dialogMessages'),
    details: (code: string): Promise<IDialogMessage> =>
        apiRequests.get(`/dialogMessages/${code}`),
    create: (dialogMessage: IDialogMessage): Promise<IDialogMessageResponse> =>
        apiRequests.post('/dialogMessages', dialogMessage),
    update: (dialogMessage: IDialogMessage): Promise<IDialogMessageResponse> =>
        apiRequests.put('/dialogMessages', dialogMessage),
}

export default dialogMessageService
