import { apiRequests } from '../..'
import {
    ISubDivision,
    ISubDivisionResponse,
} from '../../../models/common/sub-division.model'

const subDivisionService = {
    list: (): Promise<ISubDivision[]> => apiRequests.get('/subDivisions'),
    details: (code: string): Promise<ISubDivision> =>
        apiRequests.get(`/subDivisions/${code}`),
    create: (subDivision: ISubDivision): Promise<ISubDivisionResponse> =>
        apiRequests.post('/subDivisions', subDivision),
    update: (subDivision: ISubDivision): Promise<ISubDivisionResponse> =>
        apiRequests.put('/subDivisions', subDivision),
}

export default subDivisionService
