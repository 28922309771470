import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as currentBranchModel from '../../../models/common/current-branch.model'
import { UpdateMode } from '../../../models/update-mode.enum'

export const initialState: currentBranchModel.ICurrentBranchState = {
    currentBranches: [],
    errors: '',
    currentBranch: currentBranchModel.emptyCurrentBranch,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const currentBranchSlice = createSlice({
    name: 'currentBranch',
    initialState,
    reducers: {
        fetchCurrentBranchRequest: (state) => {
            state.isLoading = true
        },
        fetchCurrentBranchSuccess: (
            state,
            action: PayloadAction<currentBranchModel.ICurrentBranch>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.currentBranch = action.payload
        },
        fetchCurrentBranchError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editCurrentBranchSuccess: (
            state,
            action: PayloadAction<currentBranchModel.ICurrentBranch>
        ) => {
            state.currentBranches = state.currentBranches.map((currentbranch) => {
                return currentbranch.code === action.payload.code
                    ? action.payload
                    : currentbranch
            })
            state.updateMode = UpdateMode.NONE
        },
        addCurrentBranchSuccess: (
            state,
            action: PayloadAction<currentBranchModel.ICurrentBranch>
        ) => {
            state.currentBranches = [...state.currentBranches, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveCurrentBranch: (
            state,
            action: PayloadAction<currentBranchModel.ICurrentBranch>
        ) => {
            state.currentBranch = action.payload
        },
        setCurrentBranchUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
})

export const {
    fetchCurrentBranchRequest,
    fetchCurrentBranchSuccess,
    fetchCurrentBranchError,
    editCurrentBranchSuccess,
    addCurrentBranchSuccess,
    setActiveCurrentBranch,
    setCurrentBranchUpdateMode,
} = currentBranchSlice.actions

const reducer = currentBranchSlice.reducer

export { reducer as currentBranchReducer }
