import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as userRoleActions from '../../../redux/common/user-role/user-role.slice'
import { IUserRole, IUserRoleDto } from '../../../models/common/user-role.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import userRoleService from '../../../services/common/user-role/user-role.service'

const useUserRole = () => {
    const userRoles = useSelector<rootState, IUserRole[]>(
        (state) => state.userRole.userRoles
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.userRole.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.userRole.initialFetch
    )
    const userRole = useSelector<rootState, IUserRole>(
        (state) => state.userRole.userRole
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.userRole.updateMode
    )

    const dispatch = useDispatch()

    const loadUserRoles = useCallback(() => {
        if (initialFetch) {
            dispatch(userRoleActions.fetchUserRolesAsync())
        }
    }, [dispatch, initialFetch])

    const addUserRole = async (userRole: IUserRoleDto) => {
        return await userRoleService
            .create(userRole)
            .then((userRoleResponse) => {
                dispatch(userRoleActions.addUserRoleSuccess(userRoleResponse.data))
                dispatch(
                    userRoleActions.fetchUserRolesSuccess([
                        ...userRoles,
                        ...userRoleResponse.userRoles,
                    ])
                )
                return true
            })
            .catch((error) => {
                // dispatch(userRoleActions.)
                return false
            })
    }

    const setUserRole = (userRole: IUserRole) => {
        dispatch(userRoleActions.setActiveUserRole(userRole))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(userRoleActions.setUserRoleUpdateMode(updateMode))
    }

    const editUserRole = async (userRole: IUserRole) => {
        return await userRoleService
            .update(userRole)
            .then((userRoleResponse) => {
                dispatch(userRoleActions.editUserRoleSuccess(userRoleResponse.data))
                setUserRole(userRoleResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    // const saveUserRole = async (userRole: IUserRole, updateMode: UpdateMode) => {
    //     return updateMode === UpdateMode.ADD
    //         ? await addUserRole(userRole)
    //         : await editUserRole(userRole)
    // }

    useEffect(() => {
        loadUserRoles()
    }, [userRole, userRoles, isLoading, initialFetch, loadUserRoles])

    return {
        userRole,
        userRoles,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addUserRole,
        editUserRole,
        // saveUserRole,
        setUserRole,
    }
}

export { useUserRole }
