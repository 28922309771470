import { apiRequests } from '../'
import {
    ActivityPlanParamType,
    IActivityPlan,
    IActivityPlanResponse,
} from '../../models/budgeting/activity-plan.model'

const activityPlanService = {
    list: (
        { tenant, copYear, component, project }: ActivityPlanParamType,
        isSurge?: boolean
    ): Promise<IActivityPlan[]> =>
        apiRequests.get(
            isSurge
                ? `/activityPlans/surge?copYear=${copYear}&tenant=${tenant}&component=${component}&project=${project}`
                : `/activityPlans?copYear=${copYear}&tenant=${tenant}&component=${component}&project=${project}`
        ),
    details: (code: string): Promise<IActivityPlan> =>
        apiRequests.get(`/activityPlans/${code}`),
    create: (activityPlan: IActivityPlan): Promise<IActivityPlanResponse> =>
        apiRequests.post('/activityPlans', activityPlan),
    update: (activityPlan: IActivityPlan): Promise<IActivityPlanResponse> =>
        apiRequests.put('/activityPlans', activityPlan),
}

export default activityPlanService
