import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as regionRunnerPeriodModel from '../../models/budgeting/region-runner-period.model'
import regionRunnerPeriodService from '../../services/budgeting/region-runner-period.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: regionRunnerPeriodModel.IRegionRunnerPeriodState = {
    regionRunnerPeriods: [],
    errors: '',
    regionRunnerPeriod: regionRunnerPeriodModel.emptyRegionRunnerPeriod,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchRegionRunnerPeriodsAsync = createAsyncThunk<
    regionRunnerPeriodModel.IRegionRunnerPeriod[],
    void
>('regionRunnerPeriod/fetchregionRunnerPeriodsAsync', async (_, thunkApi) => {
    try {
        return await regionRunnerPeriodService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const regionRunnerPeriodSlice = createSlice({
    name: 'regionRunnerPeriod',
    initialState,
    reducers: {
        fetchRegionRunnerPeriodsRequest: (state) => {
            state.isLoading = true
        },
        fetchRegionRunnerPeriodsSuccess: (
            state,
            action: PayloadAction<regionRunnerPeriodModel.IRegionRunnerPeriod[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.regionRunnerPeriods = action.payload
        },
        fetchRegionRunnerPeriodsError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editRegionRunnerPeriodSuccess: (
            state,
            action: PayloadAction<regionRunnerPeriodModel.IRegionRunnerPeriod>
        ) => {
            state.regionRunnerPeriods = state.regionRunnerPeriods.map(
                (regionRunnerPeriod) => {
                    return regionRunnerPeriod.region === action.payload.region
                        ? action.payload
                        : regionRunnerPeriod
                }
            )
            state.updateMode = UpdateMode.NONE
        },
        addRegionRunnerPeriodSuccess: (
            state,
            action: PayloadAction<regionRunnerPeriodModel.IRegionRunnerPeriod>
        ) => {
            state.regionRunnerPeriods = [
                ...state.regionRunnerPeriods,
                action.payload,
            ]
            state.updateMode = UpdateMode.NONE
        },
        setActiveRegionRunnerPeriod: (
            state,
            action: PayloadAction<regionRunnerPeriodModel.IRegionRunnerPeriod>
        ) => {
            state.regionRunnerPeriod = action.payload
        },
        setRegionRunnerPeriodUpdateMode: (
            state,
            action: PayloadAction<UpdateMode>
        ) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRegionRunnerPeriodsAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchRegionRunnerPeriodsAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.regionRunnerPeriods = action.payload
        })
        builder.addCase(fetchRegionRunnerPeriodsAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchRegionRunnerPeriodsRequest,
    fetchRegionRunnerPeriodsSuccess,
    fetchRegionRunnerPeriodsError,
    editRegionRunnerPeriodSuccess,
    addRegionRunnerPeriodSuccess,
    setActiveRegionRunnerPeriod,
    setRegionRunnerPeriodUpdateMode,
} = regionRunnerPeriodSlice.actions

const reducer = regionRunnerPeriodSlice.reducer

export { reducer as regionRunnerPeriodReducer }
