import { ClockCircleOutlined } from '@ant-design/icons'
import { appRoutes } from '../../../constants/app-route'
import { ISideBarLink } from '../sidebar.data'

export const zoneReportSubmenus: ISideBarLink[] = [
    {
        app: '01',
        code: 'H00',
        description: 'BA Approach',
        route: appRoutes.ZONE_REPORT.BA_APPROACH,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'H',
    },
    {
        app: '01',
        code: 'H01',
        description: 'BA Intervention',
        route: appRoutes.ZONE_REPORT.BA_INTERVENTION,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'H',
    },
    {
        app: '01',
        code: 'H02',
        description: 'BA Site',
        route: appRoutes.ZONE_REPORT.BA_SITE,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'H',
    },
    {
        app: '01',
        code: 'H03',
        description: 'BA Cost Category',
        route: appRoutes.ZONE_REPORT.BA_COST_CATEGORY,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'H',
    },
]
