import { appRoutes } from '../../../constants/app-route'
import { IRoute } from '../../../models/common/route.model'
import ActivityHistoryBatchDetailsPage from '../../../pages/budgeting/activity-history/activity-history-batch-detail.page'
import ActivityBatchHistoryDetailsPage from '../../../pages/budgeting/activity-history/activity-summary-history-detail.page'
import ActivityBatchDetailsPage from '../../../pages/budgeting/activity/activity-summary-detail.page'
import RegionalModificationsPage from '../../../pages/budgeting/modifications/regional-modifications.page'
import ZoneAboveSiteModificationsPage from '../../../pages/budgeting/modifications/zone-above-site-modifications.page'
import ZoneSiteModificationsPage from '../../../pages/budgeting/modifications/zone-site-modifications.page'

export const modificationRoutes: IRoute[] = [
    /**
     * HIV Modification routes
     */
    {
        path: appRoutes.MAIN_ACTIVITY.HIV_REGIONAL_MODIFICATION,
        private: true,
        exact: true,
        component: RegionalModificationsPage,
    },
    {
        path: appRoutes.MAIN_ACTIVITY.HIV_ZONE_SITE_MODIFICATION,
        private: true,
        exact: true,
        component: ZoneSiteModificationsPage,
    },
    {
        path: appRoutes.MAIN_ACTIVITY.HIV_ZONE_ABOVE_SITE_MODIFICATION,
        private: true,
        exact: true,
        component: ZoneAboveSiteModificationsPage,
    },
    /**
     * Lab Modification routes
     */
    {
        path: appRoutes.LABORATORY_ACTIVITY.LAB_REGIONAL_MODIFICATION,
        private: true,
        exact: true,
        component: RegionalModificationsPage,
    },
    {
        path: appRoutes.LABORATORY_ACTIVITY.LAB_ZONE_SITE_MODIFICATION,
        private: true,
        exact: true,
        component: ZoneSiteModificationsPage,
    },
    {
        path: appRoutes.LABORATORY_ACTIVITY.LAB_ZONE_ABOVE_SITE_MODIFICATION,
        private: true,
        exact: true,
        component: ZoneAboveSiteModificationsPage,
    },

    // Details
    {
        path: appRoutes.ACTIVITY_HISTORY.BATCH_DETAIL,
        private: true,
        exact: true,
        component: ActivityHistoryBatchDetailsPage,
    },
    /**
     * Activity Batch
     */
    {
        path: appRoutes.ACTIVITY_HISTORY.REGIONAL_MODIFICATION_SUMMARY,
        private: true,
        exact: true,
        component: ActivityBatchHistoryDetailsPage,
    },
]
