import { appRoutes } from '../../../constants/app-route'
import { IRoute } from '../../../models/common/route.model'
import ActivityBatchPage from '../../../pages/budgeting/activity/activity-batch.page'
import ActivityBatchDetailsPage from '../../../pages/budgeting/activity/activity-summary-detail.page'
import ActivityPage from '../../../pages/budgeting/activity/activity.page'
import ProgramManagementBOActivityPage from '../../../pages/budgeting/program-management-activity/program-management-bo-activity.page.'
import ProgramManagementDHSActivityPage from '../../../pages/budgeting/program-management-activity/program-management-dhs-activity.page'
import ProgramManagementMVANActivityPage from '../../../pages/budgeting/program-management-activity/program-management-mvan-activity.page'

export const activityRoutes: IRoute[] = [
    /**
     * Auth routes
     */
    {
        path: appRoutes.MAIN_ACTIVITY.ACTIVITY_BATCH_SUMMARY,
        private: true,
        exact: true,
        component: ActivityPage,
    },
    {
        path: appRoutes.MAIN_ACTIVITY.ACTIVITY_BATCH,
        private: true,
        exact: true,
        component: ActivityBatchPage,
    },
    /**
     * Activity Batch
     */
    {
        path: appRoutes.MAIN_ACTIVITY.ACTIVITY_BATCH_DETAILS,
        private: true,
        exact: true,
        component: ActivityBatchDetailsPage,
    },
    {
        path: appRoutes.MAIN_ACTIVITY.PROGRAM_MANAGEMENT_BO,
        private: true,
        exact: true,
        component: ProgramManagementBOActivityPage,
    },
    {
        path: appRoutes.MAIN_ACTIVITY.PROGRAM_MANAGEMENT_DHS,
        private: true,
        exact: true,
        component: ProgramManagementDHSActivityPage,
    },
    {
        path: appRoutes.MAIN_ACTIVITY.PROGRAM_MANAGEMENT_MVAN,
        private: true,
        exact: true,
        component: ProgramManagementMVANActivityPage,
    },
]
