import {
    IDateGeneration,
    IDateGenerationResponse,
} from '../../../models/common/date-generation.model'
import { apiRequests } from '../../../services'

const dateGenerationService = {
    list: (): Promise<IDateGeneration[]> => apiRequests.get('/dateGenerations'),
    details: (code: string): Promise<IDateGeneration> =>
        apiRequests.get(`/dateGenerations/code`),
    create: (dateGeneration: IDateGeneration): Promise<IDateGenerationResponse> =>
        apiRequests.post('/dateGenerations', dateGeneration),
    update: (dateGeneration: IDateGeneration): Promise<IDateGenerationResponse> =>
        apiRequests.put('/dateGenerations', dateGeneration),
}

export default dateGenerationService
