import { useContext, useEffect, useState } from 'react'
import { Button, Space, Table, Tag } from 'antd'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useMilestoneProjectionColumns } from './milestone-project-colum.component'
import { IMilestone } from '../../../models/budgeting/milestone.model'
import MilestoneProjectionForm from './milestone-project-form.component'
import { useTranslation } from 'react-i18next'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useMilestone } from '../../../hooks/budgeting/milestone.hook'

interface IProps {
    milestones: IMilestone[]
}
const MilestoneProjectionTable: React.FC<IProps> = ({ milestones }) => {
    const { t } = useTranslation()
    const { milestoneColumns } = useMilestoneProjectionColumns()
    const { site, setMilestone, getTotalProjectionAmount } = useMilestone()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const [index, setIndex] = useState<number>(0)

    const handleRowClick = (milestone: IMilestone) => {
        setTitle('Milestone Projections')
        setMilestone(milestone)
        setContent(<MilestoneProjectionForm index={index} />)
        setWidth('40rem')
        setShow(true)
    }

    useEffect(() => {}, [milestones])
    const totalAmount = getTotalProjectionAmount(site)

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Tag color="purple">
                    <h6 style={{ marginBottom: 0 }}>Total Amount: {totalAmount}</h6>
                </Tag>
                <Space></Space>
            </div>
            {milestones &&
            milestones.length &&
            site.length > 0 &&
            site.length > 0 ? (
                <Table<IMilestone>
                    dataSource={milestones}
                    columns={milestoneColumns}
                    size={'small'}
                    rowKey={'activity'}
                    scroll={{ x: '500' }}
                    onRow={(record: IMilestone, index) => {
                        return {
                            onClick: (e) => {
                                setIndex(index!!)
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                <NoContent title="No data for Milestone" />
            )}
        </>
    )
}

export default MilestoneProjectionTable
