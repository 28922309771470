import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as quarterModel from '../../models/budgeting/quarter.model'
import quarterService from '../../services/budgeting/quarter.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: quarterModel.IQuarterState = {
    quarters: [],
    errors: '',
    quarter: quarterModel.emptyQuarter,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchquarterAsync = createAsyncThunk<quarterModel.IQuarter[], void>(
    'quarter/fetchquarterAsync',
    async (_, thunkApi) => {
        try {
            return await quarterService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const quarterSlice = createSlice({
    name: 'quarter',
    initialState,
    reducers: {
        fetchquarterRequest: (state) => {
            state.isLoading = true
        },
        fetchquarterSuccess: (
            state,
            action: PayloadAction<quarterModel.IQuarter[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.quarters = action.payload
        },
        fetchquarterError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editQuarterSuccess: (
            state,
            action: PayloadAction<quarterModel.IQuarter>
        ) => {
            state.quarters = state.quarters.map((quarter) => {
                return quarter.code === action.payload.code
                    ? action.payload
                    : quarter
            })
            state.updateMode = UpdateMode.NONE
        },
        addQuarterSuccess: (state, action: PayloadAction<quarterModel.IQuarter>) => {
            state.quarters = [...state.quarters, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveQuarter: (state, action: PayloadAction<quarterModel.IQuarter>) => {
            state.quarter = action.payload
        },
        setQuarterUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchquarterAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchquarterAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.quarters = action.payload
        })
        builder.addCase(fetchquarterAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchquarterRequest,
    fetchquarterSuccess,
    fetchquarterError,
    editQuarterSuccess,
    addQuarterSuccess,
    setActiveQuarter,
    setQuarterUpdateMode,
} = quarterSlice.actions

const reducer = quarterSlice.reducer

export { reducer as quarterReducer }
