import { Button, Card, Col, Form, Row, Select, Space } from 'antd'
import React from 'react'
import { BiPrinter } from 'react-icons/bi'
import { FiSend } from 'react-icons/fi'
import { useComponent } from '../../hooks/budgeting/component.hook'
import { useCopYear } from '../../hooks/budgeting/cop-year.hook'
import { useSite } from '../../hooks/budgeting/site.hook'

const CostedWorkPlanFilterForm: React.FC = () => {
    const [form] = Form.useForm()
    const { copYears } = useCopYear()
    const { components } = useComponent()
    const { sites } = useSite()
    const { Option } = Select

    const onFinish = (values: any) => {
        form.resetFields()
    }

    return (
        <Card size="small">
            <Form
                form={form}
                name="costed-work-plan-filter-form"
                onFinish={onFinish}
            >
                <Row gutter={[16, 8]}>
                    <Col md={6}>
                        <Form.Item
                            name="copYear"
                            label="Cop Year"
                            labelCol={{ span: 24 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Cop year is required',
                                },
                            ]}
                        >
                            <Select
                                onChange={(value) =>
                                    form.setFieldValue('copYear', value)
                                }
                            >
                                {copYears.map((copYear, index) => (
                                    <Option key={index} value={copYear.code}>
                                        {copYear.description}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={6}>
                        <Form.Item
                            name="component"
                            label="Component"
                            labelCol={{ span: 24 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Component is required',
                                },
                            ]}
                        >
                            <Select
                                onChange={(value) =>
                                    form.setFieldValue('component', value)
                                }
                            >
                                {components.map((component, index) => (
                                    <Option key={index} value={component.code}>
                                        {component.description}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={6}>
                        <Form.Item
                            name="month"
                            label="Month"
                            labelCol={{ span: 24 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Month is required',
                                },
                            ]}
                        >
                            <Select
                                onChange={(value) =>
                                    form.setFieldValue('month', value)
                                }
                            >
                                {['October', 'November', 'December'].map(
                                    (monthName, index) => (
                                        <Option key={index} value={index}>
                                            {monthName}
                                        </Option>
                                    )
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={6}>
                        <Form.Item
                            name="site"
                            label="Select site"
                            labelCol={{ span: 24 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Site is required',
                                },
                            ]}
                        >
                            <Select
                                onChange={(value) =>
                                    form.setFieldValue('site', value)
                                }
                            >
                                {sites.map((site, index) => (
                                    <Option key={index} value={site.code}>
                                        {site.description}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Space>
                    <Button
                        type="primary"
                        icon={<FiSend style={{ marginRight: '4px' }} />}
                        htmlType="submit"
                    >
                        Load
                    </Button>
                    <Button icon={<BiPrinter style={{ marginRight: '4px' }} />}>
                        Generate pdf
                    </Button>
                </Space>
            </Form>
        </Card>
    )
}

export default CostedWorkPlanFilterForm
