const useTablePagination = () => {
    const store = window.localStorage
    // store table pagination number
    const setTablePage = (n: number) => store.setItem('pagination', `${n}`)
    const tablePage = Number(store.getItem('pagination')) || 1

    // store search query
    const searchQuery = store.getItem('searchQuery') || ''
    const setSearchQuery = (query: string) =>
        store.setItem('searchQuery', query || '')

    return { tablePage, setTablePage, searchQuery, setSearchQuery }
}

export { useTablePagination }
