import { Card, Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useTicketStatus } from '../../../hooks/common/ticket-status/ticket-status.hook'

const TicketStatusDetailComponent: React.FC = () => {
    const { ticketStatus } = useTicketStatus()
    return (
        <Card bordered={false} size="small">
            <Row>
                <Col span={4}>
                    <StyledParagraph>Code: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{ticketStatus.code}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Description: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{ticketStatus.description}</StyledParagraph>
                </Col>
            </Row>

            
        </Card>
    )
}

export default TicketStatusDetailComponent

const StyledParagraph = styled.p`
    margin-bottom: 0;
    height: 2rem
`
