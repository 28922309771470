import { apiRequests } from '../'
import { IDistrict, IDistrictResponse } from '../../models/budgeting/district.model'

const districtService = {
    list: (): Promise<IDistrict[]> => apiRequests.get('/districts'),
    details: (code: string): Promise<IDistrict> =>
        apiRequests.get(`/districts/${code}`),
    create: (district: IDistrict): Promise<IDistrictResponse> =>
        apiRequests.post('/districts', district),
    update: (district: IDistrict): Promise<IDistrictResponse> =>
        apiRequests.put('/districts', district),
}

export default districtService
