import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'

const ServicePage: React.FC = () => {
    const { setTitle, setShow, setWidth } = useContext<any>(AppModalContext)

    const handleShow = () => {
        setTitle('Create new service')
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'Services']} />
            <TitleBar
                title="Services"
                subTitle="View and manage services"
                showButton
                handleShow={handleShow}
                buttonLabel="Add Service"
                icon={<FiPlus />}
            />
        </>
    )
}

export default ServicePage
