import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { emptyChart, IChart } from './chart.model'

export interface IMilestoneDash extends IChart {}

export const emptyMilestoneDash: IMilestoneDash = {
    ...emptyChart,
}

export interface IMilestoneDashState extends IBaseState {
    readonly milestoneDash: IMilestoneDash
}

export interface IMilestoneDashResponse extends IResponseBase {
    data: IMilestoneDash
}
