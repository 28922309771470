import React, { useState } from 'react'
import AppHeader from '../app-header/app-header.component'
import { Alert, Layout } from 'antd'
import { useInfoBar } from '../../hooks/common/info-bar/info-bar.hook'
import { Spin } from 'antd'
import Wrapper from '../../components/common/wrapper-class/wrapper.component'
import AppModalProvider from '../../contexts/common/app-modal.context'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Sidebar from '../sidebar/sidbar.component'
import InfoBar from '../../components/common/info-bar/info-bar.component'
import { Offline } from 'react-detect-offline'
import CheckIdleUser from '../../components/shared/check-idle-user/check-idle-user.component'
import './app-shell.scss'
import '@toast-ui/chart/dist/toastui-chart.min.css'

const AppShell: React.FC = ({ children }) => {
    const { Sider, Content, Footer } = Layout
    const { isLoading } = useInfoBar()
    const [toggled, setToggled] = useState(false)

    return (
        <AppModalProvider>
            <AppHeader />

            <Layout hasSider={true}>
                <Sider
                    theme="light"
                    className="sidebar__main"
                    collapsible
                    collapsed={toggled}
                    onCollapse={setToggled}
                    collapsedWidth={56}
                    breakpoint="xs"
                    width="257"
                    style={{
                        overflowY: 'auto',
                        position: 'sticky',
                        maxHeight: '100vh',
                        top: 48,
                        left: 0,
                    }}
                >
                    <Sidebar collapsed={toggled} />
                </Sider>
                <Layout>
                    <Content className="site-layout-background">
                        <>
                            <InfoBar />
                            <Wrapper>
                                <div>
                                    <div className="logo__container">
                                        {isLoading ? (
                                            <Spin size="large" />
                                        ) : (
                                            <>
                                                <img
                                                    src="/cbchs-logo.png"
                                                    alt="Dama Budget Monitoring logo"
                                                    className="logo__backdrop"
                                                />
                                            </>
                                        )}
                                    </div>
                                    <div style={{ padding: '10px' }}>
                                        <Offline>
                                            <Alert
                                                message="You're currently working offline"
                                                description="Please check your internet connection."
                                                type="warning"
                                                style={{ marginBottom: '1rem' }}
                                            />
                                        </Offline>
                                        {/* Check for idle users */}
                                        <CheckIdleUser />

                                        {children}
                                    </div>
                                </div>
                            </Wrapper>
                            <Footer
                                style={{
                                    textAlign: 'center',
                                    position: 'relative',
                                }}
                            >
                                Copyright &copy; {new Date().getFullYear()} CBC
                                Health Services
                            </Footer>
                        </>
                    </Content>

                    <ToastContainer />
                </Layout>
            </Layout>
        </AppModalProvider>
    )
}

export default AppShell
