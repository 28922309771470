import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import * as budgetCodeActions from '../../redux/budgeting/budget-code.slice'
import { IBudgetCode } from '../../models/budgeting/budget-code.model'
import { UpdateMode } from '../../models/update-mode.enum'
import budgetCodeService from '../../services/budgeting/budget-code.service'

const useBudgetCode = () => {
    const budgetCodes = useSelector<rootState, IBudgetCode[]>(
        (state) => state.budgetCode.budgetCodes
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.budgetCode.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.budgetCode.initialFetch
    )
    const budgetCode = useSelector<rootState, IBudgetCode>(
        (state) => state.budgetCode.budgetCode
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.budgetCode.updateMode
    )

    const dispatch = useDispatch()

    const loadBudgetCodes = useCallback(() => {
        if (initialFetch) {
            dispatch(budgetCodeActions.fetchBudgetCodeAsync())
        }
    }, [dispatch, initialFetch])

    const addBudgetCode = async (budgetCode: IBudgetCode) => {
        return await budgetCodeService
            .create(budgetCode)
            .then((budgetCodeResponse) => {
                if (budgetCodeResponse.success) {
                    dispatch(
                        budgetCodeActions.addBudgetCodeSuccess(
                            budgetCodeResponse.data
                        )
                    )
                } else {
                    return budgetCodeResponse
                }
                return budgetCodeResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setBudgetCode = (budgetCode: IBudgetCode) => {
        dispatch(budgetCodeActions.setActiveBudgetCode(budgetCode))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(budgetCodeActions.setBudgetCodeUpdateMode(updateMode))
    }

    const editBudgetCode = async (budgetCode: IBudgetCode) => {
        return await budgetCodeService
            .update(budgetCode)
            .then((budgetCodeResponse) => {
                dispatch(
                    budgetCodeActions.editBudgetCodeSuccess(budgetCodeResponse.data)
                )
                setBudgetCode(budgetCodeResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveBudgetCode = async (
        budgetCode: IBudgetCode,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addBudgetCode(budgetCode)
            : await editBudgetCode(budgetCode)
    }

    const [budgetCodeState, seBudgetCodeState] = useState<IBudgetCode[]>([])
    const budgetCodesData = useCallback(async () => {
        return budgetCodeService
            .list()
            .then((response) => {
                seBudgetCodeState(response)
                return response
            })
            .catch((error) => {
                return error
            })
    }, [])

    const getBudgetCodeDescription = useCallback(
        (budgetCode: string) => {
            return budgetCodeState.find((item) => item.code === budgetCode)
                ?.description
        },
        [budgetCodeState]
    )

    useEffect(() => {
        loadBudgetCodes()
        budgetCodesData()
    }, [
        budgetCode,
        budgetCodes,
        isLoading,
        initialFetch,
        loadBudgetCodes,
        budgetCodesData,
    ])

    return {
        budgetCode,
        budgetCodes,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addBudgetCode,
        editBudgetCode,
        saveBudgetCode,
        setBudgetCode,
        getBudgetCodeDescription,
    }
}

export { useBudgetCode }
