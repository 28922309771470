import { apiRequests } from '..'
import { IActivitySummary } from '../../models/budgeting/activity-summary.model'
import { IActivity, IActivityResponse } from '../../models/budgeting/activity.model'

type AcitivityJournalHistoryOption = {
    tenant: string
    runner: string
    activityType: string
    component: string
    transactionCode: string
    project: string;
}

const activityHistoryService = {
    summary: (options: AcitivityJournalHistoryOption): Promise<IActivitySummary[]> =>
        apiRequests.get(
            `/activityHistory?Tenant=${options.tenant}&TransactionCode=${options.transactionCode}&ActivityType=${options.activityType}&Runner=${options.runner}&Project=${options.project}`
        ),
    batchLines: (batchCode: string): Promise<IActivity[]> =>
        apiRequests.get(`/activityHistory/${batchCode}`),
    create: (activity: IActivity): Promise<IActivityResponse> =>
        apiRequests.post('/activityHistory', activity),
    update: (activity: IActivity): Promise<IActivityResponse> =>
        apiRequests.put('/activityHistory', activity),
}

export default activityHistoryService
