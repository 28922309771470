export const enBudget = {
    tenant: 'Tenant',
    copYear: 'Component year',
    project: 'Project',
    region: 'Region',
    activity: 'Activity',
    component: 'Component',
    amount: 'Amount',
    createdOn: 'Created on',
}
