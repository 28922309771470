export const enBranch = {
    description: 'Description',
    code: 'Code',
    staCode: 'Station Code',
    telephone: 'Telephone',
    fax: 'Fax',
    address: 'Address',
    postBox: 'Post Box',
    city: 'City',
    region: 'Region',
    eMail: 'Email',
    website: 'Website',
    motto: 'Motto',
    slogan: 'Slogan',
    branchName: 'Branch Name',
    branchShortName: 'Branch Short SName',
    branchType: 'Branch Type',
    bursaryStation: 'Bursary Station',
    branchTown: 'Branch Town',
    submit: 'Submit',
    reset: 'Reset',
}
