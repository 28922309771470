export interface IActivityBase {
    tenant: string
    batch: string
    batchLine: string
    batchLineDetail: string
    runner: string
    component: string
    product: string
    donor: string
    branch: string
    account: string
    userCode: string
    transDate: Date
    description: string
    activity: string
    site: string
    strategy: string
    costCategory: string
    intervention: string
    approach: string
    objective: string
    budgetCode: string
    sense: string
    amount: number
    accountLine: string
    voucher: string
    activityType: string
    tranCode: string
    project: string
    copYear: string
    region: string
    serverDate: Date
}

export const emptyActivityBase: IActivityBase = {
    tenant: '',
    batch: '',
    batchLine: '',
    batchLineDetail: '',
    runner: '',
    component: '',
    product: '',
    donor: '',
    branch: '',
    account: '',
    userCode: '',
    transDate: new Date(),
    description: '',
    activity: '',
    site: '',
    strategy: '',
    costCategory: '',
    intervention: '',
    approach: '',
    objective: '',
    budgetCode: '',
    sense: '',
    amount: 0,
    accountLine: '',
    voucher: '',
    activityType: '',
    tranCode: '',
    project: '',
    copYear: '',
    region: '',
    serverDate: new Date(),
}
