import { apiRequests } from '../'
import { IDailyData } from '../../models/budgeting/daily-data.model'

const dailyDataService = {
    list: (): Promise<IDailyData[]> => apiRequests.get('/dailyData'),
    details: (code: string): Promise<IDailyData> =>
        apiRequests.get(`/dailyData/${code}`),
    post: ({
        currentUser,
        branch,
    }: {
        currentUser: string
        branch: string
    }): Promise<IDailyData[]> =>
        apiRequests.post('/dailyData', {
            currentUser,
            branch,
        }),
}

export default dailyDataService
