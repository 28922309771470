export const enBreadCrumb = {
    home: 'Home',
    dashboard: 'Dashboard',

    cardHeader: {
        target: 'Targets',
        archievement: 'Archievements',
        budget: 'Budget',
        expenditure: 'Expenditures',
    },

    chartData: {
        title: 'Milestone Budget vs Expenditure',
        month: 'Month',
        amount: 'Amount',
    },
}
