import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as monthNameModel from '../../../models/common/month-name.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import monthNameService from '../../../services/common/month-name/month-name.service'

export const initialState: monthNameModel.IMonthNameState = {
    monthNames: [],
    errors: '',
    monthName: monthNameModel.emtpyMonthName,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchMonthNamesAsync = createAsyncThunk(
    'monthName/fetchMonthNamesAsync',
    async () => {
        return await monthNameService.list()
    }
)

export const monthNameSlice = createSlice({
    name: 'monthName',
    initialState,
    reducers: {
        fetchMonthNamesRequest: (state) => {
            state.isLoading = true
        },
        fetchMonthNamesSuccess: (
            state,
            action: PayloadAction<monthNameModel.IMonthName[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.monthNames = action.payload
        },
        fetchMonthNamesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editMonthNameSuccess: (
            state,
            action: PayloadAction<monthNameModel.IMonthName>
        ) => {
            state.monthNames = state.monthNames.map((monthName) => {
                return monthName.code === action.payload.code
                    ? action.payload
                    : monthName
            })
            state.updateMode = UpdateMode.NONE
        },
        addMonthNameSuccess: (
            state,
            action: PayloadAction<monthNameModel.IMonthName>
        ) => {
            state.monthNames = [...state.monthNames, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveMonthName: (
            state,
            action: PayloadAction<monthNameModel.IMonthName>
        ) => {
            state.monthName = action.payload
        },
        setMonthNameUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMonthNamesAsync.pending, (_state, _action) => {
            fetchMonthNamesRequest();
            _state.isLoading = true
        })
        builder.addCase(fetchMonthNamesAsync.fulfilled, (_state, action) => {
            fetchMonthNamesSuccess(action.payload);
            _state.isLoading = false
            _state.initialFetch = false
            _state.monthNames = action.payload
        })
        builder.addCase(fetchMonthNamesAsync.rejected, (_state, action) => {
            fetchMonthNamesError(action.payload as string);
            _state.isLoading = false
            _state.errors = action.payload;
        })
    },
})

export const {
    fetchMonthNamesRequest,
    fetchMonthNamesSuccess,
    fetchMonthNamesError,
    editMonthNameSuccess,
    addMonthNameSuccess,
    setActiveMonthName,
    setMonthNameUpdateMode,
} = monthNameSlice.actions

const reducer = monthNameSlice.reducer

export { reducer as monthNameReducer }
