import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IDivision {
    code: string
    description: string
    regionCode: string
    capital: string
    createdOn?: Date
}

export interface IDivisionState extends IBaseState {
    readonly divisions: IDivision[]
    readonly division: IDivision
}

export const emptyDivision: IDivision = {
    code: '',
    description: '',
    regionCode: '',
    capital: '',
    createdOn: new Date(),
}

export interface IDivisionResponse extends IResponseBase {
    data: IDivision
}
