import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { enBranchStation } from './translations/common/branch-station/branch-station.en'
import { frBranchStation } from './translations/common/branch-station/branch-station.fr'
import { frBranch } from './translations/common/branch/branch.fr'
import { enContactRelation } from './translations/common/contact-relation/contact-relation.en'
import { frContactRelation } from './translations/common/contact-relation/contact-relation.fr'
import { enDepartment } from './translations/common/department/department.en'
import { frDepartment } from './translations/common/department/department.fr'
import { enDivision } from './translations/common/division/division.en'
import { frDivision } from './translations/common/division/division.fr'
import { enReligion } from './translations/common/religion/religion.en'
import { frReligion } from './translations/common/religion/religion.fr'
import { enSource } from './translations/common/source/source.en'
import { frSource } from './translations/common/source/source.fr'
import { enStationReference } from './translations/common/station-reference/station-reference.en'
import { frStationReference } from './translations/common/station-reference/station-reference.fr'
import { enSubDivision } from './translations/common/sub-division/sub-division.en'
import { frSubDivision } from './translations/common/sub-division/sub-division.fr'
import { enTableHeaders } from './translations/common/table-headers/table-headers.en'
import { frTableHeaders } from './translations/common/table-headers/table-headers.fr'
import { enLocation } from './translations/common/location/location.en'
import { frLocation } from './translations/common/location/location.fr'
import { enSegment } from './translations/common/segment/segment.en'
import { frSegment } from './translations/common/segment/segment.fr'
import { enSideBarMenus } from './translations/common/sidebar-menus/sidebar-menus.en'
import { frSideBarMenus } from './translations/common/sidebar-menus/sidebar-menus.fr'
import { enActivity } from './translations/budgeting/activity/activity.en'
import { frActivity } from './translations/budgeting/activity/activity.fr'
import { frRunnerPeriod } from './translations/budgeting/runner-period/runner-period.fr'
import { enRunnerPeriod } from './translations/budgeting/runner-period/runner-period.en'
import { enRunner } from './translations/budgeting/runner/runner.en'
import { frRunner } from './translations/budgeting/runner/runner.fr'
import { enActivityPlan } from './translations/budgeting/activity-plan/activity-plan.en'
import { frActivityPlan } from './translations/budgeting/activity-plan/activity-plan.fr'
import { frActivityPlanSummary } from './translations/budgeting/activity-plan-summary/activity-plan-summary.fr'
import { enActivityPlanSummary } from './translations/budgeting/activity-plan-summary/activity-plan-summary.en'
import { enBudget } from './translations/budgeting/budget/budget.en'
import { frBudget } from './translations/budgeting/budget/budget.fr'
import { enComponent } from './translations/budgeting/component/component.en'
import { frComponent } from './translations/budgeting/component/component.fr'
import { frCopYearBudgetCode } from './translations/budgeting/cop-year-budget-code/cop-year-budget-code.fr'
import { enCopYearBudgetCode } from './translations/budgeting/cop-year-budget-code/cop-year-budget-code.en'
import { enCopYearIntervention } from './translations/budgeting/cop-year-intervention/cop-year-intervention.en'
import { frCopYearIntervention } from './translations/budgeting/cop-year-intervention/cop-year-intervention.fr'
import { enCopYearSite } from './translations/budgeting/cop-year-site/cop-year-site.en'
import { enCopYear } from './translations/budgeting/cop-year/cop-year.en'
import { frCopYearSite } from './translations/budgeting/cop-year-site/cop-year-site.fr'
import { frCopYear } from './translations/budgeting/cop-year/cop-year.fr'
import { frCostCategory } from './translations/budgeting/cost-category/cost-category.fr'
import { enCostCategory } from './translations/budgeting/cost-category/cost-category.en'
import { enDistrict } from './translations/budgeting/district/district.en'
import { frDistrict } from './translations/budgeting/district/district.fr'
import { frIncludeRunner } from './translations/budgeting/include-runner/include-runner.fr'
import { enIncludeRunner } from './translations/budgeting/include-runner/include-runner.en'
import { frIntervention } from './translations/budgeting/intervention/intervention.fr'
import { enIntervention } from './translations/budgeting/intervention/intervention.en'
import { enMilestoneAchievement } from './translations/budgeting/milestone-achievement/milestone-achievement.en'
import { frMilestoneAchievement } from './translations/budgeting/milestone-achievement/milestone-achievement.fr'
import { enMilestoneProjection } from './translations/budgeting/milestone-projection/milestone-projection.en'
import { frMilestoneProjection } from './translations/budgeting/milestone-projection/milestone-projection.fr'
import { frProject } from './translations/budgeting/project/project.fr'
import { enProject } from './translations/budgeting/project/project.en'
import { enQuarter } from './translations/budgeting/quarter/quarter.en'
import { frQuarter } from './translations/budgeting/quarter/quarter.fr'
import { frRegionRunnerPeriod } from './translations/budgeting/region-runner-period/region-runner-period.fr'
import { enRegionRunnerPeriod } from './translations/budgeting/region-runner-period/region-runner-period.en'
import { enRunnerComponent } from './translations/budgeting/runner-component/runner-component.en'
import { frRunnerComponent } from './translations/budgeting/runner-component/runner-component.fr'
import { enRunnerComponentHistory } from './translations/budgeting/runner-component-history/runner-component-history.en'
import { frRunnerComponentHistory } from './translations/budgeting/runner-component-history/runner-component-history.fr'
import { frRunnerHistory } from './translations/budgeting/runner-history/runner-history.fr'
import { enRunnerHistory } from './translations/budgeting/runner-history/runner-history.en'
import { enRunnerPeriodComponent } from './translations/budgeting/runner-period-component/runner-period-component.en'
import { frRunnerPeriodComponent } from './translations/budgeting/runner-period-component/runner-period-component.fr'
import { frRunnerPeriodStatus } from './translations/budgeting/runner-period-status/runner-period-status.fr'
import { enRunnerPeriodStatus } from './translations/budgeting/runner-period-status/runner-period-status.en'
import { enRunnerStatus } from './translations/budgeting/runner-status/runner-status.en'
import { frRunnerStatus } from './translations/budgeting/runner-status/runner-status.fr'
import { frSiteType } from './translations/budgeting/site-type/site-type.fr'
import { enSiteType } from './translations/budgeting/site-type/site-type.en'
import { enSite } from './translations/budgeting/site/site.en'
import { frSite } from './translations/budgeting/site/site.fr'
import { frTransactionCode } from './translations/budgeting/transaction-code/transaction-code.fr'
import { enTransactionCode } from './translations/budgeting/transaction-code/transaction-code.en'
import { enUserCoordination } from './translations/budgeting/user-coordination/user-coordination.en'
import { frUserCoordination } from './translations/budgeting/user-coordination/user-coordination.fr'
import { frZone } from './translations/budgeting/zone/zone.fr'
import { enZone } from './translations/budgeting/zone/zone.en'
import { enTarget } from './translations/budgeting/target/target.en'
import { frTarget } from './translations/budgeting/target/target.fr'
import { frAnnualTarget } from './translations/budgeting/annual-target/annual-target.fr'
import { enAnnualTarget } from './translations/budgeting/annual-target/annual-target.en'
import { enOutlay } from './translations/budgeting/outlay/outlay.en'
import { frOutlay } from './translations/budgeting/outlay/outlay.fr'
import { frCopYearOutlay } from './translations/budgeting/cop-year-outlay/cop-year-outlay.fr'
import { enCopYearOutlay } from './translations/budgeting/cop-year-outlay/cop-year-outlay.en'
import { enSiteReport } from './translations/common/site-report/site-report.en'
import { frSiteReport } from './translations/common/site-report/site-report.fr'
import { enBranch } from './translations/common/branch/branch.en'
import { enBreadCrumb } from './translations/common/bread-crumbs/bread-crumb.en'
import { frBreadCrumb } from './translations/common/bread-crumbs/bread-crumb.fr'
import { enDashboard } from './translations/budgeting/dashboard/dashboard.en'
import { frDashboard } from './translations/budgeting/dashboard/dashboard.fr'
import { enModifications } from './translations/budgeting/modifications/modifications.en'
import { frModifications } from './translations/budgeting/modifications/modificationsfr'
import { enUser } from './translations/common/user/user.en'
import { frUser } from './translations/common/user/user.fr'
import { enRole } from './translations/common/role/role.en'
import { frRole } from './translations/common/role/role.fr'
import { enClaim } from './translations/common/claim/claim.en'
import { frClaim } from './translations/common/claim/claim.fr'

const resources = {
    en: {
        translation: {
            appTitle: 'CBCHS ERP',
            Profile: 'User profile',
        },
        common: {
            ...enTableHeaders,
            ...enSideBarMenus,
            religion: {
                ...enReligion,
            },
            contact_relation: {
                ...enContactRelation,
            },
            division: {
                ...enDivision,
            },
            branch: {
                ...enBranch,
            },
            source: {
                ...enSource,
            },
            stationReference: {
                ...enStationReference,
            },
            branchStation: {
                ...enBranchStation,
            },
            department: {
                ...enDepartment,
            },
            sub_division: {
                ...enSubDivision,
            },
            location: {
                ...enLocation,
            },
            segment: {
                ...enSegment,
            },
            siteReport: {
                ...enSiteReport,
            },
            breadCrumb: {
                ...enBreadCrumb,
            },
            user: {
                ...enUser,
            },
            role: {
                ...enRole
            },
            claim: {
                ...enClaim
            }
        },
        budgeting: {
            activity: {
                ...enActivity,
            },
            runner: {
                ...enRunner,
            },
            runnerPeriod: {
                ...enRunnerPeriod,
            },
            activityPlan: {
                ...enActivityPlan,
            },
            activityPlanSummary: {
                ...enActivityPlanSummary,
            },
            Budget: {
                ...enBudget,
            },
            component: {
                ...enComponent,
            },
            copYearBudgetCode: {
                ...enCopYearBudgetCode,
            },
            copYearIntervention: {
                ...enCopYearIntervention,
            },
            copYearSite: {
                ...enCopYearSite,
            },
            copYear: {
                ...enCopYear,
            },
            costCategory: {
                ...enCostCategory,
            },
            district: {
                ...enDistrict,
            },
            includeRunner: {
                ...enIncludeRunner,
            },
            intervention: {
                ...enIntervention,
            },
            milestoneAchievement: {
                ...enMilestoneAchievement,
            },
            milestoneProjection: {
                ...enMilestoneProjection,
            },
            project: {
                ...enProject,
            },
            quarter: {
                ...enQuarter,
            },
            regionRunnerPeriod: {
                ...enRegionRunnerPeriod,
            },
            runnerComponent: {
                ...enRunnerComponent,
            },
            runnerComponentHistory: {
                ...enRunnerComponentHistory,
            },
            runnerHistory: {
                ...enRunnerHistory,
            },
            runnerPeriodcomponent: {
                ...enRunnerPeriodComponent,
            },
            runnerPeriodStatus: {
                ...enRunnerPeriodStatus,
            },
            runnerStatus: {
                ...enRunnerStatus,
            },
            siteType: {
                ...enSiteType,
            },
            site: {
                ...enSite,
            },
            transactionCode: {
                ...enTransactionCode,
            },
            userCoordination: {
                ...enUserCoordination,
            },
            zone: {
                ...enZone,
            },
            target: {
                ...enTarget,
            },
            annualTarget: {
                ...enAnnualTarget,
            },
            outlay: {
                ...enOutlay,
            },
            copYearOutlay: {
                ...enCopYearOutlay,
            },
            dashboard: {
                ...enDashboard,
            },
            modifications: {
                ...enModifications,
            },
        },
    },
    fr: {
        translation: {
            appTitle: 'CBCHS ERP',
            Profile: 'fr profile',
        },
        common: {
            ...frTableHeaders,
            ...frSideBarMenus,
            religion: {
                ...frReligion,
            },
            contact_relation: {
                ...frContactRelation,
            },
            division: {
                ...frDivision,
            },
            branch: {
                ...frBranch,
            },
            source: {
                ...frSource,
            },
            stationReference: {
                ...frStationReference,
            },
            branchStation: {
                ...frBranchStation,
            },
            department: {
                ...frDepartment,
            },
            sub_division: {
                ...frSubDivision,
            },
            location: {
                ...frLocation,
            },
            segment: {
                ...frSegment,
            },
            siteReport: {
                ...frSiteReport,
            },
            breadCrumb: {
                ...frBreadCrumb,
            },
            user: {
                ...frUser,
            },
            role: {
                ...frRole
            },
            claim: {
                ...frClaim
            }
        },

        /* Client translations */
        budgeting: {
            activity: {
                ...frActivity,
            },
            runner: {
                ...frRunner,
            },
            runnerPeriod: {
                ...frRunnerPeriod,
            },
            activityPlan: {
                ...frActivityPlan,
            },
            activityPlanSummary: {
                ...frActivityPlanSummary,
            },
            budget: {
                ...frBudget,
            },
            component: {
                ...frComponent,
            },
            copYearBudgetCode: {
                ...frCopYearBudgetCode,
            },
            copYearIntervention: {
                ...frCopYearIntervention,
            },
            copYearSite: {
                ...frCopYearSite,
            },
            copYear: {
                ...frCopYear,
            },
            costCategory: {
                ...frCostCategory,
            },
            district: {
                ...frDistrict,
            },
            includeRunner: {
                ...frIncludeRunner,
            },
            intervention: {
                ...frIntervention,
            },
            milestoneAchievement: {
                ...frMilestoneAchievement,
            },
            milestoneProjection: {
                ...frMilestoneProjection,
            },
            project: {
                ...frProject,
            },
            quarter: {
                ...frQuarter,
            },
            regionRunnerPeriod: {
                ...frRegionRunnerPeriod,
            },
            runnerComponent: {
                ...frRunnerComponent,
            },
            runnerComponentHistory: {
                ...frRunnerComponentHistory,
            },
            runnerHistory: {
                ...frRunnerHistory,
            },
            runnerPeriodComponent: {
                ...frRunnerPeriodComponent,
            },
            runnerPeriodStatus: {
                ...frRunnerPeriodStatus,
            },
            runnerStatus: {
                ...frRunnerStatus,
            },
            siteType: {
                ...frSiteType,
            },
            site: {
                ...frSite,
            },
            transactionCode: {
                ...frTransactionCode,
            },
            userCoordination: {
                ...frUserCoordination,
            },
            zone: {
                ...frZone,
            },
            target: {
                ...frTarget,
            },
            annualTarget: {
                ...frAnnualTarget,
            },
            outlay: {
                ...frOutlay,
            },
            copYearOutlay: {
                ...frCopYearOutlay,
            },
            dashboard: {
                ...frDashboard,
            },
            modifications: {
                ...frModifications,
            },
        },
    },
}

i18n.use(initReactI18next).init({
    interpolation: {
        escapeValue: false,
    },
    lng: 'en',
    ns: ['ns1', 'ns2'],
    resources,
})

export default i18n
