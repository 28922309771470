import { apiRequests } from '../'
import { ISite, ISiteResponse } from '../../models/budgeting/site.model'

const siteService = {
    list: (region: string): Promise<ISite[]> =>
        apiRequests.get(`/sites?region=${region}`),
    details: (code: string): Promise<ISite> => apiRequests.get(`/sites/${code}`),
    create: (site: ISite): Promise<ISiteResponse> =>
        apiRequests.post('/sites', site),
    update: (site: ISite): Promise<ISiteResponse> => apiRequests.put('/sites', site),
}

export default siteService
