import { Card, Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useDialogMessage } from '../../../hooks/common/dialog-message/dialog-message.hook'

const DialogMessageDetailComponent: React.FC = () => {
    const { dialogMessage } = useDialogMessage()
    return (
        <Card bordered={false} size="small">
            <Row>
                <Col span={4}>
                    <StyledParagraph>Locale: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{dialogMessage.lid}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Code: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{dialogMessage.code}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Description: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{dialogMessage.description}</StyledParagraph>
                </Col>
            </Row>
        </Card>
    )
}

export default DialogMessageDetailComponent

const StyledParagraph = styled.p`
    margin-bottom: 0;
`
