import { Table } from 'antd'
import { IMilestoneDashboard } from '../../../models/budgeting/milestone.model'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useMilestoneProjectionTableColumns } from './milestone-projection-table.column'

interface IProps {
    milestoneDashboard: IMilestoneDashboard[]
    isRefreshing: boolean
}
const MilestoneProjectionTable: React.FC<IProps> = ({
    milestoneDashboard,
    isRefreshing,
}) => {
    const { columns } = useMilestoneProjectionTableColumns()
    return (
        <>
            {milestoneDashboard && milestoneDashboard.length > 0 ? (
                <Table<IMilestoneDashboard>
                    dataSource={milestoneDashboard}
                    columns={columns}
                    size={'small'}
                    scroll={{ x: '500' }}
                    rowKey={'code'}
                    pagination={false}
                    loading={isRefreshing}
                />
            ) : (
                <NoContent
                    title="No data for milestone projection"
                    showButton={false}
                />
            )}
        </>
    )
}

export default MilestoneProjectionTable
