import { Spin } from 'antd'
import React, { useContext, useEffect } from 'react'
import { FiPlus } from 'react-icons/fi'
import RunnerPeriodForm from '../../../components/budgeting/runner-period/runner-period-form.component'
import RunnerPeriodTable from '../../../components/budgeting/runner-period/runner-period-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useRunnerPeriod } from '../../../hooks/budgeting/runner-period.hook'
import { emptyRunnerPeriod } from '../../../models/budgeting/runner-period.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import { useTranslation } from 'react-i18next'
import useRoleAction from '../../../constants/role-action-hook'

const RunnerPeriodPage: React.FC = () => {
    const { ROLE_ACTIONS } = useRoleAction()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { t } = useTranslation()

    const { reloadRunnerPeriods, isLoading, initialFetch, setRunnerPeriod } =
        useRunnerPeriod()

    const handleShow = () => {
        setTitle('Create Runner Period')
        setRunnerPeriod(emptyRunnerPeriod)
        setContent(<RunnerPeriodForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    useEffect(() => {
        reloadRunnerPeriods()
    }, [reloadRunnerPeriods])

    return (
        <>
            <PageBreadCrumbs
                items={[
                    t('budgeting:runnerPeriod.breadcrumb.runnerPeriod'),
                    t('budgeting:runnerPeriod.breadcrumb.list'),
                ]}
            />
            <TitleBar
                title={t('budgeting:runnerPeriod.mainPage.title')}
                subTitle={t('budgeting:runnerPeriod.mainPage.subTitle')}
                showButton={ROLE_ACTIONS.RUNNER_PERIOD.CREATE}
                handleShow={handleShow}
                buttonLabel={t('budgeting:runnerPeriod.mainPage.buttonLabel')}
                icon={<FiPlus />}
            />
            {isLoading && initialFetch ? (
                <Spin size="large" />
            ) : (
                <RunnerPeriodTable />
            )}
        </>
    )
}

export default RunnerPeriodPage
