import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as strategyActions from '../../redux/budgeting/strategy.slice'
import { IStrategy } from '../../models/budgeting/strategy.model'
import { UpdateMode } from '../../models/update-mode.enum'
import strategyService from '../../services/budgeting/strategy.service'

const useStrategy = () => {
    const strategies = useSelector<rootState, IStrategy[]>(
        (state) => state.strategy.strategies
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.strategy.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.strategy.initialFetch
    )
    const strategy = useSelector<rootState, IStrategy>(
        (state) => state.strategy.strategy
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.strategy.updateMode
    )

    const dispatch = useDispatch()

    const loadStrategies = useCallback(() => {
        if (initialFetch) {
            dispatch(strategyActions.fetchStrategiesAsync())
        }
    }, [dispatch, initialFetch])

    const getStragtegyDescription = useCallback(
        (code: string) => {
            return strategies.find((s) => s.code === code)?.description
        },
        [strategies]
    )

    const addStrategy = async (strategy: IStrategy) => {
        return await strategyService
            .create(strategy)
            .then((strategyResponse) => {
                if (strategyResponse.success) {
                    dispatch(
                        strategyActions.addStrategySuccess(strategyResponse.data)
                    )
                } else {
                    return strategyResponse
                }
                return strategyResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setStrategy = (strategy: IStrategy) => {
        dispatch(strategyActions.setActiveStrategy(strategy))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(strategyActions.setStrategyUpdateMode(updateMode))
    }

    const editStrategy = async (strategy: IStrategy) => {
        return await strategyService
            .update(strategy)
            .then((strategyResponse) => {
                dispatch(strategyActions.editStrategySuccess(strategyResponse.data))
                setStrategy(strategyResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveStrategy = async (strategy: IStrategy, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addStrategy(strategy)
            : await editStrategy(strategy)
    }

    useEffect(() => {
        loadStrategies()
    }, [strategy, strategies, isLoading, initialFetch, loadStrategies])

    return {
        strategy,
        strategies,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addStrategy,
        editStrategy,
        saveStrategy,
        setStrategy,
        getStragtegyDescription,
    }
}

export { useStrategy }
