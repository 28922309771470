import { apiRequests } from '..'
import { IOutlayAnalysisOption } from '../../models/budgeting/outlay-analysis-options.model'

export const dashboardReportService = {
    outlayVsTarget: ({region, dashboardType, component, tenant}: IOutlayAnalysisOption): Promise<any> => apiRequests
                    .get(`/outlayAnalysis/report?Tenant=${tenant}&Region=${region}&DashboardType=${dashboardType}&Component=${component}`),
    costCategories: (): Promise<any> => apiRequests.get('/costCategories'),
    intervention: (): Promise<any> => apiRequests.get('/interventions'),
    milestoneProjection: (): Promise<any> =>
        apiRequests.get('/milestoneProjections'),
    dailyReport: (): Promise<any> => apiRequests.get('/dailyReport'),
}
