import { Reducer } from 'redux'
import {
    emptySearchValue,
    ISearchValueState,
} from '../../../models/shared/search-value.model'
import * as SearchValueTypes from './search-value.types'
import { SetSearchValueAction } from './search-value.types'

export const INITIAL_STATE: ISearchValueState = {
    searchValue: emptySearchValue,
}

const reducer: Reducer<ISearchValueState, SetSearchValueAction> = (
    state = INITIAL_STATE,
    action: SetSearchValueAction
): ISearchValueState => {
    switch (action.type) {
        case SearchValueTypes.searchValueActionTypes.SET_SEARCH_VALUE: {
            return {
                ...state,
                searchValue: { ...state.searchValue, value: action.payload },
            }
        }
        default:
            return state
    }
}

export { reducer as searchValueReducer }
