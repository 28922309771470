import React from 'react'
import { Table } from 'antd'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'
import { useRole } from '../../../hooks/common/role/role.hook'
import { IRole } from '../../../models/common/role.model'
import { rolesTableColumns } from './roles-table-columns.component'

export const RolesTable: React.FC = () => {
    const { roles, setRole } = useRole()
    const history = useHistory()
    const handleRowClick = (role: IRole) => {
        setRole(role)
        history.push(`/roles/${slugify(role.description)}`)
    }

    return (
        <div>
            <Table<IRole>
                dataSource={roles}
                columns={rolesTableColumns}
                size={'small'}
                rowKey={'code'}
                pagination={false}
                onRow={(record: IRole) => {
                    return {
                        onClick: (e) => {
                            handleRowClick(record)
                        },
                    }
                }}
            />
        </div>
    )
}
