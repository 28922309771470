import { ISideBarLink } from '../sidebar.data'
import { BiCategoryAlt } from 'react-icons/bi'
import { TbReport, TbReportAnalytics } from 'react-icons/tb'
import { AiOutlineInteraction, AiOutlineFundProjectionScreen } from 'react-icons/ai'
import { appRoutes } from '../../../constants/app-route'

export const surgeActivitySubmenus: ISideBarLink[] = [
    {
        app: '01',
        code: 'J00',
        description: 'Surge Activities',
        route: appRoutes.SURGE_ACTIVITY.SURGE_ACTIVITY_BATCH_SUMMARY,
        dropDown: false,
        dropDownMenus: [],
        parent: '',
        icon: <BiCategoryAlt />,
    },
    {
        app: '01',
        code: 'J01',
        description: 'Milestone Projections',
        route: appRoutes.SURGE_ACTIVITY.SURGE_MILESTONE_PROJECTION,
        dropDown: false,
        dropDownMenus: [],
        parent: '',
        icon: <BiCategoryAlt />,
    },
    {
        app: '01',
        parent: '',
        code: 'J02',
        description: 'Milestone Achievements',
        route: appRoutes.SURGE_ACTIVITY.SURGE_MILESTONE_ACHIEVEMENT,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReportAnalytics />,
    },
    {
        app: '01',
        parent: '',
        code: 'J03',
        description: 'BO Program Mgt',
        route: appRoutes.SURGE_ACTIVITY.SURGE_PROGRAM_MANAGEMENT_BO,
        dropDown: false,
        dropDownMenus: [],
        icon: <AiOutlineInteraction />,
    },
    {
        app: '01',
        parent: '',
        code: 'J04',
        description: 'DHS Program Mgt',
        route: appRoutes.SURGE_ACTIVITY.SURGE_PROGRAM_MANAGEMENT_DHS,
        dropDown: false,
        dropDownMenus: [],
        icon: <AiOutlineFundProjectionScreen />,
    },
    {
        app: '01',
        parent: '',
        code: 'J05',
        description: 'MVAN Program Mgt',
        route: appRoutes.SURGE_ACTIVITY.SURGE_PROGRAM_MANAGEMENT_MVAN,
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReport />,
    },
]
