export const roleActions = {
    runner: {
        create: ['00', '01'],
        edit: ['00',  '01'],
        view: ['00',  '01'],
    },
    runnerPeriod: {
        create: ['00'],
        edit: ['00'],
        view: ['00'],
    },
}
