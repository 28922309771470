import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as budgetHistoryModel from '../../models/budgeting/budget-history.model'
import budgetHistoryService from '../../services/budgeting/budget-history.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: budgetHistoryModel.IBudgetHistoryState = {
    budgetHistories: [],
    errors: '',
    budgetHistory: budgetHistoryModel.emptyBudgetHistory,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchbudgetHistoriesAsync = createAsyncThunk<
    budgetHistoryModel.IBudgetHistory[],
    void
>('budgetHistory/fetchbudgetHistoriesAsync', async (_, thunkApi) => {
    try {
        return await budgetHistoryService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const budgetHistorySlice = createSlice({
    name: 'budgetHistory',
    initialState,
    reducers: {
        fetchbudgetHistoriesRequest: (state) => {
            state.isLoading = true
        },
        fetchbudgetHistoriesSuccess: (
            state,
            action: PayloadAction<budgetHistoryModel.IBudgetHistory[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.budgetHistories = action.payload
        },
        fetchbudgetHistoriesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editBudgetHistorySuccess: (
            state,
            action: PayloadAction<budgetHistoryModel.IBudgetHistory>
        ) => {
            state.budgetHistories = state.budgetHistories.map((budgetHistory) => {
                return budgetHistory.activity === action.payload.activity
                    ? action.payload
                    : budgetHistory
            })
            state.updateMode = UpdateMode.NONE
        },
        addBudgetHistorySuccess: (
            state,
            action: PayloadAction<budgetHistoryModel.IBudgetHistory>
        ) => {
            state.budgetHistories = [...state.budgetHistories, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveBudgetHistory: (
            state,
            action: PayloadAction<budgetHistoryModel.IBudgetHistory>
        ) => {
            state.budgetHistory = action.payload
        },
        setBudgetHistoryUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchbudgetHistoriesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchbudgetHistoriesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.budgetHistories = action.payload
        })
        builder.addCase(fetchbudgetHistoriesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchbudgetHistoriesRequest,
    fetchbudgetHistoriesSuccess,
    fetchbudgetHistoriesError,
    editBudgetHistorySuccess,
    addBudgetHistorySuccess,
    setActiveBudgetHistory,
    setBudgetHistoryUpdateMode,
} = budgetHistorySlice.actions

const reducer = budgetHistorySlice.reducer

export { reducer as budgetHistoryReducer }
