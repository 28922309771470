import { apiRequests } from '..'
import {
    ICopYearSite,
    ICopYearSiteResponse,
} from '../../models/budgeting/cop-year-site.model'

const copYearSiteService = {
    list: (): Promise<ICopYearSite[]> => apiRequests.get('/copYearSites'),
    details: (code: string): Promise<ICopYearSite> =>
        apiRequests.get(`/copYearSites/${code}`),
    create: (copyearsite: ICopYearSite): Promise<ICopYearSiteResponse> =>
        apiRequests.post('/copYearSites', copyearsite),
    update: (copyearsite: ICopYearSite): Promise<ICopYearSiteResponse> =>
        apiRequests.put('/copYearSites', copyearsite),
}

export default copYearSiteService
