import { apiRequests } from '..'
import { IChart } from '../../models/budgeting/chart.model'
import { IMilestoneDashParameters } from '../../models/budgeting/milestone-dash-parameters.model'

const milestoneDashService = {
    list: (params: IMilestoneDashParameters): Promise<IChart> =>
        apiRequests.get(
            `/milestoneDash?region=${params.region}&copYear=${params.copYear}&project=${params.project}&component=${params.component}`
        ),
}

export default milestoneDashService
