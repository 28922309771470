import {
    ICurrentUser,
    ICurrentUserResponse,
} from '../../../models/common/current-user.model'
import { apiRequests } from '../../../services'

const currentUserService = {
    list: (): Promise<ICurrentUser[]> => apiRequests.get('/currentUsers'),
    details: (code: string): Promise<ICurrentUser> =>
        apiRequests.get(`/currentUsers/${code}`),
    create: (currentUser: ICurrentUser): Promise<ICurrentUserResponse> =>
        apiRequests.post('/currentUsers', currentUser),
    update: (currentUser: ICurrentUser): Promise<ICurrentUserResponse> =>
        apiRequests.put('/currentUsers', currentUser),
}

export default currentUserService
