import { Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton, ResetButton, Select } from 'formik-antd'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useActivityPlan } from '../../../../hooks/budgeting/activity-plan.hook'
import { useMilestone } from '../../../../hooks/budgeting/milestone.hook'
import { useSite } from '../../../../hooks/budgeting/site.hook'
import { IMilestone } from '../../../../models/budgeting/milestone.model'
import {
    FlexedDiv,
    FormLabels,
    SizedLabel,
    width100,
} from '../../../profile/profile.styles'

const { TextArea } = Input
const FormItem = Form.Item

const MilestoneAchievementFormFieldsUpdate: React.FC = () => {
    const { isSubmitting, setValues, setFieldValue } = useFormikContext<IMilestone>()
    const { t } = useTranslation()
    const { activityPlans } = useActivityPlan()
    const { sites } = useSite()
    const { milestone } = useMilestone()

    useEffect(() => {
        setValues(milestone)
    }, [milestone])

    // const siteDescription =
    //     sites && sites.length > 0
    //         ? sites.find((s) => s.code === site)?.description
    //         : ' '

    const handleSelectActivityPlan = useCallback((activity: string) => {
        console.log('activitiy: ', activity)
        setFieldValue('activity', activity)
    }, [])
    const handleSelectSite = useCallback((site: string) => {
        console.log('site: ', site)
        setFieldValue('site', site)
    }, [])

    return (
        <>
            <Form autoComplete="off">
                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:milestoneProjection.form.site')}
                        </FormLabels>
                        <FormItem name="site" style={{ marginBottom: 5 }}>
                            <Select
                                style={width100}
                                disabled={isSubmitting}
                                onChange={handleSelectSite}
                                placeholder="Select a Runner Period"
                                name={'site'}
                            >
                                {sites && sites.length > 0
                                    ? sites.map((site) => (
                                          <Select.Option
                                              value={site.code}
                                              key={site.code}
                                          >
                                              {site.description}
                                          </Select.Option>
                                      ))
                                    : []}
                            </Select>
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:milestoneProjection.form.activity')}
                        </FormLabels>
                        <FormItem name="activity" style={{ marginBottom: 5 }}>
                            <Select
                                style={width100}
                                disabled={isSubmitting}
                                onChange={handleSelectActivityPlan}
                                placeholder="Select activity"
                                name={'activity'}
                            >
                                {activityPlans && activityPlans.length > 0
                                    ? activityPlans.map((activityPlan) => (
                                          <Select.Option
                                              value={activityPlan.code}
                                              key={activityPlan.code}
                                          >
                                              {activityPlan.description}
                                          </Select.Option>
                                      ))
                                    : []}
                            </Select>
                            {/* <Input
                                name="activity"
                                style={width100}
                                disabled={true}
                                value={
                                    milestone.activity +
                                        ' - ' +
                                        getActivityPlan(milestone.activity)
                                            ?.description ?? ''
                                }
                            /> */}
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:milestoneProjection.form.budgetNote')}
                        </FormLabels>
                        <FormItem
                            name="budgetNote"
                            required={true}
                            style={{ marginBottom: 5 }}
                        >
                            <TextArea
                                name="budgetNote"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:milestoneAchievement.form.target')}
                        </FormLabels>
                        <FormItem name="target" style={{ marginBottom: 5 }}>
                            <Input name="target" style={width100} disabled={true} />
                        </FormItem>
                    </SizedLabel>

                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:milestoneAchievement.form.achievement')}
                        </FormLabels>
                        <FormItem name="achievement" style={{ marginBottom: 5 }}>
                            <Input
                                name="achievement"
                                style={width100}
                                disabled={isSubmitting}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <Space size={'small'} style={{ marginTop: 10 }}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('budgeting:runner.form.submit')}
                    </SubmitButton>
                    <ResetButton disabled={isSubmitting}>
                        {t('budgeting:runner.form.reset')}
                    </ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default MilestoneAchievementFormFieldsUpdate
