import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RouteProps } from 'react-router'
import { rootState } from '../../../redux/root-reducer'
import AppShell from '../../../layout/app-shell/app-shell.component'

const ProtectedRoute: React.FC<RouteProps> = ({
    children,
    component: Component,
    ...rest
}) => {
    const user = useSelector<rootState>((state) => state.auth.user)

    return user ? (
        <AppShell>
            <Route {...rest} component={Component} />
        </AppShell>
    ) : (
        <Redirect to={'/welcome'} />
    )
}

export default ProtectedRoute
