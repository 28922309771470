import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IOutlay {
    tenant: string
    code: string
    budgetCode: string
    indicator: string
    type: string
    createdOn: Date
}

export const emptyOutlay: IOutlay = {
    tenant: '',
    code: '',
    budgetCode: '',
    indicator: '',
    type: '',
    createdOn: new Date(),
}

export interface IOutlayState extends IBaseState {
    readonly outlays: IOutlay[]
    readonly outlay: IOutlay
}

export interface IOutlayResponse extends IResponseBase {
    data: IOutlay
}
