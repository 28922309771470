import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as sourceModel from '../../../models/common/source.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import sourceService from '../../../services/common/source/source.service'

export const initialState: sourceModel.ISourceState = {
    sources: [],
    errors: '',
    source: sourceModel.emptySource,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchSourcesAsync = createAsyncThunk(
    'source/fetchSourcesAsync',
    async () => {
        return await sourceService.list()
    }
)

export const sourceSlice = createSlice({
    name: 'source',
    initialState,
    reducers: {
        fetchSourcesRequest: (state) => {
            state.isLoading = true
        },
        fetchSourcesSuccess: (
            state,
            action: PayloadAction<sourceModel.ISource[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.sources = action.payload
        },
        fetchSourcesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editSourceSuccess: (state, action: PayloadAction<sourceModel.ISource>) => {
            state.sources = state.sources.map((source) => {
                return source.code === action.payload.code ? action.payload : source
            })
            state.updateMode = UpdateMode.NONE
        },
        addSourceSuccess: (state, action: PayloadAction<sourceModel.ISource>) => {
            state.sources = [...state.sources, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveSource: (state, action: PayloadAction<sourceModel.ISource>) => {
            state.source = action.payload
        },
        setSourceUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSourcesAsync.pending, (_state, _action) => {
            // fetchSourcesRequest();
            _state.isLoading = true
        })
        builder.addCase(fetchSourcesAsync.fulfilled, (_state, action) => {
            // fetchSourcesSuccess(action.payload);
            _state.isLoading = false
            _state.initialFetch = false
            _state.sources = action.payload
        })
        builder.addCase(fetchSourcesAsync.rejected, (_state, action) => {
            // fetchSourcesError(action.payload as string);
            _state.isLoading = false
            // state.errors = action.payload;
        })
    },
})

export const {
    fetchSourcesRequest,
    fetchSourcesSuccess,
    fetchSourcesError,
    editSourceSuccess,
    addSourceSuccess,
    setActiveSource,
    setSourceUpdateMode,
} = sourceSlice.actions

const reducer = sourceSlice.reducer

export { reducer as sourceReducer }
