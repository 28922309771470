import { apiRequests } from '../'
import {
    ICopYearIntervention,
    ICopYearInterventionResponse,
} from '../../models/budgeting/cop-year-intervention.model'

const copYearInterventionService = {
    list: (): Promise<ICopYearIntervention[]> =>
        apiRequests.get('/copYearInterventions'),
    details: (code: string): Promise<ICopYearIntervention> =>
        apiRequests.get(`/copYearInterventions/${code}`),
    create: (
        copyearintervention: ICopYearIntervention
    ): Promise<ICopYearInterventionResponse> =>
        apiRequests.post('/copYearInterventions', copyearintervention),
    update: (
        copyearintervention: ICopYearIntervention
    ): Promise<ICopYearInterventionResponse> =>
        apiRequests.put('/copYearInterventions', copyearintervention),
}

export default copYearInterventionService
