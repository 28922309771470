import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import * as userActions from '../../../redux/shared/user/user.slice'
import { UpdateMode } from '../../../models/update-mode.enum'
import * as Yup from 'yup'
import { IUser } from '../../../models/shared/user.model'
import userService from '../../../services/shared/user/user.service'

const useUser = () => {
    const users = useSelector<rootState, IUser[]>((state) => state.user.users)
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.user.isLoading
    )
    const isSearching = useSelector<rootState, boolean>(
        (state) => state.user.isSearching!
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.user.isLoading
    )
    const user = useSelector<rootState, IUser>((state) => state.user.user)
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.user.updateMode
    )

    const dispatch = useDispatch()

    const userSchema = Yup.object().shape({
        code: Yup.string().max(10, 'Too Long!').required('Required'),
        userName: Yup.string().max(75, 'Too Long!').required('Required'),
        gender: Yup.string().max(2, 'Too Long!').required('Required'),
    })

    const loadUsers = useCallback(() => {
        if (initialFetch) {
            dispatch(userActions.fetchUsersAsync())
        }
    }, [dispatch, initialFetch])

    const addUser = async (user: IUser) => {
        return await userService
            .create(user)
            .then((userResponse) => {
                if (userResponse.success) {
                    dispatch(userActions.addUserSuccess(userResponse.data))
                } else {
                    return userResponse
                }
                return userResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setUser = (user: IUser) => {
        dispatch(userActions.setActiveUser(user))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(userActions.setUserUpdateMode(updateMode))
    }

    const editUser = async (user: IUser) => {
        return await userService
            .update(user)
            .then((userResponse) => {
                dispatch(userActions.editUserSuccess(userResponse.data))
                setUser(userResponse.data)
                return userResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const saveUser = async (user: IUser, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addUser(user)
            : await editUser(user)
    }

    const [searchUsers, setSearchUsers] = useState<IUser[]>([])
    const loadUsersSearch = useCallback(
        async (searchQuery: string) => {
            dispatch(userActions.searchUsersRequest())
            await userService
                .search(searchQuery)
                .then((users) => {
                    setSearchUsers(users)
                    dispatch(userActions.searchUsersSuccess(users))
                    dispatch(userActions.fetchUsersSuccess(users))
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        [dispatch]
    )

    useEffect(() => {
        loadUsers()
    }, [user, users, isLoading, initialFetch, loadUsers])

    return {
        user,
        users,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addUser,
        editUser,
        saveUser,
        setUser,
        userSchema,
        loadUsersSearch,
        searchUsers,
        isSearching,
    }
}

export { useUser }
