import { ColumnsType } from "antd/lib/table";
import { ACTIVITY_TYPE_RECORD } from "../../../constants/configurations.constant";
import { IActivity } from "../../../models/budgeting/activity.model";
import { format } from "../../../utils/format";

export const activityHistoryTableColumns: ColumnsType<IActivity> = [
    {
        title: 'Line',
        dataIndex: 'batchLine',
        key: 'batchLine',
        width: '6rem',
        filtered: true,
    },
    {
        title: 'Activity',
        dataIndex: 'activity',
        key: 'activity',
        width: '10rem',
    },
    {
        title: 'Activity Type',
        width: '8rem',
        dataIndex: 'activityType',
        key: 'activityType',
        render: (_, record) => ACTIVITY_TYPE_RECORD[record.activityType]
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        ellipsis: true,
    },
    {
        title: 'Budget code',
        dataIndex: 'budgetCode',
        key: 'budgetCode',
        width: '7rem',
        align: 'right',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: '10rem',
        align: 'right',
        render: (_, record) => format.number(record.amount),
    },
]

