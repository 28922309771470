import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as regionActions from '../../redux/budgeting/region.slice'
import { IRegion } from '../../models/budgeting/region.model'
import { UpdateMode } from '../../models/update-mode.enum'
import regionService from '../../services/budgeting/region.service'

const useRegion = () => {
    const regions = useSelector<rootState, IRegion[]>(
        (state) => state.region.regions
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.region.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.region.initialFetch
    )
    const region = useSelector<rootState, IRegion>((state) => state.region.region)
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.region.updateMode
    )

    const dispatch = useDispatch()

    const loadRegions = useCallback(() => {
        if (initialFetch) {
            dispatch(regionActions.fetchRegionsAsync())
        }
    }, [dispatch, initialFetch])

    const addRegion = async (region: IRegion) => {
        return await regionService
            .create(region)
            .then((regionResponse) => {
                if (regionResponse.success) {
                    dispatch(regionActions.addRegionSuccess(regionResponse.data))
                } else {
                    return regionResponse
                }
                return regionResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setRegion = (region: IRegion) => {
        dispatch(regionActions.setActiveRegion(region))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(regionActions.setRegionUpdateMode(updateMode))
    }

    const editRegion = async (region: IRegion) => {
        return await regionService
            .update(region)
            .then((regionResponse) => {
                dispatch(regionActions.editRegionSuccess(regionResponse.data))
                setRegion(regionResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveRegion = async (region: IRegion, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addRegion(region)
            : await editRegion(region)
    }

    useEffect(() => {
        loadRegions()
    }, [region, regions, isLoading, initialFetch, loadRegions])

    return {
        region,
        regions,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addRegion,
        editRegion,
        saveRegion,
        setRegion,
    }
}

export { useRegion }
