import { apiRequests } from '../..'
import {
    IClaimType,
    IClaimTypeResponse,
} from '../../../models/common/claim-type.model'

const claimTypeService = {
    list: (): Promise<IClaimType[]> => apiRequests.get('/claimTypes'),
    details: (code: string): Promise<IClaimType> =>
        apiRequests.get(`/claimTypes/${code}`),
    create: (claimType: IClaimType): Promise<IClaimTypeResponse> =>
        apiRequests.post('/claimTypes', claimType),
    update: (claimType: IClaimType): Promise<IClaimTypeResponse> =>
        apiRequests.put('/claimTypes', claimType),
}

export default claimTypeService
