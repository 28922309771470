import { apiRequests } from '..'
import {
    ITransactionCode,
    ITransactionCodeResponse,
} from '../../models/budgeting/transaction-code.model'

const transactionCodeService = {
    list: (): Promise<ITransactionCode[]> => apiRequests.get('/transactionCodes'),
    details: (code: string): Promise<ITransactionCode> =>
        apiRequests.get(`/transactionCodes/${code}`),
    create: (transactionCode: ITransactionCode): Promise<ITransactionCodeResponse> =>
        apiRequests.post('/transactionCodes', transactionCode),
    update: (transactionCode: ITransactionCode): Promise<ITransactionCodeResponse> =>
        apiRequests.put('/transactionCodes', transactionCode),
}

export default transactionCodeService
