import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { emptyTargetBase, ITargetBase } from './target-base.model'

export interface IAnnualTarget extends ITargetBase {}

export const emptyAnnualTarget: IAnnualTarget = emptyTargetBase

export interface IAnnualTargetState extends IBaseState {
    readonly annualTargets: IAnnualTarget[]
    readonly annualTarget: IAnnualTarget
}

export interface IAnnualTargetResponse extends IResponseBase {
    data: IAnnualTarget
}
