import { ColumnsType } from 'antd/lib/table'
import { ISource } from '../../../models/common/source.model'

export const sourceTableColumns: ColumnsType<ISource> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
