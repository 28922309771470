import { useCurrentUser } from '../hooks/common/current-user/current-user.hook'
import { useUserRole } from '../hooks/common/user-role/user-role.hook'
import { roleActions } from './role-actions'

const useRoleAction = () => {
    const { userRoles } = useUserRole()
    const { currentUser } = useCurrentUser()

    const currentUserRoleCodes = userRoles
        .filter((userRole) => userRole.usrCode === currentUser.code)
        .map((userRole) => userRole.roleCode)

    function checkIfUserCanPerfomAction(actionRoles: Array<string>) {
        return (
            actionRoles.filter((item) => currentUserRoleCodes.includes(item))
                .length > 0
        )
    }
    const ROLE_ACTIONS = {
        RUNNER: {
            CREATE: checkIfUserCanPerfomAction(roleActions.runner.create),
            EDIT: checkIfUserCanPerfomAction(roleActions.runner.edit),
        },
        RUNNER_PERIOD: {
            CREATE: checkIfUserCanPerfomAction(roleActions.runnerPeriod.create),
            EDIT: checkIfUserCanPerfomAction(roleActions.runnerPeriod.edit),
        },
    }
    return {
        ROLE_ACTIONS,
    }
}
export default useRoleAction
