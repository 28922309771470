import { apiRequests } from '../..'
import {
    IServerStatus,
    IServerStatusResponse,
} from '../../../models/common/server-status.model'

const serverStatusService = {
    list: (): Promise<IServerStatus[]> => apiRequests.get('/serverStatuses'),
    // details: (code: string): Promise<IServerStatus> =>
    //   apiRequests.get(`/serverStatuses/${code}`),
    create: (serverStatus: IServerStatus): Promise<IServerStatusResponse> =>
        apiRequests.post('/serverStatuses', serverStatus),
    update: (serverStatus: IServerStatus): Promise<IServerStatusResponse> =>
        apiRequests.put('/serverStatuses', serverStatus),
    branch: (branchCode: string): Promise<IServerStatus> =>
        apiRequests.get(`/serverStatuses/${branchCode}`),
}

export default serverStatusService
