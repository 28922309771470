import { useCallback, useEffect } from 'react'
import { useCurrentBranch } from '../current-branch/current-branch.hook'
import { useDateGeneration } from '../date-generation/date-generation.hook'

const useInfoBar = () => {
    const { currentBranch, isLoading: branchIsLoading } = useCurrentBranch()
    const { isLoading: dateGenerationIsLoading, dateGeneration } =
        useDateGeneration()

    const getIsLoading = useCallback(() => {
        return branchIsLoading || dateGenerationIsLoading
    }, [branchIsLoading, dateGenerationIsLoading])

    useEffect(() => {}, [getIsLoading, currentBranch, dateGeneration])

    return {
        isLoading: getIsLoading(),
        currentBranch,
        dateGeneration,
    }
}

export { useInfoBar }
