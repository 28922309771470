import { IServerStatus } from './server-status.model'
import { IResponseBase } from '../response.base'

export interface IServerStatusBase {
    tenant: string
    region: string
    runner: string
    copYear: string
    dayStatus: string
}

export const emptyServerStatusBase: IServerStatusBase = {
    tenant: '',
    region: '',
    runner: '',
    copYear: '',
    dayStatus: '',
}
export interface IServerStatusResponse extends IResponseBase {
    data: IServerStatus
}
