import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import DivisionForm from '../../../components/common/division/division-form.component'
import DivisionTable from '../../../components/common/division/division-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useDivision } from '../../../hooks/common/division/division.hook'
import { emptyDivision } from '../../../models/common/division.model'
import { UpdateMode } from '../../../models/update-mode.enum'

const DivisionPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)

    const { setDivision } = useDivision()

    const handleShow = () => {
        setTitle('Create new division')
        setDivision(emptyDivision)
        setContent(<DivisionForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'Divisions']} />
            <TitleBar
                title="Divisions"
                subTitle="View and manage divisions"
                showButton
                handleShow={handleShow}
                buttonLabel="Add Division"
                icon={<FiPlus />}
            />
            <DivisionTable />
        </>
    )
}

export default DivisionPage
