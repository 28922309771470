import { authReducer } from '../common/auth/auth.slice'
import { activeTabReducer } from './active-tab/active-tab.reducer'
import { sessionBatchReducer } from './batch/session-batch.slice'
import { languageReducer } from './lang/lang.reducer'
import { searchValueReducer } from './search-value/search-value.reducer'
import { tokenReducer } from './token/token.reducer'
import { userReducer } from './user/user.slice'
import { validationErrorReducer } from './validation-error/validation-error.slice'

const reducers = {
    auth: authReducer,
    token: tokenReducer,
    language: languageReducer,
    sessionBatch: sessionBatchReducer,
    activeTab: activeTabReducer,
    validationError: validationErrorReducer,
    searchValue: searchValueReducer,
    user: userReducer,
}

export { reducers as sharedReducers }
