import { Card, Col, Row, Statistic } from 'antd'
import React, { useEffect } from 'react'
import {
    LikeTwoTone,
    ThunderboltTwoTone,
    DollarTwoTone,
    InteractionTwoTone,
} from '@ant-design/icons'
import PageBreadCrumbs from '../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { ColumnChart } from '@toast-ui/react-chart'
import { useOutlayDash } from '../../hooks/budgeting/outlay-dash.hook'
import { format } from '../../utils/format'
import './home.styles.scss'
import { useMilestoneDash } from '../../hooks/budgeting/milestone-dash.hook'
import { useTranslation } from 'react-i18next'

const HomePage: React.FunctionComponent = () => {
    const {
        targetsComponentOne,
        targetsComponentTwo,
        targetsComponentThree,
        targetsComponentFour,
        achievementsComponentOne,
        achievementsComponentTwo,
        achievementsComponentThree,
        achievementsComponentFour,
        budgetsComponentOne,
        budgetsComponentTwo,
        budgetsComponentThree,
        budgetsComponentFour,
        expendituresComponentOne,
        expendituresComponentTwo,
        expendituresComponentThree,
        expendituresComponentFour,
        totalTargetComponentOne,
        totalTargetComponentTwo,
        totalTargetComponentThree,
        totalTargetComponentFour,
        totalAchievementComponentOne,
        totalAchievementComponentTwo,
        totalAchievementComponentThree,
        totalAchievementComponentFour,
        totalBudgetComponentOne,
        totalBudgetComponentTwo,
        totalBudgetComponentThree,
        totalBudgetComponentFour,
        totalExpenditureComponentOne,
        totalExpenditureComponentTwo,
        totalExpenditureComponentThree,
        totalExpenditureComponentFour,
    } = useOutlayDash()
    const { reloadOutlayDash } = useOutlayDash()
    const { budgetChartData } = useMilestoneDash()
    const { t } = useTranslation()

    const chartOptions = {
        chart: {
            width: 'auto',
            height: 300,
            title: t('common:breadCrumb.chartData.title'),
        },
        yAxis: {
            title: t('common:breadCrumb.chartData.month'),
        },
        xAxis: {
            title: t('common:breadCrumb.chartData.amount'),
        },
        rules: [
            {
                condition: ({ width: w }: any) => {
                    return w > 800
                },
                options: {
                    legend: {
                        align: 'bottom',
                    },
                },
            },
        ],
    }

    useEffect(() => {
        reloadOutlayDash()
    }, [reloadOutlayDash])

    return (
        <div>
            <PageBreadCrumbs items={[t('common:breadCrumb.dashboard')]} />

            {/* component four */}
            <div style={{ display: 'none' }}>
                <h3 style={{ marginTop: '1rem' }}>Component Four Outlays</h3>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t('common:breadCrumb.cardHeader.target')}
                                    value={format.number(totalTargetComponentFour)}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={
                                <ThunderboltTwoTone style={{ fontSize: '2rem' }} />
                            }
                        >
                            {targetsComponentFour.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t(
                                        'common:breadCrumb.cardHeader.archievement'
                                    )}
                                    value={format.number(
                                        totalAchievementComponentFour
                                    )}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={<LikeTwoTone style={{ fontSize: '2rem' }} />}
                        >
                            {achievementsComponentFour.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t('common:breadCrumb.cardHeader.budget')}
                                    value={format.number(totalBudgetComponentFour)}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={<DollarTwoTone style={{ fontSize: '2rem' }} />}
                        >
                            {budgetsComponentFour.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t(
                                        'common:breadCrumb.cardHeader.expenditure'
                                    )}
                                    value={format.number(
                                        totalExpenditureComponentFour
                                    )}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={
                                <InteractionTwoTone style={{ fontSize: '2rem' }} />
                            }
                        >
                            {expendituresComponentFour.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* component three */}
            <div style={{ display: 'none' }}>
                <h3 style={{ marginTop: '1rem' }}>Component Three Outlays</h3>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t('common:breadCrumb.cardHeader.target')}
                                    value={format.number(totalTargetComponentThree)}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={
                                <ThunderboltTwoTone style={{ fontSize: '2rem' }} />
                            }
                        >
                            {targetsComponentThree.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t(
                                        'common:breadCrumb.cardHeader.archievement'
                                    )}
                                    value={format.number(
                                        totalAchievementComponentThree
                                    )}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={<LikeTwoTone style={{ fontSize: '2rem' }} />}
                        >
                            {achievementsComponentThree.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t('common:breadCrumb.cardHeader.budget')}
                                    value={format.number(totalBudgetComponentThree)}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={<DollarTwoTone style={{ fontSize: '2rem' }} />}
                        >
                            {budgetsComponentThree.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t(
                                        'common:breadCrumb.cardHeader.expenditure'
                                    )}
                                    value={format.number(
                                        totalExpenditureComponentThree
                                    )}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={
                                <InteractionTwoTone style={{ fontSize: '2rem' }} />
                            }
                        >
                            {expendituresComponentThree.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* component two */}
            <div style={{ display: 'none' }}>
                <h3 style={{ marginTop: '1rem' }}>Component Two Outlays</h3>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t('common:breadCrumb.cardHeader.target')}
                                    value={format.number(totalTargetComponentTwo)}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={
                                <ThunderboltTwoTone style={{ fontSize: '2rem' }} />
                            }
                        >
                            {targetsComponentTwo.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t(
                                        'common:breadCrumb.cardHeader.archievement'
                                    )}
                                    value={format.number(
                                        totalAchievementComponentTwo
                                    )}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={<LikeTwoTone style={{ fontSize: '2rem' }} />}
                        >
                            {achievementsComponentTwo.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t('common:breadCrumb.cardHeader.budget')}
                                    value={format.number(totalBudgetComponentTwo)}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={<DollarTwoTone style={{ fontSize: '2rem' }} />}
                        >
                            {budgetsComponentTwo.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            size="small"
                            title={
                                <Statistic
                                    title={t(
                                        'common:breadCrumb.cardHeader.expenditure'
                                    )}
                                    value={format.number(
                                        totalExpenditureComponentTwo
                                    )}
                                />
                            }
                            bodyStyle={{ padding: 0 }}
                            extra={
                                <InteractionTwoTone style={{ fontSize: '2rem' }} />
                            }
                        >
                            {expendituresComponentTwo.map((item, index) => (
                                <div
                                    className="card__column"
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        fontSize: '.78rem',
                                        backgroundColor:
                                            index % 2 === 0 ? '#f8f8f8' : '',
                                    }}
                                    key={item.budgetCode + index}
                                >
                                    <span
                                        style={{
                                            width: '80px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {item.budgetCode}
                                    </span>
                                    <span style={{ width: '80px' }}>
                                        {item.indicator}
                                    </span>
                                    <span
                                        style={{ flexGrow: 1, textAlign: 'right' }}
                                    >
                                        {format.number(item.amount)}
                                    </span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* component one */}
            <h3>Component One Outlays</h3>
            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
                <Col xs={24} md={12} lg={6}>
                    <Card
                        size="small"
                        title={
                            <Statistic
                                title={t('common:breadCrumb.cardHeader.target')}
                                value={format.number(totalTargetComponentOne)}
                            />
                        }
                        bodyStyle={{ padding: 0 }}
                        extra={<ThunderboltTwoTone style={{ fontSize: '2rem' }} />}
                    >
                        {targetsComponentOne.map((item, index) => (
                            <div
                                className="card__column"
                                style={{
                                    display: 'flex',
                                    padding: '8px',
                                    fontSize: '.78rem',
                                    backgroundColor:
                                        index % 2 === 0 ? '#f8f8f8' : '',
                                }}
                                key={item.budgetCode + index}
                            >
                                <span style={{ width: '80px', marginRight: '10px' }}>
                                    {item.budgetCode}
                                </span>
                                <span style={{ width: '80px' }}>
                                    {item.indicator}
                                </span>
                                <span style={{ flexGrow: 1, textAlign: 'right' }}>
                                    {format.number(item.amount)}
                                </span>
                            </div>
                        ))}
                    </Card>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Card
                        size="small"
                        title={
                            <Statistic
                                title={t(
                                    'common:breadCrumb.cardHeader.archievement'
                                )}
                                value={format.number(totalAchievementComponentOne)}
                            />
                        }
                        bodyStyle={{ padding: 0 }}
                        extra={<LikeTwoTone style={{ fontSize: '2rem' }} />}
                    >
                        {achievementsComponentOne.map((item, index) => (
                            <div
                                className="card__column"
                                style={{
                                    display: 'flex',
                                    padding: '8px',
                                    fontSize: '.78rem',
                                    backgroundColor:
                                        index % 2 === 0 ? '#f8f8f8' : '',
                                }}
                                key={item.budgetCode + index}
                            >
                                <span style={{ width: '80px', marginRight: '10px' }}>
                                    {item.budgetCode}
                                </span>
                                <span style={{ width: '80px' }}>
                                    {item.indicator}
                                </span>
                                <span style={{ flexGrow: 1, textAlign: 'right' }}>
                                    {format.number(item.amount)}
                                </span>
                            </div>
                        ))}
                    </Card>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Card
                        size="small"
                        title={
                            <Statistic
                                title={t('common:breadCrumb.cardHeader.budget')}
                                value={format.number(totalBudgetComponentOne)}
                            />
                        }
                        bodyStyle={{ padding: 0 }}
                        extra={<DollarTwoTone style={{ fontSize: '2rem' }} />}
                    >
                        {budgetsComponentOne.map((item, index) => (
                            <div
                                className="card__column"
                                style={{
                                    display: 'flex',
                                    padding: '8px',
                                    fontSize: '.78rem',
                                    backgroundColor:
                                        index % 2 === 0 ? '#f8f8f8' : '',
                                }}
                                key={item.budgetCode + index}
                            >
                                <span style={{ width: '80px', marginRight: '10px' }}>
                                    {item.budgetCode}
                                </span>
                                <span style={{ width: '80px' }}>
                                    {item.indicator}
                                </span>
                                <span style={{ flexGrow: 1, textAlign: 'right' }}>
                                    {format.number(item.amount)}
                                </span>
                            </div>
                        ))}
                    </Card>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Card
                        size="small"
                        title={
                            <Statistic
                                title={t('common:breadCrumb.cardHeader.expenditure')}
                                value={format.number(totalExpenditureComponentOne)}
                            />
                        }
                        bodyStyle={{ padding: 0 }}
                        extra={<InteractionTwoTone style={{ fontSize: '2rem' }} />}
                    >
                        {expendituresComponentOne.map((item, index) => (
                            <div
                                className="card__column"
                                style={{
                                    display: 'flex',
                                    padding: '8px',
                                    fontSize: '.78rem',
                                    backgroundColor:
                                        index % 2 === 0 ? '#f8f8f8' : '',
                                }}
                                key={item.budgetCode + index}
                            >
                                <span style={{ width: '80px', marginRight: '10px' }}>
                                    {item.budgetCode}
                                </span>
                                <span style={{ width: '80px' }}>
                                    {item.indicator}
                                </span>
                                <span style={{ flexGrow: 1, textAlign: 'right' }}>
                                    {format.number(item.amount)}
                                </span>
                            </div>
                        ))}
                    </Card>
                </Col>
            </Row>

            {/* milestone graphs */}
            {/* component four */}

            <div style={{ display: 'none' }}>
                <h3 style={{ marginTop: '1rem' }}>Component Four Milestones</h3>
                <Card
                    size="small"
                    style={{ marginTop: '1rem' }}
                    className="chart__container"
                >
                    <div style={{ width: '100%' }}>
                        <ColumnChart
                            data={budgetChartData}
                            options={chartOptions as any}
                            style={{ width: '100%' }}
                        />
                    </div>
                </Card>
            </div>

            {/* component three */}
            <div style={{ display: 'none' }}>
                <h3 style={{ marginTop: '1rem' }}>Component Three Milestones</h3>
                <Card
                    size="small"
                    style={{ marginTop: '1rem' }}
                    className="chart__container"
                >
                    <div style={{ width: '100%' }}>
                        <ColumnChart
                            data={budgetChartData}
                            options={chartOptions as any}
                            style={{ width: '100%' }}
                        />
                    </div>
                </Card>
            </div>

            {/* component two */}
            <div style={{ display: 'none' }}>
                <h3 style={{ marginTop: '1rem' }}>Component Two Milestones</h3>
                <Card
                    size="small"
                    style={{ marginTop: '1rem' }}
                    className="chart__container"
                >
                    <div style={{ width: '100%' }}>
                        <ColumnChart
                            data={budgetChartData}
                            options={chartOptions as any}
                            style={{ width: '100%' }}
                        />
                    </div>
                </Card>
            </div>

            {/* component one */}
            <h3 style={{ marginTop: '1rem' }}>Component One Milestones</h3>
            <Card
                size="small"
                style={{ marginTop: '1rem', marginBottom: '5rem' }}
                className="chart__container"
            >
                <div style={{ width: '100%' }}>
                    <ColumnChart
                        data={budgetChartData}
                        options={chartOptions as any}
                        style={{ width: '100%' }}
                    />
                </div>
            </Card>
        </div>
    )
}

export default HomePage
