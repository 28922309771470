import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as interventionModel from '../../models/budgeting/intervention.model'
import interventionService from '../../services/budgeting/intervention.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: interventionModel.IInterventionState = {
    interventions: [],
    errors: '',
    intervention: interventionModel.emptyIntervention,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchinterventionAsync = createAsyncThunk<
    interventionModel.IIntervention[],
    void
>('intervention/fetchinterventionAsync', async (_, thunkApi) => {
    try {
        return await interventionService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const interventionSlice = createSlice({
    name: 'intervention',
    initialState,
    reducers: {
        fetchinterventionRequest: (state) => {
            state.isLoading = true
        },
        fetchinterventionSuccess: (
            state,
            action: PayloadAction<interventionModel.IIntervention[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.interventions = action.payload
        },
        fetchinterventionError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editInterventionSuccess: (
            state,
            action: PayloadAction<interventionModel.IIntervention>
        ) => {
            state.interventions = state.interventions.map((intervention) => {
                return intervention.code === action.payload.code
                    ? action.payload
                    : intervention
            })
            state.updateMode = UpdateMode.NONE
        },
        addInterventionSuccess: (
            state,
            action: PayloadAction<interventionModel.IIntervention>
        ) => {
            state.interventions = [...state.interventions, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveIntervention: (
            state,
            action: PayloadAction<interventionModel.IIntervention>
        ) => {
            state.intervention = action.payload
        },
        setInterventionUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchinterventionAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchinterventionAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.interventions = action.payload
        })
        builder.addCase(fetchinterventionAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchinterventionRequest,
    fetchinterventionSuccess,
    fetchinterventionError,
    editInterventionSuccess,
    addInterventionSuccess,
    setActiveIntervention,
    setInterventionUpdateMode,
} = interventionSlice.actions

const reducer = interventionSlice.reducer

export { reducer as interventionReducer }
