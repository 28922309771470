import React from 'react'
import { Provider } from 'react-redux'
import userManager from './services/shared/auth/auth.service'
import AuthProvider from './utils/auth-provider'
import withLoading from './components/shared/with-loading/with-loading.component'
import AppRoutes from './components/shared/route/route.component'
import store from './redux/store'
import './App.less'

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { ReportsProvider } from './contexts/reports/report.context'
import { ModificationsProvider } from './contexts/modifications/modifications.context'

let persistor = persistStore(store)

const App: React.FunctionComponent = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AuthProvider userManager={userManager} store={store}>
                    <ReportsProvider>
                        <ModificationsProvider>
                            <AppRoutes />
                        </ModificationsProvider>
                    </ReportsProvider>
                </AuthProvider>
            </PersistGate>
        </Provider>
    )
}

export default withLoading(App)
