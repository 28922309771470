export const enActivityPlanSummary = {
    batch: 'Batch',
    branch: 'Branch',
    runner: 'Runner',
    transDate: 'Transaction date',
    userCode: 'User code',
    usrName: 'User Name',
    amount: 'Amount',
    number: 'number',
    region: 'Region',
    description: 'description',
    project: 'Project',
    activityType: 'Activity type',
    transactionCode: 'Transaction code',
    transactionCodeDescription: 'Transaction Description',
}
