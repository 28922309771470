import {
    ControlOutlined,
    SubnodeOutlined,
    UsergroupAddOutlined,
    UserSwitchOutlined
} from '@ant-design/icons'
import { ISideBarLink } from '../sidebar.data'

export const settingSubmenus: ISideBarLink[] = [
    {
        app: '01',
        code: 'I00',
        description: 'Users',
        route: '/users',
        dropDown: false,
        dropDownMenus: [],
        icon: <UsergroupAddOutlined />,
        parent: 'I',
    },
    {
        app: '01',
        code: 'I01',
        description: 'Role management',
        route: '/role-management',
        dropDown: true,
        dropDownMenus: [],
        icon: <ControlOutlined />,
        parent: '',
    },
    {
        app: '01',
        code: 'I02',
        description: 'Claims',
        route: '/claims',
        dropDown: true,
        dropDownMenus: [],
        icon: <SubnodeOutlined />,
        parent: '',
    },
    {
        app: '01',
        code: 'I03',
        description: 'User Coordination',
        route: '/user-coordination',
        dropDown: true,
        dropDownMenus: [],
        icon: <UserSwitchOutlined />,
        parent: '',
    },
]
