import { apiRequests } from '../..'
import { ISource, ISourceResponse } from '../../../models/common/source.model'

const sourceService = {
    list: (): Promise<ISource[]> => apiRequests.get('/sources'),
    details: (code: string): Promise<ISource> => apiRequests.get(`/sources/${code}`),
    create: (source: ISource): Promise<ISourceResponse> =>
        apiRequests.post('/sources', source),
    update: (source: ISource): Promise<ISourceResponse> =>
        apiRequests.put('/sources', source),
}

export default sourceService
