import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IBudgetCode {
    tenant: string
    code: string
    copYear: string
    description: string
    createdOn: Date
}

export const emptyBudgetCode: IBudgetCode = {
    tenant: '',
    code: '',
    copYear: '',
    description: '',
    createdOn: new Date(),
}
export interface IBudgetCodeState extends IBaseState {
    readonly budgetCodes: IBudgetCode[]
    readonly budgetCode: IBudgetCode
}

export interface IBudgetCodeResponse extends IResponseBase {
    data: IBudgetCode
}
