import { PlusOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClaimsFormFields } from '../../../components/common/claim/claim-form-fields.component'
import ClaimForm from '../../../components/common/claim/claim-form.component'
import { ClaimsTable } from '../../../components/common/claim/claim-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import { NoContent } from '../../../components/shared/no-content/no-content.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { IClaim } from '../../../models/common/claim.model'
import claimService from '../../../services/common/claim/claim.service'

const ClaimPage: React.FC = () => {
    const { t } = useTranslation()
    const [claims, setClaims] = useState<IClaim[]>()
    const [visible, setVisible] = useState(false)
    const { setContent, setShow, setTitle } = useContext<any>(AppModalContext)

    const handleCancel = () => {
        setVisible(false)
    }

    function handleShow() {
        setTitle(t('common:claim.title'))
        setContent(<ClaimForm />)
        setShow(true)
    }

    const getClaims = useCallback(async () => {
        const claimsData = await claimService.list()
        setClaims(claimsData)
    }, [])

    useEffect(() => {
        getClaims()
    }, [])

    return (
        <>
            <PageBreadCrumbs
                items={[
                    t('common:role.claims.breadcrumb.setting'),
                    t('common:role.claims.breadcrumb.claim'),
                ]}
            />
            {claims && claims.length > 0 ? (
                <>
                    <TitleBar
                        title={t('common:role.claims.title')}
                        showButton={true}
                        buttonLabel={t('common:role.claims.btnLabel')}
                        handleShow={handleShow}
                        icon={<PlusOutlined />}
                    />
                    <Card size="small" bodyStyle={{ padding: 0, marginBottom: "4rem" }}>
                        <ClaimsTable claims={claims} />
                    </Card>
                </>
            ) : (
                <NoContent
                    title="No Data Here"
                    showButton
                    handleClick={handleShow}
                />
            )}

            <ClaimsFormFields
                onCancel={handleCancel}
                visible={visible}
                title={t('role:form.titleAdd')}
            />
        </>
    )
}

export default ClaimPage
