import { Card, Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useTicket } from '../../../hooks/common/ticket/ticket.hook'
import { useTicketStatus } from '../../../hooks/common/ticket-status/ticket-status.hook'

const TicketDetailComponent: React.FC = () => {
    const { ticket } = useTicket()
    const {ticketStatuses} = useTicketStatus()
    return (
        <Card bordered={false} size="small">
            <Row>
                <Col span={4}>
                    <StyledParagraph>User Code: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{ticket.userCode}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Phone Number: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{ticket.userPhoneNumber}</StyledParagraph>
                </Col>
            </Row>

            <Row>
                <Col span={4}>
                    <StyledParagraph>User Email: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{ticket.userEmail}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Description: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{ticket.description}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Comment: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{ticket.comment}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Status: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{ticketStatuses.find(item => item.code === ticket.status)?.description}</StyledParagraph>
                </Col>
            </Row>
        </Card>
    )
}

export default TicketDetailComponent

const StyledParagraph = styled.p`
    margin-bottom: 0;
    height: 2rem
`
