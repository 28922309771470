import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as activityPlanActions from '../../redux/budgeting/activity-plan.slice'
import {
    emptyActivityPlan,
    IActivityPlan,
} from '../../models/budgeting/activity-plan.model'
import { UpdateMode } from '../../models/update-mode.enum'
import activityPlanService from '../../services/budgeting/activity-plan.service'
import { useConfiguration } from '../configuration.hook'
import { useRunner } from './runner.hook'
import { useRunnerComponent } from './runner-component.hook'
import { useSurgeActivity } from './surge-activity.hook'

const useActivityPlan = () => {
    const { runner } = useRunner()

    const activityPlans = useSelector<rootState, IActivityPlan[]>(
        (state) => state.activityPlan.activityPlans
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.activityPlan.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.activityPlan.initialFetch
    )
    const activityPlan = useSelector<rootState, IActivityPlan>(
        (state) => state.activityPlan.activityPlan
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.activityPlan.updateMode
    )
    const { configuration } = useConfiguration()
    const { runnerComponent } = useRunnerComponent()
    const { surgeActivityCodes } = useSurgeActivity()

    const dispatch = useDispatch()

    const loadActivityPlans = useCallback(() => {
        const activityPlanParams = {
            copYear: runner.copYear,
            component: runnerComponent.component,
            project: runnerComponent.project,
            tenant: runner.tenant,
        }
        if (initialFetch) {
            dispatch(activityPlanActions.fetchActivityPlansAsync(activityPlanParams))
        }
    }, [dispatch, initialFetch, runner.component, runner.copYear, runner.project])

    const addActivityPlan = async (activityPlan: IActivityPlan) => {
        return await activityPlanService
            .create(activityPlan)
            .then((activityPlanResponse) => {
                if (activityPlanResponse.success) {
                    dispatch(
                        activityPlanActions.addActivityPlanSuccess(
                            activityPlanResponse.data
                        )
                    )
                } else {
                    return activityPlanResponse
                }
                return activityPlanResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setActivityPlan = (activityPlan: IActivityPlan) => {
        dispatch(activityPlanActions.setActiveActivityPlan(activityPlan))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(activityPlanActions.setActivityPlanUpdateMode(updateMode))
    }

    const editActivityPlan = async (activityPlan: IActivityPlan) => {
        return await activityPlanService
            .update(activityPlan)
            .then((activityPlanResponse) => {
                dispatch(
                    activityPlanActions.editActivityPlanSuccess(
                        activityPlanResponse.data
                    )
                )
                setActivityPlan(activityPlanResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveActivityPlan = async (
        activityPlan: IActivityPlan,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addActivityPlan(activityPlan)
            : await editActivityPlan(activityPlan)
    }

    const getActivityPlans = useCallback(() => {
        return activityPlans
            .filter(
                (x) =>
                    x.project === configuration.project &&
                    !surgeActivityCodes.includes(x.code)
            )
            .sort((a, b) => (a.code > b.code ? 1 : -1))
    }, [activityPlans, surgeActivityCodes, configuration.project])

    const getSurgeActivityPlans = useCallback(() => {
        return activityPlans
            .filter(
                (x) =>
                    x.project === configuration.project &&
                    surgeActivityCodes.includes(x.code)
            )
            .sort((a, b) => (a.code > b.code ? 1 : -1))
    }, [activityPlans, surgeActivityCodes, configuration.project])

    const getActivityPlan = useCallback(
        (activityCode: string) => {
            const activityPlan = activityPlans.find((x) => x.code === activityCode)
            if (activityPlan) return activityPlan
            return emptyActivityPlan
        },
        [activityPlans]
    )

    useEffect(() => {
        loadActivityPlans()
    }, [activityPlan, activityPlans, isLoading, initialFetch, loadActivityPlans])

    return {
        activityPlan,
        activityPlans: getActivityPlans(),
        surgeActivityPlans: getSurgeActivityPlans(),
        isLoading,
        initialFetch,
        updateMode,
        getActivityPlan,
        setUpdateMode,
        addActivityPlan,
        editActivityPlan,
        saveActivityPlan,
        setActivityPlan,
    }
}

export { useActivityPlan }
