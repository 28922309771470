import { Space } from 'antd'
import { useFormikContext } from 'formik'
import {
    Form,
    Input,
    SubmitButton,
    ResetButton,
    DatePicker,
    Select,
} from 'formik-antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IRunnerPeriod } from '../../../models/budgeting/runner-period.model'
import {
    FlexedDiv,
    FormLabels,
    SizedLabel,
    width100,
} from '../../profile/profile.styles'
import { useComponent } from '../../../hooks/budgeting/component.hook'

const FormItem = Form.Item
const RunnerPeriodFormFields: React.FC = () => {
    const { isSubmitting } = useFormikContext<IRunnerPeriod>()
    const { t } = useTranslation()
    const { components } = useComponent()

    return (
        <>
            <Form autoComplete="off">
                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:runnerPeriod.form.description')}
                        </FormLabels>
                        <FormItem
                            name="description"
                            required={true}
                            style={{ marginBottom: 5 }}
                        >
                            <Input
                                name="description"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:runnerPeriod.form.startDate')}
                        </FormLabels>
                        <FormItem name="startDate" style={{ marginBottom: 5 }}>
                            <DatePicker
                                name="startDate"
                                style={width100}
                                disabled={isSubmitting}
                                format={'DD/MM/YYYY'}
                            />
                        </FormItem>
                    </SizedLabel>

                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:runnerPeriod.form.endDate')}
                        </FormLabels>
                        <FormItem name="endDate" style={{ marginBottom: 5 }}>
                            <DatePicker
                                name="endDate"
                                style={width100}
                                disabled={isSubmitting}
                                format={'DD/MM/YYYY'}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:runnerPeriod.form.component')}
                            <FormItem name="endDate" style={{ marginBottom: 5 }}>
                                <Select name="component" style={{ width: '100%' }}>
                                    {components.map((component) => (
                                        <Select.Option
                                            key={component.code}
                                            value={component.code}
                                        >
                                            {component.longDescription}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </FormLabels>
                    </SizedLabel>
                </FlexedDiv>

                <Space size={'small'} style={{ marginTop: 10 }}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('budgeting:runnerPeriod.form.submit')}
                    </SubmitButton>
                    <ResetButton disabled={isSubmitting}>
                        {t('budgeting:runnerPeriod.form.reset')}
                    </ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default RunnerPeriodFormFields
