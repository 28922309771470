import { apiRequests } from '../'
import { IZone, IZoneResponse } from '../../models/budgeting/zone.model'

const zoneService = {
    list: (): Promise<IZone[]> => apiRequests.get('/zones'),
    details: (code: string): Promise<IZone> => apiRequests.get(`/zones/${code}`),
    create: (zone: IZone): Promise<IZoneResponse> =>
        apiRequests.post('/zones', zone),
    update: (zone: IZone): Promise<IZoneResponse> => apiRequests.put('/zones', zone),
}

export default zoneService
