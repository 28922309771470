import { Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton, ResetButton } from 'formik-antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IStationReference } from '../../../models/common/station-reference.model'

const FormItem = Form.Item
const StationReferenceFormFields: React.FC = () => {
    const { isSubmitting } = useFormikContext<IStationReference>()
    const { t } = useTranslation()
    return (
        <>
            <Form autoComplete="off">
                <p className="mb-0">{t('common:stationReference.description')}</p>
                <FormItem name="description" required={true}>
                    <Input
                        name="description"
                        disabled={isSubmitting}
                        required={true}
                    />
                </FormItem>
                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('common:stationReference.submit')}
                    </SubmitButton>
                    <ResetButton disabled={isSubmitting}>
                        {t('common:stationReference.reset')}
                    </ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default StationReferenceFormFields
