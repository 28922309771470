import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as surgeActivityModel from '../../models/budgeting/surge-activity.model'
import { UpdateMode } from '../../models/update-mode.enum'
import surgeActivityService from '../../services/budgeting/surge-activity.service'

export const initialState: surgeActivityModel.ISurgeActivityState = {
    surgeActivities: [],
    errors: '',
    surgeActivity: surgeActivityModel.emptySurgeActivity,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchSurgeActivitiesAsync = createAsyncThunk<surgeActivityModel.ISurgeActivity[], void>(
    'surgeActivity/fetchsurgeActivitiesAsync',
    async (_, thunkApi) => {
        try {
            return await surgeActivityService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const surgeActivitySlice = createSlice({
    name: 'surgeActivity',
    initialState,
    reducers: {
        fetchSurgeActivitiesRequest: (state) => {
            state.isLoading = true
        },
        fetchSurgeActivitiesSuccess: (
            state,
            action: PayloadAction<surgeActivityModel.ISurgeActivity[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.surgeActivities = action.payload
        },
        fetchSurgeActivitiesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editSurgeActivitySuccess: (state, action: PayloadAction<surgeActivityModel.ISurgeActivity>) => {
            state.surgeActivities = state.surgeActivities.map((surgeActivity) => {
                return surgeActivity.code === action.payload.code ? action.payload : surgeActivity
            })
            state.updateMode = UpdateMode.NONE
        },
        addSurgeActivitySuccess: (state, action: PayloadAction<surgeActivityModel.ISurgeActivity>) => {
            state.surgeActivities = [...state.surgeActivities, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveSurgeActivity: (state, action: PayloadAction<surgeActivityModel.ISurgeActivity>) => {
            state.surgeActivity = action.payload
        },
        setSurgeActivityUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSurgeActivitiesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchSurgeActivitiesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.surgeActivities = action.payload
        })
        builder.addCase(fetchSurgeActivitiesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchSurgeActivitiesRequest,
    fetchSurgeActivitiesSuccess,
    fetchSurgeActivitiesError,
    editSurgeActivitySuccess,
    addSurgeActivitySuccess,
    setActiveSurgeActivity,
    setSurgeActivityUpdateMode,
} = surgeActivitySlice.actions

const reducer = surgeActivitySlice.reducer

export { reducer as surgeActivityReducer }
