export const enIntervention = {
    tenant: 'Tenant',
    code: 'Code',
    copYear: 'Component year',
    description: ' Description',
    budgetCode: 'Budget code',
    project: 'Project',
    percentage: 'Percentage',
    createdOn: 'Created on',
}
