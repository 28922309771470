import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as userRoleModel from '../../../models/common/user-role.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import userRoleService from '../../../services/common/user-role/user-role.service'

export const initialState: userRoleModel.IUserRoleState = {
    userRoles: [],
    errors: '',
    userRole: userRoleModel.emptyUserRole,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchUserRolesAsync = createAsyncThunk<userRoleModel.IUserRole[], void>(
    'userRole/fetchUserRolesAsync',
    async (_, thunkApi) => {
        try {
            return await userRoleService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const userRoleSlice = createSlice({
    name: 'userRole',
    initialState,
    reducers: {
        fetchUserRolesRequest: (state) => {
            state.isLoading = true
        },
        fetchUserRolesSuccess: (
            state,
            action: PayloadAction<userRoleModel.IUserRole[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.userRoles = action.payload
        },
        fetchUserRolesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editUserRoleSuccess: (
            state,
            action: PayloadAction<userRoleModel.IUserRole>
        ) => {
            state.userRoles = state.userRoles.map((userRole) => {
                return userRole.roleCode === action.payload.roleCode
                    ? action.payload
                    : userRole
            })
            state.updateMode = UpdateMode.NONE
        },
        addUserRoleSuccess: (
            state,
            action: PayloadAction<userRoleModel.IUserRole>
        ) => {
            state.userRoles = [...state.userRoles, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveUserRole: (
            state,
            action: PayloadAction<userRoleModel.IUserRole>
        ) => {
            state.userRole = action.payload
        },
        setUserRoleUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserRolesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchUserRolesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.userRoles = action.payload
        })
        builder.addCase(fetchUserRolesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchUserRolesRequest,
    fetchUserRolesSuccess,
    fetchUserRolesError,
    editUserRoleSuccess,
    addUserRoleSuccess,
    setActiveUserRole,
    setUserRoleUpdateMode,
} = userRoleSlice.actions

const reducer = userRoleSlice.reducer

export { reducer as userRoleReducer }
