import { EditOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import TitleBar from '../../common/title-bar/title-bar.component'
import { useTranslation } from 'react-i18next'
import { UserFormFields } from './user-form-fields.component'
import { UserDetails } from './user-details.component'
import { Button, Space } from 'antd'
import { useHistory } from 'react-router'
import { slugify } from '../../../utils/slug'
import { useUser } from '../../../hooks/shared/user/user.hook'
import { UpdateMode } from '../../../models/update-mode.enum'

export const UsersTab: React.FC = () => {
    const { t } = useTranslation()
    const { user, setUpdateMode } = useUser()
    const [visible, setVisible] = useState(false)
    const history = useHistory()

    const handleCancel = () => {
        setVisible(false)
    }

    const handleIsVisible = () => {
        setVisible(true)
        setUpdateMode(UpdateMode.EDIT)
    }

    const manageSites = () => {
        history.push(`/manage-user-sites/${slugify(user.usrName)}`)
    }

    return (
        <>
            <TitleBar
                title={user.usrName}
                showExtra={true}
                extra={
                    <Space>
                        <Button danger onClick={manageSites}>
                            {t('common:user.form.mgtSiteBtn')}
                        </Button>
                        <Button
                            icon={<EditOutlined />}
                            onClick={handleIsVisible}
                            type="primary"
                        >
                            {t('common:user.form.editBtn')}
                        </Button>
                    </Space>
                }
            />
            <UserDetails />

            {/* Modal Component */}
            <UserFormFields
                onCancel={handleCancel}
                visible={visible}
                title={t('common:user.form.editBtn')}
            />
        </>
    )
}
