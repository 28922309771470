import { Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IUser } from '../../../models/shared/user.model'

const FormItem = Form.Item
export const VerifyTelephoneFormFields: React.FC = () => {
    const { isSubmitting } = useFormikContext<IUser>()
    const { t } = useTranslation()

    return (
        <>
            <div
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    background: '#fafafa',
                    padding: 5,
                }}
            >
                <p style={{ marginBottom: 0 }}>
                    <strong>Phone Verification</strong>
                </p>
                <small>
                    A verified phone number provides our team with a secure method of
                    verifying your identity as the owner of this user account. This
                    phone number is only ever used to send an SMS message with a
                    verification code. <br /> Standard carrier messaging fees may
                    apply. By clicking Send Verification Code you are opting in to
                    receive SMS messages.
                </small>
            </div>
            <Form autoComplete="off">
                <p className="mb-0">{t('user:telephone')}</p>
                <FormItem name="telephone" required={true}>
                    <Input
                        name="telephone"
                        disabled={isSubmitting}
                        required={true}
                        value={'+(237) - '}
                    />
                </FormItem>

                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('user:update')}
                    </SubmitButton>
                </Space>
            </Form>
        </>
    )
}
