import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as activityModel from '../../models/budgeting/activity.model'
import activityService from '../../services/budgeting/activity.service'
import { UpdateMode } from '../../models/update-mode.enum'
import { IFetchActivityParams } from '../../models/budgeting/activity.model'
import {
    emptyActivitySummary,
    IActivitySummary,
} from '../../models/budgeting/activity-summary.model'

export const initialState: activityModel.IActivityState = {
    activities: [],
    activityHistories: [],
    errors: '',
    activity: activityModel.emptyActivity,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
    activitySummary: emptyActivitySummary,
}

export const fetchActivitiesAsync = createAsyncThunk<
    activityModel.IActivity[],
    IFetchActivityParams
>(
    'activity/fetchActivitiesAsync',
    async (params: IFetchActivityParams, thunkApi) => {
        try {
            return await activityService.list(params)
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        fetchActivitiesRequest: (state) => {
            state.isLoading = true
        },
        fetchActivitiesSuccess: (
            state,
            action: PayloadAction<activityModel.IActivity[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.activities = action.payload
        },
        fetchActivitiesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editActivitySuccess: (
            state,
            action: PayloadAction<activityModel.IActivity>
        ) => {
            state.activities = state.activities.map((activity) => {
                return activity.batch === action.payload.batch &&
                    activity.batchLine === action.payload.batchLine
                    ? action.payload
                    : activity
            })
            state.updateMode = UpdateMode.NONE
        },
        addActivitySuccess: (
            state,
            action: PayloadAction<activityModel.IActivity>
        ) => {
            state.activities = [...state.activities, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        deleteActivitySuccess: (
            state,
            action: PayloadAction<activityModel.IActivity>
        ) => {
            state.activities = state.activities.filter(
                (activity) =>
                    !(
                        activity.batch === action.payload.batch &&
                        activity.batchLine === action.payload.batchLine
                    )
            )
            state.updateMode = UpdateMode.NONE
        },
        setActiveActivity: (
            state,
            action: PayloadAction<activityModel.IActivity>
        ) => {
            state.activity = action.payload
        },
         setActiveActivityHistories: (
            state,
            action: PayloadAction<activityModel.IActivity[]>
        ) => {
            state.activityHistories = action.payload
        },
        setActiveActivitySummary: (
            state,
            action: PayloadAction<IActivitySummary>
        ) => {
            state.activitySummary = action.payload
        },
        setActivityUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchActivitiesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchActivitiesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.activities = action.payload
        })
        builder.addCase(fetchActivitiesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchActivitiesRequest,
    fetchActivitiesSuccess,
    fetchActivitiesError,
    editActivitySuccess,
    addActivitySuccess,
    deleteActivitySuccess,
    setActiveActivity,
    setActiveActivitySummary,
    setActivityUpdateMode,
    setActiveActivityHistories
} = activitySlice.actions

const reducer = activitySlice.reducer

export { reducer as activityReducer }
