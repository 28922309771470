import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as activityPlanModel from '../../models/budgeting/activity-plan.model'
import activityPlanService from '../../services/budgeting/activity-plan.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: activityPlanModel.IActivityPlanState = {
    activityPlans: [],
    errors: '',
    activityPlan: activityPlanModel.emptyActivityPlan,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchActivityPlansAsync = createAsyncThunk<
    activityPlanModel.IActivityPlan[],
    activityPlanModel.ActivityPlanParamType
>('activityPlan/fetchActivityPlansAsync', async (params, thunkApi) => {
    try {
        return await activityPlanService.list(params)
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const activityPlanSlice = createSlice({
    name: 'activityPlan',
    initialState,
    reducers: {
        fetchactivityPlanRequest: (state) => {
            state.isLoading = true
        },
        fetchactivityPlanSuccess: (
            state,
            action: PayloadAction<activityPlanModel.IActivityPlan[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.activityPlans = action.payload
        },
        fetchactivityPlanError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editActivityPlanSuccess: (
            state,
            action: PayloadAction<activityPlanModel.IActivityPlan>
        ) => {
            state.activityPlans = state.activityPlans.map((activityPlan) => {
                return activityPlan.code === action.payload.code
                    ? action.payload
                    : activityPlan
            })
            state.updateMode = UpdateMode.NONE
        },
        addActivityPlanSuccess: (
            state,
            action: PayloadAction<activityPlanModel.IActivityPlan>
        ) => {
            state.activityPlans = [...state.activityPlans, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveActivityPlan: (
            state,
            action: PayloadAction<activityPlanModel.IActivityPlan>
        ) => {
            state.activityPlan = action.payload
        },
        setActivityPlanUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchActivityPlansAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchActivityPlansAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.activityPlans = action.payload
        })
        builder.addCase(fetchActivityPlansAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchactivityPlanRequest,
    fetchactivityPlanSuccess,
    fetchactivityPlanError,
    editActivityPlanSuccess,
    addActivityPlanSuccess,
    setActiveActivityPlan,
    setActivityPlanUpdateMode,
} = activityPlanSlice.actions

const reducer = activityPlanSlice.reducer

export { reducer as activityPlanReducer }
