export const frQuarter = {
    tenant: 'Locataire',
    code: 'Code',
    copYear: 'Année composante',
    description: 'La description',
    startDate: 'date de début',
    endDate: 'Date de fin',
    status: 'Statut',
    createdOn: 'Créé sur',
}
