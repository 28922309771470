import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import TicketForm from '../../../components/common/ticket/ticket-form.component'
import TicketTable from '../../../components/common/ticket/ticket-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useTicket } from '../../../hooks/common/ticket/ticket.hook'
import { emptyTicket } from '../../../models/common/ticket.model'
import { UpdateMode } from '../../../models/update-mode.enum'

const TicketPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { setTicket } = useTicket()

    const handleShow = () => {
        setTitle('Create new ticket')
        setTicket(emptyTicket)
        setContent(<TicketForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Tickets']} />
            <TitleBar
                title="Tickets"
                subTitle="View and manage tickets"
                showButton
                handleShow={handleShow}
                buttonLabel="Add Ticket"
                icon={<FiPlus />}
            />
            <TicketTable />
        </>
    )
}

export default TicketPage
