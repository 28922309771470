import { Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, Select, SubmitButton } from 'formik-antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IUser } from '../../../models/shared/user.model'
import { FlexedDiv, FormLabels, SizedLabel, width100 } from '../profile.styles'

const FormItem = Form.Item
const SecurityQuestionFormFields: React.FC = () => {
    const { isSubmitting } = useFormikContext<IUser>()
    const { t } = useTranslation()

    return (
        <>
            <div
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    background: '#fafafa',
                    padding: 5,
                }}
            >
                <p style={{ marginBottom: 0 }}>
                    <strong>Security Questions</strong>
                </p>
                <small>
                    Security questions enable you to regain access to your Linode
                    user account in certain situations, such as when 2FA is enabled
                    and you no longer have access to the token or recovery codes.
                    Answers to security questions should not be easily guessed or
                    discoverable through research.
                </small>
            </div>
            <Form autoComplete="off">
                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('user:question1')}
                        </FormLabels>
                        <FormItem name="question1" required={true}>
                            <Select
                                name="question1"
                                style={width100}
                                disabled={isSubmitting}
                            >
                                <Select.Option value="01">
                                    In Which City were you born?
                                </Select.Option>
                                <Select.Option value="02">
                                    What is the name of your oldest sibling?
                                </Select.Option>
                                <Select.Option value="03">
                                    What is the name of your favorite city?
                                </Select.Option>
                                <Select.Option value="04">
                                    In which city did your parents meet?
                                </Select.Option>
                            </Select>
                        </FormItem>
                    </SizedLabel>

                    <SizedLabel>
                        <FormLabels className="mb-0">{t('user:answer1')}</FormLabels>
                        <FormItem name="answer1" required={true}>
                            <Input
                                name="answer1"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('user:question2')}
                        </FormLabels>
                        <FormItem name="question2" required={true}>
                            <Select
                                name="question2"
                                style={width100}
                                disabled={isSubmitting}
                            >
                                <Select.Option value="01">
                                    In Which City were you born?
                                </Select.Option>
                                <Select.Option value="02">
                                    What is the name of your oldest sibling?
                                </Select.Option>
                                <Select.Option value="03">
                                    What is the name of your favorite city?
                                </Select.Option>
                                <Select.Option value="04">
                                    In which city did your parents meet?
                                </Select.Option>
                            </Select>
                        </FormItem>
                    </SizedLabel>

                    <SizedLabel>
                        <FormLabels className="mb-0">{t('user:answer2')}</FormLabels>
                        <FormItem name="answer2" required={true}>
                            <Input
                                name="answer2"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('user:question4')}
                        </FormLabels>
                        <FormItem name="question4" required={true}>
                            <Select
                                name="question4"
                                style={width100}
                                disabled={isSubmitting}
                            >
                                <Select.Option value="01">
                                    In Which City were you born?
                                </Select.Option>
                                <Select.Option value="02">
                                    What is the name of your oldest sibling?
                                </Select.Option>
                                <Select.Option value="03">
                                    What is the name of your favorite city?
                                </Select.Option>
                                <Select.Option value="04">
                                    In which city did your parents meet?
                                </Select.Option>
                            </Select>
                        </FormItem>
                    </SizedLabel>

                    <SizedLabel>
                        <FormLabels className="mb-0">{t('user:answer4')}</FormLabels>
                        <FormItem name="answer4" required={true}>
                            <Input
                                name="answer4"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('user:question3')}
                        </FormLabels>
                        <FormItem name="question3" required={true}>
                            <Select
                                name="question3"
                                style={width100}
                                disabled={isSubmitting}
                            >
                                <Select.Option value="01">
                                    In Which City were you born?
                                </Select.Option>
                                <Select.Option value="02">
                                    What is the name of your oldest sibling?
                                </Select.Option>
                                <Select.Option value="03">
                                    What is the name of your favorite city?
                                </Select.Option>
                                <Select.Option value="04">
                                    In which city did your parents meet?
                                </Select.Option>
                            </Select>
                        </FormItem>
                    </SizedLabel>

                    <SizedLabel>
                        <FormLabels className="mb-0">{t('user:answer3')}</FormLabels>
                        <FormItem name="answer3" required={true}>
                            <Input
                                name="answer3"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('user:addSecurityQuestion')}
                    </SubmitButton>
                </Space>
            </Form>
        </>
    )
}

export default SecurityQuestionFormFields
