import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IRunnerPeriodStatus {
    code: string
    Description: string
    createdOn: Date
}

export const emptyRunnerPeriodStatus: IRunnerPeriodStatus = {
    code: '',
    Description: '',
    createdOn: new Date(),
}

export interface IRunnerPeriodStatusState extends IBaseState {
    readonly runnerPeriodStatuses: IRunnerPeriodStatus[]
    readonly runnerPeriodStatus: IRunnerPeriodStatus
}

export interface IRunnerPeriodStatusResponse extends IResponseBase {
    data: IRunnerPeriodStatus
}
