import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as milestoneModel from '../../models/budgeting/milestone.model'
import milestoneService from '../../services/budgeting/milestone.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: milestoneModel.IMilestoneState = {
    milestones: [],
    errors: '',
    milestone: milestoneModel.emptyMilestone,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
    site: '',
}

export const fetchmilestoneAsync = createAsyncThunk<
    milestoneModel.IMilestone[],
    void
>('milestone/fetchmilestoneAsync', async (_, thunkApi) => {
    try {
        return await milestoneService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const milestoneSlice = createSlice({
    name: 'milestone',
    initialState,
    reducers: {
        fetchmilestoneRequest: (state) => {
            state.isLoading = true
        },
        fetchmilestoneSuccess: (
            state,
            action: PayloadAction<milestoneModel.IMilestone[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.milestones = action.payload
        },
        fetchmilestoneError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        updateMilestoneProjectionSuccess: (
            state,
            action: PayloadAction<milestoneModel.IMilestone>
        ) => {
            const ms = state.milestones.find(
                (x) =>
                    x.runner === action.payload.runner &&
                    x.project === action.payload.project &&
                    x.activity === action.payload.activity &&
                    x.site === action.payload.site
            )
            if (ms) {
                state.milestones = state.milestones.map((milestone) => {
                    return milestone.runner === action.payload.runner &&
                        milestone.project === action.payload.project &&
                        milestone.activity === action.payload.activity &&
                        milestone.site === action.payload.site
                        ? action.payload
                        : milestone
                })
            } else {
                state.milestones = [...state.milestones, action.payload]
            }

            state.updateMode = UpdateMode.NONE
        },
        updateMilestoneAchievementSuccess: (
            state,
            action: PayloadAction<milestoneModel.IMilestone>
        ) => {
            state.milestones = state.milestones.map((milestone) => {
                return milestone.runner === action.payload.runner &&
                    milestone.project === action.payload.project &&
                    milestone.activity === action.payload.activity &&
                    milestone.site === action.payload.site
                    ? action.payload
                    : milestone
            })
            state.updateMode = UpdateMode.NONE
        },
        setActiveMilestone: (
            state,
            action: PayloadAction<milestoneModel.IMilestone>
        ) => {
            state.milestone = action.payload
        },
        setMilestoneSite: (state, action: PayloadAction<string>) => {
            state.site = action.payload
        },
        setMilestoneUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchmilestoneAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchmilestoneAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.milestones = action.payload
        })
        builder.addCase(fetchmilestoneAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchmilestoneRequest,
    fetchmilestoneSuccess,
    fetchmilestoneError,
    updateMilestoneProjectionSuccess,
    updateMilestoneAchievementSuccess,
    setActiveMilestone,
    setMilestoneSite,
    setMilestoneUpdateMode,
} = milestoneSlice.actions

const reducer = milestoneSlice.reducer

export { reducer as milestoneReducer }
