import { Formik } from 'formik'
import React, { useContext } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useDialogMessage } from '../../../hooks/common/dialog-message/dialog-message.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import {
    emptyDialogMessage,
    IDialogMessage,
} from '../../../models/common/dialog-message.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import DialogMessageFormFields from './dialog-message-form-field.component'

interface IProp {
    updateMode: UpdateMode
}

const DialogMessageForm: React.FC<IProp> = ({ updateMode }) => {
    const { toastSuccess, toastError } = useToast()
    const { saveDialogMessage, dialogMessage } = useDialogMessage()
    const { setShow } = useContext<any>(AppModalContext)
    return (
        <div>
            <Formik<IDialogMessage>
                initialValues={dialogMessage || emptyDialogMessage}
                onSubmit={async (values, { setSubmitting }) => {
                    const feedback = await saveDialogMessage(values, updateMode)
                    if (feedback) {
                        toastSuccess('M0002')
                    } else {
                        toastError('M0003')
                    }
                    setSubmitting(false)
                    setShow(false)
                }}
            >
                <>
                    <DialogMessageFormFields />
                </>
            </Formik>
        </div>
    )
}
export default DialogMessageForm
