import { List } from 'antd'
import React from 'react'

const DashboardCardFooter: React.FC = () => {
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                gap: '2rem',
            }}
        >
            <List
                style={{ flexBasis: '35%' }}
                size="small"
                dataSource={[
                    {
                        label: 'Expected Weekly Target',
                        value: '100%',
                    },
                    {
                        label: 'Actual target met for the week',
                        value: '0%',
                    },
                    {
                        label: '%tage of target still to be met',
                        value: '100%',
                    },
                ]}
                renderItem={(item, index) => {
                    return (
                        <div
                            className="flex__dashboard__row"
                            key={index}
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <p>{item.label}</p>
                            <p className="flex__right">{item.value}</p>
                        </div>
                    )
                }}
            />
            <List
                style={{ flexBasis: '35%' }}
                size="small"
                dataSource={[
                    {
                        label: 'Expected Weekly Expenditure',
                        value: '100%',
                    },
                    {
                        label: 'Actual weekly expenditure',
                        value: '0%',
                    },
                    {
                        label: '%tage of weekly expenditure to be met',
                        value: '100%',
                    },
                ]}
                renderItem={(item, index) => {
                    return (
                        <div
                            className="flex__dashboard__row"
                            key={index}
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <p>{item.label}</p>
                            <p className="flex__right">{item.value}</p>
                        </div>
                    )
                }}
            />
        </div>
    )
}

export default DashboardCardFooter
