import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IRole {
    code: string
    description: string
    slug: string
    status: string;
    createdOn: Date
}

export interface IRoleState extends IBaseState {
    readonly roles: IRole[]
    readonly role: IRole
}

export const emptyRole: IRole = {
    code: '',
    description: '',
    slug: '',
    status: '',
    createdOn: new Date(),
}

export interface IRoleResponse extends IResponseBase {
    data: IRole
}
