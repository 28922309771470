import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ICopYear {
    tenant: string
    code: string
    description: string
    startDate: Date
    endDate: Date
    status: string
    createdOn: Date
}

export const emptyCopYear: ICopYear = {
    tenant: '',
    code: '',
    description: '',
    startDate: new Date(),
    endDate: new Date(),
    status: '',
    createdOn: new Date(),
}

export interface ICopYearState extends IBaseState {
    readonly copYears: ICopYear[]
    readonly copYear: ICopYear
}

export interface ICopYearResponse extends IResponseBase {
    data: ICopYear
}
