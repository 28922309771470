import { ColumnsType } from 'antd/lib/table'
import { useMilestone } from '../../../hooks/budgeting/milestone.hook'
import { useSite } from '../../../hooks/budgeting/site.hook'
import { IMilestone } from '../../../models/budgeting/milestone.model'
import { format } from '../../../utils/format'

const useMilestoneAchievementColumns = () => {
    const { sites } = useSite()
    const { site } = useMilestone()

    const formatFun = (row: IMilestone) => {
        return sites.find((s) => s.code === site)?.description
    }

    const milestoneColumns: ColumnsType<IMilestone> = [
        {
            title: 'Site',
            dataIndex: 'site',
            key: 'site',
            width: '20%',
            render: (_, row) => formatFun(row),
            ellipsis: true,
        },
        {
            title: 'Activity',
            dataIndex: 'activity',
            key: 'activity',
            align: 'right',
            width: '8%',
        },
        {
            title: 'Target',
            dataIndex: 'target',
            key: 'target',
            align: 'right',
            width: '8%',
        },
        {
            title: 'Achievement',
            dataIndex: 'achievement',
            key: 'achievement',
            align: 'right',
            width: '8%',
        },
        {
            title: 'Budget',
            dataIndex: 'budget',
            key: 'budget',
            width: '8%',
            align: 'right',
            render: (_, row) => format.number(row.budget),
        },
        {
            title: 'Actual',
            dataIndex: 'budget',
            key: 'budget',
            width: '8%',
            align: 'right',
            render: (_, row) => format.number(row.budget),
        },
        {
            title: 'Budget Notes',
            dataIndex: 'budgetNote',
            key: 'budgetNote',
            width: '40%',
            ellipsis: true,
        },
    ]

    return {
        milestoneColumns,
    }
}

export { useMilestoneAchievementColumns }
