import { Button, Form, Modal, Space } from 'antd'
import React, { useContext } from 'react'
import { UsersContext } from './users-context.provider'
import { StyledInput, StyleFormItem } from './users-styles.component'

const EditUsersFormModal: React.FunctionComponent = () => {
    const { showModal, setShowModal, editFormTarget } = useContext<any>(UsersContext)

    return (
        <Modal
            okText="save"
            cancelText="cancel"
            visible={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            title="Edit user"
            footer={null}
        >
            <Form layout="vertical">
                {editFormTarget === 'email' && (
                    <StyleFormItem name="email" id="email" label="Email address">
                        <StyledInput
                            type="email"
                            placeholder="email"
                            name="email"
                            id="email"
                        />
                    </StyleFormItem>
                )}

                {editFormTarget === 'name' && (
                    <StyleFormItem name="name" id="name" label="Name">
                        <StyledInput
                            type="name"
                            placeholder="name"
                            name="name"
                            id="name"
                        />
                    </StyleFormItem>
                )}

                <Space style={{ textAlign: 'right' }}>
                    <Button onClick={() => setShowModal(false)} type="primary">
                        Submit
                    </Button>
                    <Button
                        style={{ borderRadius: '4px' }}
                        type="ghost"
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </Button>
                </Space>
            </Form>
        </Modal>
    )
}

export default EditUsersFormModal
