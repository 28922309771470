import { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IActiveTab } from '../../../models/shared/active-tab'
import { rootState } from '../../../redux/root-reducer'
import * as activeTabActions from '../../../redux/shared/active-tab/active-tab.actions'

const useActiveTab = () => {
    const dispatch = useDispatch()

    const activeTab = useSelector<rootState, IActiveTab>(
        (state) => state.activeTab.activeTab,
        shallowEqual
    )

    const setActiveTab = useCallback(
        (activeTab: string) => {
            dispatch(activeTabActions.setActiveTabTab(activeTab))
        },
        [dispatch]
    )

    return {
        activeTab,
        setActiveTab,
    }
}

export { useActiveTab }
