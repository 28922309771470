import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ICopYearIntervention {
    tenant: string
    copYear: string
    project: string
    intervention: string
}

export const emptyCopYearIntervention: ICopYearIntervention = {
    tenant: '',
    copYear: '',
    project: '',
    intervention: '',
}

export interface ICopYearInterventionState extends IBaseState {
    readonly copYearInterventions: ICopYearIntervention[]
    readonly copYearIntervention: ICopYearIntervention
}

export interface ICopYearInterventionResponse extends IResponseBase {
    data: ICopYearIntervention
}
