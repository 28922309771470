import React, { useEffect } from 'react'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import ModificationsFilterForm from '../../../components/modifications/modifications-filter-form.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { MODIFICATION_TYPE } from '../../../constants/configurations.constant'
import { useTranslation } from 'react-i18next'
import ActivityHistoryTable from '../../../components/budgeting/activity/activity-history-table'
import {
    IConfiguration,
    emptyConfiguration,
} from '../../../models/configuration.model'
import { useConfiguration } from '../../../hooks/configuration.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'


const RegionalModificationsPage: React.FC = () => {
    const { t } = useTranslation()
    const { setConfiguration } = useConfiguration()
    const { currentBranch } = useCurrentBranch();

    const initialConfig: IConfiguration = {
        ...emptyConfiguration,
        activityType: '01',
        tranCode: '01',
        project: currentBranch.project,
        activeTab: '',
    }
    
    useEffect(() => {
        setConfiguration(initialConfig)
    }, [setConfiguration])

    return (
        <>
            <PageBreadCrumbs
                items={[
                    t('budgeting:modifications.regionBreadcrumb.modifications'),
                    t(
                        'budgeting:modifications.regionBreadcrumb.regionalModifications'
                    ),
                ]}
            />
            <TitleBar
                title={t('budgeting:modifications.regionMainPage.title')}
                subTitle={t('budgeting:modifications.regionMainPage.subTitle')}
            />
            <ModificationsFilterForm modificationType={MODIFICATION_TYPE.REGION} />
            <ActivityHistoryTable activities={[]} />  
        </>
    )
}

export default RegionalModificationsPage
