import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import DialogMessageForm from '../../../components/common/dialog-message/dialog-message-form.component'
import DialogMessageTable from '../../../components/common/dialog-message/dialog-message-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useDialogMessage } from '../../../hooks/common/dialog-message/dialog-message.hook'
import { emptyDialogMessage } from '../../../models/common/dialog-message.model'
import { UpdateMode } from '../../../models/update-mode.enum'

const DialogMessagesPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { setDialogMessage } = useDialogMessage()

    const handleShow = () => {
        setTitle('Create new Dialog Message')
        setDialogMessage(emptyDialogMessage)
        setContent(<DialogMessageForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'Dialog Messages']} />
            <TitleBar
                title="Dialog Messages"
                subTitle="View and manage Dialog Messages"
                showButton
                handleShow={handleShow}
                buttonLabel="Add Dialog Message"
                icon={<FiPlus />}
            />
            <DialogMessageTable />
        </>
    )
}

export default DialogMessagesPage
