import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as milestoneDashModel from '../../models/budgeting/milestone-dash.model'
import milestoneDashService from '../../services/budgeting/milestone-dash.service'
import { UpdateMode } from '../../models/update-mode.enum'
import { IMilestoneDashParameters } from '../../models/budgeting/milestone-dash-parameters.model'

export const initialState: milestoneDashModel.IMilestoneDashState = {
    errors: '',
    milestoneDash: milestoneDashModel.emptyMilestoneDash,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchMilestoneDashAsync = createAsyncThunk<
    milestoneDashModel.IMilestoneDash,
    IMilestoneDashParameters
>(
    'milestoneDash/fetchMilestoneDashAsync',
    async (params: IMilestoneDashParameters, thunkApi) => {
        try {
            return await milestoneDashService.list(params)
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const milestoneDashSlice = createSlice({
    name: 'milestoneDash',
    initialState,
    reducers: {
        fetchMilestoneDashRequest: (state) => {
            state.isLoading = true
        },
        fetchMilestoneDashSuccess: (
            state,
            action: PayloadAction<milestoneDashModel.IMilestoneDash>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.milestoneDash = action.payload
        },
        fetchMilestoneDashError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMilestoneDashAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchMilestoneDashAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.milestoneDash = action.payload
        })
        builder.addCase(fetchMilestoneDashAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchMilestoneDashRequest,
    fetchMilestoneDashSuccess,
    fetchMilestoneDashError,
} = milestoneDashSlice.actions

const reducer = milestoneDashSlice.reducer

export { reducer as milestoneDashReducer }
