import { Col, Row, Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton, ResetButton } from 'formik-antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDivision } from '../../../hooks/common/division/division.hook'
import { ISubDivision } from '../../../models/common/sub-division.model'
import SearchableDropDown from '../../shared/seachable-dropdown/searchable-dropdown.component'

const FormItem = Form.Item
const SubDivisionFormFields: React.FC = () => {
    const { isSubmitting, setFieldValue, initialValues } =
        useFormikContext<ISubDivision>()
    const { t } = useTranslation()
    const { divisions } = useDivision()

    return (
        <>
            <Form autoComplete="off">
                <Row>
                    <Col span={4}>
                        <p className="mb-0">
                            {t('common:sub_division.description')}
                        </p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="description" required={true}>
                            <Input
                                name="description"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <p className="mb-0">
                            {t('common:sub_division.divisionCode')}
                        </p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="divisionCode" required={true}>
                            <SearchableDropDown
                                items={divisions}
                                disabled={isSubmitting}
                                required={true}
                                value={initialValues.divisionCode}
                                parentCallBack={(selectedValue: string) => {
                                    setFieldValue('divisionCode', selectedValue)
                                }}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('common:sub_division.submit')}
                    </SubmitButton>
                    <ResetButton disabled={isSubmitting}>
                        {t('common:sub_division.reset')}
                    </ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default SubDivisionFormFields
