import React, { useCallback, useEffect, useState } from 'react'
import { Card, Transfer } from 'antd'
import { Formik } from 'formik'
import { Form, FormItem, Select, SubmitButton } from 'formik-antd'
import { useRole } from '../../../hooks/common/role/role.hook'
import { ISideBarLink, sidebarData } from '../../../layout/sidebar/sidebar.data'
import { useRoleClaim } from '../../../hooks/common/role-claim/role-claim.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import { UpdateMode } from '../../../models/update-mode.enum'
import { IClaim } from '../../../models/common/claim.model'
import claimService from '../../../services/common/claim/claim.service'
import roleClaimService from '../../../services/common/role-claim/role-claim.service'
import { t } from 'i18next'

const { Option } = Select

const RoleClaimsTab: React.FunctionComponent = () => {
    const { roles } = useRole()
    const { saveRoleClaim } = useRoleClaim()
    const { currentBranch } = useCurrentBranch()
    const { toastError, toastSuccess } = useToast()

    const [updateMode, setUpdateMode] = useState(UpdateMode.NONE)
    const [claims, setClaims] = useState<Array<IClaim>>([])
    const [selectedRole, setSelecteRole] = useState('')
    const [targetKeys, setTargetKeys] = useState<string[]>([])
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])

    let completeSideBarData: Array<ISideBarLink> = []
    sidebarData.slice(1).forEach((sideBarLink) => {
        if (sideBarLink.dropDownMenus.length > 0) {
            sideBarLink.dropDownMenus.forEach((dropdown) => {
                completeSideBarData.push(dropdown)
            })
        }
        completeSideBarData.push(sideBarLink)
    })

    const onChange = (targetKeys: string[]) => setTargetKeys(targetKeys)

    const onSelectChange = (
        sourceSelectedKeys: string[],
        targetSelectedKeys: string[]
    ) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
    }

    const handleChange = async (roleCode: string) => {
        setSelecteRole(roleCode)
        const roleClaimsData = await roleClaimService.getClaimsForRole(roleCode)
        const roleClaimsWithCode = roleClaimsData.map((item) => item.code)
        setTargetKeys(roleClaimsWithCode)
    }

    const onSearch = useCallback((input: string, option) => {
        return option?.props.children.props.children
            .toString()
            .toLowerCase()
            .includes(input)
    }, [])

    const reloadClaims = useCallback(async () => {
        const claimsData = await claimService.list()
        setClaims(claimsData)
    }, [])

    useEffect(() => {
        reloadClaims()
    }, [])

    return (
        <>
            <Formik
                onSubmit={async (values, { setSubmitting }) => {
                    const obj = {
                        ...values,
                        role: selectedRole,
                        menuCodes: targetKeys.join(','),
                        tenant: currentBranch.tenant,
                    }

                    // const feedback = await saveRoleClaim(updateMode, obj)
                    const feedback = true

                    if (feedback) {
                        toastSuccess('M0002')
                    } else {
                        toastError('M0003')
                    }
                    setSubmitting(false)
                }}
                initialValues={[]}
            >
                {({ isSubmitting, handleSubmit }) => (
                    <Form autoComplete="off" onFinish={handleSubmit}>
                        <Card
                            size="small"
                            title={t('common:role.RolePrv.title')}
                            className="mb-lg"
                        >
                            <div className="mb-lg">
                                <label
                                    style={{ marginBottom: '1rem', width: '100%' }}
                                >
                                    <p className="mb-0">
                                        {t('common:role.RolePrv.subTitle')}
                                    </p>
                                    <FormItem name="role" required={true}>
                                        <Select
                                            name="role"
                                            disabled={isSubmitting}
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                onSearch(input, option)
                                            }
                                            onChange={handleChange}
                                        >
                                            {roles.map((roleItem, index) => (
                                                <Option
                                                    value={roleItem.code}
                                                    key={index}
                                                >
                                                    <p className="mb-0">
                                                        {roleItem.description}
                                                    </p>
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                </label>
                            </div>

                            <div className="mb-lg">
                                <Transfer
                                    className="vulnerability-criteria-transfer"
                                    rowKey={(record) => record.code}
                                    // dataSource={users.sort((a, b) => a.usrName > b.usrName ? 1 : -1)}
                                    dataSource={claims}
                                    showSearch
                                    listStyle={{
                                        height: 300,
                                    }}
                                    operations={[
                                        t('common:role.RolePrv.addBtn'),
                                        t('common:role.RolePrv.removeBtn'),
                                    ]}
                                    operationStyle={{
                                        color: '#fff',
                                        backgroundColor: '#fff',
                                    }}
                                    titles={[
                                        t('common:role.RolePrv.allRol'),
                                        t('common:role.RolePrv.selRol'),
                                    ]}
                                    targetKeys={targetKeys}
                                    selectedKeys={selectedKeys}
                                    onChange={onChange}
                                    onSelectChange={onSelectChange}
                                    render={(item) => `${item.description}`}
                                    // filterOption={onSearch}
                                />
                            </div>

                            <SubmitButton
                                style={{ marginTop: '1rem' }}
                                loading={isSubmitting}
                            >
                                {t('common:role.form.savebtn')}
                            </SubmitButton>
                        </Card>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default RoleClaimsTab
