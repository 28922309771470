import { Button, Col, Row, Space } from 'antd'
import React, { useContext } from 'react'
import { UsersContext } from '../users-context.provider'

const UserDetailTabContent: React.FunctionComponent = () => {
    const { setShowModal, setEditFormTarget } = useContext<any>(UsersContext)

    const editUserName = () => {
        setShowModal(true)
        setEditFormTarget('name')
    }

    const editUserEmail = () => {
        setShowModal(true)
        setEditFormTarget('email')
    }

    return (
        <div
            style={{
                border: '1px solid #eee',
                borderRadius: '8px',
                padding: '.6rem',
            }}
        >
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Row>
                    <Col xs={24} md={8}>
                        <p style={{ marginBottom: 0 }}>Name</p>
                        <p style={{ marginBottom: 0 }}>MANKAIN GENEVIV</p>
                        <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={editUserName}
                        >
                            Edit
                        </Button>
                    </Col>

                    <Col xs={24} md={8}>
                        <p style={{ marginBottom: 0 }}>Email</p>
                        <p style={{ marginBottom: 0 }}>makaingeneviv72@gmail.com</p>
                        <p style={{ marginBottom: 0 }}>(verified)</p>
                        <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={editUserEmail}
                        >
                            Edit
                        </Button>
                    </Col>

                    <Col xs={24} md={8}>
                        <p style={{ marginBottom: 0 }}>Signed Up</p>
                        <p style={{ marginBottom: 0 }}>
                            {' '}
                            April 14th 2021, 1:20:44 PM
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={8}>
                        <section>
                            <p style={{ marginBottom: 0 }}>
                                Primary Identity Provider
                            </p>
                            <p style={{ marginBottom: 0 }}>Database</p>
                        </section>
                    </Col>

                    <Col xs={24} md={8}>
                        <p style={{ marginBottom: 0 }}>Latest Login</p>
                        <p style={{ marginBottom: 0 }}>
                            November 20th 2021, 9:37:21 AM
                        </p>
                    </Col>

                    <Col xs={24} md={8}>
                        <p style={{ marginBottom: 0 }}>Accounts Associated</p>
                        <p style={{ marginBottom: 0 }}> None</p>
                    </Col>
                </Row>
            </Space>
        </div>
    )
}

export default UserDetailTabContent
