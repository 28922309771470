import { Alert } from 'antd'
import { Formik } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useRunner } from '../../../hooks/budgeting/runner.hook'
import { useFormErrors } from '../../../hooks/common/form/form-error.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import { emptyRunner, IRunner } from '../../../models/budgeting/runner.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import RunnerFormFields from './runner-form-fields.component'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { RUNNER_STATUS } from '../../../constants/configurations.constant'

interface IProp {
    updateMode: UpdateMode
}
const RunnerForm: React.FC<IProp> = ({ updateMode }) => {
    const { toastSuccess, toastError } = useToast()
    const { saveRunner, runner } = useRunner()
    const { currentBranch } = useCurrentBranch()

    const { formError } = useFormErrors()
    const [hasSubmitted, setSubmitted] = useState(false)
    const { setShow } = useContext<any>(AppModalContext)

    const onClose = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSubmitted(false)
        },
        []
    )

    useEffect(() => {}, [hasSubmitted])
    return (
        <div>
            {hasSubmitted && (
                <Alert
                    message="Error Messages"
                    description={
                        <>
                            {formError.validationErrors &&
                            formError.validationErrors.length > 0 ? (
                                formError.validationErrors.map((v, index) => (
                                    <span key={index} style={{ display: 'block' }}>
                                        {v}
                                    </span>
                                ))
                            ) : (
                                <span>
                                    {JSON.stringify(
                                        formError.validationErrors,
                                        null,
                                        2
                                    )}
                                </span>
                            )}
                        </>
                    }
                    type="error"
                    closable
                    onClose={onClose}
                    style={{ marginBottom: 10 }}
                />
            )}
            <Formik<IRunner>
                initialValues={runner || emptyRunner}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitted(false)
                    const obj: IRunner = {
                        ...values,
                        statusDescription: 'OPEN',
                        status: RUNNER_STATUS.OPEN,
                        region: currentBranch.region,
                        project: currentBranch.project, //pick the current project
                        regionDescription: currentBranch.branchName, //pick the current region description
                        tenant: currentBranch.tenant, //pick the current tenant
                    }
                    const feedback = await saveRunner(obj, updateMode)
                    if (feedback) {
                        toastSuccess('M0002')
                        setShow(false)
                        resetForm()
                    } else {
                        toastError('M0003')
                        setShow(true)
                        setSubmitted(true)
                    }
                    setSubmitting(false)
                }}
            >
                <>
                    <RunnerFormFields />
                </>
            </Formik>
        </div>
    )
}
export default RunnerForm
