import { Spin } from 'antd'
import React, { useContext } from 'react'
import { FiEdit } from 'react-icons/fi'
import ActivitySummaryDetails from '../../../components/budgeting/activity-summary/activity-summary-detail.component'
import ActivityForm from '../../../components/budgeting/activity/activity-form.component'
import BackButton from '../../../components/common/back-button/back-button.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useActivity } from '../../../hooks/budgeting/activity.hook'
import { useActiveTab } from '../../../hooks/shared/active-tab/active-tab.hook'
import { UpdateMode } from '../../../models/update-mode.enum'

const LaboratoryActivityBatchDetailsPage: React.FunctionComponent = () => {
    const { isLoading, initialFetch, activity, setActivity } = useActivity()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { activeTab } = useActiveTab()

    const handleShow = () => {
        setActivity({ ...activity })
        setWidth('40rem')
        setShow(true)
        setContent(<ActivityForm updateMode={UpdateMode.EDIT} />)
        setTitle('Edit Activity')
    }
    return (
        <>
            <PageBreadCrumbs
                items={['Laboratory', 'Laboratory Activities', 'Batch Details']}
            />

            <TitleBar
                title="Activity BatchLine"
                subTitle="View and manage Lab Activity BatchLine"
                showButton={activeTab.isActive !== 'site-activities' ? false : true}
                buttonLabel="Edit Activity"
                handleShow={handleShow}
                icon={<FiEdit />}
            />
            <BackButton page="Activity Batch" />
            {isLoading && initialFetch ? (
                <Spin size="large" />
            ) : (
                <ActivitySummaryDetails activity={activity} />
            )}
        </>
    )
}

export default LaboratoryActivityBatchDetailsPage
