import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as runnerComponentHistoryModel from '../../models/budgeting/runner-component-history.model'
import runnerComponentHistoryService from '../../services/budgeting/runner-component-history.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: runnerComponentHistoryModel.IRunnerComponentHistoryState =
    {
        runnerComponentHistories: [],
        errors: '',
        runnerComponentHistory:
            runnerComponentHistoryModel.emptyRunnerComponentHistory,
        isLoading: false,
        initialFetch: true,
        updateMode: UpdateMode.NONE,
    }

export const fetchRunnerComponentHistoriesAsync = createAsyncThunk<
    runnerComponentHistoryModel.IRunnerComponentHistory[],
    void
>(
    'runnerComponentHistory/fetchrunnerComponentHistoriesAsync',
    async (_, thunkApi) => {
        try {
            return await runnerComponentHistoryService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const runnerComponentHistorySlice = createSlice({
    name: 'runnerComponentHistory',
    initialState,
    reducers: {
        fetchRunnerComponentHistoriesRequest: (state) => {
            state.isLoading = true
        },
        fetchRunnerComponentHistoriesSuccess: (
            state,
            action: PayloadAction<
                runnerComponentHistoryModel.IRunnerComponentHistory[]
            >
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerComponentHistories = action.payload
        },
        fetchRunnerComponentHistoriesError: (
            state,
            action: PayloadAction<string>
        ) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editRunnerComponentHistorySuccess: (
            state,
            action: PayloadAction<runnerComponentHistoryModel.IRunnerComponentHistory>
        ) => {
            state.runnerComponentHistories = state.runnerComponentHistories.map(
                (runnerComponentHistory) => {
                    return runnerComponentHistory.runner === action.payload.runner
                        ? action.payload
                        : runnerComponentHistory
                }
            )
            state.updateMode = UpdateMode.NONE
        },
        addRunnerComponentHistorySuccess: (
            state,
            action: PayloadAction<runnerComponentHistoryModel.IRunnerComponentHistory>
        ) => {
            state.runnerComponentHistories = [
                ...state.runnerComponentHistories,
                action.payload,
            ]
            state.updateMode = UpdateMode.NONE
        },
        setActiveRunnerComponentHistory: (
            state,
            action: PayloadAction<runnerComponentHistoryModel.IRunnerComponentHistory>
        ) => {
            state.runnerComponentHistory = action.payload
        },
        setRunnerComponentHistoryUpdateMode: (
            state,
            action: PayloadAction<UpdateMode>
        ) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRunnerComponentHistoriesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(
            fetchRunnerComponentHistoriesAsync.fulfilled,
            (state, action) => {
                state.isLoading = false
                state.initialFetch = false
                state.runnerComponentHistories = action.payload
            }
        )
        builder.addCase(
            fetchRunnerComponentHistoriesAsync.rejected,
            (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            }
        )
    },
})

export const {
    fetchRunnerComponentHistoriesRequest,
    fetchRunnerComponentHistoriesSuccess,
    fetchRunnerComponentHistoriesError,
    editRunnerComponentHistorySuccess,
    addRunnerComponentHistorySuccess,
    setActiveRunnerComponentHistory,
    setRunnerComponentHistoryUpdateMode,
} = runnerComponentHistorySlice.actions

const reducer = runnerComponentHistorySlice.reducer

export { reducer as runnerComponentHistoryReducer }
