import React from 'react'
import { Menu, MenuProps } from 'antd'
import { useLanguage } from '../../hooks/shared/language/language.hook'

const AppHeaderLanguageMenus: React.FC = () => {
    const { changeLanguage } = useLanguage()
    const toggleLanguage = (key: string) => {
        changeLanguage(key)
    }
    const items: MenuProps['items'] = [
        {
            label: `English`,
            key: 'en',
            icon: (
                <>
                    <img
                        src="/en.png"
                        alt="English"
                        style={{
                            maxWidth: '100%',
                            height: '18px',
                            marginRight: '8px',
                        }}
                    />
                </>
            ),
            onClick: () => toggleLanguage('en'),
        },
        {
            label: `French`,
            key: 'fr',
            icon: (
                <>
                    <img
                        src="/fr.png"
                        alt="English"
                        style={{
                            maxWidth: '100%',
                            height: '18px',
                            marginRight: '8px',
                        }}
                    />
                </>
            ),
            onClick: () => toggleLanguage('fr'),
        },
    ]

    return <Menu items={items} />
}

export default AppHeaderLanguageMenus
