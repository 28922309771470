import { ColumnsType } from 'antd/lib/table'
import { IUser } from '../../models/shared/user.model'
import UsersTableAvatarRenderer from './users-table-avatar.component'
// import UsersTableDropdownRenderer from "./users-table-dropdown-renderer.component";

export const usersTableColumns: ColumnsType<IUser> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
    },
    {
        title: 'Username',
        dataIndex: 'usrName',
        key: 'usrName',
        width: '25rem',
        render: (_, record) => <UsersTableAvatarRenderer user={record} />,
    },
    {
        title: 'Computer',
        dataIndex: 'computer',
        key: 'computer',
    },
    {
        title: 'Device Serial',
        dataIndex: 'deviceSerial',
        key: 'deviceSerial',
    },
]
