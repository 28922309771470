import { Alert } from 'antd'
import { Formik } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useBranch } from '../../../hooks/common/branch.hook'
import { useFormErrors } from '../../../hooks/common/form/form-error.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import { emptyBranch, IBranch } from '../../../models/common/branch.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import BranchFormFields from './branch-form-field.component'

interface IProp {
    updateMode: UpdateMode
}

const BranchForm: React.FC<IProp> = ({ updateMode }) => {
    const { toastSuccess, toastError } = useToast()
    const { saveBranch, branch, branchSchema } = useBranch()
    const { setShow } = useContext<any>(AppModalContext)

    const { formError } = useFormErrors()
    const [hasSubmitted, setSubmitted] = useState(false)

    const onClose = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSubmitted(false)
        },
        []
    )

    useEffect(() => {}, [hasSubmitted])

    return (
        <div>
            {hasSubmitted && (
                <Alert
                    message="Error Messages"
                    description={
                        <>
                            {formError.validationErrors &&
                            formError.validationErrors.length > 0 ? (
                                formError.validationErrors.map((v, index) => (
                                    <span key={index} style={{ display: 'block' }}>
                                        {v}
                                    </span>
                                ))
                            ) : (
                                <span>
                                    {JSON.stringify(
                                        formError.validationErrors,
                                        null,
                                        2
                                    )}
                                </span>
                            )}
                        </>
                    }
                    type="error"
                    closable
                    onClose={onClose}
                    style={{ marginBottom: 10 }}
                />
            )}

            <Formik<IBranch>
                initialValues={branch || emptyBranch}
                validationSchema={branchSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitted(false)
                    const feedback = await saveBranch(values, updateMode)
                    if (feedback) {
                        toastSuccess('M0002')
                        setShow(false)
                    } else {
                        toastError('M0003')
                        setShow(true)
                        setSubmitted(true)
                    }
                    setSubmitting(false)
                }}
            >
                <>
                    <BranchFormFields />
                </>
            </Formik>
        </div>
    )
}
export default BranchForm
