export const frSite = {
    tenant: 'Locataire',
    code: 'Code',
    project: 'Projet',
    copYear: 'Année composante',
    region: 'Région',
    description: 'La description',
    district: 'District',
    siteType: 'Type de site',
    siteTier: 'Niveau du site',
    createdOn: 'Créé sur',
}
