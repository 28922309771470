export enum searchValueActionTypes {
    SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
}

export interface SetSearchValueAction {
    type: typeof searchValueActionTypes.SET_SEARCH_VALUE
    payload: any
}

export type searchValueActionsCreators = SetSearchValueAction
