export const enLocation = {
    description: 'Description',
    code: 'Code',
    tenant: 'Tenant',
    createdOn: 'Created On',
    subDivisionCode: 'Bub Division',

    submit: 'Submit',
    reset: 'Reset',
}
