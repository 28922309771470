import { Button, Empty } from 'antd'
import React from 'react'
import { useHistory } from 'react-router'

const NotFoundPage: React.FC = () => {
    const history = useHistory()
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'grid',
                placeItems: 'center',
            }}
        >
            <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                    height: 200,
                }}
                description={<span>No data found.</span>}
            >
                <Button type="primary" onClick={() => history.go(-1)}>
                    go back
                </Button>
            </Empty>
        </div>
    )
}

export default NotFoundPage
