import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as projectActions from '../../redux/budgeting/project.slice'
import { IProject } from '../../models/budgeting/project.model'
import { UpdateMode } from '../../models/update-mode.enum'
import projectService from '../../services/budgeting/project.service'

const useProject = () => {
    const projects = useSelector<rootState, IProject[]>(
        (state) => state.project.projects
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.project.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.project.initialFetch
    )
    const project = useSelector<rootState, IProject>(
        (state) => state.project.project
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.project.updateMode
    )

    const dispatch = useDispatch()

    const loadProjects = useCallback(() => {
        if (initialFetch) {
            dispatch(projectActions.fetchprojectAsync())
        }
    }, [dispatch, initialFetch])

    const addProject = async (project: IProject) => {
        return await projectService
            .create(project)
            .then((projectResponse) => {
                if (projectResponse.success) {
                    dispatch(projectActions.addProjectSuccess(projectResponse.data))
                } else {
                    return projectResponse
                }
                return projectResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setProject = (project: IProject) => {
        dispatch(projectActions.setActiveProject(project))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(projectActions.setProjectUpdateMode(updateMode))
    }

    const editProject = async (project: IProject) => {
        return await projectService
            .update(project)
            .then((projectResponse) => {
                dispatch(projectActions.editProjectSuccess(projectResponse.data))
                setProject(projectResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveProject = async (project: IProject, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addProject(project)
            : await editProject(project)
    }

    useEffect(() => {
        loadProjects()
    }, [project, projects, isLoading, initialFetch, loadProjects])

    return {
        project,
        projects,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addProject,
        editProject,
        saveProject,
        setProject,
    }
}

export { useProject }
