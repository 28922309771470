import { Modal, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import React, { useState } from 'react'

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
    })

export const ChangeProfilePicture: React.FC = () => {
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [fileList, setFileList] = useState<UploadFile[]>([])

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList)
    }

    const handleCancel = () => setPreviewOpen(false)

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile)
        }

        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
        setPreviewTitle(
            file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
        )
    }

    return (
        <ImgCrop rotate>
            <>
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={handlePreview}
                    maxCount={1}
                    // disabled={fileList && fileList.length > 0 ? true : false}
                >
                    {fileList.length < 5 && '+ Upload'}
                </Upload>
                <Modal
                    visible={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                >
                    <img
                        alt="example"
                        style={{ width: '100%' }}
                        src={previewImage}
                    />
                </Modal>
                <div
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                        background: '#fafafa',
                        padding: 5,
                    }}
                >
                    <p style={{ marginBottom: 0 }}>
                        <strong>Profile Photo</strong>
                    </p>
                    <small>
                        Profile photo Create, upload, and manage your globally
                        recognized avatar from a single place with Gravatar.
                    </small>
                </div>
            </>
        </ImgCrop>
    )
}
