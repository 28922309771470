import { ColumnsType } from 'antd/lib/table'
import { IBudgetAnalysis } from '../../models/reports/budget-analysis.model'
import { format } from '../../utils/format'

export const budgetAnalysisTableColumns: ColumnsType<IBudgetAnalysis> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'HTXS',
        dataIndex: 'm01',
        key: 'm01',
        align: 'right',
        render: (_, record) => format.number(record.m01),
    },
    {
        title: 'HVCT',
        dataIndex: 'm02',
        key: 'm02',
        align: 'right',
        render: (_, record) => format.number(record.m02),
    },
    {
        title: 'HVSI',
        dataIndex: 'm03',
        key: 'm03',
        align: 'right',
        render: (_, record) => format.number(record.m03),
    },
    {
        title: 'MTCT',
        dataIndex: 'm04',
        key: 'm04',
        align: 'right',
        render: (_, record) => format.number(record.m04),
    },
    {
        title: 'PDTX',
        dataIndex: 'm05',
        key: 'm05',
        align: 'right',
        render: (_, record) => format.number(record.m05),
    },
    {
        title: 'PREP',
        dataIndex: 'm06',
        key: 'm06',
        align: 'right',
        render: (_, record) => format.number(record.m06),
    },
    {
        title: 'VL',
        dataIndex: 'm07',
        key: 'm07',
        align: 'right',
        render: (_, record) => format.number(record.m07),
    },
    {
        title: 'PREP F',
        dataIndex: 'm08',
        key: 'm08',
        align: 'right',
        render: (_, record) => format.number(record.m08),
    },
    {
        title: 'Total',
        dataIndex: 'totalExpenditure',
        key: 'totalExpenditure',
        align: 'right',
        render: (_, record) => format.number(record.totalExpenditure),
    },
]
