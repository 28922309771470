import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IProjectSite {
    tenant: string
    copYear: string
    project: string
    site: string
    createdOn: Date
}

export const emptyProjectSite: IProjectSite = {
    tenant: '',
    copYear: '',
    project: '',
    site: '',
    createdOn: new Date(),
}

export interface IProjectSiteState extends IBaseState {
    readonly projectSites: IProjectSite[]
    readonly projectSite: IProjectSite
}

export interface IProjectSiteResponse extends IResponseBase {
    data: IProjectSite
}
