import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ISite {
    tenant: string
    code: string
    project: string
    copYear: string
    region: string
    description: string
    district: string
    siteType: string
    siteTier: string
    createdOn: Date
}

export const emptySite: ISite = {
    tenant: '',
    code: '',
    project: '',
    copYear: '',
    region: '',
    description: '',
    district: '',
    siteType: '',
    siteTier: '',
    createdOn: new Date(),
}

export interface ISiteState extends IBaseState {
    readonly sites: ISite[]
    readonly site: ISite
}

export interface ISiteResponse extends IResponseBase {
    data: ISite
}
