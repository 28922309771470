import { ColumnsType } from 'antd/lib/table'
import { ITicketStatus } from '../../../models/common/ticket-status.model'

export const ticketStatusTableColumns: ColumnsType<ITicketStatus> = [

    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
    },

    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
