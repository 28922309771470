import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ICopYearSite {
    tenant: string
    copYear: string
    project: string
    site: string
}

export const emptyCopYearSite: ICopYearSite = {
    tenant: '',
    copYear: '',
    project: '',
    site: '',
}

export interface ICopYearSiteState extends IBaseState {
    readonly copYearSites: ICopYearSite[]
    readonly copYearSite: ICopYearSite
}

export interface ICopYearSiteResponse extends IResponseBase {
    data: ICopYearSite
}
