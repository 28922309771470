import { apiRequests } from '..'
import { IOutlay, IOutlayResponse } from '../../models/budgeting/outlay.model'

const outlayService = {
    list: (): Promise<IOutlay[]> => apiRequests.get('/outlays'),
    details: (code: string): Promise<IOutlay> => apiRequests.get(`/outlays/${code}`),
    create: (outlay: IOutlay): Promise<IOutlayResponse> =>
        apiRequests.post('/outlays', outlay),
    update: (outlay: IOutlay): Promise<IOutlayResponse> =>
        apiRequests.put('/outlays', outlay),
}

export default outlayService
