import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as userCoordinationModel from '../../models/budgeting/user-coordination.model'
import userCoordinationService from '../../services/budgeting/user-coordination.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: userCoordinationModel.IUserCoordinationState = {
    userCoordinations: [],
    errors: '',
    userCoordination: userCoordinationModel.emptyUserCoordination,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchUserCoordinationAsync = createAsyncThunk<
    userCoordinationModel.IUserCoordination[],
    void
>('userCoordination/fetchuserCoordinationAsync', async (_, thunkApi) => {
    try {
        return await userCoordinationService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const userCoordinationSlice = createSlice({
    name: 'userCoordination',
    initialState,
    reducers: {
        fetchuserCoordinationRequest: (state) => {
            state.isLoading = true
        },
        fetchuserCoordinationSuccess: (
            state,
            action: PayloadAction<userCoordinationModel.IUserCoordination[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.userCoordinations = action.payload
        },
        fetchuserCoordinationError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editUserCoordinationSuccess: (
            state,
            action: PayloadAction<userCoordinationModel.IUserCoordination>
        ) => {
            state.userCoordinations = state.userCoordinations.map(
                (userCoordination) => {
                    return userCoordination.coordination ===
                        action.payload.coordination
                        ? action.payload
                        : userCoordination
                }
            )
            state.updateMode = UpdateMode.NONE
        },
        addUserCoordinationSuccess: (
            state,
            action: PayloadAction<userCoordinationModel.IUserCoordination>
        ) => {
            state.userCoordinations = [...state.userCoordinations, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveUserCoordination: (
            state,
            action: PayloadAction<userCoordinationModel.IUserCoordination>
        ) => {
            state.userCoordination = action.payload
        },
        setUserCoordinationUpdateMode: (
            state,
            action: PayloadAction<UpdateMode>
        ) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserCoordinationAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchUserCoordinationAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.userCoordinations = action.payload
        })
        builder.addCase(fetchUserCoordinationAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchuserCoordinationRequest,
    fetchuserCoordinationSuccess,
    fetchuserCoordinationError,
    editUserCoordinationSuccess,
    addUserCoordinationSuccess,
    setActiveUserCoordination,
    setUserCoordinationUpdateMode,
} = userCoordinationSlice.actions

const reducer = userCoordinationSlice.reducer

export { reducer as userCoordinationReducer }
