export const frSideBarMenus = {
    A: 'Maison',
    B: 'Tableau de bord',
    B00: 'Dépense vs cible',
    B01: 'Catégorie de coût',
    B02: 'Intervention',
    B03: 'Projections des jalons',
    B04: 'Rapport journalier',

    // Activities
    C: ' Activités VIH',
    C00: 'Activités sur le site',
    C01: "Projections d'étapes",
    C02: "Réalisation d'étapes importantes",
    C03: 'Gestion du programme BO',
    C04: 'Gestion du programme DHS',
    C05: 'Gestion du programme Mvan',
    C06: 'HIV Regional Modification',
    C07: 'HIV Zone Site Modifications',
    C08: 'HIV Zone Above Site Modification',

    D: 'Activités de laboratoire',
    D00: 'Activités de laboratoire',
    D01: 'Projections des jalons du laboratoire',
    D02: 'Réalisations marquantes du laboratoire',
    D03: 'Gestion du programme BO du laboratoire',
    D04: 'Gestion du programme DHS du laboratoire',
    D05: 'Gestion du programme MVAN du laboratoire',
    D06: 'Lab Regional Modification',
    D07: 'Lab Zone Site Modifications',
    D08: 'Lab Zone Above Site Modification',

    E: 'Coureurs',
    E00: 'Période du coureur',
    E01: 'Coureur',
    //Site reports
    F: 'Rapports de sites',
    F00: 'Catégorie de coût BA',
    F01: 'Intervention BA',
    F02: 'Site BA',
    F03: 'Exécution budgétaire',
    F04: 'Modèle CDV ER',
    F05: 'Modèle CDV ACC',
    F06: 'Plan de travail chiffré',

    // Program reports
    G: 'Rapports de programme',
    G00: 'Catégorie de coût BA',
    G01: 'Intervention BA',
    G02: 'Site BA',
    G03: 'Exécution budgétaire',
    G04: 'Plan de travail chiffré',

    // Program reports
    H: 'Rapports de zone',
    H00: 'Approche BA',
    H01: 'Intervention BA',
    H02: 'Site BA',
    H03: 'Catégorie de coût BA',

    // Setting
    I: 'Réglages',
    I00: 'Utilisateurs',
    I01: 'Gestion des rôles',
    I02: 'Réclamations',
    I03: 'User Coordination',

    // Modifications
    J: 'Modifications',
    J00: 'Région',
    J01: 'Zone au-dessus du site',
    J02: 'Site zonal',

    //faqs
    K:'Foire aux questions',
    K00: 'Billet',
    K01: 'Statut du ticket'
}
