import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { UpdateMode } from '../../../models/update-mode.enum'
import { IDialogMessage } from '../../../models/common/dialog-message.model'
import dialogMessageService from '../../../services/common/dialog-message/dialog-message.service'
import * as dialogMessageActions from '../../../redux/common/dialog-message/dialog-message.slice'

const useDialogMessage = () => {
    const dialogMessages = useSelector<rootState, IDialogMessage[]>(
        (state) => state.dialogMessage.dialogMessages
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.dialogMessage.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.dialogMessage.initialFetch
    )
    const dialogMessage = useSelector<rootState, IDialogMessage>(
        (state) => state.dialogMessage.dialogMessage
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.dialogMessage.updateMode
    )

    const dispatch = useDispatch()

    const loadDialogMessages = useCallback(() => {
        if (initialFetch) {
            dispatch(dialogMessageActions.fetchDialogMessagesAsync())
        }
    }, [dispatch, initialFetch])

    const addDialogMessage = async (dialogMessage: IDialogMessage) => {
        return await dialogMessageService
            .create(dialogMessage)
            .then((dialogMessageResponse) => {
                dispatch(
                    dialogMessageActions.addDialogMessageSuccess(
                        dialogMessageResponse.data
                    )
                )
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const setDialogMessage = (dialogMessage: IDialogMessage) => {
        dispatch(dialogMessageActions.setActiveDialogMessage(dialogMessage))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(dialogMessageActions.setDialogMessageUpdateMode(updateMode))
    }

    const editDialogMessage = async (dialogMessage: IDialogMessage) => {
        return await dialogMessageService
            .update(dialogMessage)
            .then((dialogMessageResponse) => {
                dispatch(
                    dialogMessageActions.editDialogMessageSuccess(
                        dialogMessageResponse.data
                    )
                )
                setDialogMessage(dialogMessageResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveDialogMessage = async (
        dialogMessage: IDialogMessage,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addDialogMessage(dialogMessage)
            : await editDialogMessage(dialogMessage)
    }

    useEffect(() => {
        loadDialogMessages()
    }, [dialogMessage, dialogMessages, isLoading, initialFetch, loadDialogMessages])

    return {
        dialogMessage,
        dialogMessages,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addDialogMessage,
        editDialogMessage,
        saveDialogMessage,
        setDialogMessage,
    }
}

export { useDialogMessage }
