import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { emptyRunnerPeriodBase, IRunnerPeriodBase } from './runner-period-base.model'

export interface runnerHistoryCodes {
    items: string[]
}

export interface IRunnerPeriod extends IRunnerPeriodBase {}

export interface IRunnerPeriodState extends IBaseState {
    readonly runnerPeriods: IRunnerPeriod[]
    readonly runnerPeriod: IRunnerPeriod
    readonly runnerHistoryCodes: string[]
}

export const emptyRunnerPeriod: IRunnerPeriod = emptyRunnerPeriodBase

export interface IRunnerPeriodResponse extends IResponseBase {
    data: IRunnerPeriod
}

export interface IRunnerPeriodHistoryCodesResponse extends IResponseBase {
    data: string[]
}
