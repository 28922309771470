import { apiRequests } from '..'
import {
    IProjectSite,
    IProjectSiteResponse,
} from '../../models/budgeting/project-site.model'

const projectSiteService = {
    list: (): Promise<IProjectSite[]> => apiRequests.get('/projectSites'),
    details: (code: string): Promise<IProjectSite> =>
        apiRequests.get(`/projectSites/${code}`),
    create: (projectSite: IProjectSite): Promise<IProjectSiteResponse> =>
        apiRequests.post('/projectSites', projectSite),
    update: (projectSite: IProjectSite): Promise<IProjectSiteResponse> =>
        apiRequests.put('/projectSites', projectSite),
}

export default projectSiteService
