import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as runnerActions from '../../redux/budgeting/runner.slice'
import * as weekActions from '../../redux/budgeting/week.slice'
import { emptyRunner, IRunner } from '../../models/budgeting/runner.model'
import { UpdateMode } from '../../models/update-mode.enum'
import runnerService from '../../services/budgeting/runner.service'
import { useCurrentBranch } from '../common/current-branch/current-branch.hook'
import { useRegion } from './region.hook'
import { useRunnerStatus } from './runner-status.hook'
import { fetchInitialDataBranchAsync } from '../../redux/shared/batch/batch.action'

const useRunner = () => {
    const runners = useSelector<rootState, IRunner[]>(
        (state) => state.runner.runners
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.runner.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.runner.initialFetch
    )
    const runner = useSelector<rootState, IRunner>((state) => state.runner.runner)
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.runner.updateMode
    )
    const { regions } = useRegion()
    const { runnerStatuses } = useRunnerStatus()
    const { currentBranch } = useCurrentBranch()

    const dispatch = useDispatch()

    const getRegionRunners = useCallback(() => {
        return runners.filter(
            (x) =>
                x.region === currentBranch.region &&
                x.project === currentBranch.project
        )
    }, [currentBranch.project, currentBranch.region, runners])

    const getRunner = useCallback(
        (runner: IRunner): IRunner => {
            const region =
                regions.find((x) => x.code === runner.region) ?? emptyRunner
            const regionDescription = region ? region.description : ''

            const runnerStatus = runnerStatuses.find((x) => x.code === runner.status)
            const statusDescription = runnerStatus ? runnerStatus.description : ''

            return {
                ...runner,
                regionDescription,
                statusDescription,
            }
        },
        [regions, runnerStatuses]
    )

    const getRunners = useCallback(
        (runners: IRunner[]): IRunner[] => {
            return runners.map((runner) => getRunner(runner))
        },
        [getRunner]
    )

    const loadRunners = useCallback(() => {
        if (initialFetch) {
            dispatch(runnerActions.fetchRunnersAsync())
        }
    }, [dispatch, initialFetch])

    const reloadRunners = useCallback(() => {
        dispatch(runnerActions.fetchRunnersAsync())
    }, [dispatch])

    const addRunner = async (runner: IRunner) => {
        return await runnerService
            .create(runner)
            .then((runnerResponse) => {
                if (runnerResponse.success) {
                    dispatch(runnerActions.addRunnerSuccess(runnerResponse.data))
                    dispatch(weekActions.fetchWeeksAsync(runner.copYear))
                    dispatch(fetchInitialDataBranchAsync(currentBranch.code))
                } else {
                    return runnerResponse
                }
                return runnerResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setRunner = (runner: IRunner) => {
        dispatch(runnerActions.setActiveRunner(runner))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(runnerActions.setRunnerUpdateMode(updateMode))
    }

    const editRunner = async (runner: IRunner) => {
        return await runnerService
            .update(runner)
            .then((runnerResponse) => {
                dispatch(runnerActions.editRunnerSuccess(runnerResponse.data))
                dispatch(weekActions.fetchWeeksAsync(runner.copYear))
                setRunner(runnerResponse.data)
                dispatch(fetchInitialDataBranchAsync(currentBranch.code))
                return true
            })
            .catch((error) => {
                return false
            })
    }
    const archiveRunner = async (runner: IRunner) => {
        return await runnerService
            .archive(runner)
            .then((runnerResponse) => {
                dispatch(runnerActions.editRunnerSuccess(runnerResponse.data))
                setRunner(runnerResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveRunner = async (runner: IRunner, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addRunner(runner)
            : await editRunner(runner)
    }

    const getActiveRegionRunner = useCallback((): IRunner => {
        const regionRunner = runners.find(
            (x) =>
                x.region === currentBranch.region &&
                x.project === currentBranch.project
        )
        if (regionRunner) {
            var activeRunner = getRunner(regionRunner)
            return activeRunner
        }
        return {
            ...getRunner(runner),
        }
    }, [getRunner, currentBranch, runners, runner])

    const getRunnerIsActive = useCallback(() => {
        const runner = getActiveRegionRunner()
        return runner.status === '60' || runner.status === '61'
    }, [getActiveRegionRunner])

    useEffect(() => {
        loadRunners()
    }, [runner, runners, currentBranch.region, isLoading, initialFetch, loadRunners])

    return {
        runner: getActiveRegionRunner(),
        activeRunner: getActiveRegionRunner(),
        runners: getRunners(getRegionRunners()),
        runnerIsActive: getRunnerIsActive(),
        reloadRunners,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addRunner,
        editRunner,
        archiveRunner,
        saveRunner,
        setRunner,
    }
}

export { useRunner }
