import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import LocationForm from '../../../components/common/location/location-form.component'
import LocationTable from '../../../components/common/location/location-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'

const LocationPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)

    const handleShow = () => {
        setTitle('Create new location')
        setContent(<LocationForm />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'Locations']} />
            <TitleBar
                title="Locations"
                subTitle="View and manage locations"
                showButton
                handleShow={handleShow}
                buttonLabel="Add Location"
                icon={<FiPlus />}
            />
            <LocationTable />
        </>
    )
}

export default LocationPage
