import { Button, Modal, Space } from "antd";
import { Form, Input, SubmitButton, FormItem } from "formik-antd";
import { Formik } from "formik";
import React from "react";
import { useToast } from "../../../hooks/common/toast/toast.hook";
import { emptyClaim } from "../../../models/common/claim.model";

interface Props {
  onCancel: () => void;
  visible: boolean;
  title: string;
}

export const ClaimsFormFields: React.FC<Props> = ({
  onCancel,
  visible,
  title,
}) => {

  const { toastSuccess, toastError } = useToast();

  return (
      <Modal
          title={title}
          visible={visible}
          footer={null}
          maskClosable={false}
          onCancel={onCancel}
          width={'25rem'}
          className="patient-form__modal"
      >
          <Formik
              initialValues={emptyClaim}
              // validationSchema={roomValidationSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                  // const obj = {
                  //     ...values,
                  //     slug: slugify(values.description),
                  // }
                  // const feedback = await saveClaim(obj, updateMode)
                  const feedback = true;
                  if (feedback) {
                      toastSuccess('M0002')
                      // if (updateMode === 'EDIT') {
                      //     //nothing doing
                      // } else {
                      //     resetForm({
                      //         values: emptyRole,
                      //     })
                      // }

                      resetForm()
                      onCancel()
                  } else {
                      toastError('M0003')
                  }
                  setSubmitting(false)
              }}
          >
              {({ handleSubmit, isSubmitting, isValid }) => (
                  <Form onFinish={handleSubmit} autoComplete="off">
                      <label>
                          <p className="mb-0">Code</p>
                          <FormItem name="claimCode">
                              <Input name="claimCode" disabled={isSubmitting} />
                          </FormItem>
                      </label>

                      <label>
                          <p className="mb-0">Parent</p>
                          <FormItem name="parent">
                              <Input name="parent" disabled={isSubmitting} />
                          </FormItem>
                      </label>

                      <label>
                          <p className="mb-0">Description</p>
                          <FormItem name="description">
                              <Input name="description" disabled={isSubmitting} />
                          </FormItem>
                      </label>

                      <Space>
                          <SubmitButton disabled={isValid && isSubmitting}>
                              Save
                          </SubmitButton>

                          <Button style={{ marginLeft: '5px' }} onClick={onCancel}>
                              Cancel
                          </Button>
                      </Space>
                  </Form>
              )}
          </Formik>
      </Modal>
  )
};
