import { Spin } from 'antd'
import React, { useCallback, useContext, useEffect } from 'react'
import { FiPlus, FiUser } from 'react-icons/fi'
import ActivitySummaryTable from '../../../components/budgeting/activity-summary/activity-summary-component.table'
import ActivityForm from '../../../components/budgeting/activity/activity-form.component'
import BackButton from '../../../components/common/back-button/back-button.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useActivity } from '../../../hooks/budgeting/activity.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { emptyActivitySummary } from '../../../models/budgeting/activity-summary.model'

import { UpdateMode } from '../../../models/update-mode.enum'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const ActivityBatchPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { isLoading, initialFetch, activitySummary, activities, setActivity } =
        useActivity()
    const { currentBranch } = useCurrentBranch()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const router = useHistory()

    const getFirstActivitySummaryInBatch = useCallback(() => {
        if (activitySummary) {
            if (activities && activities.length) {
                const journal = activities.find(
                    (x) => x.batch === activitySummary.batch
                )
                if (journal) {
                    return journal
                }
                return emptyActivitySummary
            }
            return emptyActivitySummary
        }
        return emptyActivitySummary
    }, [activitySummary, activities])

    const getActivityBatchDescription = useCallback(() => {
        if (activitySummary) {
            if (activities && activities.length) {
                const summary = activities.find(
                    (x) => x.batch === activitySummary.batch
                )
                if (summary) {
                    return (
                        <p className="mb-0">
                            <FiUser /> {summary.userCode} | Batch: {summary.batch}
                        </p>
                    )
                }
                return ''
            }
            return ''
        }
        return ''
    }, [activitySummary, activities])

    const handleShow = () => {
        setWidth('40rem')
        setShow(true)
        setContent(<ActivityForm updateMode={UpdateMode.ADD} />)
        setTitle(t('budgeting:activity.form.addActivity'))

        const initialModel: any = {
            ...getFirstActivitySummaryInBatch(),
            amount: 0,
            description: '',
            site: '',
            activity: '',
            batchLine: '00000',
        }
        setActivity(initialModel)
    }

    useEffect(() => {}, [currentBranch.code, currentBranch.region])

    return (
        <>
            <PageBreadCrumbs
                items={[
                    t('budgeting:activity.detailPage.breadcrumb.mainActivities'),
                    t('budgeting:activity.detailPage.breadcrumb.siteActivities'),
                    t('budgeting:activity.detailPage.breadcrumb.batch'),
                ]}
            />
            <TitleBar
                title={t('budgeting:activity.detailPage.title')}
                subTitle={t('budgeting:activity.detailPage.subTitle')}
                showButton
                buttonLabel={t('budgeting:activity.detailPage.buttonLabel')}
                handleShow={handleShow}
                icon={<FiPlus />}
            />
            <BackButton page="Activity Batch Summary" />

            {getActivityBatchDescription()}

            {isLoading && initialFetch ? (
                <Spin size="large" />
            ) : (
                <ActivitySummaryTable showButton={false} />
            )}
        </>
    )
}

export default ActivityBatchPage
