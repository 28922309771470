import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IStrategy {
    tenant: string
    code: string
    description: string
    createdOn: Date
}

export const emptyStrategy: IStrategy = {
    tenant: '',
    code: '',
    description: '',
    createdOn: new Date(),
}

export interface IStrategyState extends IBaseState {
    readonly strategies: IStrategy[]
    readonly strategy: IStrategy
}

export interface IStrategyResponse extends IResponseBase {
    data: IStrategy
}
