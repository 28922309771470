import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IOutlayTarget {
    component: string
    outlay: string
    budgetCode: string
    indicator: string
    annualTarget: number
    componentTarget: number
    periodTarget: number
    periodAchievement: number
    percentageAchievement: number
    componentBudget: number
    periodBudget: number
    periodExpenditure: number
    percentageExpenditure: number
}

export const emptyOutlayTarget: IOutlayTarget = {
    component: '',
    outlay: '',
    budgetCode: '',
    indicator: '',
    annualTarget: 0,
    componentTarget: 0,
    periodTarget: 0,
    periodAchievement: 0,
    percentageAchievement: 0,
    componentBudget: 0,
    periodBudget: 0,
    periodExpenditure: 0,
    percentageExpenditure: 0,
}

export interface IOutlayDashState extends IBaseState {
    readonly outlayDashs: IOutlayTarget[]
    readonly outlayDash: IOutlayTarget
}

export interface IOutlayDashResponse extends IResponseBase {
    data: IOutlayTarget
}
