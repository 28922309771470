import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ITransactionCode {
    code: string
    description: string
    createdOn: Date
}

export const emptyTransactionCode: ITransactionCode = {
    code: '',
    description: '',
    createdOn: new Date(),
}

export interface ITransactionCodeState extends IBaseState {
    readonly transactionCodes: ITransactionCode[]
    readonly transactionCode: ITransactionCode
}

export interface ITransactionCodeResponse extends IResponseBase {
    data: ITransactionCode
}
