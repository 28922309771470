interface EmployeeValue {
    label: string
    value: any
}

export interface ISearchValue extends EmployeeValue {}
export const emptySearchValue: ISearchValue = {
    label: '',
    value: '',
}
export interface ISearchValueState {
    readonly searchValue: ISearchValue
}
