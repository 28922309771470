import { apiRequests } from '..'
import { IBudgetExecutionOptions } from '../../models/reports/budget-execution-options.model'
import { format } from '../../utils/format'

export const budgetExecutionService = {
    getBudgetExecutionData: (options: IBudgetExecutionOptions): Promise<any> =>
        apiRequests.post('budgetExecution', options),

    getBugdetExecutionReport: (options: IBudgetExecutionOptions): Promise<any> =>
        apiRequests.get(
            `/budgetExecution?tenant=${options.tenant}&project=${
                options.project
            }&copYear=04&component=${options.component}&region=${
                options.region
            }&startDate=${format.isodate(
                options.startDate
            )}&endDate=${format.isodate(options.endDate)}&user=${
                options.user
            }&activityType=${options.activityType}&reportType=01&tranCode=${
                options.tranCode
            }`
        ),
}
