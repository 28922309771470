import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IBranch {
    tenant: string
    code: string
    description: string
    branchShortName: string
    staCode: string
    telephone: string
    address: string
    postBox: string
    city: string
    region: string
    eMail: string
    website: string
    slogan: string
    branchName: string
    branchType: string
    bursaryStation: string
    branchTown: string
    createdOn: Date
}

export interface IBranchState extends IBaseState {
    readonly branches: IBranch[]
    readonly branch: IBranch
}

export const emptyBranch: IBranch = {
    tenant: '',
    code: '',
    description: '',
    branchShortName: '',
    staCode: '',
    telephone: '',
    address: '',
    postBox: '',
    city: '',
    region: '',
    eMail: '',
    website: '',
    slogan: '',
    branchName: '',
    branchType: '',
    bursaryStation: '',
    branchTown: '',
    createdOn: new Date(),
}

export interface IBranchResponse extends IResponseBase {
    data: IBranch
}
