import { apiRequests } from '..'
import {
    IAnnualTarget,
    IAnnualTargetResponse,
} from '../../models/budgeting/annual-target.model'

const annualTargetService = {
    list: (): Promise<IAnnualTarget[]> => apiRequests.get('/annualTargets'),
    details: (code: string): Promise<IAnnualTarget> =>
        apiRequests.get(`/annualTargets/${code}`),
    create: (annualTarget: IAnnualTarget): Promise<IAnnualTargetResponse> =>
        apiRequests.post('/annualTargets', annualTarget),
    update: (annualTarget: IAnnualTarget): Promise<IAnnualTargetResponse> =>
        apiRequests.put('/annualTargets', annualTarget),
}

export default annualTargetService
