import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as dateGenerationModel from '../../../models/common/date-generation.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import dateGenerationService from '../../../services/common/date-generation/date-generation.service'

export const initialState: dateGenerationModel.IDateGenerationState = {
    dateGenerations: [],
    errors: '',
    dateGeneration: dateGenerationModel.emptyDateGeneration,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchDateGenerationsAsync = createAsyncThunk(
    'dateGeneration/fetchDateGenerationsAsync',
    async () => {
        return await dateGenerationService.list()
    }
)

export const dateGenerationSlice = createSlice({
    name: 'dateGeneration',
    initialState,
    reducers: {
        fetchDateGenerationsRequest: (state) => {
            state.isLoading = true
        },
        fetchDateGenerationsSuccess: (
            state,
            action: PayloadAction<dateGenerationModel.IDateGeneration[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.dateGenerations = action.payload
        },
        fetchDateGenerationsError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editDateGenerationSuccess: (
            state,
            action: PayloadAction<dateGenerationModel.IDateGeneration>
        ) => {
            state.dateGenerations = state.dateGenerations.map((dateGeneration) => {
                return dateGeneration.branch === action.payload.branch
                    ? action.payload
                    : dateGeneration
            })
            state.updateMode = UpdateMode.NONE
        },
        addDateGenerationSuccess: (
            state,
            action: PayloadAction<dateGenerationModel.IDateGeneration>
        ) => {
            state.dateGenerations = [...state.dateGenerations, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveDateGeneration: (
            state,
            action: PayloadAction<dateGenerationModel.IDateGeneration>
        ) => {
            state.dateGeneration = action.payload
        },
        setDateGenerationUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDateGenerationsAsync.pending, (_state, _action) => {
            fetchDateGenerationsRequest()
        })
        builder.addCase(fetchDateGenerationsAsync.fulfilled, (_state, action) => {
            _state.dateGenerations = action.payload
        })
        builder.addCase(fetchDateGenerationsAsync.rejected, (_state, action) => {
            fetchDateGenerationsError(action.payload as string)
        })
    },
})

export const {
    fetchDateGenerationsRequest,
    fetchDateGenerationsSuccess,
    fetchDateGenerationsError,
    editDateGenerationSuccess,
    addDateGenerationSuccess,
    setActiveDateGeneration,
    setDateGenerationUpdateMode,
} = dateGenerationSlice.actions

const reducer = dateGenerationSlice.reducer

export { reducer as dateGenerationReducer }
