import React, { useContext } from 'react'
import { FiEdit } from 'react-icons/fi'
import BackButton from '../../../components/common/back-button/back-button.component'
import BranchStationDetailComponent from '../../../components/common/branch-station/branch-station-detail.component'
import BranchStationForm from '../../../components/common/branch-station/branch-station-form.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { UpdateMode } from '../../../models/update-mode.enum'

const BranchStationDetailsPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)

    const handleShow = () => {
        setTitle('Edit Branch Station')
        setContent(<BranchStationForm updateMode={UpdateMode.EDIT} />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs
                items={['Configurations', 'Branch Stations', 'Details']}
            />
            <TitleBar
                title="Branch Station"
                subTitle="View and manage branch stations"
                showButton
                handleShow={handleShow}
                buttonLabel="Edit Branch Station"
                icon={<FiEdit />}
            />

            <BackButton page="Branch Station" />

            {/* details */}
            <BranchStationDetailComponent />
        </>
    )
}

export default BranchStationDetailsPage
