export const enModifications = {
    regionBreadcrumb: {
        modifications: 'Modifications',
        regionalModifications: 'Regional modifications',
    },

    zoneAboveBreadcrumb: {
        modifications: 'Modifications',
        zoneAboveModifications: 'Zone Above Site',
    },

    zoneSiteBreadcrumb: {
        modifications: 'Modifications',
        zoneModifications: 'Zone Site',
    },

    regionMainPage: {
        title: 'Regional modifications',
        subTitle: 'Modify regional activities',
        buttonLabel: 'Modify',
    },

    zoneAboveMainPage: {
        title: 'Zone Above Site Modifications',
        subTitle: 'Modify Zone Above Site activities',
        buttonLabel: 'Modify',
    },

    zoneSiteMainPage: {
        title: 'Zone Above Site Modifications',
        subTitle: 'Modify Zone Above Site activities',
        buttonLabel: 'Modify',
    },
    modificationTable: {
        edit: "Edit",
        delete: "Delete"

    },

    form: {
        copYear: 'Select Cop Year',
        project: 'Project',
        component: 'Component',
        runner: 'Runner',
        submit: 'Load',
        reset: 'Reset',
    },
}
