import React, { useEffect } from 'react'
import DailyReportTable from '../../components/dashboard/daily-data/daily-data-table.component'
import PageBreadCrumbs from '../../components/shared/page-breadcrumb/page-breadcrumb.component'
import './home.page.scss'
import { useTranslation } from 'react-i18next'
import { useDailyData } from '../../hooks/budgeting/daily-data.hook'
import { useCurrentBranch } from '../../hooks/common/current-branch/current-branch.hook'
import { useCurrentUser } from '../../hooks/common/current-user/current-user.hook'
import TitleBar from '../../components/common/title-bar/title-bar.component'

const DailyReportDashboardPage: React.FC = () => {
    const { t } = useTranslation()
    const { getDailyData } = useDailyData()
    const { currentBranch } = useCurrentBranch()
    const { currentUser } = useCurrentUser()

    useEffect(() => {
        getDailyData({
            currentUser: currentUser.code,
            branch: currentBranch.code,
        })
    }, [])
    return (
        <div>
            <PageBreadCrumbs items={[t('common:breadCrumb.dashboard')]} />
            <TitleBar title={'Daily Data'} subTitle={'View and manage daily data'} />
            <DailyReportTable />
        </div>
    )
}

export default DailyReportDashboardPage
