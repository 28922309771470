import { Table, Tag } from 'antd'
import React, {  useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useActivity } from '../../../hooks/budgeting/activity.hook'
import { IActivity } from '../../../models/budgeting/activity.model'
import { format } from '../../../utils/format'
import { activityHistoryTableColumns } from './activity-history-table.columns'

type Props = {
    activities: Array<IActivity>
}

const ActivityHistoryDetailTable: React.FC<Props> = ({activities}) => {
    const recordRef = useRef<any>(null)
    const {setActivity} = useActivity()
    const history = useHistory()
    const total = activities.reduce((a, b)=> a + b.amount, 0)

    const handleRowClick = (record: IActivity) => {
        setActivity(record)
        history.push(`/regional-modifications/batch-summary/${record.batch}/${record.batchLine}`);
    }

    return (
        <>
        <Tag color="blue" style={{marginBottom: '1rem', fontWeight: 'bold'}}>Total: {format.number(total)}</Tag>
            <Table<IActivity>
                dataSource={activities}
                columns={activityHistoryTableColumns}
                className="activity__table activity__table--infinite"
                id="activity__table"
                size={'small'}
                rowKey={`description`}
                scroll={{ x: '500' }}
                onRow={(record: IActivity) => {
                    return {
                        onClick: (e) => {
                            recordRef.current = e.target
                            const target = recordRef.current.closest(
                                '.ant-table-row-expand-icon-cell'
                            )
                            if (Object.is(target, null)) {
                                handleRowClick(record)
                            }
                        },
                    }
                }}
            />
        </>
    )
}

export default ActivityHistoryDetailTable
