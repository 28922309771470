import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ICopYearCostCategory {
    tenant: string
    copYear: string
    project: string
    costCategory: string
}

export const emptyCopYearCostCategory: ICopYearCostCategory = {
    tenant: '',
    copYear: '',
    project: '',
    costCategory: '',
}

export interface ICopYearCostCategoryState extends IBaseState {
    readonly copYearCostCategories: ICopYearCostCategory[]
    readonly copYearCostCategory: ICopYearCostCategory
}

export interface ICopYearCostCategoryResponse extends IResponseBase {
    data: ICopYearCostCategory
}
