import { apiRequests } from '../'
import { ICopYear, ICopYearResponse } from '../../models/budgeting/cop-year.model'

const copYearService = {
    list: (): Promise<ICopYear[]> => apiRequests.get('/copYears'),
    details: (code: string): Promise<ICopYear> =>
        apiRequests.get(`/copYears/${code}`),
    create: (copyear: ICopYear): Promise<ICopYearResponse> =>
        apiRequests.post('/copYears', copyear),
    update: (copyear: ICopYear): Promise<ICopYearResponse> =>
        apiRequests.put('/copYears', copyear),
}

export default copYearService
