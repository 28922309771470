import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import StationReferenceForm from '../../../components/common/station-reference/station-reference-form.component'
import StationReferenceTable from '../../../components/common/station-reference/station-reference-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useStationReference } from '../../../hooks/common/station-reference/station-reference.hook'
import { emptyStationReference } from '../../../models/common/station-reference.model'
import { UpdateMode } from '../../../models/update-mode.enum'

const StationReferencePage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { setStationReference } = useStationReference()

    const handleShow = () => {
        setTitle('Create new station reference')
        setStationReference(emptyStationReference)
        setContent(<StationReferenceForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'StationReferences']} />
            <TitleBar
                title="Station References"
                subTitle="View and manage station references"
                showButton
                handleShow={handleShow}
                buttonLabel="Add Station Reference"
                icon={<FiPlus />}
            />
            <StationReferenceTable />
        </>
    )
}

export default StationReferencePage
