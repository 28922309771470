import { apiRequests } from '..'
import {
    IActivity,
    IActivityResponse,
    IFetchActivityParams,
} from '../../models/budgeting/activity.model'

const activityService = {
    list: (params: IFetchActivityParams): Promise<IActivity[]> =>
        apiRequests.get(
            `/activities/?branch=${params.Branch}&region=${params.region}&activityType=${params.activityType}`
        ),
    details: (code: string): Promise<IActivity> =>
        apiRequests.get(`/activities/${code}`),
    create: (activity: IActivity): Promise<IActivityResponse> =>
        apiRequests.post('/activities', activity),
    update: (activity: IActivity): Promise<IActivityResponse> =>
        apiRequests.put('/activities', activity),
    delete: (activity: IActivity): Promise<IActivityResponse> =>
        apiRequests.del('/activities', activity),
}

export default activityService
