import { Alert, Col } from 'antd'
import { Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormErrors } from '../../../hooks/common/form/form-error.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import { useUser } from '../../../hooks/shared/user/user.hook'
import { ICurrentUser } from '../../../models/common/current-user.model'
import { emptyUser, IUser } from '../../../models/shared/user.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import DiplayFormFields from './display-form-fields.component'

interface IProps {
    currentUser: ICurrentUser
}
const DisplayForm: React.FC<IProps> = ({ currentUser }) => {
    const { user, saveUser } = useUser()
    const { toastSuccess, toastError } = useToast()
    const { formError } = useFormErrors()
    const [hasSubmitted, setSubmitted] = useState(false)

    const onClose = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSubmitted(false)
        },
        []
    )

    useEffect(() => {}, [hasSubmitted])

    return (
        <>
            {hasSubmitted && (
                <Alert
                    message="Error Messages"
                    description={
                        <>
                            {formError.validationErrors &&
                            formError.validationErrors.length > 0 ? (
                                formError.validationErrors.map((v, index) => (
                                    <span key={index} style={{ display: 'block' }}>
                                        {v}
                                    </span>
                                ))
                            ) : (
                                <span>
                                    {JSON.stringify(
                                        formError.validationErrors,
                                        null,
                                        2
                                    )}
                                </span>
                            )}
                        </>
                    }
                    type="error"
                    closable
                    onClose={onClose}
                    style={{ marginBottom: 10 }}
                />
            )}

            <Formik<IUser>
                initialValues={user || emptyUser}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitted(false)
                    const feedback = await saveUser(values, UpdateMode.EDIT)
                    if (feedback) {
                        toastSuccess('M0002')
                    } else {
                        toastError('M0003')
                        setSubmitted(true)
                    }
                    setSubmitting(false)
                }}
            >
                <Col span={12}>
                    <DiplayFormFields />
                </Col>
            </Formik>
        </>
    )
}

export default DisplayForm
