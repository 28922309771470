import { apiRequests } from '../'
import { IOutlayAnalysisOption } from '../../models/budgeting/outlay-analysis-options.model'
import { IOutlayAnalysis } from '../../models/budgeting/outlay-analysis.model'

const outlayAnalysisService = {
    list: (options: IOutlayAnalysisOption): Promise<IOutlayAnalysis[]> => 
            apiRequests.get(`/outlayAnalysis?Region=${options.region}&Component=${options.component}&DashboardType=${options.dashboardType}&Tenant=${options.tenant}`),
}

export default outlayAnalysisService

