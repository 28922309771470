import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { emptyTargetBase, ITargetBase } from './target-base.model'

export interface ITarget extends ITargetBase {}

export const emptyTarget: ITarget = emptyTargetBase

export interface ITargetState extends IBaseState {
    readonly targets: ITarget[]
    readonly target: ITarget
}

export interface ITargetResponse extends IResponseBase {
    data: ITarget
}
