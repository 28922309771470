import { apiRequests } from '../..'
import { ITicket, ITicketResponse } from '../../../models/common/ticket.model'

const ticketService = {
    list: (): Promise<ITicket[]> => apiRequests.get('/tickets'),
    details: (code: string): Promise<ITicket> =>
        apiRequests.get(`/tickets/${code}`),
    create: (ticket: ITicket): Promise<ITicketResponse> =>
        apiRequests.post('/tickets', ticket),
    update: (ticket: ITicket): Promise<ITicketResponse> =>
        apiRequests.put('/tickets', ticket),
}

export default ticketService
