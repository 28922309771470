import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as runnerComponentModel from '../../models/budgeting/runner-component.model'
import runnerComponentservice from '../../services/budgeting/runner-component.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: runnerComponentModel.IRunnerComponentState = {
    runnerComponents: [],
    errors: undefined,
    runnerComponent: runnerComponentModel.emptyRunnerComponent,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchRunnerComponentsAsync = createAsyncThunk<
    runnerComponentModel.IRunnerComponent[],
    void
>('runnerComponent/fetchRunnerComponentsAsync', async (_, thunkApi) => {
    try {
        return await runnerComponentservice.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const runnerComponentslice = createSlice({
    name: 'runnerComponent',
    initialState,
    reducers: {
        fetchRunnerComponentsRequest: (state) => {
            state.isLoading = true
        },
        fetchRunnerComponentsSuccess: (
            state,
            action: PayloadAction<runnerComponentModel.IRunnerComponent[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerComponents = action.payload
        },
        fetchRunnerComponentsError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editRunnerComponentSuccess: (
            state,
            action: PayloadAction<runnerComponentModel.IRunnerComponent>
        ) => {
            state.runnerComponents = state.runnerComponents.map(
                (runnerComponent) => {
                    return runnerComponent.runner === action.payload.runner
                        ? action.payload
                        : runnerComponent
                }
            )
            state.updateMode = UpdateMode.NONE
        },
        addRunnerComponentSuccess: (
            state,
            action: PayloadAction<runnerComponentModel.IRunnerComponent>
        ) => {
            state.runnerComponents = [...state.runnerComponents, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveRunnerComponent: (
            state,
            action: PayloadAction<runnerComponentModel.IRunnerComponent>
        ) => {
            state.runnerComponent = action.payload
        },
        setRunnerComponentUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRunnerComponentsAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchRunnerComponentsAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerComponents = action.payload
        })
        builder.addCase(fetchRunnerComponentsAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchRunnerComponentsRequest,
    fetchRunnerComponentsSuccess,
    fetchRunnerComponentsError,
    editRunnerComponentSuccess,
    addRunnerComponentSuccess,
    setActiveRunnerComponent,
    setRunnerComponentUpdateMode,
} = runnerComponentslice.actions

const reducer = runnerComponentslice.reducer

export { reducer as runnerComponentReducer }
