import { apiRequests } from '../'
import {
    IComponent,
    IComponentResponse,
} from '../../models/budgeting/component.model'

const componentService = {
    list: (): Promise<IComponent[]> => apiRequests.get('/components'),
    details: (code: string): Promise<IComponent> =>
        apiRequests.get(`/components/${code}`),
    create: (component: IComponent): Promise<IComponentResponse> =>
        apiRequests.post('/components', component),
    update: (component: IComponent): Promise<IComponentResponse> =>
        apiRequests.put('/components', component),
}

export default componentService
