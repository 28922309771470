import React from 'react'
import PageBreadCrumbs from '../../components/shared/page-breadcrumb/page-breadcrumb.component'
import './home.page.scss'
import { useTranslation } from 'react-i18next'
import OutlayDashboardFilterForm from '../../components/dashboard/outlay-target-filter-form.component'
import ReportWrapper from '../../components/reports/report-wrapper.component'

const OutlayVsTargetDashboardPage: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div>
            <PageBreadCrumbs items={[t('common:breadCrumb.dashboard')]} />
            <OutlayDashboardFilterForm />
            <ReportWrapper />
        </div>
    )
}

export default OutlayVsTargetDashboardPage
