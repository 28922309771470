import { ColumnsType } from 'antd/lib/table'
import { IOutlayAnalysis } from '../../../models/budgeting/outlay-analysis.model'
import { format } from '../../../utils/format'

const width = '10%'

export const outlayTargetTableColumns: ColumnsType<IOutlayAnalysis> = [
    {
        title: 'Budge Code',
        dataIndex: 'budgetCode',
        key: 'code',
        width,
        filtered: true,
    },
    {
        title: 'Indicator',
        dataIndex: 'indicator',
        key: 'indicator',
        width,
        filtered: true,
    },
    {
        title: 'Annual Target',
        dataIndex: 'periodBudget',
        key: 'periodBudget',
        align: 'right',
        filtered: true,
        render: (_, record) => format.number(record.annualTarget) 
    },
    {
        title: 'Comp Target',
        dataIndex: 'componentTarget',
        key: 'componentTarget',
        width,
        align: 'right',
        filtered: true,
        render: (_, record) => format.number(record.componentTarget),
    },
    {
        title: 'Weekly Target',
        dataIndex: 'periodTarget',
        key: 'periodTarget',
        width,
        align: 'right',
        filtered: true,
        render: (_, record) => format.number(record.periodTarget as number)
    },
    {
        title: 'Achievement',
        dataIndex: 'periodAchievement',
        key: 'periodAchievement',
        width,
        align: 'right',
        filtered: true,
        render: (_, record) => format.number(record.percentageAchievement as number),
    },
    {
        title: '% Achieved',
        dataIndex: 'percentageAchievement',
        key: 'percentageAchievement',
        width,
        align: 'right',
        filtered: true,
        render: (_, record) => format.number(record.percentageAchievement as number),
    },
    {
        title: 'Comp. Budget',
        dataIndex: 'periodBudget',
        key: 'periodBudget',
        width,
        align: 'right',
        filtered: true,
        render: (_, record) => format.number(record.periodBudget as number),
    },
    {
        title: 'Expenditure',
        dataIndex: 'periodExpenditure',
        key: 'periodExpenditure',
        width,
        align: 'right',
        filtered: true,
        render: (_, record) => format.number(record.percentageAchievement as number),
    },
    {
        title: '% Expenditure',
        dataIndex: 'percentageExpenditure',
        key: 'percentageExpenditure',
        width,
        align: 'right',
        filtered: true,
        render: (_, record) => format.number(record.percentageAchievement as number),
    },
]
