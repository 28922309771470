import { apiRequests } from '../'
import {
    IIntervention,
    IInterventionResponse,
} from '../../models/budgeting/intervention.model'

const interventionService = {
    list: (): Promise<IIntervention[]> => apiRequests.get('/interventions'),
    details: (code: string): Promise<IIntervention> =>
        apiRequests.get(`/interventions/${code}`),
    create: (intervention: IIntervention): Promise<IInterventionResponse> =>
        apiRequests.post('/interventions', intervention),
    update: (intervention: IIntervention): Promise<IInterventionResponse> =>
        apiRequests.put('/interventions', intervention),
}

export default interventionService
