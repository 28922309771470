import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as milestoneDashActions from '../../redux/budgeting/milestone-dash.slice'
import { UpdateMode } from '../../models/update-mode.enum'
import { IMilestoneDash } from '../../models/budgeting/milestone-dash.model'
import { IMilestoneDashParameters } from '../../models/budgeting/milestone-dash-parameters.model'
import { IChart } from '../../models/budgeting/chart.model'
import { useCurrentBranch } from '../common/current-branch/current-branch.hook'
import { useRunner } from './runner.hook'

const useMilestoneDash = () => {
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.milestoneDash.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.milestoneDash.initialFetch
    )
    const milestoneDash = useSelector<rootState, IMilestoneDash>(
        (state) => state.milestoneDash.milestoneDash
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.milestoneDash.updateMode
    )
    const { currentBranch } = useCurrentBranch()
    const { runner } = useRunner()

    const dispatch = useDispatch()

    const loadMilestoneDash = useCallback(
        (params: IMilestoneDashParameters) => {
            if (initialFetch) {
                dispatch(milestoneDashActions.fetchMilestoneDashAsync(params))
            }
        },
        [dispatch, initialFetch]
    )

    const reloadMilestoneDash = useCallback(
        (params: IMilestoneDashParameters) => {
            dispatch(milestoneDashActions.fetchMilestoneDashAsync(params))
        },
        [dispatch]
    )

    const getChart = useCallback(
        (seriesFilter: string[]): IChart => {
            const chart: IChart = {
                categories: milestoneDash.categories,
                series: milestoneDash.series.filter((x) =>
                    seriesFilter
                        .map((x) => x.toLowerCase())
                        .includes(x.name.toLowerCase())
                ),
            }
            return chart
        },
        [milestoneDash.categories, milestoneDash.series]
    )

    const getBudgetExpenditure = useCallback((): IChart => {
        const seriesFilter: string[] = ['Budget', 'Actual']
        return getChart(seriesFilter)
    }, [getChart])

    const getProjectionAchievement = useCallback((): IChart => {
        const seriesFilter: string[] = ['Projection', 'Achievement']
        return getChart(seriesFilter)
    }, [getChart])

    useEffect(() => {
        const params: IMilestoneDashParameters = {
            component: runner.component,
            copYear: runner.copYear,
            project: currentBranch.project,
            region: currentBranch.region,
        }
        loadMilestoneDash(params)
    }, [
        milestoneDash,
        isLoading,
        initialFetch,
        loadMilestoneDash,
        reloadMilestoneDash,
        currentBranch.project,
        currentBranch.region,
        runner.component,
        runner.copYear,
    ])

    return {
        isLoading,
        initialFetch,
        updateMode,
        budgetChartData: getBudgetExpenditure(),
        projectionChartData: getProjectionAchievement(),
        loadMilestoneDash,
        reloadMilestoneDash,
    }
}

export { useMilestoneDash }
