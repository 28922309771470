export const enRunnerPeriod = {
    breadcrumb: {
        runnerPeriod: 'Runner Periods',
        list: 'List',
    },

    mainPage: {
        title: 'Runner Period',
        subTitle:
            'View and manage Runner Periods to control runners that regions have access to.',
        buttonLabel: 'Add Runner Period',
    },

    detailPage: {
        title: 'Runner Period',
        subTitle: 'View and manage runner Period',
        buttonLabel: 'Archive',
        modalTitle: 'Edit runner period',
    },
    form: {
        code: 'code',
        description: 'Description',
        startDate: 'Start Date',
        endDate: 'End Date',
        component: "Component",
        submit: 'Submit',
        reset: 'Reset',
    },
}
