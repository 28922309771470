export const enUser = {
    formikValidation: {
        usrName: 'Username is required !',
    },
    form: {
        title: 'Users',
        titleAdd: 'Add User',
        titleEdit: 'Edit User',
        usrName: 'User Name',
        usrSite: 'User Site',
        mgtUsrSite: 'Manage user sites',
        mgtSiteBtn: "Manage Site",
        editBtn: 'Edit',
        detail: 'Details',
        saveBtn: "Save",
        cancelBtn: "Cancel",
        code: "Code",
        role: "Role",
    },
    userForm: {
        title:"Create New User",
        code: "Code",
        name: "Name",
        email: "Email",
    }
}
