import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as currentUserModel from '../../../models/common/current-user.model'
import { ICurrentUserState } from '../../../models/common/current-user.model'
import { UpdateMode } from '../../../models/update-mode.enum'

export const initialState: ICurrentUserState = {
    currentUsers: [],
    errors: '',
    currentUser: currentUserModel.emptyCurrentUser,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const currentUserSlice = createSlice({
    name: 'currentuser',
    initialState,
    reducers: {
        fetchCurrentUserRequest: (state) => {
            state.isLoading = true
        },
        fetchCurrentUserSuccess: (
            state,
            action: PayloadAction<currentUserModel.ICurrentUser>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.currentUser = action.payload
        },
        fetchCurrentUserError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editCurrentUserSuccess: (
            state,
            action: PayloadAction<currentUserModel.ICurrentUser>
        ) => {
            state.currentUsers = state.currentUsers.map((currentuser) => {
                return currentuser.code === action.payload.code
                    ? action.payload
                    : currentuser
            })
            state.updateMode = UpdateMode.NONE
        },
        addCurrentUserSuccess: (
            state,
            action: PayloadAction<currentUserModel.ICurrentUser>
        ) => {
            state.currentUsers = [...state.currentUsers, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveCurrentUser: (
            state,
            action: PayloadAction<currentUserModel.ICurrentUser>
        ) => {
            state.currentUser = action.payload
        },
        setCurrentUserUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
})

export const {
    fetchCurrentUserRequest,
    fetchCurrentUserSuccess,
    fetchCurrentUserError,
    editCurrentUserSuccess,
    addCurrentUserSuccess,
    setActiveCurrentUser,
    setCurrentUserUpdateMode,
} = currentUserSlice.actions

const reducer = currentUserSlice.reducer

export { reducer as currentUserReducer }
