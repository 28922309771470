import { Button, message, Modal, Spin, Typography } from 'antd'
import React, { useContext, useState } from 'react'
import { FiEdit, FiTrash } from 'react-icons/fi'
import ActivitySummaryDetails from '../../../components/budgeting/activity-summary/activity-summary-detail.component'
import ActivityForm from '../../../components/budgeting/activity/activity-form.component'
import BackButton from '../../../components/common/back-button/back-button.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useActivity } from '../../../hooks/budgeting/activity.hook'
import { useActiveTab } from '../../../hooks/shared/active-tab/active-tab.hook'
import { UpdateMode } from '../../../models/update-mode.enum'
import { useTranslation } from 'react-i18next'
import './pop-confirm.style.scss'
import { useHistory } from 'react-router-dom'

const ActivityBatchDetailsPage: React.FunctionComponent = () => {
    const [open, setOpen] = useState(false)

    const { isLoading, initialFetch, activity, setActivity, deleteActivity } =
        useActivity()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { activeTab } = useActiveTab()
    const { t } = useTranslation()
    const router = useHistory()

    const handleShow = () => {
        setActivity({ ...activity })
        setWidth('40rem')
        setShow(true)
        setContent(
            <ActivityForm
                updateMode={UpdateMode.EDIT}
                isCoordinationArea={
                    activeTab.isActive === 'coordination-area-activities'
                }
            />
        )
        setTitle('Edit Activity')
    }

    const handleDeleteActivity = async () => {
        const feedback = await deleteActivity(activity)
        if (feedback) {
            message.success('You have successfully deleted activity')
        }
        setOpen(false)
        router.goBack(-2)
        // router.goBack() 
    }
    return (
        <>
            <PageBreadCrumbs
                items={[
                    t(
                        'budgeting:activity.activitySummaryDetailPage.breadcrumb.mainActivities'
                    ),
                    t(
                        'budgeting:activity.activitySummaryDetailPage.breadcrumb.siteActivities'
                    ),
                    t(
                        'budgeting:activity.activitySummaryDetailPage.breadcrumb.batchDetails'
                    ),
                ]}
            />

            <TitleBar
                title={t('budgeting:activity.activitySummaryDetailPage.title')}
                subTitle={t('budgeting:activity.activitySummaryDetailPage.subTitle')}
                showButton={
                    activeTab.isActive !== 'regional-activities' ? true : false
                }
                buttonLabel={t(
                    'budgeting:activity.activitySummaryDetailPage.buttonLabel'
                )}
                handleShow={handleShow}
                icon={<FiEdit />}
                showExtra={
                    activeTab.isActive !== 'regional-activities' ? true : false
                }
                extra={
                    <Button
                        type="primary"
                        htmlType={'button'}
                        onClick={() => setOpen(true)}
                        danger
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FiTrash />{' '}
                            <span className="button__text" style={{ marginLeft: 5 }}>
                                Delete
                            </span>
                        </div>
                    </Button>
                }
            />
            <BackButton page="Activity Batch" />
            {isLoading && initialFetch ? (
                <Spin size="large" />
            ) : (
                <ActivitySummaryDetails activity={activity} />
            )}

            <ConfirmAction
                handleDeleteActivity={handleDeleteActivity}
                open={open}
                setOpen={setOpen}
            />
        </>
    )
}

interface IProps {
    handleDeleteActivity: () => void
    open: boolean
    setOpen: (val: boolean) => void
}
const ConfirmAction: React.FC<IProps> = ({
    handleDeleteActivity,
    open,
    setOpen,
}) => {
    const [confirmLoading, setConfirmLoading] = useState(false)

    const handleOk = () => {
        setConfirmLoading(true)
        handleDeleteActivity()
        setConfirmLoading(false)
    }

    const handleCancel = () => {
        setOpen(false)
    }

    return (
        <Modal
            title={
                <Typography.Paragraph type="danger" style={{ margin: 0 }}>
                    Warning: Deleting activity
                </Typography.Paragraph>
            }
            visible={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            okText="Yes"
            cancelText="No"
        >
            <Typography.Paragraph style={{ margin: 0 }}>
                Do you really want to delete this activity line? <br />
                This action cannot be reversed.
            </Typography.Paragraph>
        </Modal>
    )
}
export default ActivityBatchDetailsPage
