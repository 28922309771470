export const frMilestoneProjection = {
    breadcrumb: {
        milestoneProjection: "Projections d'étape",
        list: 'Liste',
    },

    mainPage: {
        title: "Projections d'étapes",
        subTitle: "Afficher et gérer les projections d'étape",
        buttonLabel: 'Ajouter un nouveau jalon',
        addNewMilestone: 'Ajouter un nouveau jalon',
    },

    // detailPage: {
    //     title: 'Runner detail',
    //     subTitle: 'Make changes to runner',
    //     buttonLabel: 'Edit runner',
    // },

    form: {
        modalTitleAdd: 'Ajouter une nouvelle projection de jalon',
        modalTitleEdit: 'Modifier la projection du jalon',
        tenant: 'Locataire',
        runner: 'Coureur',
        region: 'Région',
        project: 'Projet',
        activity: 'Activité',
        site: 'Placer',
        budgetNote: 'Note budgétaire',
        target: 'Cible',
        achievement: 'Réalisation',
        budget: 'Budget',
        createdOn: 'Créé sur',
    },
}
