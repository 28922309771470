import React from 'react'
import PageBreadCrumbs from '../../components/shared/page-breadcrumb/page-breadcrumb.component'
import './home.page.scss'
import { useTranslation } from 'react-i18next'
import InterventionDashboardFilterForm from '../../components/dashboard/intervention-filter-form.component'
import ReportWrapper from '../../components/reports/report-wrapper.component'

const InterventionDashboardPage: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div>
            <PageBreadCrumbs items={[t('common:breadCrumb.dashboard')]} />
            <InterventionDashboardFilterForm />
            <ReportWrapper />
        </div>
    )
}

export default InterventionDashboardPage
