import { apiRequests } from '..'
import { ISessionBatch } from '../../models/batch/session-batch.model'

const sessionBatchService = {
    getBatch: (): Promise<ISessionBatch> => apiRequests.get('/sessionBatch'),
    getBatchBranch: (branch: string): Promise<ISessionBatch> =>
        apiRequests.get(`/sessionBatch/${branch}`),
}

export { sessionBatchService }
