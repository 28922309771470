export const enMilestoneProjection = {
    breadcrumb: {
        milestoneProjection: 'Milestone Projections',
        list: 'List',
    },

    mainPage: {
        title: 'Milestone Projections',
        subTitle: 'View and manage Milestone Projections',
        buttonLabel: 'Add Milestone Projection',
        addNewMilestone: 'Add New Milestone',
    },

    // detailPage: {
    //     title: 'Milestone Projection detail',
    //     subTitle: 'Make changes to Milestone Projections',
    //     buttonLabel: 'Edit runner',
    // },

    form: {
        modalTitleAdd: 'Add New Milestone Projection',
        modalTitleEdit: 'Edit Milestone Projection',
        tenant: 'Tenant',
        runner: 'Runner',
        region: 'Region',
        project: 'Project',
        activity: 'Activity',
        site: 'Site',
        budgetNote: 'Budget Note',
        target: 'Target',
        achievement: 'Archivement',
        budget: 'Budget',
        createdOn: 'Created on',
    },
}
