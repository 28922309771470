import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useSessionBatch } from '../../../hooks/shared/session-batch/session-batch.hook'
import AppShellSkeleton from '../../../layout/app-skeleton/app-shell-skeleton.component'
import ProtectedRoute from '../private-route/protected-route.component'
import { routes } from './route.data'

function LoadingMessage() {
    return (
        <AppShellSkeleton>
            <div className="splash-screen">
                {/* Loading initial data, please wait... */}
                <div className="loading-dot">.</div>
            </div>
        </AppShellSkeleton>
    )
}

const AppRoutes: React.FC = () => {
    const { isLoading } = useSessionBatch()

    if (isLoading) {
        return LoadingMessage()
    }

    return (
        <Router>
            <Switch>
                {routes.map((route, index) => {
                    if (route.private) {
                        return (
                            <ProtectedRoute
                                key={index}
                                exact={route.exact}
                                path={route.path}
                                component={route.component}
                            />
                        )
                    }

                    return (
                        <Route
                            key={index}
                            exact={route.exact}
                            path={route.path}
                            component={route.component}
                        />
                    )
                })}
            </Switch>
        </Router>
    )
}

export default AppRoutes
