import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as includeRunnerModel from '../../models/budgeting/include-runner.model'
import includeRunnerservice from '../../services/budgeting/include-runner.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: includeRunnerModel.IIncludeRunnerState = {
    includeRunners: [],
    errors: '',
    includeRunner: includeRunnerModel.emptyIncludeRunner,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchIncludeRunnersAsync = createAsyncThunk<
    includeRunnerModel.IIncludeRunner[],
    void
>('includeRunner/fetchIncludeRunnersAsync', async (_, thunkApi) => {
    try {
        return await includeRunnerservice.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const includeRunnerslice = createSlice({
    name: 'includeRunner',
    initialState,
    reducers: {
        fetchIncludeRunnersRequest: (state) => {
            state.isLoading = true
        },
        fetchIncludeRunnersSuccess: (
            state,
            action: PayloadAction<includeRunnerModel.IIncludeRunner[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.includeRunners = action.payload
        },
        fetchIncludeRunnersError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editIncludeRunnersuccess: (
            state,
            action: PayloadAction<includeRunnerModel.IIncludeRunner>
        ) => {
            state.includeRunners = state.includeRunners.map((includeRunner) => {
                return includeRunner.runner === action.payload.runner
                    ? action.payload
                    : includeRunner
            })
            state.updateMode = UpdateMode.NONE
        },
        addIncludeRunnersuccess: (
            state,
            action: PayloadAction<includeRunnerModel.IIncludeRunner>
        ) => {
            state.includeRunners = [...state.includeRunners, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveIncludeRunner: (
            state,
            action: PayloadAction<includeRunnerModel.IIncludeRunner>
        ) => {
            state.includeRunner = action.payload
        },
        setIncludeRunnerUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchIncludeRunnersAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchIncludeRunnersAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.includeRunners = action.payload
        })
        builder.addCase(fetchIncludeRunnersAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchIncludeRunnersRequest,
    fetchIncludeRunnersSuccess,
    fetchIncludeRunnersError,
    editIncludeRunnersuccess,
    addIncludeRunnersuccess,
    setActiveIncludeRunner,
    setIncludeRunnerUpdateMode,
} = includeRunnerslice.actions

const reducer = includeRunnerslice.reducer

export { reducer as includeRunnerReducer }
