import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { emptyRunnerBase, IRunnerBase } from './runner-base.model'

export interface IRunnerHistory extends IRunnerBase {}

export interface IRunnerHistoryState extends IBaseState {
    readonly runnerHistories: IRunnerHistory[]
    readonly runnerHistory: IRunnerHistory
}

export const emptyRunnerHistory: IRunnerHistory = emptyRunnerBase

export interface IRunnerHistoryResponse extends IResponseBase {
    data: IRunnerHistory
}
