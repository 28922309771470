import { ColumnsType } from 'antd/lib/table'
import { ITicket } from '../../../models/common/ticket.model'
import { Tag } from 'antd'
import { useTicketStatus } from '../../../hooks/common/ticket-status/ticket-status.hook'

export const useTicketColumns = () => {
    const {ticketStatuses} = useTicketStatus();

    const ticketsTableColumns: ColumnsType<ITicket> = [
        {
            title: 'User Email',
            dataIndex: 'userEmail',
            width: '15rem',
            key: 'userEmail',
        },
        {
            title: 'Active Phone Number',
            dataIndex: 'userPhoneNumber',
            width: '15rem',
            key: 'userPhoneNumber',
        },

        {
            title: 'Problem Description',
            dataIndex: 'description',
            key: 'description',
        },

        {
            title: 'Status',
            dataIndex: 'status',
            width: '8rem',
            render: (_, row) => <Tag color={row.status === '01' ? 'yellow': 'green'}>{ticketStatuses.find(status => status.code === row.status)?.description}</Tag>
        },
    ]

    return {
        ticketsTableColumns,
    }
}
