import { ColumnsType } from 'antd/lib/table'
import { IRunner } from '../../../models/budgeting/runner.model'
import { format } from '../../../utils/format'

export const runnerTableColumns: ColumnsType<IRunner> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (_, record) => format.date(record.startDate),
    },
    {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (_, record) => format.date(record.endDate),
    },
    {
        title: 'Status',
        dataIndex: 'statusDescription',
        key: 'statusDescription',
    },
]
