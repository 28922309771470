import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UpdateMode } from '../../models/update-mode.enum'
import {
    emptyTransactionCode,
    ITransactionCode,
    ITransactionCodeState,
} from '../../models/budgeting/transaction-code.model'
import transactionCodeService from '../../services/budgeting/transaction-code.service'

export const initialState: ITransactionCodeState = {
    transactionCodes: [],
    errors: '',
    transactionCode: emptyTransactionCode,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchTransactionCodesAsync = createAsyncThunk<ITransactionCode[], void>(
    'transactionCode/fetchTransactionCodesAsync',
    async (_, thunkApi) => {
        try {
            return await transactionCodeService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const transactionCodeSlice = createSlice({
    name: 'transactionCode',
    initialState,
    reducers: {
        fetchTransactionCodesRequest: (state) => {
            state.isLoading = true
        },
        fetchTransactionCodesSuccess: (
            state,
            action: PayloadAction<ITransactionCode[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.transactionCodes = action.payload
        },
        fetchTransactionCodesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editTransactionCodeSuccess: (
            state,
            action: PayloadAction<ITransactionCode>
        ) => {
            state.transactionCodes = state.transactionCodes.map((runner) => {
                return runner.code === action.payload.code ? action.payload : runner
            })
            state.updateMode = UpdateMode.NONE
        },
        addTransactionCodeSuccess: (
            state,
            action: PayloadAction<ITransactionCode>
        ) => {
            state.transactionCodes = [...state.transactionCodes, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveTransactionCode: (
            state,
            action: PayloadAction<ITransactionCode>
        ) => {
            state.transactionCode = action.payload
        },
        setTransactionCodeUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTransactionCodesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchTransactionCodesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.transactionCodes = action.payload
        })
        builder.addCase(fetchTransactionCodesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchTransactionCodesRequest,
    fetchTransactionCodesSuccess,
    fetchTransactionCodesError,
    editTransactionCodeSuccess,
    addTransactionCodeSuccess,
    setActiveTransactionCode,
    setTransactionCodeUpdateMode,
} = transactionCodeSlice.actions

const reducer = transactionCodeSlice.reducer

export { reducer as transactionCodeReducer }
