import { ColumnsType } from 'antd/lib/table'
import { IDivision } from '../../../models/common/division.model'

export const divisionsTableColumns: ColumnsType<IDivision> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
