import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as runnerHistoryModel from '../../models/budgeting/runner-history.model'
import runnerHistoryService from '../../services/budgeting/runner-history.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: runnerHistoryModel.IRunnerHistoryState = {
    runnerHistories: [],
    errors: '',
    runnerHistory: runnerHistoryModel.emptyRunnerHistory,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchRunnerHistoriesAsync = createAsyncThunk<
    runnerHistoryModel.IRunnerHistory[],
    void
>('runnerHistory/fetchrunnerHistoriesAsync', async (_, thunkApi) => {
    try {
        return await runnerHistoryService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const runnerHistorySlice = createSlice({
    name: 'runnerHistory',
    initialState,
    reducers: {
        fetchRunnerHistoriesRequest: (state) => {
            state.isLoading = true
        },
        fetchRunnerHistoriesSuccess: (
            state,
            action: PayloadAction<runnerHistoryModel.IRunnerHistory[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerHistories = action.payload
        },
        fetchRunnerHistoriesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editRunnerHistorySuccess: (
            state,
            action: PayloadAction<runnerHistoryModel.IRunnerHistory>
        ) => {
            state.runnerHistories = state.runnerHistories.map((runnerHistory) => {
                return runnerHistory.code === action.payload.code
                    ? action.payload
                    : runnerHistory
            })
            state.updateMode = UpdateMode.NONE
        },
        addRunnerHistorySuccess: (
            state,
            action: PayloadAction<runnerHistoryModel.IRunnerHistory>
        ) => {
            state.runnerHistories = [...state.runnerHistories, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveRunnerHistory: (
            state,
            action: PayloadAction<runnerHistoryModel.IRunnerHistory>
        ) => {
            state.runnerHistory = action.payload
        },
        setRunnerHistoryUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRunnerHistoriesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchRunnerHistoriesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerHistories = action.payload
        })
        builder.addCase(fetchRunnerHistoriesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchRunnerHistoriesRequest,
    fetchRunnerHistoriesSuccess,
    fetchRunnerHistoriesError,
    editRunnerHistorySuccess,
    addRunnerHistorySuccess,
    setActiveRunnerHistory,
    setRunnerHistoryUpdateMode,
} = runnerHistorySlice.actions

const reducer = runnerHistorySlice.reducer

export { reducer as runnerHistoryReducer }
