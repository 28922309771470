import { Table } from 'antd'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'
import { useSubDivision } from '../../../hooks/common/sub-division/sub-division.hook'
import { ISubDivision } from '../../../models/common/sub-division.model'
import { subDivisionsTableColumns } from './sub-division-column.component'

const SubDivisionTable = () => {
    const { subDivisions, setSubDivision } = useSubDivision()

    const router = useHistory()
    // const route = use
    const handleRowClick = (subDivision: ISubDivision) => {
        setSubDivision(subDivision)
        router.push(`/configurations/subdivisions/${slugify(subDivision.code)}`)
    }

    return (
        <>
            {subDivisions && subDivisions.length ? (
                <Table<ISubDivision>
                    dataSource={subDivisions}
                    columns={subDivisionsTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    onRow={(record: ISubDivision) => {
                        return {
                            onClick: (e) => {
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                <NoContent title="No data for sub division" />
            )}
        </>
    )
}

export default SubDivisionTable
