import React, { useContext, useState } from 'react'
import {
    ACTIVITY_TYPE,
    BUDGET_ANALYSIS,
} from '../../constants/configurations.constant'

const ReportContext = React.createContext<any>({})

export const ReportsProvider: React.FC = ({ children }) => {
    const [hasPdf, setHasPdf] = useState(false)
    const [hasReportData, setHasReportData] = useState(false)
    const [reportTitle, setReportTitle] = useState('Report title')
    const [reportData, setReportData] = useState(null)
    const [activityType, setActivityType] = useState(ACTIVITY_TYPE.SITE)
    const [reportFile, setReportFile] = useState('')
    const [budgetAnalysisType, setBudgetAnalysisType] = useState('')

    const values = {
        hasPdf,
        setHasPdf,
        reportTitle,
        setReportTitle,
        reportData,
        setReportData,
        hasReportData,
        setHasReportData,
        activityType,
        setActivityType,
        reportFile,
        setReportFile,
        budgetAnalysisType,
        setBudgetAnalysisType,
    }

    return <ReportContext.Provider value={values}>{children}</ReportContext.Provider>
}

export const useBudgetAnalysisContext = () => useContext(ReportContext)
