import { ClockCircleOutlined } from '@ant-design/icons'
import { appRoutes } from '../../../constants/app-route'
import { ISideBarLink } from '../sidebar.data'

export const programReportSubmenus: ISideBarLink[] = [
    {
        app: '01',
        code: 'G00',
        description: 'BA Cost Category',
        route: appRoutes.PROGRAM_REPORT.BA_COST_CATEGORY,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'G',
    },
    {
        app: '01',
        code: 'G01',
        description: 'BA Intervention',
        route: appRoutes.PROGRAM_REPORT.BA_INTERVENTION,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'G',
    },
    {
        app: '01',
        code: 'G02',
        description: 'BA Site',
        route: appRoutes.PROGRAM_REPORT.BA_SITE,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'G',
    },
    {
        app: '01',
        code: 'G03',
        description: 'Budget Execution',
        route: appRoutes.PROGRAM_REPORT.BUDGET_EXECUTION,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'G',
    },
    {
        app: '01',
        code: 'G04',
        description: 'Costed Work Plan',
        route: appRoutes.PROGRAM_REPORT.COSTED_WORK_PLAN,
        dropDown: false,
        dropDownMenus: [],
        icon: <ClockCircleOutlined />,
        parent: 'G',
    },
]
