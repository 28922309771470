import React, { useContext, useState } from 'react'
import { IActivity } from '../../models/budgeting/activity.model'

const ModificationsContext = React.createContext<any>({})

export const ModificationsProvider: React.FC = ({ children }) => {
    const [modificationsData, setModificationsData] = useState<IActivity[]>([])

    const values = {
        modificationsData,
        setModificationsData,
    }

    return (
        <ModificationsContext.Provider value={values}>
            {children}
        </ModificationsContext.Provider>
    )
}

export const useModificationsContext = () => useContext(ModificationsContext)
