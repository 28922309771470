import { Space } from 'antd'
import { Skeleton } from 'antd'
import './sidebar.scss'

interface IProps {
    active: boolean
    collapsed: boolean
}
const SidebarSkeleton: React.FC<IProps> = ({ active, collapsed }) => {
    let skeletonCards = []
    for (let i = 0; i < 10; i++) {
        skeletonCards.push(
            <div
                key={i}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {collapsed ? (
                    <Skeleton.Button
                        active={active}
                        key={i}
                        size="small"
                        shape="square"
                        style={{ width: '30px', margin: '.78rem 0 .78rem .4rem' }}
                        className="sidebar__icon"
                    />
                ) : (
                    <>
                        <Skeleton.Button
                            active={active}
                            key={i}
                            size="small"
                            shape="square"
                            style={{
                                width: '30px',
                                margin: '.78rem .45rem .78rem 0',
                            }}
                            className="sidebar__icon"
                        />
                        <Skeleton.Button
                            active={active}
                            size="small"
                            shape="square"
                            style={{ width: '150px', margin: '.78rem 0' }}
                        />
                    </>
                )}
            </div>
        )
    }
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Space style={{ display: 'flex', flexDirection: 'column' }}>
                {skeletonCards}
            </Space>
        </div>
    )
}

export default SidebarSkeleton
