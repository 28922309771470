import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IDateGeneration {
    tenant: string
    branch: string
    transDate: Date
    transMonth: string
    transYear: string
    transDay: string
    prevDay: Date
    weekStart: Date
    weekEnd: Date
    monthStart: Date
    monthEnd: Date
    lastTransDate: Date
}

export interface IDateGenerationState extends IBaseState {
    readonly dateGenerations: IDateGeneration[]
    readonly dateGeneration: IDateGeneration
}

export const emptyDateGeneration: IDateGeneration = {
    tenant: '',
    branch: '',
    transDate: new Date(),
    transMonth: '',
    transYear: '',
    transDay: '',
    prevDay: new Date(),
    weekStart: new Date(),
    weekEnd: new Date(),
    monthStart: new Date(),
    monthEnd: new Date(),
    lastTransDate: new Date(),
}
export interface IDateGenerationResponse extends IResponseBase {
    data: IDateGeneration
}
