import React from 'react'
import { Table } from 'antd'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'
import { dialogMessagesTableColumns } from './dialog-message-column.component'
import { IDialogMessage } from '../../../models/common/dialog-message.model'
import { useDialogMessage } from '../../../hooks/common/dialog-message/dialog-message.hook'

const DialogMessageTable = () => {
    const { dialogMessages, setDialogMessage } = useDialogMessage()
    const router = useHistory()

    // const route = use
    const handleRowClick = (dialogMessage: IDialogMessage) => {
        setDialogMessage(dialogMessage)
        router.push(
            `/configurations/contact-relations/${slugify(dialogMessage.code)}`
        )
    }

    return (
        <>
            {dialogMessages && dialogMessages.length ? (
                <Table<IDialogMessage>
                    dataSource={dialogMessages}
                    columns={dialogMessagesTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    onRow={(record: IDialogMessage) => {
                        return {
                            onClick: (e) => {
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                <NoContent title="No data for Dialog Messages" />
            )}
        </>
    )
}

export default DialogMessageTable
