import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as branchActions from '../../redux/common/branch/branch.slice'
import { IBranch } from '../../models/common/branch.model'
import { UpdateMode } from '../../models/update-mode.enum'
import branchService from '../../services/common/branch/branch.service'
import * as Yup from 'yup'

const useBranch = () => {
    const branches = useSelector<rootState, IBranch[]>(
        (state) => state.branch.branches
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.branch.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.branch.initialFetch
    )
    const branch = useSelector<rootState, IBranch>((state) => state.branch.branch)
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.branch.updateMode
    )

    const dispatch = useDispatch()

    const branchSchema = Yup.object().shape({
        description: Yup.string().max(200, 'Too Long!').required('Required'),
        branchName: Yup.string().max(200, 'Too Long!').required('Required'),
        branchShortName: Yup.string().max(100, 'Too Long!').required('Required'),
        staCode: Yup.string().max(10, 'Too Long!').required('Required'),
        telephone: Yup.string().max(25, 'Too Long!').required('Required'),
        address: Yup.string().max(150, 'Too Long!').required('Required'),
        postBox: Yup.string().max(75, 'Too Long!').required('Required'),
        city: Yup.string().max(50, 'Too Long!').required('Required'),
        region: Yup.string().max(15, 'Too Long!').required('Required'),
        email: Yup.string().max(50, 'Too Long!').required('Required'),
        webSite: Yup.string().max(70, 'Too Long!').required('Required'),
        slogan: Yup.string().max(250, 'Too Long!').required('Required'),
        branchType: Yup.string().max(2, 'Too Long!').required('Required'),
        bursaryStation: Yup.string().max(10, 'Too Long!').required('Required'),
        branchTown: Yup.string().max(10, 'Too Long!').required('Required'),
    })

    const loadBranches = useCallback(() => {
        if (initialFetch) {
            dispatch(branchActions.fetchBranchesAsync())
        }
    }, [dispatch, initialFetch])

    const addBranch = async (branch: IBranch) => {
        return await branchService
            .create(branch)
            .then((branchResponse) => {
                if (branchResponse.success) {
                    dispatch(branchActions.addBranchSuccess(branchResponse.data))
                } else {
                    return branchResponse
                }
                return branchResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setBranch = (branch: IBranch) => {
        dispatch(branchActions.setActiveBranch(branch))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(branchActions.setBranchUpdateMode(updateMode))
    }

    const editBranch = async (branch: IBranch) => {
        return await branchService
            .update(branch)
            .then((branchResponse) => {
                dispatch(branchActions.editBranchSuccess(branchResponse.data))
                setBranch(branchResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveBranch = async (branch: IBranch, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addBranch(branch)
            : await editBranch(branch)
    }

    useEffect(() => {
        loadBranches()
    }, [branch, branches, isLoading, initialFetch, loadBranches])

    return {
        branch,
        branches,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addBranch,
        editBranch,
        saveBranch,
        setBranch,
        branchSchema,
    }
}

export { useBranch }
