import { apiRequests } from '..'
import {
    ICopYearOutlay,
    ICopYearOutlayResponse,
} from '../../models/budgeting/cop-year-outlay.model'

const copYearOutlayService = {
    list: (): Promise<ICopYearOutlay[]> => apiRequests.get('/copYearOutlays'),
    details: (code: string): Promise<ICopYearOutlay> =>
        apiRequests.get(`/copYearOutlays/${code}`),
    create: (copYearOutlay: ICopYearOutlay): Promise<ICopYearOutlayResponse> =>
        apiRequests.post('/copYearOutlays', copYearOutlay),
    update: (copYearOutlay: ICopYearOutlay): Promise<ICopYearOutlayResponse> =>
        apiRequests.put('/copYearOutlays', copYearOutlay),
}

export default copYearOutlayService
