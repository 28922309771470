import { IRoute } from '../../../models/common/route.model'
import UsersDetailsPage from '../../../pages/manage/users/user-details.page'
import UsersPage from '../../../pages/manage/users/users.page'

export const manageRoutes: IRoute[] = [
    /**
     * Auth routes
     */
    {
        path: '/manage/users',
        private: true,
        exact: true,
        component: UsersPage,
    },
    {
        path: '/manage/users/:code',
        private: true,
        exact: true,
        component: UsersDetailsPage,
    },
]
