import React from 'react'
import { Table } from 'antd'
import { sourceTableColumns } from './source-column.component'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'
import { ISource } from '../../../models/common/source.model'
import { useSource } from '../../../hooks/common/source/source.hook'

const SourceTable: React.FC = () => {
    const { sources, setSource } = useSource()
    const router = useHistory()
    // const route = use
    const handleRowClick = (source: ISource) => {
        setSource(source)
        router.push(`/configurations/religions/${slugify(source.description)}`)
    }

    return (
        <>
            {sources && sources.length ? (
                <Table<ISource>
                    dataSource={sources}
                    columns={sourceTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    onRow={(record: ISource) => {
                        return {
                            onClick: (e) => {
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                <NoContent title="No data for source" />
            )}
        </>
    )
}

export default SourceTable
