import { apiRequests } from '../..'
import { ITicketStatus, ITicketStatusResponse } from '../../../models/common/ticket-status.model'

const ticketStatusService = {
    list: (): Promise<ITicketStatus[]> => apiRequests.get('/ticketStatuses'),
    details: (code: string): Promise<ITicketStatus> =>
        apiRequests.get(`/ticketStatuses/${code}`),
    create: (ticketStatus: ITicketStatus): Promise<ITicketStatusResponse> =>
        apiRequests.post('/ticketStatuses', ticketStatus),
    update: (ticketStatus: ITicketStatus): Promise<ITicketStatusResponse> =>
        apiRequests.put('/ticketStatuses', ticketStatus),
}

export default ticketStatusService
