import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ICurrentBranch } from '../../../models/common/current-branch.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import {
    setActiveCurrentBranch,
    setCurrentBranchUpdateMode,
} from '../../../redux/common/current-branch/current-branch.slice'
import { rootState } from '../../../redux/root-reducer'

const useCurrentBranch = () => {
    const currentBranches = useSelector<rootState, ICurrentBranch[]>(
        (state) => state.currentBranch.currentBranches
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.currentBranch.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.currentBranch.initialFetch
    )
    const currentBranch = useSelector<rootState, ICurrentBranch>(
        (state) => state.currentBranch.currentBranch
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.currentBranch.updateMode
    )

    const dispatch = useDispatch()

    const setCurrentBranch = (currentBranch: ICurrentBranch) => {
        dispatch(setActiveCurrentBranch(currentBranch))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(setCurrentBranchUpdateMode(updateMode))
    }

    useEffect(() => {}, [currentBranch, currentBranches, isLoading, initialFetch])

    return {
        currentBranch,
        currentBranches,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        setCurrentBranch,
    }
}

export { useCurrentBranch }
