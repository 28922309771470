export const frActivity = {
    breadcrumb: {
        HIVActivities: 'Activités VIH',
        siteActivities: 'Activités sur le site',
        BOProgram: {
            BOActivity:
                "Récapitulatif des lots d'activités BO de gestion de programme",
            list: 'liste',
        },
        DHSProgram: {
            DHSActivity:
                "Gestion du programme Récapitulatif des lots d'activités DHS",
            list: 'liste',
        },
        MvanProgram: {
            MvanActivity:
                "Gestion du programme Récapitulatif des lots d'activités MVAN",
            list: 'liste',
        },
        labActivity: {
            lab: 'Laboratoire',
            labActivities: 'Activités de laboratoire',
        },
    },
    mainPage: {
        title: "Résumé des lots d'activité",
        subTitle: "Afficher et gérer le résumé des lots d'activités",
        buttonLabel: "Ajouter un lot d'activité",
    },

    tabs: {
        siteActivity: 'Activité du site',
        coordinationAreaActivity: 'Activité de la zone de coordination',
        regionalActivities: 'Activités régionales',
    },

    detailPage: {
        title: "Lot d'activité",
        subTitle: "Afficher et gérer le lot d'activité",
        buttonLabel: 'Ajouter une activité',
        breadcrumb: {
            mainActivities: 'Activités principales',
            siteActivities: 'Activités sur le site',
            batch: 'lot',
        },
    },

    form: {
        Batch: 'Lot',
        userName: "Nom d'utilisateur",
        description: 'Description',
        ActivityType: "Type d'activité",
        addActivity: 'Ajouter une activité',
        activity: 'Activité',
        site: 'Placement',
        month: 'Mois',
        runner: 'Coureur',
        activityBatch: 'Ajouter une nouvelle activité batch',
        amount: 'Montant',
        submit: 'Soumettre',
        reset: 'Réinitialiser',
    },

    activitySummaryDetailPage: {
        title: "Ligne de lot d'activité",
        subTitle: 'Afficher et gérer la ligne de lot',
        buttonLabel: "Modifier l'activité",
        breadcrumb: {
            mainActivities: 'Activités principales',
            siteActivities: 'Activités sur le site',
            batchDetails: 'Détail du lot',
        },
    },
}
