import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IUserMenu {
    app: string
    usrCode: string
    language: string
    menCode: string
    menDescription: string
    status: string
}

export interface IUserMenuState extends IBaseState {
    readonly userMenus: IUserMenu[]
    readonly userMenu: IUserMenu
}

export interface IUserMenuResponse extends IResponseBase {
    data: IUserMenu
}

export const emptyUserMenu: IUserMenu = {
    app: '',
    usrCode: '',
    language: '',
    menCode: '',
    menDescription: '',
    status: '',
}
