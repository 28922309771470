import { IToken } from '../../../models/shared/token.model'

export enum TokenActionTypes {
    TOKEN_FETCH_REQUEST = 'TOKEN_FETCH_REQUEST',
    TOKEN_FETCH_SUCCESS = 'TOKEN_FETCH_SUCCESS',
    TOKEN_FETCH_ERROR = 'TOKEN_FETCH_ERROR',
}

interface FetchTokenRequestAction {
    type: typeof TokenActionTypes.TOKEN_FETCH_REQUEST
}

interface FetchTokenSuccessAction {
    type: typeof TokenActionTypes.TOKEN_FETCH_SUCCESS
    payload: IToken
}

interface FetchTokenFailureAction {
    type: typeof TokenActionTypes.TOKEN_FETCH_ERROR
    payload: string
}

export type TokenActionsCreators =
    | FetchTokenRequestAction
    | FetchTokenSuccessAction
    | FetchTokenFailureAction
