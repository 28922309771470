import { Table } from 'antd'
import React, { useRef } from 'react'
import { useUser } from '../../hooks/shared/user/user.hook'
import { IUser } from '../../models/shared/user.model'
import { usersTableColumns } from './users-table.columns'

const UsersTable: React.FC = () => {
    const { users } = useUser()
    const recordRef = useRef<any>()

    return (
        <div ref={recordRef} style={{ margin: '1rem 0' }}>
            <Table<IUser>
                dataSource={users}
                columns={usersTableColumns}
                id="patient__table"
                size={'small'}
                scroll={{ x: '500px' }}
                rowKey={'code'}
                // onRow={(record: IUser) => {
                //   return {
                //     onClick: (e) => {
                //       recordRef.current = e.target;
                //       // if (recordRef.current.classList.closest('.ant-btn'))
                //       //   return;
                //       const target = recordRef.current.closest(
                //         ".ant-table-row-expand-icon-cell"
                //       );
                //       if (Object.is(target, null)) {
                //         handleRowClick(record);
                //       }
                //     },
                //   };
                // }}
            />
        </div>
    )
}

export default UsersTable
