import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ILocation {
    tenant: string
    code: string
    description: string
    subDivisionCode: string
    createdOn: Date
}

export interface ILocationState extends IBaseState {
    readonly locations: ILocation[]
    readonly location: ILocation
}

export const emptyLocation: ILocation = {
    code: '',
    description: '',
    tenant: '',
    subDivisionCode: '',
    createdOn: new Date(),
}
export interface ILocationResponse extends IResponseBase {
    data: ILocation
}
