import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as copYearActions from '../../redux/budgeting/cop-year.slice'
import { ICopYear } from '../../models/budgeting/cop-year.model'
import { UpdateMode } from '../../models/update-mode.enum'
import copYearService from '../../services/budgeting/cop-year.service'

const useCopYear = () => {
    const copYears = useSelector<rootState, ICopYear[]>(
        (state) => state.copYear.copYears
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.copYear.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.copYear.initialFetch
    )
    const copYear = useSelector<rootState, ICopYear>(
        (state) => state.copYear.copYear
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.copYear.updateMode
    )

    const dispatch = useDispatch()

    const loadcopYears = useCallback(() => {
        if (initialFetch) {
            dispatch(copYearActions.fetchcopYearsAsync())
        }
    }, [dispatch, initialFetch])

    const addcopYear = async (copYear: ICopYear) => {
        return await copYearService
            .create(copYear)
            .then((copYearResponse) => {
                if (copYearResponse.success) {
                    dispatch(copYearActions.addCopYearsuccess(copYearResponse.data))
                } else {
                    return copYearResponse
                }
                return copYearResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setcopYear = (copYear: ICopYear) => {
        dispatch(copYearActions.setActiveCopYear(copYear))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(copYearActions.setCopYearUpdateMode(updateMode))
    }

    const editcopYear = async (copYear: ICopYear) => {
        return await copYearService
            .update(copYear)
            .then((copYearResponse) => {
                dispatch(copYearActions.editCopYearsuccess(copYearResponse.data))
                setcopYear(copYearResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const savecopYear = async (copYear: ICopYear, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addcopYear(copYear)
            : await editcopYear(copYear)
    }

    useEffect(() => {
        loadcopYears()
    }, [copYear, copYears, isLoading, initialFetch, loadcopYears])

    return {
        copYear,
        copYears,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addcopYear,
        editcopYear,
        savecopYear,
        setcopYear,
    }
}

export { useCopYear }
