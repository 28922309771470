import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as outlayDashModel from '../../models/budgeting/outlay-target.model'
import outlayDashService from '../../services/budgeting/outlay-dash.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: outlayDashModel.IOutlayDashState = {
    outlayDashs: [],
    errors: '',
    outlayDash: outlayDashModel.emptyOutlayTarget,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchOutlayDashAsync = createAsyncThunk<
    outlayDashModel.IOutlayTarget[],
    string
>('outlayDash/fetchOutlayDashAsync', async (region: string, thunkApi) => {
    try {
        return await outlayDashService.list(region)
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const outlayDashSlice = createSlice({
    name: 'outlayDash',
    initialState,
    reducers: {
        fetchOutlayDashRequest: (state) => {
            state.isLoading = true
        },
        fetchOutlayDashSuccess: (
            state,
            action: PayloadAction<outlayDashModel.IOutlayTarget[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.outlayDashs = action.payload
        },
        fetchOutlayDashError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOutlayDashAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchOutlayDashAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.outlayDashs = action.payload
        })
        builder.addCase(fetchOutlayDashAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchOutlayDashRequest,
    fetchOutlayDashSuccess,
    fetchOutlayDashError,
} = outlayDashSlice.actions

const reducer = outlayDashSlice.reducer

export { reducer as outlayDashReducer }
