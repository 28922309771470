import React, { useState } from 'react'
import { Button, Layout, Result, Space } from 'antd'
import { MenuFoldOutlined } from '@ant-design/icons'
import { signinRedirect } from '../../services/shared/auth/auth.service'
import { SidebarNavigation } from '../../components/welcome/sidebar-nav.component'
import { NavigationMenu } from '../../components/welcome/navigation-menu.component'
import './access-denied.scss'

const { Header } = Layout

const AccessDeniedPage: React.FC = () => {
    const [show, setShow] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const showDrawer = () => {
        setShow(true)
    }
    const onClose = () => {
        setShow(false)
    }

    function login() {
        console.log('redirecting login...', isLoading)
        setLoading(true)
        signinRedirect()
    }

    return (
        <Layout className="layout" style={{ overflow: 'hidden' }}>
            <SidebarNavigation onClose={onClose} visible={show} login={login} />
            <Header
                style={{
                    position: 'fixed',
                    zIndex: 1,
                    width: '100%',
                    background: '#12638F',
                }}
                className={'page__header'}
            >
                <div className="logo">
                    <p
                        style={{
                            fontSize: '20px',
                            marginTop: '1rem',
                            fontWeight: 'bold',
                            textShadow: '4px 0px rgb(18, 99, 143)',
                        }}
                    >
                        DAMA
                    </p>
                </div>

                <MenuFoldOutlined
                    onClick={showDrawer}
                    style={{ color: '#572321', fontSize: '18px' }}
                    className="trigger toggle"
                />
                <div className="menu">
                    <NavigationMenu />
                </div>
            </Header>

            <div
                style={{
                    height: '92vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Result
                    className="access-denied"
                    status="403"
                    title="Access Denied"
                    subTitle="sorry, you are not authorized to access this page."
                    extra={
                        <Space size={'small'}>
                            <Button type="ghost" onClick={login} className="home">
                                Home
                            </Button>
                            <Button
                                type="primary"
                                onClick={login}
                                className="get_authorization"
                            >
                                Signin
                            </Button>
                        </Space>
                    }
                />
            </div>

            <footer
                className="text-white p-3 d-flex align-items-center justify-content-center footer__one"
                style={{ marginBottom: 0 }}
            >
                <div>
                    {' '}
                    Copyright &copy; {new Date().getFullYear()} CBC Health Services
                </div>
            </footer>
        </Layout>
    )
}

export default AccessDeniedPage
