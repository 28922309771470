import { Alert, Card, Form, Space, Transfer } from 'antd'
import { Formik } from 'formik'
import { FormItem, Select, SubmitButton } from 'formik-antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BackButton from '../../../components/common/back-button/back-button.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { useBranch } from '../../../hooks/common/branch.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import { useUserBranch } from '../../../hooks/common/user-branch/user-branch.hook'
import { useUser } from '../../../hooks/shared/user/user.hook'
import { IUserBranchDto } from '../../../models/common/user-branch.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useFormErrors } from '../../../hooks/common/form/form-error.hook'
import { useHistory } from 'react-router-dom'

const { Option } = Select

const ManageUserSites: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { users, user } = useUser()
    const { branches } = useBranch()
    const { currentBranch } = useCurrentBranch()
    const { userBranch, saveUserBranch, userBranches } = useUserBranch()
    const { formError } = useFormErrors()
    const [hasSubmitted, setSubmitted] = useState(false)
    const history = useHistory()

    // Change update mode based on available user branches.
    // const [oneWay, setOneWay] = useState(false);
    const [targetKeys, setTargetKeys] = useState<string[]>([])
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])

    const onChange = (arr: string[]) => {
        setTargetKeys([...arr])
    }

    const onSelectChange = (
        sourceSelectedKeys: string[],
        targetSelectedKeys: string[]
    ) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
    }

    const onSearch = useCallback((input: string, option) => {
        return option?.props.children.props.children
            .toString()
            .toLowerCase()
            .includes(input)
    }, [])

    const { toastSuccess, toastError } = useToast()

    const handleBranches = useCallback(async () => {
        const userBranchesArr = userBranches
            .filter((item) => item.usrCode === user.code)
            .map((item) => item.branchCode)
        setTargetKeys(userBranchesArr)
        console.log('userBranchesArr: ', userBranchesArr)
    }, [user.code, userBranches])

    const onClose = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSubmitted(false)
        },
        []
    )

    useEffect(() => {
        handleBranches()
    }, [handleBranches, hasSubmitted, userBranches])

    return (
        <>
            <PageBreadCrumbs
                items={[
                    t('common:user.form.title'),
                    t('common:user.form.mgtUsrSite'),
                    user.usrName,
                ]}
            />
            <BackButton page="Manage user sites" />
            <TitleBar title="Manage user sites" />
            {hasSubmitted && (
                <Alert
                    message="Error Messages"
                    description={
                        <>
                            {formError.validationErrors &&
                            formError.validationErrors.length > 0 ? (
                                formError.validationErrors.map((v, index) => (
                                    <span key={index} style={{ display: 'block' }}>
                                        {v}
                                    </span>
                                ))
                            ) : (
                                <span>
                                    {JSON.stringify(
                                        formError.validationErrors,
                                        null,
                                        2
                                    )}
                                </span>
                            )}
                        </>
                    }
                    type="error"
                    closable
                    onClose={onClose}
                    style={{ marginBottom: 10 }}
                />
            )}
            <Formik
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitted(false)
                    const obj: IUserBranchDto = {
                        user: user.code,
                        branches: targetKeys,
                        tenant: currentBranch.code,
                    }
                    console.log('values: ', obj)
                    const feedback = await saveUserBranch(obj, UpdateMode.ADD)
                    if (feedback) {
                        toastSuccess('M0002')
                    } else {
                        toastError('M0003')
                        setSubmitted(true)
                    }
                    setSubmitting(false)
                    handleBranches()
                }}
                initialValues={userBranch}
            >
                {({ isSubmitting, handleSubmit }) => (
                    <Form autoComplete="off" onFinish={handleSubmit}>
                        <Card size="small">
                            <div className="mb-lg">
                                <label
                                    className="form-control mb-lg"
                                    style={{ display: 'none' }}
                                >
                                    {/* <p className="mb-0">{t("outReach:form.name")}</p> */}
                                    <FormItem name="user" required={true}>
                                        <Select
                                            name="user"
                                            disabled={isSubmitting}
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                onSearch(input, option)
                                            }
                                            // onChange={handleChange}
                                            defaultValue={user.code}
                                        >
                                            {users.map((user, index) => (
                                                <Option
                                                    value={user.code}
                                                    key={index}
                                                >
                                                    <p className="mb-0">
                                                        {user.usrName}
                                                    </p>
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                </label>
                            </div>

                            <div className="mb-lg">
                                <Transfer
                                    rowKey={(record) => record.code}
                                    oneWay={true}
                                    dataSource={branches}
                                    showSearch
                                    listStyle={{
                                        height: 300,
                                    }}
                                    operations={['add', 'remove']}
                                    operationStyle={{
                                        color: '#fff',
                                        backgroundColor: '#fff',
                                    }}
                                    titles={[
                                        'All branches',
                                        'Selected user branches',
                                    ]}
                                    targetKeys={targetKeys}
                                    selectedKeys={selectedKeys}
                                    onChange={onChange}
                                    onSelectChange={onSelectChange}
                                    render={(item) =>
                                        `${item.code}: ${item.branchName}`
                                    }
                                    // filterOption={onSearch}
                                />
                            </div>
                            <Space style={{ marginTop: 10 }}>
                                <SubmitButton loading={isSubmitting}>
                                    Save
                                </SubmitButton>
                            </Space>
                        </Card>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ManageUserSites
