import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as runnerArchiveModel from '../../models/budgeting/runner-archive.model'
import { UpdateMode } from '../../models/update-mode.enum'
import runnerPeriodService from '../../services/budgeting/runner-period.service'
import { IRunnerArchiveOptions } from '../../models/budgeting/runner-archive.model'

export const initialState: runnerArchiveModel.IRunnerArchiveState = {
    codes: [],
    errors: '',
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchRunnerArchivesAsync = createAsyncThunk<string[], IRunnerArchiveOptions>(
    'runnerArchive/fetchRunnerArchivesAsync',
    async (options, thunkApi) => {
        try {
            return await runnerPeriodService.historyCodes(options)
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const runnerArchiveSlice = createSlice({
    name: 'runnerArchive',
    initialState,
    reducers: {
        fetchRunnerArchivesRequest: (state) => {
            state.isLoading = true
        },
        fetchRunnerArchivesSuccess: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.codes = action.payload
        },
        fetchRunnerArchivesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRunnerArchivesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchRunnerArchivesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.codes = action.payload
        })
        builder.addCase(fetchRunnerArchivesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchRunnerArchivesRequest,
    fetchRunnerArchivesSuccess,
    fetchRunnerArchivesError,
} = runnerArchiveSlice.actions

const reducer = runnerArchiveSlice.reducer

export { reducer as runnerArchiveReducer }
