import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as monthNameActions from '../../../redux/common/month-name/month-name.slice'
import { IMonthName } from '../../../models/common/month-name.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import monthNameService from '../../../services/common/month-name/month-name.service'

const useMonthName = () => {
    const monthNames = useSelector<rootState, IMonthName[]>(
        (state) => state.monthName.monthNames
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.monthName.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.monthName.initialFetch
    )
    const monthName = useSelector<rootState, IMonthName>(
        (state) => state.monthName.monthName
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.monthName.updateMode
    )

    const dispatch = useDispatch()

    const loadMonthNames = useCallback(() => {
        if (initialFetch) {
            dispatch(monthNameActions.fetchMonthNamesAsync())
        }
    }, [dispatch, initialFetch])

    const addMonthName = async (monthName: IMonthName) => {
        return await monthNameService
            .create(monthName)
            .then((monthNameResponse) => {
                dispatch(monthNameActions.addMonthNameSuccess(monthNameResponse.data))
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const setMonthName = (monthName: IMonthName) => {
        dispatch(monthNameActions.setActiveMonthName(monthName))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(monthNameActions.setMonthNameUpdateMode(updateMode))
    }

    const editMonthName = async (monthName: IMonthName) => {
        return await monthNameService
            .update(monthName)
            .then((monthNameResponse) => {
                dispatch(monthNameActions.editMonthNameSuccess(monthNameResponse.data))
                setMonthName(monthNameResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveMonthName = async (monthName: IMonthName, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addMonthName(monthName)
            : await editMonthName(monthName)
    }

    useEffect(() => {
        loadMonthNames()
    }, [monthName, monthNames, isLoading, initialFetch, loadMonthNames])

    return {
        monthName,
        monthNames,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addMonthName,
        editMonthName,
        saveMonthName,
        setMonthName,
    }
}

export { useMonthName }
