import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as strategyModel from '../../models/budgeting/strategy.model'
import strategyService from '../../services/budgeting/strategy.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: strategyModel.IStrategyState = {
    strategies: [],
    errors: '',
    strategy: strategyModel.emptyStrategy,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchStrategiesAsync = createAsyncThunk<
    strategyModel.IStrategy[],
    void
>('strategy/fetchStrategiesAsync', async (_, thunkApi) => {
    try {
        return await strategyService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const strategySlice = createSlice({
    name: 'strategy',
    initialState,
    reducers: {
        fetchstrategyRequest: (state) => {
            state.isLoading = true
        },
        fetchstrategySuccess: (
            state,
            action: PayloadAction<strategyModel.IStrategy[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.strategies = action.payload
        },
        fetchstrategyError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editStrategySuccess: (
            state,
            action: PayloadAction<strategyModel.IStrategy>
        ) => {
            state.strategies = state.strategies.map((strategy) => {
                return strategy.code === action.payload.code
                    ? action.payload
                    : strategy
            })
            state.updateMode = UpdateMode.NONE
        },
        addStrategySuccess: (
            state,
            action: PayloadAction<strategyModel.IStrategy>
        ) => {
            state.strategies = [...state.strategies, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveStrategy: (
            state,
            action: PayloadAction<strategyModel.IStrategy>
        ) => {
            state.strategy = action.payload
        },
        setStrategyUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStrategiesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchStrategiesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.strategies = action.payload
        })
        builder.addCase(fetchStrategiesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchstrategyRequest,
    fetchstrategySuccess,
    fetchstrategyError,
    editStrategySuccess,
    addStrategySuccess,
    setActiveStrategy,
    setStrategyUpdateMode,
} = strategySlice.actions

const reducer = strategySlice.reducer

export { reducer as strategyReducer }
