import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as districtModel from '../../models/budgeting/district.model'
import districtService from '../../services/budgeting/district.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: districtModel.IDistrictState = {
    districts: [],
    errors: '',
    district: districtModel.emptyDistrict,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchdistrictAsync = createAsyncThunk<districtModel.IDistrict[], void>(
    'district/fetchdistrictAsync',
    async (_, thunkApi) => {
        try {
            return await districtService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const districtSlice = createSlice({
    name: 'district',
    initialState,
    reducers: {
        fetchdistrictRequest: (state) => {
            state.isLoading = true
        },
        fetchdistrictSuccess: (
            state,
            action: PayloadAction<districtModel.IDistrict[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.districts = action.payload
        },
        fetchdistrictError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editDistrictSuccess: (
            state,
            action: PayloadAction<districtModel.IDistrict>
        ) => {
            state.districts = state.districts.map((district) => {
                return district.code === action.payload.code
                    ? action.payload
                    : district
            })
            state.updateMode = UpdateMode.NONE
        },
        addDistrictSuccess: (
            state,
            action: PayloadAction<districtModel.IDistrict>
        ) => {
            state.districts = [...state.districts, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveDistrict: (
            state,
            action: PayloadAction<districtModel.IDistrict>
        ) => {
            state.district = action.payload
        },
        setDistrictUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchdistrictAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchdistrictAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.districts = action.payload
        })
        builder.addCase(fetchdistrictAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchdistrictRequest,
    fetchdistrictSuccess,
    fetchdistrictError,
    editDistrictSuccess,
    addDistrictSuccess,
    setActiveDistrict,
    setDistrictUpdateMode,
} = districtSlice.actions

const reducer = districtSlice.reducer

export { reducer as districtReducer }
