import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as ticketStatusActions from '../../../redux/common/ticket-status/ticket-status.slice'
import { ITicketStatus } from '../../../models/common/ticket-status.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import ticketStatusService from '../../../services/common/ticket-status/ticket-status.service'

const useTicketStatus = () => {
    const ticketStatuses = useSelector<rootState, ITicketStatus[]>(
        (state) => state.ticketStatus.ticketStatuses
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.ticketStatus.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.ticketStatus.initialFetch
    )
    const ticketStatus = useSelector<rootState, ITicketStatus>(
        (state) => state.ticketStatus.ticketStatus
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.ticketStatus.updateMode
    )

    const dispatch = useDispatch()

    const loadTicketStatuses = useCallback(() => {
        if (initialFetch) {
            dispatch(ticketStatusActions.fetchTicketStatusesAsync())
        }
    }, [dispatch, initialFetch])

    const addTicketStatus = async (ticketStatus: ITicketStatus) => {
        return await ticketStatusService
            .create(ticketStatus)
            .then((ticketStatusResponse) => {
                dispatch(ticketStatusActions.addTicketStatusSuccess(ticketStatusResponse.data))
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const setTicketStatus = (ticketStatus: ITicketStatus) => {
        dispatch(ticketStatusActions.setActiveTicketStatus(ticketStatus))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(ticketStatusActions.setTicketStatusUpdateMode(updateMode))
    }

    const editTicketStatus = async (ticketStatus: ITicketStatus) => {
        return await ticketStatusService
            .update(ticketStatus)
            .then((ticketStatusResponse) => {
                dispatch(ticketStatusActions.editTicketStatusSuccess(ticketStatusResponse.data))
                setTicketStatus(ticketStatusResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveTicketStatus = async (ticketStatus: ITicketStatus, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addTicketStatus(ticketStatus)
            : await editTicketStatus(ticketStatus)
    }

    useEffect(() => {
        loadTicketStatuses()
    }, [ticketStatus, ticketStatuses, isLoading, initialFetch, loadTicketStatuses])

    return {
        ticketStatus,
        ticketStatuses,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addTicketStatus,
        editTicketStatus,
        saveTicketStatus,
        setTicketStatus,
    }
}

export { useTicketStatus }
