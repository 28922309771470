import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as claimTypeModel from '../../../models/common/claim-type.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import claimTypeService from '../../../services/common/claim-type/claim-type.service'

export const initialState: claimTypeModel.IClaimTypeState = {
    claimTypes: [],
    errors: '',
    claimType: claimTypeModel.emptyClaimType,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchClaimTypesAsync = createAsyncThunk<
    claimTypeModel.IClaimType[],
    void
>('claimType/fetchClaimTypesAsync', async (_, thunkApi) => {
    try {
        return await claimTypeService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const claimTypeSlice = createSlice({
    name: 'claimType',
    initialState,
    reducers: {
        fetchClaimTypesRequest: (state) => {
            state.isLoading = true
        },
        fetchClaimTypesSuccess: (
            state,
            action: PayloadAction<claimTypeModel.IClaimType[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.claimTypes = action.payload
        },
        fetchClaimTypesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editClaimTypeSuccess: (
            state,
            action: PayloadAction<claimTypeModel.IClaimType>
        ) => {
            state.claimTypes = state.claimTypes.map((claimType) => {
                return claimType.code === action.payload.code
                    ? action.payload
                    : claimType
            })
            state.updateMode = UpdateMode.NONE
        },
        addClaimTypeSuccess: (
            state,
            action: PayloadAction<claimTypeModel.IClaimType>
        ) => {
            state.claimTypes = [...state.claimTypes, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveClaimType: (
            state,
            action: PayloadAction<claimTypeModel.IClaimType>
        ) => {
            state.claimType = action.payload
        },
        setClaimTypeUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClaimTypesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchClaimTypesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.claimTypes = action.payload
        })
        builder.addCase(fetchClaimTypesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchClaimTypesRequest,
    fetchClaimTypesSuccess,
    fetchClaimTypesError,
    editClaimTypeSuccess,
    addClaimTypeSuccess,
    setActiveClaimType,
    setClaimTypeUpdateMode,
} = claimTypeSlice.actions

const reducer = claimTypeSlice.reducer

export { reducer as claimTypeReducer }
