import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as sourceActions from '../../../redux/common/source/source.slice'
import { ISource } from '../../../models/common/source.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import sourceService from '../../../services/common/source/source.service'

const useSource = () => {
    const sources = useSelector<rootState, ISource[]>(
        (state) => state.source.sources
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.source.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.source.initialFetch
    )
    const source = useSelector<rootState, ISource>((state) => state.source.source)
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.source.updateMode
    )

    const dispatch = useDispatch()

    const loadSources = useCallback(() => {
        if (initialFetch) {
            dispatch(sourceActions.fetchSourcesAsync())
        }
    }, [dispatch, initialFetch])

    const addSource = async (source: ISource) => {
        return await sourceService
            .create(source)
            .then((sourceResponse) => {
                dispatch(sourceActions.addSourceSuccess(sourceResponse.data))
                return true
            })
            .catch(() => {
                return false
            })
    }

    const setSource = (source: ISource) => {
        dispatch(sourceActions.setActiveSource(source))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(sourceActions.setSourceUpdateMode(updateMode))
    }

    const editSource = async (source: ISource) => {
        return await sourceService
            .update(source)
            .then((sourceResponse) => {
                dispatch(sourceActions.editSourceSuccess(sourceResponse.data))
                setSource(sourceResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveSource = async (source: ISource, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addSource(source)
            : await editSource(source)
    }

    useEffect(() => {
        loadSources()
    }, [source, sources, isLoading, initialFetch, loadSources])

    return {
        source,
        sources,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addSource,
        editSource,
        saveSource,
        setSource,
    }
}

export { useSource }
