export const frRunner = {
    breadcrumb: {
        runner: 'coureurs',
        list: 'Liste',
    },
    mainPage: {
        title: 'Coureurs',
        subTitle: 'Afficher et gérer le coureur',
        buttonLabel: 'Créer le Runner',
    },

    detailPage: {
        title: 'Détail du Runner',
        subTitle: 'Apporter des modifications au coureur',
        buttonLabel: 'Modifier le Runner',
    },

    form: {
        code: 'code',
        creatTitle: 'Créer un nouveau coureur',
        editTitle: 'Modifier le coureur',
        description: 'La description',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        submit: 'Soumettre',
        reset: 'Réinitialiser',
    },
}
