import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import TicketStatusForm from '../../../components/common/ticket-status/ticket-status-form.component'
import TicketStatusTable from '../../../components/common/ticket-status/ticket-status-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useTicketStatus } from '../../../hooks/common/ticket-status/ticket-status.hook'
import { emptyTicketStatus } from '../../../models/common/ticket-status.model'
import { UpdateMode } from '../../../models/update-mode.enum'

const TicketStatusPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { setTicketStatus } = useTicketStatus()

    const handleShow = () => {
        setTitle('Create new ticketstatus')
        setTicketStatus(emptyTicketStatus)
        setContent(<TicketStatusForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['TicketStatus']} />
            <TitleBar
                title="Ticket Status"
                subTitle="View and manage ticket status"
                showButton
                handleShow={handleShow}
                buttonLabel="Add Ticket Status"
                icon={<FiPlus />}
            />
            <TicketStatusTable />
        </>
    )
}

export default TicketStatusPage
