import { Formik } from 'formik'
import React, { useContext } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useDivision } from '../../../hooks/common/division/division.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import { emptyDivision, IDivision } from '../../../models/common/division.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import DivisionFormFields from './division-form-field.component'

interface IProp {
    updateMode: UpdateMode
}

const DivisionForm: React.FC<IProp> = ({ updateMode }) => {
    const { toastSuccess, toastError } = useToast()
    const { saveDivision, division } = useDivision()
    const { setShow } = useContext<any>(AppModalContext)
    return (
        <div>
            <Formik<IDivision>
                initialValues={division || emptyDivision}
                onSubmit={async (values, { setSubmitting }) => {
                    const feedback = await saveDivision(values, updateMode)
                    if (feedback) {
                        toastSuccess('M0002')
                    } else {
                        toastError('M0003')
                    }
                    setSubmitting(false)
                    setShow(false)
                }}
            >
                <>
                    <DivisionFormFields />
                </>
            </Formik>
        </div>
    )
}
export default DivisionForm
