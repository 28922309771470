const baseURL = window.location.origin

//dev
export const devConfig = {
    apiUrl: 'https://localhost:44392',
    authUrl: 'https://login.damafin.net',
    clientId: 'emr.js.client',
    redirectUri: `${baseURL}/signin-oidc`,
    responseType: 'id_token token',
    scope: 'openid profile damaapi',
    postLogoutRedirectUri: `${baseURL}/signout-oidc`,
}

//prod
export const prodConfig = {
    apiUrl: 'https://api.damafin.net',
    authUrl: 'https://login.damafin.net',
    clientId: 'damafin.js.client',
    redirectUri: `${baseURL}/signin-oidc`,
    responseType: 'id_token token',
    scope: 'openid profile damaapi',
    postLogoutRedirectUri: `${baseURL}/signout-oidc`,
}

export const appConfig =
    process.env.NODE_ENV === 'development' ? devConfig : prodConfig
