import { Spin } from 'antd'
import React from 'react'

const SpinnerComponent: React.FunctionComponent = () => {
    return (
        <>
            <div style={{ width: '200px', height: '100%', margin: 'auto' }}>
                <Spin size="large" />
            </div>
        </>
    )
}
export default SpinnerComponent
