import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { IAuthState } from '../redux/common/auth/auth.slice'
import { rootState } from '../redux/root-reducer'
import {
    signoutRedirect,
    signoutRedirectWithHint,
} from '../services/shared/auth/auth.service'

const useAuthentication = () => {
    const auth = useSelector<rootState, IAuthState>((state) => state.auth)
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.auth.isLoadingUser
    )

    function signOut() {
        if (auth.user) {
            signoutRedirectWithHint(auth.user.id_token)
        } else {
            signoutRedirect()
        }
    }

    useEffect(() => {
        // fetch current user from cookies
        // loadUserFromStorage();
    }, [isLoading])

    return { auth, isLoading, signOut }
}

export { useAuthentication }
