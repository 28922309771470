import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as runnerPeriodComponentModel from '../../models/budgeting/runner-period-component.model'
import runnerPeriodComponentService from '../../services/budgeting/runner-period-component.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: runnerPeriodComponentModel.IRunnerPeriodComponentState = {
    runnerPeriodComponents: [],
    errors: '',
    runnerPeriodComponent: runnerPeriodComponentModel.emptyRunnerPeriodComponent,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchRunnerPeriodComponentsAsync = createAsyncThunk<
    runnerPeriodComponentModel.IRunnerPeriodComponent[],
    void
>('runnerPeriodComponent/fetchrunnerPeriodComponentsAsync', async (_, thunkApi) => {
    try {
        return await runnerPeriodComponentService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const runnerPeriodComponentSlice = createSlice({
    name: 'runnerPeriodComponent',
    initialState,
    reducers: {
        fetchRunnerPeriodComponentsRequest: (state) => {
            state.isLoading = true
        },
        fetchRunnerPeriodComponentsSuccess: (
            state,
            action: PayloadAction<
                runnerPeriodComponentModel.IRunnerPeriodComponent[]
            >
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerPeriodComponents = action.payload
        },
        fetchRunnerPeriodComponentsError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editRunnerPeriodComponentSuccess: (
            state,
            action: PayloadAction<runnerPeriodComponentModel.IRunnerPeriodComponent>
        ) => {
            state.runnerPeriodComponents = state.runnerPeriodComponents.map(
                (runnerPeriodComponent) => {
                    return runnerPeriodComponent.runnerPeriod ===
                        action.payload.runnerPeriod
                        ? action.payload
                        : runnerPeriodComponent
                }
            )
            state.updateMode = UpdateMode.NONE
        },
        addRunnerPeriodComponentSuccess: (
            state,
            action: PayloadAction<runnerPeriodComponentModel.IRunnerPeriodComponent>
        ) => {
            state.runnerPeriodComponents = [
                ...state.runnerPeriodComponents,
                action.payload,
            ]
            state.updateMode = UpdateMode.NONE
        },
        setActiveRunnerPeriodComponent: (
            state,
            action: PayloadAction<runnerPeriodComponentModel.IRunnerPeriodComponent>
        ) => {
            state.runnerPeriodComponent = action.payload
        },
        setRunnerPeriodComponentUpdateMode: (
            state,
            action: PayloadAction<UpdateMode>
        ) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRunnerPeriodComponentsAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(
            fetchRunnerPeriodComponentsAsync.fulfilled,
            (state, action) => {
                state.isLoading = false
                state.initialFetch = false
                state.runnerPeriodComponents = action.payload
            }
        )
        builder.addCase(
            fetchRunnerPeriodComponentsAsync.rejected,
            (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            }
        )
    },
})

export const {
    fetchRunnerPeriodComponentsRequest,
    fetchRunnerPeriodComponentsSuccess,
    fetchRunnerPeriodComponentsError,
    editRunnerPeriodComponentSuccess,
    addRunnerPeriodComponentSuccess,
    setActiveRunnerPeriodComponent,
    setRunnerPeriodComponentUpdateMode,
} = runnerPeriodComponentSlice.actions

const reducer = runnerPeriodComponentSlice.reducer

export { reducer as runnerPeriodComponentReducer }
