import { Card, Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useLocation } from '../../../hooks/common/location/location.hook'

const LocationDetailComponent: React.FC = () => {
    const { location } = useLocation()
    return (
        <Card bordered={false} size="small">
            <Row>
                <Col span={4}>
                    <StyledParagraph>Code: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{location.code}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Description: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{location.description}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Sub Division: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{location.subDivisionCode}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Tenant: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{location.tenant}</StyledParagraph>
                </Col>
            </Row>
        </Card>
    )
}

export default LocationDetailComponent

const StyledParagraph = styled.p`
    margin-bottom: 0;
`
