import { ColumnsType } from 'antd/lib/table'
import { IBranch } from '../../../models/common/branch.model'

export const branchesTableColumns: ColumnsType<IBranch> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Branch Name',
        dataIndex: 'branchName',
        key: 'branchName',
    },
    {
        title: 'Telephone',
        dataIndex: 'telephone',
        key: 'telephone',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
    },
    {
        title: 'Email',
        dataIndex: 'eMail',
        key: 'eMail',
    },
    {
        title: 'Website',
        dataIndex: 'website',
        key: 'website',
    },
]
