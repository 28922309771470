export const frModifications = {
    regionBreadcrumb: {
        modifications: 'Modifications',
        regionalModifications: 'Modifications régionales',
    },

    zoneAboveBreadcrumb: {
        modifications: 'Modifications',
        zoneAboveModifications: 'Zone au-dessus du site',
    },

    zoneSiteBreadcrumb: {
        modifications: 'Modifications',
        zoneModifications: 'Site de zone',
    },

    regionMainPage: {
        title: 'Modifications régionales',
        subTitle: 'Modifier les activités régionales',
        buttonLabel: 'Modifier',
    },

    zoneAboveMainPage: {
        title: 'Zone au-dessus des modifications du site',
        subTitle: 'Modifier les activités de la zone au-dessus du site',
        buttonLabel: 'Modifier',
    },

    zoneSiteMainPage: {
        title: 'Zone au-dessus des modifications du site',
        subTitle: 'Modifier les activités de la zone au-dessus du site',
        buttonLabel: 'Modifier',
    },

    form: {
        copYear: "Sélectionnez l'année du flic",
        project: 'Projet',
        component: 'Composant',
        runner: 'Coureur',
        submit: 'Soumettre',
        reset: 'Réinitialiser',
    },
}
