import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IBranchStation {
    tenant: string
    branch: string
    station: string
    status: string
    createdOn: Date
}

export const emptyBranchStation: IBranchStation = {
    tenant: '',
    branch: '',
    station: '',
    status: '',
    createdOn: new Date(),
}

export interface IBranchStationState extends IBaseState {
    readonly branchStations: IBranchStation[]
    readonly branchStation: IBranchStation
}
export interface IBranchStationResponse extends IResponseBase {
    data: IBranchStation
}
