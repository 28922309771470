import { Modal } from 'antd'
import React, { useEffect, createContext, FC, useState } from 'react'

export const AppModalContext = createContext({})

const AppModalProvider: FC = ({ children }) => {
    const [show, setShow] = useState(false)
    const [content, setContent] = useState(<p>content here!</p>)
    const [width, setWidth] = useState('30rem')
    const [title, setTitle] = useState('Modal title')
    const [handleSave, setHandleSave] = useState()

    useEffect(() => {}, [content, title])
    return (
        <>
            <AppModalContext.Provider
                value={{
                    show,
                    setShow,
                    title,
                    setTitle,
                    content,
                    setContent,
                    width,
                    setWidth,
                    handleSave,
                    setHandleSave,
                }}
            >
                <Modal
                    title={title}
                    visible={show}
                    width={width}
                    onOk={handleSave}
                    onCancel={() => setShow(false)}
                    footer={null}
                    bodyStyle={{ padding: '1rem' }}
                    maskClosable={false}
                >
                    {content}
                </Modal>
                {children}
            </AppModalContext.Provider>
        </>
    )
}

export default AppModalProvider
