import { apiRequests } from '../'
import { IProject, IProjectResponse } from '../../models/budgeting/project.model'

const projectService = {
    list: (): Promise<IProject[]> => apiRequests.get('/projects'),
    details: (code: string): Promise<IProject> =>
        apiRequests.get(`/projects/${code}`),
    create: (project: IProject): Promise<IProjectResponse> =>
        apiRequests.post('/projects', project),
    update: (project: IProject): Promise<IProjectResponse> =>
        apiRequests.put('/projects', project),
}

export default projectService
