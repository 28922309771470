import { ISideBarToggle } from '../../../models/shared/side-bar-toggle.model'

export enum configurationActionTypes {
    SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE',
}
interface SideBarTogglesToggleAction {
    type: typeof configurationActionTypes.SIDEBAR_TOGGLE
}

export const defaultSideBarTogle: ISideBarToggle = {
    isOpen: false,
}

export type configurationActionsCreators = SideBarTogglesToggleAction
