import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IReligion {
    code: string
    description: string
    tenant: string
    createdOn: Date
}

export interface IReligionState extends IBaseState {
    readonly religions: IReligion[]
    readonly religion: IReligion
}

export const emptyReligion: IReligion = {
    code: '',
    description: '',
    tenant: '',
    createdOn: new Date(),
}

export interface IReligionResponse extends IResponseBase {
    data: IReligion
}
