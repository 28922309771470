import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as projectSiteModel from '../../models/budgeting/project-site.model'
import projectSiteService from '../../services/budgeting/project-site.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: projectSiteModel.IProjectSiteState = {
    projectSites: [],
    errors: '',
    projectSite: projectSiteModel.emptyProjectSite,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchProjectSitesAsync = createAsyncThunk<
    projectSiteModel.IProjectSite[],
    void
>('projectSite/fetchprojectSiteAsync', async (_, thunkApi) => {
    try {
        return await projectSiteService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const projectSiteSlice = createSlice({
    name: 'projectSite',
    initialState,
    reducers: {
        fetchprojectSiteRequest: (state) => {
            state.isLoading = true
        },
        fetchprojectSiteSuccess: (
            state,
            action: PayloadAction<projectSiteModel.IProjectSite[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.projectSites = action.payload
        },
        fetchprojectSiteError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editProjectSiteSuccess: (
            state,
            action: PayloadAction<projectSiteModel.IProjectSite>
        ) => {
            state.projectSites = state.projectSites.map((projectSite) => {
                return projectSite.site === action.payload.site
                    ? action.payload
                    : projectSite
            })
            state.updateMode = UpdateMode.NONE
        },
        addProjectSiteSuccess: (
            state,
            action: PayloadAction<projectSiteModel.IProjectSite>
        ) => {
            state.projectSites = [...state.projectSites, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveProjectSite: (
            state,
            action: PayloadAction<projectSiteModel.IProjectSite>
        ) => {
            state.projectSite = action.payload
        },
        setProjectSiteUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProjectSitesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchProjectSitesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.projectSites = action.payload
        })
        builder.addCase(fetchProjectSitesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchprojectSiteRequest,
    fetchprojectSiteSuccess,
    fetchprojectSiteError,
    editProjectSiteSuccess,
    addProjectSiteSuccess,
    setActiveProjectSite,
    setProjectSiteUpdateMode,
} = projectSiteSlice.actions

const reducer = projectSiteSlice.reducer

export { reducer as projectSiteReducer }
