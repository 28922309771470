import styled from 'styled-components'
import { Button, Menu } from 'antd'
import { Input, Select, DatePicker, FormItem } from 'formik-antd'

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const Title = styled.div`
    font-weight: bold;
    font-size: 2rem;
`

export const Paragraph = styled.p`
    font-size: 1rem;
    line-height: 1.5;
    color: #65676e;
    margin: 0;
`

export const StyledButton = styled(Button)`
    background-color: #635dff;
    color: #fff;
    border-radius: 4px;
    &.ant-btn {
        height: auto;
    }
`

export const StyledInput = styled(Input)`
    border-radius: 4px;
    height: 36px;
`

export const StyleUserTableAvatar = styled.div`
    display: flex;
    align-items: center;
`

export const StyledUserAvatarContent = styled.div`
    margin-left: 1rem;

    p {
        margin-bottom: 0;
    }

    p:first-child {
        text-transform: uppercase;
        font-weight: bold;
        color: #635dff;
    }
`

export const MenuGroup = styled.div`
    margin-bottom: 0.35rem;
    padding-bottom: 0.35rem;
    border-bottom: 1px solid #e1e2e6;
`

export const StyledMenu = styled(Menu)`
    border-radius: 8px;
    padding: 10px;
`

export const StyledTableOptions = styled(Button)`
    border-radius: 6px;
`

export const StyledMenuItem = styled(Menu.Item)`
    margin: 4px 0;
    :hover {
        border-radius: 4px;
        background-color: '#e3e3e3';
    }
`
export const StyledLink = styled.a`
    display: inline-block;
    color: #6b66fa;
    font-weight: bold;
    margin: 10px 0 1rem;
    transition: all 300ms ease;

    :hover {
        opacity: 0.8;
        color: #6b66fa;
    }
`

export const StyleFormItem = styled(FormItem)`
    margin-bottom: 1rem;
`

export const StyledSelect = styled(Select)`
    height: 36px;
    & * {
        border-radius: 4px !important;
    }
    /* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px !important;
  } */
`

export const StyledDatePicker = styled(DatePicker)`
    border-radius: 4px;
    width: 100%;
`
