import { Col, Row, Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton, ResetButton, Select } from 'formik-antd'
import React from 'react'
import { ITicket } from '../../../models/common/ticket.model'
import { width100 } from '../../profile/profile.styles'
import { useTicketStatus } from '../../../hooks/common/ticket-status/ticket-status.hook'
import { UpdateMode } from '../../../models/update-mode.enum'

const FormItem = Form.Item
const { TextArea } = Input

type Props = {
    mode?: UpdateMode
}
const TicketFormFields: React.FC<Props> = ({ mode = UpdateMode.ADD }) => {
    const { isSubmitting } = useFormikContext<ITicket>()
    const { ticketStatuses } = useTicketStatus()

    return (
        <>
            <Form autoComplete="off">
                <Row gutter={16}>
                    <Col className="gutter-row" md={12}>
                        <p className="mb-0">User Email</p>
                        <FormItem name="userEmail" required={true}>
                            <Input
                                name="userEmail"
                                disabled={mode === UpdateMode.EDIT || isSubmitting }
                                required={true}
                                placeholder="Email"
                            />
                        </FormItem>
                    </Col>
                    <Col className="gutter-row" md={12}>
                        <p className="mb-0">Active Phone Number</p>
                        <FormItem name="userPhoneNumber" required={true}>
                            <Input
                                name="userPhoneNumber"
                                disabled={mode === UpdateMode.EDIT || isSubmitting }
                                required={true}
                                placeholder="Phone Number"
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col className="gutter-row" md={24}>
                        <p className="mb-0">Problem Description</p>
                        <FormItem name="description" required={true}>
                            <TextArea
                                rows={4}
                                style={width100}
                                name="description"
                                disabled={mode === UpdateMode.EDIT || isSubmitting }
                                required={true}
                                placeholder="Description "
                            />
                        </FormItem>
                    </Col>
                </Row>

                {mode === UpdateMode.EDIT && (
                    <>
                        <Row>
                            <Col className="gutter-row" md={24}>
                                <p className="mb-0">Status</p>
                                <Form.Item
                                    name="status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'You must select a student',
                                        },
                                    ]}
                                >
                                    <Select
                                        name="status"
                                        showSearch
                                        optionFilterProp="label"
                                    >
                                        {ticketStatuses.map((status, index) => (
                                            <Select.Option
                                                key={index}
                                                value={status.code}
                                            >
                                                {status.description}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="gutter-row" md={24}>
                                <p className="mb-0">Comment</p>
                                <FormItem name="comment" required={true}>
                                    <TextArea
                                        rows={4}
                                        style={width100}
                                        name="comment"
                                        disabled={isSubmitting}
                                        required={true}
                                        placeholder="comment "
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </>
                )}

                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>Submit</SubmitButton>
                    <ResetButton disabled={isSubmitting}>Reset</ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default TicketFormFields
