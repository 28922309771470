import { ColumnsType } from 'antd/lib/table'
import { useActivityPlan } from '../../../hooks/budgeting/activity-plan.hook'
import { useMilestone } from '../../../hooks/budgeting/milestone.hook'
import { useSite } from '../../../hooks/budgeting/site.hook'
import { IMilestone } from '../../../models/budgeting/milestone.model'
import { format } from '../../../utils/format'

const useMilestoneProjectionColumns = () => {
    const { sites } = useSite()
    const { site } = useMilestone()
    const { activityPlans } = useActivityPlan()

    const formatFun = (row: IMilestone) => {
        console.log('row: ', site)
        return sites.find((s) => s.code === site)?.description
    }
    const getActivityName = (code: string): string => {
        if (activityPlans && activityPlans.length > 0) {
            const activity = activityPlans.find((a) => a.code === code)
            return `${activity?.description}`
        }
        return 'Not found'
    }

    const milestoneColumns: ColumnsType<IMilestone> = [
        {
            title: 'Site',
            dataIndex: 'site',
            key: 'site',
            width: '25%',
            ellipsis: true,
            render: (_, row) => formatFun(row),
        },
        {
            title: 'Activity',
            dataIndex: 'activity',
            key: 'activity',
            width: '10%',
            align: 'right',
        },
        // {
        //     title: 'Activity Name',
        //     dataIndex: 'activityName',
        //     key: 'activityName',
        //     render: (_, record) => getActivityName(record.activity),
        // },
        {
            title: 'Target',
            dataIndex: 'target',
            key: 'target',
            width: '10%',
            align: 'right',
            render: (_, row) => format.number(row.target),
        },
        {
            title: 'Budget',
            dataIndex: 'budget',
            key: 'budget',
            width: '10%',
            align: 'right',
            render: (_, row) => format.number(row.budget),
        },
        {
            title: 'Budget Note',
            dataIndex: 'budgetNote',
            key: 'budgetNote',
            width: '45%',
            ellipsis: true,
        },
    ]

    return {
        milestoneColumns,
    }
}

export { useMilestoneProjectionColumns }
