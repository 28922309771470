import React from 'react'
import { Table } from 'antd'
import { ITicketStatus } from '../../../models/common/ticket-status.model'
import { useTicketStatus } from '../../../hooks/common/ticket-status/ticket-status.hook'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'
import { appRoutes } from '../../../constants/app-route'
import { ticketStatusTableColumns } from './ticket-status-column.component'

const TicketStatusTable = () => {
    const { ticketStatuses, setTicketStatus } = useTicketStatus()
    const router = useHistory()
    // const route = use
    const handleRowClick = (ticketStatus: ITicketStatus) => {
        setTicketStatus(ticketStatus)
        router.push(`${appRoutes.FAQs.TICKET_STATUS}/${slugify(ticketStatus.description)}`)
    }
    return (
        <>
            {ticketStatuses && ticketStatuses.length ? (
                <Table<ITicketStatus>
                    dataSource={ticketStatuses}
                    columns={ticketStatusTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    onRow={(record: ITicketStatus) => {
                        return {
                            onClick: (e) => {
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                <NoContent title="No data for ticket status" />
            )}
        </>
    )
}

export default TicketStatusTable
