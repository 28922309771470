import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as copYearCostCategoryModel from '../../models/budgeting/cop-year-cost-category.model'
import copYearCostCategorieservice from '../../services/budgeting/cop-year-cost-category.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: copYearCostCategoryModel.ICopYearCostCategoryState = {
    copYearCostCategories: [],
    errors: '',
    copYearCostCategory: copYearCostCategoryModel.emptyCopYearCostCategory,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchcopYearCostCategoriesAsync = createAsyncThunk<
    copYearCostCategoryModel.ICopYearCostCategory[],
    void
>('copYearCostCategory/fetchcopYearCostCategoriesAsync', async (_, thunkApi) => {
    try {
        return await copYearCostCategorieservice.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const copYearCostCategorieslice = createSlice({
    name: 'copYearCostCategory',
    initialState,
    reducers: {
        fetchcopYearCostCategoriesRequest: (state) => {
            state.isLoading = true
        },
        fetchcopYearCostCategoriesSuccess: (
            state,
            action: PayloadAction<copYearCostCategoryModel.ICopYearCostCategory[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.copYearCostCategories = action.payload
        },
        fetchcopYearCostCategoriesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editCopYearCostCategoriesuccess: (
            state,
            action: PayloadAction<copYearCostCategoryModel.ICopYearCostCategory>
        ) => {
            state.copYearCostCategories = state.copYearCostCategories.map(
                (copYearCostCategory) => {
                    return copYearCostCategory.costCategory ===
                        action.payload.costCategory
                        ? action.payload
                        : copYearCostCategory
                }
            )
            state.updateMode = UpdateMode.NONE
        },
        addCopYearCostCategoriesuccess: (
            state,
            action: PayloadAction<copYearCostCategoryModel.ICopYearCostCategory>
        ) => {
            state.copYearCostCategories = [
                ...state.copYearCostCategories,
                action.payload,
            ]
            state.updateMode = UpdateMode.NONE
        },
        setActiveCopYearCostCategory: (
            state,
            action: PayloadAction<copYearCostCategoryModel.ICopYearCostCategory>
        ) => {
            state.copYearCostCategory = action.payload
        },
        setCopYearCostCategoryUpdateMode: (
            state,
            action: PayloadAction<UpdateMode>
        ) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchcopYearCostCategoriesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(
            fetchcopYearCostCategoriesAsync.fulfilled,
            (state, action) => {
                state.isLoading = false
                state.initialFetch = false
                state.copYearCostCategories = action.payload
            }
        )
        builder.addCase(
            fetchcopYearCostCategoriesAsync.rejected,
            (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            }
        )
    },
})

export const {
    fetchcopYearCostCategoriesRequest,
    fetchcopYearCostCategoriesSuccess,
    fetchcopYearCostCategoriesError,
    editCopYearCostCategoriesuccess,
    addCopYearCostCategoriesuccess,
    setActiveCopYearCostCategory,
    setCopYearCostCategoryUpdateMode,
} = copYearCostCategorieslice.actions

const reducer = copYearCostCategorieslice.reducer

export { reducer as copYearCostCategoryReducer }
