import { Table, TableColumnsType } from 'antd'
import { useCallback, useRef } from 'react'
// import { useHistory } from 'react-router-dom'
import { useActivity } from '../../../hooks/budgeting/activity.hook'
import { IActivitySummary } from '../../../models/budgeting/activity-summary.model'
import { IActivity } from '../../../models/budgeting/activity.model'
import { format } from '../../../utils/format'

const columns: TableColumnsType<IActivity> = [
    {
        title: 'Line',
        dataIndex: 'batchLine',
        key: 'batchLine',
        filtered: true,
    },
    {
        title: 'Site',
        dataIndex: 'site',
        key: 'site',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: '6rem',
        align: 'right',
        render: (_, record) => format.number(record.amount),
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]

type Props = {
    data?: IActivitySummary
}

export const ActivityEntryHiddenTable: React.FC<Props> = ({ data }) => {
    const { activities } = useActivity()
    const recordRef = useRef<any>()
    // const router = useHistory();

    const getActivitySummary = useCallback(() => {
        if (data) {
            if (activities && activities.length) {
                let journals: IActivity[] = activities.filter(
                    (x) => x.batch === data.batch
                )
                return journals
            }
            return []
        }
        return []
    }, [data, activities])

    const handleRowClick = () => {
        // router.push(`/activities/${data?.batch}/${data.}`)
    }

    return (
        <div ref={recordRef}>
            <>
                <Table
                    showHeader={false}
                    size="small"
                    columns={columns}
                    dataSource={getActivitySummary()}
                    pagination={false}
                    onRow={() => {
                        return {
                            onClick: (e) => {
                                handleRowClick()
                            },
                        }
                    }}
                />
            </>
        </div>
    )
}
