import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as roleModel from '../../../models/common/role.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import roleService from '../../../services/common/role/role.service'

export const initialState: roleModel.IRoleState = {
    roles: [],
    errors: '',
    role: roleModel.emptyRole,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchRolesAsync = createAsyncThunk<roleModel.IRole[], void>(
    'role/fetchRolesAsync',
    async (_, thunkApi) => {
        try {
            return await roleService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        fetchRolesRequest: (state) => {
            state.isLoading = true
        },
        fetchRolesSuccess: (state, action: PayloadAction<roleModel.IRole[]>) => {
            state.isLoading = false
            state.initialFetch = false
            state.roles = action.payload
        },
        fetchRolesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editRoleSuccess: (state, action: PayloadAction<roleModel.IRole>) => {
            state.roles = state.roles.map((role) => {
                return role.code === action.payload.code ? action.payload : role
            })
            state.updateMode = UpdateMode.NONE
        },
        addRoleSuccess: (state, action: PayloadAction<roleModel.IRole>) => {
            state.roles = [...state.roles, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveRole: (state, action: PayloadAction<roleModel.IRole>) => {
            state.role = action.payload
        },
        setRoleUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRolesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchRolesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.roles = action.payload
        })
        builder.addCase(fetchRolesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchRolesRequest,
    fetchRolesSuccess,
    fetchRolesError,
    editRoleSuccess,
    addRoleSuccess,
    setActiveRole,
    setRoleUpdateMode,
} = roleSlice.actions

const reducer = roleSlice.reducer

export { reducer as roleReducer }
