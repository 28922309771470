import { apiRequests } from '../..'
import {
    IStationReference,
    IStationReferenceResponse,
} from '../../../models/common/station-reference.model'

const stationReferenceService = {
    list: (): Promise<IStationReference[]> => apiRequests.get('/stationReferences'),
    details: (code: string): Promise<IStationReference> =>
        apiRequests.get(`/stationReferences/${code}`),
    create: (
        stationReference: IStationReference
    ): Promise<IStationReferenceResponse> =>
        apiRequests.post('/stationReferences', stationReference),
    update: (
        stationReference: IStationReference
    ): Promise<IStationReferenceResponse> =>
        apiRequests.put('/stationReferences', stationReference),
}

export default stationReferenceService
