import { Button, Form, Input, message, Space } from 'antd'
import React, { useContext, useState } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { emptyRole } from '../../../models/common/role.model'
import roleService from '../../../services/common/role/role.service'
import { useTranslation } from 'react-i18next'

const RoleForm: React.FC = () => {
    const { setShow } = useContext<any>(AppModalContext)
    const [saving, setSaving] = useState(false)
    const [form] = Form.useForm()
    const { currentBranch } = useCurrentBranch()
    const { t } = useTranslation()

    const onFinish = async (values: any) => {
        setSaving(true)
        console.log('values: ', values)
        form.resetFields()
        const feedback = await roleService.create({
            ...values,
            code: '01',
            status: '01',
            tenant: currentBranch.tenant,
        })
        if (feedback.success) {
            message.success('New role added successfully')
        } else if (feedback.validationErrors.length > 0) {
            console.log('validationErrors: ', feedback.validationErrors)
        } else {
            console.log('error occurred: ', feedback)
        }
        setSaving(false)
        setShow(false)
    }

    return (
        <>
            <Form
                form={form}
                name="role-form"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{}}
            >
                <Form.Item
                    requiredMark={false}
                    label={t('common:role.roleMgtForm.title')}
                    name="description"
                    rules={[{ required: true, message: 'Role name is required' }]}
                >
                    <Input placeholder="Description" name="description" />
                </Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit" loading={saving}>
                        {t('common:role.roleMgtForm.saveBtn')}
                    </Button>
                    <Button onClick={() => setShow(false)}>
                        {t('common:role.roleMgtForm.cancelBtn')}
                    </Button>
                </Space>
            </Form>
        </>
    )
}

export default RoleForm
