import { ColumnsType } from "antd/lib/table";
import { IClaim } from "../../../models/common/claim.model";
import ClaimsParentRenderer from "./claim-parent-renderer.component";

export const claimTableColumns: ColumnsType<IClaim> = [
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    width: "5rem",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    ellipsis: true,
    width: "15rem"
  },
  {
    title: "Parent",
    dataIndex: "parent",
    key: "parent",
    render: (_, record) => <ClaimsParentRenderer parentCode={record.parent} />
  },
];
