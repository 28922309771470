import React, { useCallback, useState } from 'react'
import { Card, Transfer } from 'antd'
import { Formik } from 'formik'
import { Form, FormItem, Select, SubmitButton } from 'formik-antd'
import { useRole } from '../../../hooks/common/role/role.hook'
import { useUserRole } from '../../../hooks/common/user-role/user-role.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useUser } from '../../../hooks/shared/user/user.hook'
import { useTranslation } from 'react-i18next'
import userRoleService from '../../../services/common/user-role/user-role.service'
import { useToast } from '../../../hooks/common/toast/toast.hook'

const { Option } = Select

const RoleUserTab: React.FunctionComponent = () => {
    const { users } = useUser()
    const { roles } = useRole()
    const { t } = useTranslation()
    const { userRoles } = useUserRole()
    const [selectedRole, setSelectedRole] = useState('')
    const { currentBranch } = useCurrentBranch()
    const { toastError, toastSuccess}= useToast();

    const [targetKeys, setTargetKeys] = useState<string[]>([])
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])

    const onChange = (targetKeys: string[]) => setTargetKeys(targetKeys)

    const onSelectChange = (
        sourceSelectedKeys: string[],
        targetSelectedKeys: string[]
    ) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
    }

    const handleChange = async (roleCode: string) => {
        setSelectedRole(roleCode)
        const usersWithSelectedRole = userRoles
            .filter((item) => item.roleCode === roleCode)
            .map((item) => item.usrCode)
        setTargetKeys(usersWithSelectedRole)
    }

    const onSearch = useCallback((input: string, option) => {
        return option?.props.children.props.children
            .toString()
            .toLowerCase()
            .includes(input)
    }, [])

    return (
        <>
            <Formik
                onSubmit={async (values, { setSubmitting }) => {
                    const obj = {
                        ...values,
                        role: selectedRole,
                        users: targetKeys.join(','),
                        tenant: currentBranch.tenant,
                    }

                    // console.log('users role data: ', obj)
                    const feedback = await userRoleService.assignGroupRole(obj)

                    if (feedback) {
                        toastSuccess('M0002')
                    } else {
                        toastError('M0003')
                    }
                    setSubmitting(false)
                }}
                initialValues={{}}
            >
                {({ isSubmitting, handleSubmit }) => (
                    <Form autoComplete="off" onFinish={handleSubmit}>
                        <Card size="small" title={t('common:role.RolePrv.assGrp')}>
                            <div className="mb-lg">
                                <label
                                    style={{ marginBottom: '1rem', width: '100%' }}
                                >
                                    <p className="mb-0">
                                        {t('common:role.form.title')}
                                    </p>
                                    <FormItem name="user" required={true}>
                                        <Select
                                            name="role"
                                            disabled={isSubmitting}
                                            showSearch
                                            placeholder="Search Role"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                onSearch(input, option)
                                            }
                                            onChange={handleChange}
                                        >
                                            {roles.map((role, index) => (
                                                <Option
                                                    value={role.code}
                                                    key={index}
                                                >
                                                    <p className="mb-0">
                                                        {role.description}
                                                    </p>
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                </label>
                            </div>

                            <div>
                                <Transfer
                                    className="vulnerability-criteria-transfer"
                                    rowKey={(record) => record.code}
                                    dataSource={users}
                                    showSearch
                                    listStyle={{
                                        height: 300,
                                    }}
                                    operations={[
                                        t('common:role.RolePrv.addBtn'),
                                        t('common:role.RolePrv.removeBtn'),
                                    ]}
                                    operationStyle={{
                                        color: '#571f1f',
                                        backgroundColor: '#fff',
                                    }}
                                    titles={[
                                        t('common:role.RolePrv.allUsers'),
                                        t('common:role.RolePrv.selUsers'),
                                    ]}
                                    targetKeys={targetKeys}
                                    selectedKeys={selectedKeys}
                                    onChange={onChange}
                                    onSelectChange={onSelectChange}
                                    render={(item) => `${item.usrName}`}
                                    // filterOption={onSearch}
                                />
                            </div>
                            <SubmitButton
                                style={{ marginTop: '1rem'}}
                                loading={isSubmitting}
                            >
                                {t('common:role.form.savebtn')}
                            </SubmitButton>
                        </Card>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default RoleUserTab
