export const enQuarter = {
    tenant: 'Tenant',
    code: 'Code',
    copYear: 'Component year',
    description: 'Description',
    startDate: 'start date',
    endDate: 'End date',
    status: 'Status',
    createdOn: 'Created on',
}
