import { Button, Card, Input, Select, Space } from 'antd'
import React, { useState } from 'react'
import { BiRefresh } from 'react-icons/bi'
import { BsPrinter } from 'react-icons/bs'
import CostCategoryTable from '../../components/dashboard/cost-category/cost-category-table.component'
import DashboardCardFooter from '../../components/dashboard/dashboard-card-footer.component'
import PageBreadCrumbs from '../../components/shared/page-breadcrumb/page-breadcrumb.component'
import './home.page.scss'
import { useTranslation } from 'react-i18next'

const CostCategoryDashboardPage: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [activeCard, setActiveCard] = useState('wtd')
    const { t } = useTranslation()

    return (
        <div>
            <PageBreadCrumbs items={[t('common:breadCrumb.dashboard')]} />
            <Space direction="vertical" style={{ width: '100%' }}>
                <Space>
                    <Select
                        onChange={(op) => setActiveCard(op)}
                        defaultValue="wtd"
                        style={{ width: '10rem' }}
                    >
                        <Select.Option value="wtd">Week to date</Select.Option>
                        <Select.Option value="mtd">Month to date</Select.Option>
                        <Select.Option value="ytd">Year to date</Select.Option>
                    </Select>
                    <Button
                        loading={loading}
                        onClick={() => setLoading(false)}
                        icon={<BiRefresh style={{ marginRight: '5px' }} />}
                        type="primary"
                    >
                        {t('budgeting:dashboard.costCategory.refreshButton')}
                    </Button>
                    <Button icon={<BsPrinter style={{ marginRight: '5px' }} />}>
                        {t('budgeting:dashboard.costCategory.printPreviewButton')}
                    </Button>
                </Space>

                {activeCard === 'wtd' && (
                    <>
                        <CostCategoryTable />
                        <DashboardCardFooter />
                    </>
                )}

                {activeCard === 'mtd' && (
                    <>
                        <CostCategoryTable />
                        <DashboardCardFooter />
                    </>
                )}
                {activeCard === 'ytd' && (
                    <>
                        <CostCategoryTable />
                        <DashboardCardFooter />
                    </>
                )}
            </Space>
        </div>
    )
}

export default CostCategoryDashboardPage
