export const enDashboard = {
    costCategory: {
        refreshButton: 'Refresh',
        printPreviewButton: 'Print Preview',
    },
    outlayTarget: {
        refreshButton: 'Refresh',
        printPreviewButton: 'Print Preview',
    },
    intervention: {
        refreshButton: 'Refresh',
        printPreviewButton: 'Print Preview',
    },
    milestoneProjection: {
        refreshButton: 'Refresh',
        resetSelection: 'Reset Selection',
        printPreviewButton: 'Print Preview',
    },
}
