import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import {
    emptyRunnerPeriodComponentBase,
    IRunnerPeriodComponentBase,
} from './runner-period-component-base.model'

export interface IRunnerPeriodComponent extends IRunnerPeriodComponentBase {}

export interface IRunnerPeriodComponentState extends IBaseState {
    readonly runnerPeriodComponents: IRunnerPeriodComponent[]
    readonly runnerPeriodComponent: IRunnerPeriodComponent
}

export const emptyRunnerPeriodComponent: IRunnerPeriodComponent =
    emptyRunnerPeriodComponentBase

export interface IRunnerPeriodComponentResponse extends IResponseBase {
    data: IRunnerPeriodComponent
}
