import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as ticketActions from '../../../redux/common/ticket/ticket.slice'
import { ITicket } from '../../../models/common/ticket.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import ticketService from '../../../services/common/ticket/ticket.service'

const useTicket = () => {
    const tickets = useSelector<rootState, ITicket[]>(
        (state) => state.ticket.tickets
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.ticket.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.ticket.initialFetch
    )
    const ticket = useSelector<rootState, ITicket>(
        (state) => state.ticket.ticket
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.ticket.updateMode
    )

    const dispatch = useDispatch()

    const loadTickets = useCallback(() => {
        if (initialFetch) {
            dispatch(ticketActions.fetchTicketsAsync())
        }
    }, [dispatch, initialFetch])

    const addTicket = async (ticket: ITicket) => {
        return await ticketService
            .create(ticket)
            .then((ticketResponse) => {
                dispatch(ticketActions.addTicketSuccess(ticketResponse.data))
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const setTicket = (ticket: ITicket) => {
        dispatch(ticketActions.setActiveTicket(ticket))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(ticketActions.setTicketUpdateMode(updateMode))
    }

    const editTicket = async (ticket: ITicket) => {
        return await ticketService
            .update(ticket)
            .then((ticketResponse) => {
                dispatch(ticketActions.editTicketSuccess(ticketResponse.data))
                setTicket(ticketResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveTicket = async (ticket: ITicket, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addTicket(ticket)
            : await editTicket(ticket)
    }

    useEffect(() => {
        loadTickets()
    }, [ticket, tickets, isLoading, initialFetch, loadTickets])

    return {
        ticket,
        tickets,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addTicket,
        editTicket,
        saveTicket,
        setTicket,
    }
}

export { useTicket }
