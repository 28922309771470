export const frSiteReport = {
    //BA cost category
    mainPage: {
        title: 'Analyse du budget du site par catégorie de coût',
        generateBtn: 'Générer',
        loadReportBtn: 'Charger le rapport',
        exportToExel: 'Exporter vers Excel',
    },
    form: {
        description: 'La description',
        copYear: 'Année composante',
        code: 'Code',
        region: 'Région',
        transactionCode: 'Code de transaction',
        reportType: 'Type de rapport',
        submit: 'Soumettre',
        reset: 'Réinitialiser',
        date: 'Date de début et de fin',
    },
    //BA intervention report
    interventionMainPage: {
        title: "Rapport d'intervention sur site",
    },

    budgetExecutionPage: {
        title: 'Rapport de site Analyse budgétaire',
    },
}
