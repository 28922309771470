import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as branchStationActions from '../../../redux/common/branch-station/branch-station.slice'
import { IBranchStation } from '../../../models/common/branch-station.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import branchStationService from '../../../services/common/branch-station/branch-station.service'
import * as Yup from 'yup'

const useBranchStation = () => {
    const branchStations = useSelector<rootState, IBranchStation[]>(
        (state) => state.branchStation.branchStations
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.branchStation.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.branchStation.initialFetch
    )
    const branchStation = useSelector<rootState, IBranchStation>(
        (state) => state.branchStation.branchStation
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.branchStation.updateMode
    )

    const dispatch = useDispatch()

    const branchStationSchema = Yup.object().shape({
        tenant: Yup.string().max(10, 'Too Long!').required('Required'),
        branch: Yup.string().max(10, 'Too Long!').required('Required'),
        station: Yup.string().max(10, 'Too Long!').required('Required'),
    })

    const loadBranchStations = useCallback(() => {
        if (initialFetch) {
            dispatch(branchStationActions.fetchBranchStationsAsync())
        }
    }, [dispatch, initialFetch])

    const addBranchStation = async (branchStation: IBranchStation) => {
        return await branchStationService
            .create(branchStation)
            .then((branchStationResponse) => {
                if (branchStationResponse.success) {
                    dispatch(
                        branchStationActions.addBranchStationSuccess(
                            branchStationResponse.data
                        )
                    )
                } else {
                    return branchStationResponse
                }
                return branchStationResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setBranchStation = (branchStation: IBranchStation) => {
        dispatch(branchStationActions.setActiveBranchStation(branchStation))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(branchStationActions.setBranchStationUpdateMode(updateMode))
    }

    const editBranchStation = async (branchStation: IBranchStation) => {
        return await branchStationService
            .update(branchStation)
            .then((branchStationResponse) => {
                dispatch(
                    branchStationActions.editBranchStationSuccess(
                        branchStationResponse.data
                    )
                )
                setBranchStation(branchStationResponse.data)
                return branchStationResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const saveBranchStation = async (
        branchStation: IBranchStation,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addBranchStation(branchStation)
            : await editBranchStation(branchStation)
    }

    useEffect(() => {
        loadBranchStations()
    }, [branchStation, branchStations, isLoading, initialFetch, loadBranchStations])

    return {
        branchStation,
        branchStations,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addBranchStation,
        editBranchStation,
        saveBranchStation,
        setBranchStation,
        branchStationSchema,
    }
}

export { useBranchStation }
