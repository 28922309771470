import React from 'react'
import { useTranslation } from 'react-i18next'
import BackButton from '../../../components/common/back-button/back-button.component'
import { UsersTab } from '../../../components/common/user/user-tab.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'

const UserDetailsPage: React.FC = () => {
    const { t } = useTranslation()
    return (
        <>
            <PageBreadCrumbs
                items={[t('common:user.form.title'), t('common:user.form.detail')]}
            />
            <BackButton page={t('common:user.form.title')} />
            <UsersTab />
        </>
    )
}

export default UserDetailsPage
