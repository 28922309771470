import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as subDivisionModel from '../../../models/common/sub-division.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import subDivisionService from '../../../services/common/sub-division/sub-division.service'

export const initialState: subDivisionModel.ISubDivisionState = {
    subDivisions: [],
    errors: '',
    subDivision: subDivisionModel.emptySubDivision,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchSubDivisionsAsync = createAsyncThunk(
    'subDivision/fetchSubDivisionsAsync',
    async () => {
        return await subDivisionService.list()
    }
)

export const subDivisionSlice = createSlice({
    name: 'subDivision',
    initialState,
    reducers: {
        fetchSubDivisionsRequest: (state) => {
            state.isLoading = true
        },
        fetchSubDivisionsSuccess: (
            state,
            action: PayloadAction<subDivisionModel.ISubDivision[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.subDivisions = action.payload
        },
        fetchSubDivisionsError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editSubDivisionSuccess: (
            state,
            action: PayloadAction<subDivisionModel.ISubDivision>
        ) => {
            state.subDivisions = state.subDivisions.map((subDivision) => {
                return subDivision.code === action.payload.code
                    ? action.payload
                    : subDivision
            })
            state.updateMode = UpdateMode.NONE
        },
        addSubDivisionSuccess: (
            state,
            action: PayloadAction<subDivisionModel.ISubDivision>
        ) => {
            state.subDivisions = [...state.subDivisions, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveSubDivision: (
            state,
            action: PayloadAction<subDivisionModel.ISubDivision>
        ) => {
            state.subDivision = action.payload
        },
        setSubDivisionUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSubDivisionsAsync.pending, (_state, _action) => {
            // fetchSubDivisionsRequest();
            _state.isLoading = true
        })
        builder.addCase(fetchSubDivisionsAsync.fulfilled, (_state, action) => {
            // fetchSubDivisionsSuccess(action.payload);
            _state.isLoading = false
            _state.initialFetch = false
            _state.subDivisions = action.payload
        })
        builder.addCase(fetchSubDivisionsAsync.rejected, (_state, action) => {
            // fetchSubDivisionsError(action.payload as string);
            _state.isLoading = false
            // state.errors = action.payload;
        })
    },
})

export const {
    fetchSubDivisionsRequest,
    fetchSubDivisionsSuccess,
    fetchSubDivisionsError,
    editSubDivisionSuccess,
    addSubDivisionSuccess,
    setActiveSubDivision,
    setSubDivisionUpdateMode,
} = subDivisionSlice.actions

const reducer = subDivisionSlice.reducer

export { reducer as subDivisionReducer }
