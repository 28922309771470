import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as siteTypeModel from '../../models/budgeting/site-type.model'
import siteTypeservice from '../../services/budgeting/site-type.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: siteTypeModel.ISiteTypeState = {
    siteTypes: [],
    errors: '',
    siteType: siteTypeModel.emptySiteType,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchsiteTypesAsync = createAsyncThunk<siteTypeModel.ISiteType[], void>(
    'siteType/fetchsiteTypesAsync',
    async (_, thunkApi) => {
        try {
            return await siteTypeservice.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const siteTypeslice = createSlice({
    name: 'siteType',
    initialState,
    reducers: {
        fetchsiteTypesRequest: (state) => {
            state.isLoading = true
        },
        fetchsiteTypesSuccess: (
            state,
            action: PayloadAction<siteTypeModel.ISiteType[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.siteTypes = action.payload
        },
        fetchsiteTypesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editSiteTypesuccess: (
            state,
            action: PayloadAction<siteTypeModel.ISiteType>
        ) => {
            state.siteTypes = state.siteTypes.map((siteType) => {
                return siteType.code === action.payload.code
                    ? action.payload
                    : siteType
            })
            state.updateMode = UpdateMode.NONE
        },
        addSiteTypesuccess: (
            state,
            action: PayloadAction<siteTypeModel.ISiteType>
        ) => {
            state.siteTypes = [...state.siteTypes, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveSiteType: (
            state,
            action: PayloadAction<siteTypeModel.ISiteType>
        ) => {
            state.siteType = action.payload
        },
        setSiteTypeUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchsiteTypesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchsiteTypesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.siteTypes = action.payload
        })
        builder.addCase(fetchsiteTypesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchsiteTypesRequest,
    fetchsiteTypesSuccess,
    fetchsiteTypesError,
    editSiteTypesuccess,
    addSiteTypesuccess,
    setActiveSiteType,
    setSiteTypeUpdateMode,
} = siteTypeslice.actions

const reducer = siteTypeslice.reducer

export { reducer as siteTypeReducer }
