import { Button, Card, Form, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { BiRefresh } from 'react-icons/bi'
import { BsPrinter } from 'react-icons/bs'
import DashboardCardFooter from '../../components/dashboard/dashboard-card-footer.component'
import OutlayVsTargetTable from '../../components/dashboard/outlay-vs-target/outlay-target-table.component'
import { useTranslation } from 'react-i18next'
import { NoContent } from '../../components/shared/no-content/no-content.component'
import { dashboardReportService } from '../../services/reports/dashboard-report.service'
import { useRunner } from '../../hooks/budgeting/runner.hook'
import { IOutlayAnalysis } from '../../models/budgeting/outlay-analysis.model'
import { IOutlayAnalysisOption } from '../../models/budgeting/outlay-analysis-options.model'
import outlayAnalysisService from '../../services/budgeting/outlay-analysis.service'
import { useBudgetAnalysisContext } from '../../contexts/reports/report.context'

const OutlayDashboardFilterForm: React.FC = () => {
    const { t } = useTranslation()
    const { hasPdf, setHasPdf, setReportFile } = useBudgetAnalysisContext()
    const [loading, setLoading] = useState(false)
    const [isLoadingReport, setIsLoadingReport] = useState(false)
    const { runner } = useRunner()
    const [outlayAnalysis, setOutlayAnalysis] = useState<IOutlayAnalysis[]>([])
    const { Option } = Select
    const [form] = Form.useForm()
    const [activeDashboard, setActiveDashboard] = useState('')
    const [isSubmitButton, setIsSubmitButton] = useState<boolean>()

    const getData = async (op: any) => {
        setActiveDashboard(op.type)

        let obj: IOutlayAnalysisOption = {
            tenant: runner.tenant,
            region: runner.region,
            dashboardType: op.type,
            component: runner.component,
        }

        let data
        if (isSubmitButton) {
            // get report data
            setHasPdf(true)
            data = await dashboardReportService.outlayVsTarget(obj)
            setReportFile(data)
            console.log('report data: ', data)
            setIsLoadingReport(false)
        } else {
            // get outlay data
            setHasPdf(false)
            data = await outlayAnalysisService.list(obj)
            console.log('outlay data: ', data)
            setOutlayAnalysis(data)
            setLoading(false)
        }
    }

    const handleSubmit = ({ isReport }: { isReport: boolean }) => {
        setIsSubmitButton(isReport)
        form.submit()
    }

    useEffect(() => {
        console.log('haspdf: ', hasPdf)
    }, [hasPdf])

    return (
        <div>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Form
                    form={form}
                    name="outlay-dashboard-filter-form"
                    layout="inline"
                    onFinish={getData}
                >
                    <Form.Item name="type">
                        <Select
                            style={{ width: '10rem', marginBottom: 0 }}
                            placeholder="Select type"
                            disabled={loading}
                        >
                            <Option value="01">Week to date</Option>
                            <Option value="02">Month to date</Option>
                            <Option value="03">Component to date</Option>
                            <Option value="04">Year to date</Option>
                        </Select>
                    </Form.Item>
                    <Button
                        loading={loading}
                        disabled={loading}
                        onClick={() => handleSubmit({ isReport: false })}
                        icon={<BiRefresh style={{ marginRight: '5px' }} />}
                        type="primary"
                    >
                        {t('budgeting:dashboard.outlayTarget.refreshButton')}
                    </Button>
                    <Button
                        onClick={() => handleSubmit({ isReport: true })}
                        loading={isLoadingReport}
                        icon={<BsPrinter style={{ marginRight: '5px' }} />}
                        data-report={true}
                    >
                        {t('budgeting:dashboard.outlayTarget.printPreviewButton')}
                    </Button>
                </Form>
                {!hasPdf && (
                    <>
                        {setOutlayAnalysis.length > 0 ? (
                            <div>
                                {activeDashboard === '01' && (
                                    <Card
                                        size="small"
                                        headStyle={{ textAlign: 'center' }}
                                        title="Outlay Vs Target Week to Date (WTD)"
                                        style={{ marginBottom: '1.5rem' }}
                                        loading={loading}
                                    >
                                        <OutlayVsTargetTable
                                            outlayAnalysis={outlayAnalysis}
                                        />
                                        {/* Card footer */}
                                        <DashboardCardFooter />
                                    </Card>
                                )}

                                {activeDashboard === '02' && (
                                    <Card
                                        size="small"
                                        headStyle={{ textAlign: 'center' }}
                                        title="Outlay Vs Target Month to Date (MTD)"
                                        style={{ marginBottom: '1.5rem' }}
                                    >
                                        <OutlayVsTargetTable
                                            outlayAnalysis={outlayAnalysis}
                                        />
                                        {/* Card footer */}
                                        <DashboardCardFooter />
                                    </Card>
                                )}

                                {activeDashboard === '03' && (
                                    <Card
                                        size="small"
                                        headStyle={{ textAlign: 'center' }}
                                        title="Outlay Vs Target Component to Date (CTD)"
                                        style={{ marginBottom: '1.5rem' }}
                                    >
                                        <OutlayVsTargetTable
                                            outlayAnalysis={outlayAnalysis}
                                        />
                                        {/* Card footer */}
                                        <DashboardCardFooter />
                                    </Card>
                                )}
                                {activeDashboard === '04' && (
                                    <Card
                                        size="small"
                                        headStyle={{ textAlign: 'center' }}
                                        title="Outlay Vs Target Year to Date (YTD)"
                                        style={{ marginBottom: '1.5rem' }}
                                    >
                                        <OutlayVsTargetTable
                                            outlayAnalysis={outlayAnalysis}
                                        />
                                        {/* Card footer */}
                                        <DashboardCardFooter />
                                    </Card>
                                )}
                            </div>
                        ) : (
                            <NoContent
                                title="No data found"
                                buttonLabel="Add New Runner"
                            />
                        )}
                    </>
                )}
            </Space>
        </div>
    )
}

export default OutlayDashboardFilterForm
