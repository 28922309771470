import { Formik } from 'formik'
import React from 'react'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useSource } from '../../../hooks/common/source/source.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import { emptySource, ISource } from '../../../models/common/source.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import SourceFormFields from './source-form-field.component'

const SourceForm: React.FC = () => {
    const { toastSuccess, toastError } = useToast()
    const { saveSource, source } = useSource()
    const { currentBranch } = useCurrentBranch()
    return (
        <div>
            <Formik<ISource>
                initialValues={source || emptySource}
                onSubmit={async (values, { setSubmitting }) => {
                    const obj = {
                        ...values,
                        tenant: currentBranch.tenant,
                    }
                    const feedback = await saveSource(obj, UpdateMode.ADD)
                    if (feedback) {
                        toastSuccess('M0002')
                    } else {
                        toastError('M0003')
                    }
                    setSubmitting(false)
                }}
            >
                <>
                    <SourceFormFields />
                </>
            </Formik>
        </div>
    )
}
export default SourceForm
