import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as locationModel from '../../../models/common/location.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import locationService from '../../../services/common/location/location.service'

export const initialState: locationModel.ILocationState = {
    locations: [],
    errors: '',
    location: locationModel.emptyLocation,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchlocationsAsync = createAsyncThunk(
    'location/fetchlocationsAsync',
    async () => {
        return await locationService.list()
    }
)

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        fetchlocationsRequest: (state) => {
            state.isLoading = true
        },
        fetchlocationsSuccess: (
            state,
            action: PayloadAction<locationModel.ILocation[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.locations = action.payload
        },
        fetchlocationsError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editlocationSuccess: (
            state,
            action: PayloadAction<locationModel.ILocation>
        ) => {
            state.locations = state.locations.map((location) => {
                return location.code === action.payload.code
                    ? action.payload
                    : location
            })
            state.updateMode = UpdateMode.NONE
        },
        addlocationSuccess: (
            state,
            action: PayloadAction<locationModel.ILocation>
        ) => {
            state.locations = [...state.locations, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActivelocation: (
            state,
            action: PayloadAction<locationModel.ILocation>
        ) => {
            state.location = action.payload
        },
        setlocationUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchlocationsAsync.pending, (_state, _action) => {
            // fetchlocationsRequest();
            _state.isLoading = true
        })
        builder.addCase(fetchlocationsAsync.fulfilled, (_state, action) => {
            // fetchlocationsSuccess(action.payload);
            _state.isLoading = false
            _state.initialFetch = false
            _state.locations = action.payload
        })
        builder.addCase(fetchlocationsAsync.rejected, (_state, action) => {
            // fetchlocationsError(action.payload as string);
            _state.isLoading = false
            // state.errors = action.payload;
        })
    },
})

export const {
    fetchlocationsRequest,
    fetchlocationsSuccess,
    fetchlocationsError,
    editlocationSuccess,
    addlocationSuccess,
    setActivelocation,
    setlocationUpdateMode,
} = locationSlice.actions

const reducer = locationSlice.reducer

export { reducer as locationReducer }
