import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as divisionActions from '../../../redux/common/division/division.slice'
import { IDivision } from '../../../models/common/division.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import divisionService from '../../../services/common/division/division.service'

const useDivision = () => {
    const divisions = useSelector<rootState, IDivision[]>(
        (state) => state.division.divisions
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.division.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.division.initialFetch
    )
    const division = useSelector<rootState, IDivision>(
        (state) => state.division.division
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.division.updateMode
    )

    const dispatch = useDispatch()

    const loadDivisions = useCallback(() => {
        if (initialFetch) {
            dispatch(divisionActions.fetchDivisionsAsync())
        }
    }, [dispatch, initialFetch])

    const addDivision = async (division: IDivision) => {
        return await divisionService
            .create(division)
            .then((divisionResponse) => {
                dispatch(divisionActions.addDivisionSuccess(divisionResponse.data))
                return true
            })
            .catch((error) => {
                // dispatch(divisionActions.)
                return false
            })
    }

    const setDivision = (division: IDivision) => {
        dispatch(divisionActions.setActivedivision(division))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(divisionActions.setDivisionUpdateMode(updateMode))
    }

    const editDivision = async (division: IDivision) => {
        return await divisionService
            .update(division)
            .then((divisionResponse) => {
                dispatch(divisionActions.editDivisionSuccess(divisionResponse.data))
                setDivision(divisionResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveDivision = async (division: IDivision, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addDivision(division)
            : await editDivision(division)
    }

    useEffect(() => {
        loadDivisions()
    }, [division, divisions, isLoading, initialFetch, loadDivisions])

    return {
        division,
        divisions,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addDivision,
        editDivision,
        saveDivision,
        setDivision,
    }
}

export { useDivision }
