import { apiRequests } from '../'
import { ISiteType, ISiteTypeResponse } from '../../models/budgeting/site-type.model'

const siteTypeService = {
    list: (): Promise<ISiteType[]> => apiRequests.get('/siteTypes'),
    details: (code: string): Promise<ISiteType> =>
        apiRequests.get(`/siteTypes/${code}`),
    create: (siteType: ISiteType): Promise<ISiteTypeResponse> =>
        apiRequests.post('/siteTypes', siteType),
    update: (siteType: ISiteType): Promise<ISiteTypeResponse> =>
        apiRequests.put('/siteTypes', siteType),
}

export default siteTypeService
