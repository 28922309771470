export interface IConfiguration {
    activityType: string
    activityTypes: string[]
    tranCode: string
    tranCodes: string[]
    project: string
    activeTab: string
    budgetAnalysis: string // 01 : Cost Category, 02 : Intervention, 03 : Site
    reportType: string // 01 : Site, 02 : Program, 03 : Zone
    dashboardName: string
    showDashboardName: boolean
    page: string
}

export const emptyConfiguration: IConfiguration = {
    activityType: '',
    activityTypes: [],
    tranCode: '',
    tranCodes: [],
    project: '',
    activeTab: '',
    showDashboardName: false,
    dashboardName: '',
    budgetAnalysis: '',
    reportType: '',
    page: '',
}

export interface IConfigurationState {
    readonly configuration: IConfiguration
}
