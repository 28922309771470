import { Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import React from 'react'
import { useMilestone } from '../../../hooks/budgeting/milestone.hook'
import { useSite } from '../../../hooks/budgeting/site.hook'
import { width100 } from '../../profile/profile.styles'

interface IProp {
    setShow: (show: boolean) => void
}

export const SelectSiteComponent: React.FC<IProp> = ({ setShow }) => {
    const { sites } = useSite()
    const { setMilestoneSite } = useMilestone()

    const handleOnChange = (
        value: string,
        option: DefaultOptionType | DefaultOptionType[]
    ) => {
        setMilestoneSite(value)
        setShow(true)
    }

    return (
        <Select
            style={{ ...width100, marginBottom: 5 }}
            disabled={false}
            onChange={handleOnChange}
            placeholder="Select site..."
        >
            {sites && sites.length > 0
                ? sites.map((site, index) => (
                      <Select.Option value={site.code} key={site.code + index}>
                          {site.code + ' - ' + site.description}
                      </Select.Option>
                  ))
                : []}
        </Select>
    )
}
