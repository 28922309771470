import React, { useEffect } from 'react'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import ModificationsFilterForm from '../../../components/modifications/modifications-filter-form.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { MODIFICATION_TYPE } from '../../../constants/configurations.constant'
import { useTranslation } from 'react-i18next'
import {
    IConfiguration,
    emptyConfiguration,
} from '../../../models/configuration.model'
import { useConfiguration } from '../../../hooks/configuration.hook'

const initialConfig: IConfiguration = {
    ...emptyConfiguration,
    activityType: '01',
    tranCode: '01',
    project: '01',
    activeTab: '',
}

const ZoneSiteModificationsPage: React.FC = () => {
    const { t } = useTranslation()
    const { setConfiguration } = useConfiguration()

    useEffect(() => {
        setConfiguration(initialConfig)
    }, [setConfiguration])
    return (
        <>
            <PageBreadCrumbs
                items={[
                    t('budgeting:modifications.zoneSiteBreadcrumb.modifications'),
                    t(
                        'budgeting:modifications.zoneSiteBreadcrumb.zoneModifications'
                    ),
                ]}
            />
            <TitleBar
                title={t('budgeting:modifications.regionMainPage.title')}
                subTitle={t('budgeting:modifications.regionMainPage.subTitle')}
            />
            <ModificationsFilterForm
                modificationType={MODIFICATION_TYPE.ZONE_SITE}
            />
        </>
    )
}

export default ZoneSiteModificationsPage
