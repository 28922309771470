import { ColumnsType } from 'antd/lib/table'
import { ICostCategoryMock } from '../../../mock-data/dashboards/cost-category.mock'

export const costCategoryTableColumns: ColumnsType<ICostCategoryMock> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Cost category name',
        dataIndex: 'costCatgory',
        key: 'costCatgory',
        width: '8rem',
        filtered: true,
    },
    {
        title: 'Budget code',
        dataIndex: 'budgetCode',
        key: 'budgetCode',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Component budget',
        dataIndex: 'componentBudget',
        key: 'componentBudget',
        width: '4rem',
        align: 'right',
        filtered: true,
    },
    {
        title: 'Component expenditure',
        dataIndex: 'componentExpenditure',
        key: 'componentExpenditure',
        width: '4rem',
        align: 'right',
        filtered: true,
    },
    {
        title: 'Month budget',
        dataIndex: 'monthBudget',
        key: 'monthBudget',
        width: '4rem',
        align: 'right',
        filtered: true,
    },
    {
        title: 'Month expenditure',
        dataIndex: 'monthExpenditure',
        key: 'monthExpenditure',
        width: '4rem',
        align: 'right',
        filtered: true,
    },
    {
        title: 'Week budget',
        dataIndex: 'weekBudget',
        key: 'weekBudget',
        width: '4rem',
        align: 'right',
        filtered: true,
    },
    {
        title: 'Week expenditure',
        dataIndex: 'weekExpenditure',
        key: 'weekExpenditure',
        width: '4rem',
        align: 'right',
        filtered: true,
    },
    {
        title: 'Percentage',
        dataIndex: 'percentage',
        key: 'percentage',
        width: '4rem',
        align: 'right',
        filtered: true,
        render: (_, row) => `${row.percentage}%`,
    },
]
