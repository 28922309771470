import { Card, Col, List, Row, Typography } from 'antd'
import React from 'react'
import { useRunnerPeriod } from '../../../hooks/budgeting/runner-period.hook'

const RunnerPeriodDetailComponent: React.FC = () => {
    const { runnerPeriod } = useRunnerPeriod()
    return (
        <Card bordered={false} size="small">
            <List
                size="small"
                dataSource={[
                    {
                        label: 'Code',
                        value: runnerPeriod.code,
                    },
                    {
                        label: 'Description',
                        value: runnerPeriod.description,
                    },
                ]}
                renderItem={(item) => (
                    <List.Item>
                        <Row style={{ width: '100%' }}>
                            <Col md={4}>
                                <Typography.Text>{item.label}</Typography.Text>
                            </Col>
                            <Col md={20}>
                                <Typography.Text>{item.value}</Typography.Text>
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />
        </Card>
    )
}

export default RunnerPeriodDetailComponent
