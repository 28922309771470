import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IChartSeries {
    name: string
    data: number[]
}

export const emptyChartSeries: IChartSeries = {
    name: '',
    data: [0],
}

export interface IChartSeriesState extends IBaseState {
    readonly components: IChartSeries[]
    readonly component: IChartSeries
}

export interface IChartSeriesResponse extends IResponseBase {
    data: IChartSeries
}
