import { useHistory } from 'react-router-dom'
import React from 'react'
import { useLanguage } from '../../../hooks/shared/language/language.hook'
import { Button } from 'antd'
import { FiArrowLeft } from 'react-icons/fi'

type Props = {
    page?: string
}

const BackButton: React.FunctionComponent<Props> = ({ page }) => {
    const router = useHistory()
    const { languageKey } = useLanguage()

    const bk = languageKey === 'en' ? 'Back to' : 'Retour à'
    const bkDefault = languageKey === 'en' ? 'Go back' : 'Retour'

    return (
        <Button
            type="link"
            style={{
                padding: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#778fad',
                marginBottom: 5,
            }}
            onClick={() => router.goBack()}
        >
            <span style={{ marginRight: '.32em' }}>
                <FiArrowLeft />
            </span>
            <span>{page ? `${bk} ${page}` : bkDefault}</span>
        </Button>
    )
}

export default BackButton
