import { Formik } from 'formik'
import React, { useContext } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useStationReference } from '../../../hooks/common/station-reference/station-reference.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import {
    emptyStationReference,
    IStationReference,
} from '../../../models/common/station-reference.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import StationReferenceFormFields from './station-reference-form-field.component'

interface IProp {
    updateMode: UpdateMode
}

const StationReferenceForm: React.FC<IProp> = ({ updateMode }) => {
    const { toastSuccess, toastError } = useToast()
    const { saveStationReference, stationReference } = useStationReference()
    const { setShow } = useContext<any>(AppModalContext)
    return (
        <div>
            <Formik<IStationReference>
                initialValues={stationReference || emptyStationReference}
                onSubmit={async (values, { setSubmitting }) => {
                    const feedback = await saveStationReference(values, updateMode)
                    if (feedback) {
                        toastSuccess('M0002')
                    } else {
                        toastError('M0003')
                    }
                    setSubmitting(false)
                    setShow(false)
                }}
            >
                <>
                    <StationReferenceFormFields />
                </>
            </Formik>
        </div>
    )
}
export default StationReferenceForm
