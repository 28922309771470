import { apiRequests } from '../'
import {
    IBudgetCode,
    IBudgetCodeResponse,
} from '../../models/budgeting/budget-code.model'

const budgetCodeService = {
    list: (): Promise<IBudgetCode[]> => apiRequests.get('/budgetCodes'),
    details: (code: string): Promise<IBudgetCode> =>
        apiRequests.get(`/budgetCodes/${code}`),
    create: (budgetCode: IBudgetCode): Promise<IBudgetCodeResponse> =>
        apiRequests.post('/budgetCodes', budgetCode),
    update: (budgetCode: IBudgetCode): Promise<IBudgetCodeResponse> =>
        apiRequests.put('/budgetCodes', budgetCode),
}

export default budgetCodeService
