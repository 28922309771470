import React from 'react'
import { Card, Col, Row } from 'antd'
import UserRolesRenderer from './user-role-renderer.component'
import { useUser } from '../../../hooks/shared/user/user.hook'

export const UserDetails: React.FC = () => {
    const { user } = useUser()

    return (
        <>
            <Card>
                <Row className="medical__condition_item">
                    <Col span={24} md={5}>
                        Code
                    </Col>
                    <Col span={24} md={18} className="sm-bgcolor">
                        {user.code}
                    </Col>
                    <Col span={24} md={5}>
                        Username
                    </Col>
                    <Col span={24} md={18} className="sm-bgcolor">
                        {user.usrName}
                    </Col>
                    <Col span={24} md={5}>
                        Roles
                    </Col>
                    <Col span={24} md={18} className="sm-bgcolor">
                        <UserRolesRenderer userCode={user.code} />
                    </Col>
                </Row>
            </Card>
        </>
    )
}
