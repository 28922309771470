import React from 'react'
// import { ProSidebar, SidebarHeader, SidebarFooter } from 'react-pro-sidebar'
import { Skeleton } from 'antd'
import SidebarSkeleton from '../sidebar/sidebar.skeleton'
// import SidebarSkeleton from "./sidebar-skeleton.component";

export const SidebarSkeletonContainer: React.FC = () => {
    return (
        <div>
            <SidebarSkeleton active={true} collapsed={false} />
            {/* sidebar footer */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '1rem',
                }}
            >
                <Skeleton.Button shape="square" /> <Skeleton.Input />
            </div>
        </div>
    )
}
