import { apiRequests } from '../..'
import { IClaim, IClaimResponse } from '../../../models/common/claim.model'

const claimService = {
    list: (): Promise<IClaim[]> => apiRequests.get('/systemMenus'),
    details: (code: string): Promise<IClaim> => apiRequests.get(`/systemMenu/${code}`),
    create: (claim: IClaim): Promise<IClaimResponse> =>
        apiRequests.post('/systemMenu', claim),
    update: (claim: IClaim): Promise<IClaimResponse> =>
        apiRequests.put('/systemMenu', claim),
}

export default claimService
