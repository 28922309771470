import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { emptyBudgetBase, IBudgetBase } from './budget-base.model'

export interface IBudget extends IBudgetBase {}

export const emptyBudget: IBudget = emptyBudgetBase

export interface IBudgetState extends IBaseState {
    readonly budgets: IBudget[]
    readonly budget: IBudget
}

export interface IBudgetResponse extends IResponseBase {
    data: IBudget
}
