import React, { useState } from 'react'
import { Layout, Skeleton } from 'antd'
import Wrapper from '../../components/common/wrapper-class/wrapper.component'
import AppModalProvider from '../../contexts/common/app-modal.context'
import AppHeaderSkeleton from './app-header-skeleton.component'
import { SidebarSkeletonContainer } from './sidebar-skeleton-container.component'
import InfoBarSkeleton from '../../components/shared/skeleton/info-bar/info-bar-skeleton.component'

const AppShellSkeleton: React.FC = ({ children }) => {
    const { Footer } = Layout
    const [toggled, setToggled] = useState(false)

    const handleToggleSidebar = (value: boolean) => {
        setToggled(value)
    }

    return (
        <AppModalProvider>
            {/* App Header Skeleton */}
            <AppHeaderSkeleton handleToggleSidebar={handleToggleSidebar} />
            {/* <Layout className="site-layout"> */}
            <div className={`grid-container ${toggled ? 'toggled' : ''}`}>
                <SidebarSkeletonContainer />
                <div
                    className="grid-item main-content"
                    style={{ background: '#f0f2f5' }}
                >
                    <>
                        <InfoBarSkeleton />
                        <Wrapper>
                            <div className="logo__container">
                                <img
                                    src="/cbchs-logo.png"
                                    alt="cbchs logo"
                                    className="logo__backdrop"
                                />
                            </div>

                            <div className="app__main__content">{children}</div>
                        </Wrapper>
                        <Footer style={{ textAlign: 'center' }}>
                            <Skeleton.Input />
                        </Footer>
                    </>
                </div>
            </div>
        </AppModalProvider>
    )
}

export default AppShellSkeleton
