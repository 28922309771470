import { appRoutes } from '../../../constants/app-route'
import { IRoute } from '../../../models/common/route.model'
import RolePage from '../../../pages/common/role-management/role-management'
import ManageUserSites from '../../../pages/common/user/manage-user-sites.page'
import UserDetailsPage from '../../../pages/common/user/user-details.page'
import UserPage from '../../../pages/common/user/user.page'
import ClaimPage from '../../../pages/common/claim/claim.page'
import { UserCoordinationPage } from '../../../pages/common/user-coordination/user-coordination.page'

export const settingsRoutes: IRoute[] = [
    /**
     * Modification routes
     */
    {
        path: appRoutes.SETTINGS.USERS,
        private: true,
        exact: true,
        component: UserPage,
    },
    {
        path: appRoutes.SETTINGS.ROLE_MANAGEMENT,
        private: true,
        exact: true,
        component: RolePage,
    },
    {
        path: appRoutes.SETTINGS.CLAIMS,
        private: true,
        exact: true,
        component: ClaimPage,
    },
    {
        path: appRoutes.SETTINGS.USER_COORDINATION,
        private: true,
        exact: true,
        component: UserCoordinationPage,
    },
    {
        path: '/users',
        private: true,
        exact: true,
        component: UserPage,
    },

    {
        path: '/users/:code',
        private: true,
        exact: true,
        component: UserDetailsPage,
    },
    {
        path: '/manage-user-sites/:code',
        private: true,
        exact: true,
        component: ManageUserSites,
    },
]
