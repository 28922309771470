import { Reducer } from 'redux'
import { ITokenState } from '../../../models/shared/token.model'
import { TokenActionTypes, TokenActionsCreators } from './token.types'

export const INITIAL_STATE: ITokenState = {
    errors: undefined,
    token: { accessToken: '', email: '' },
    isLoading: true,
    initialFetch: true,
}

const reducer: Reducer<ITokenState> = (
    state = INITIAL_STATE,
    action: TokenActionsCreators
): ITokenState => {
    switch (action.type) {
        case TokenActionTypes.TOKEN_FETCH_REQUEST: {
            return { ...state, isLoading: true }
        }
        case TokenActionTypes.TOKEN_FETCH_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                initialFetch: false,
                token: action.payload,
            }
        }
        case TokenActionTypes.TOKEN_FETCH_ERROR: {
            return {
                ...state,
                isLoading: false,
                errors: action.payload,
            }
        }
        default:
            return state
    }
}

export { reducer as tokenReducer }
