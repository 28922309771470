export const frBudget = {
    tenant: 'Locataire',
    copYear: 'Année composante',
    project: 'Projet',
    region: 'Région',
    activity: 'Activité',
    component: 'Composante',
    amount: 'Montante',
    createdOn: 'Créé sur',
}
