export const enSideBarMenus = {
    A: 'Home',
    B: 'Dashboard',
    B00: 'Outlay vs Target',
    B01: 'Cost category',
    B02: 'Intervention',
    B03: 'Milestone Projections',
    B04: 'Daily report',

    // Activities
    C: ' HIV Activities',
    C00: 'Site Activities',
    C01: 'Milestone Projections',
    C02: 'Milestone Archievement',
    C03: 'BO Program Management',
    C04: 'DHS Program Management',
    C05: 'Mvan Program Management',
    C06: 'HIV Regional Modifications',
    C07: 'HIV Zone Site Modifications',
    C08: 'HIV Zone Above Site Modifications',

    D: 'Laboratory Activities',
    D00: 'Lab Site Activities',
    D01: 'Lab Milestone Projections',
    D02: 'Lab Milestone Achievements',
    D03: 'Lab BO Program Management',
    D04: 'Lab DHS Program Management',
    D05: 'Lab MVAN Program Management',
    D06: 'Lab Regional Modifications',
    D07: 'Lab Zone Site Modifications',
    D08: 'Lab Zone Above Site Modifications',

    E: 'Runners',
    E00: 'Runner Period',
    E01: 'Runner',

    //Site reports
    F: 'Site Reports',
    F00: 'BA Cost Category',
    F01: 'BA Intervention',
    F02: 'BA Site',
    F03: 'Budget Execution',
    F04: 'CDV ER Template',
    F05: 'CDV ACC Template',
    F06: 'Costed Work Plan',

    // Program reports
    G: 'Program Reports',
    G00: 'BA Cost Category',
    G01: 'BA Intervention',
    G02: 'BA Site',
    G03: 'Budget Execution',
    G04: 'Costed Work Plan',

    // Program reports
    H: 'Zone Reports',
    H00: 'BA Approach',
    H01: 'BA Intervention',
    H02: 'BA Site',
    H03: 'BA Cost Category',

    // Setting
    I: 'Settings',
    I00: 'Users',
    I01: 'Role Management',
    I02: 'Claims',
    I03: 'User Coordination',

    // Modifications
    J: 'Surge activities',
    J00: 'Site activities',
    J01: 'Zone above site',
    J02: 'Zone site',

    //faqs
    K:'Faqs',
    K00: 'Ticket',
    K01: 'Ticket Status'
}
