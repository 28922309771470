import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IWeek {
    weekSerial: number
    weekSerialAdjusted: number
    weekStartDate: Date
    weekEndDate: Date
}

export const emptyWeek: IWeek = {
    weekSerial: 0,
    weekSerialAdjusted: 0,
    weekStartDate: new Date(),
    weekEndDate: new Date(),
}

export interface IWeekState extends IBaseState {
    readonly weeks: IWeek[]
    readonly week: IWeek
}

export interface IWeekResponse extends IResponseBase {
    data: IWeek
}
