export const BUDGET_ANALYSIS = {
    COST_CATEGORY: '01',
    INTERVENTION: '02',
    SITE: '03',
}

export const REPORT_TYPE = {
    SITE: '01',
    PROGRAM: '02',
    ZONE: '03',
}

export const RUNNER_STATUS = {
    OPEN: '60',
    CLOSED: '62',
    REOPENED: '61',
    ARCHIVED: '63',
}

export const RUNNER_STATUS_TYPE: Record<string, string> = {
    '60': 'OPEN',
    '62': 'CLOSED',
    '61': 'REOPENED',
    '63': 'ARCHIVED',
}

export const ACTIVITY_TYPE = {
    SITE: '01',
    PROGRAM: '02',
}
export const ACTIVITY_TYPE_RECORD: Record<string, string> = {
    '01': 'Site',
    '02': 'Program',
}

export const MODIFICATION_TYPE = {
    ZONE_SITE: '01',
    ZONE_ABOVE_SITE: '02',
    REGION: '03',
}
