import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as runnerPeriodActions from '../../redux/budgeting/runner-period.slice'
import { IRunnerPeriod } from '../../models/budgeting/runner-period.model'
import { UpdateMode } from '../../models/update-mode.enum'
import runnerPeriodService from '../../services/budgeting/runner-period.service'
import { useFormErrors } from '../common/form/form-error.hook'
import { useRunner } from './runner.hook'
import { useRunnerArchive } from './runner-archive.hook'

const useRunnerPeriod = () => {
    const runnerPeriods = useSelector<rootState, IRunnerPeriod[]>(
        (state) => state.runnerPeriod.runnerPeriods
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.runnerPeriod.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.runnerPeriod.initialFetch
    )
    const runnerPeriod = useSelector<rootState, IRunnerPeriod>(
        (state) => state.runnerPeriod.runnerPeriod
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.runnerPeriod.updateMode
    )
    const { runnerCodes } = useRunnerArchive()
    const { runner } = useRunner()

    const dispatch = useDispatch()
    const { setformError } = useFormErrors()

    const reloadRunnerPeriods = useCallback(() => {
        dispatch(runnerPeriodActions.fetchRunnerPeriodsAsync())
    }, [dispatch])

    const loadRunnerPeriods = useCallback(() => {
        if (initialFetch) {
            reloadRunnerPeriods()
        }
    }, [initialFetch, reloadRunnerPeriods])

    const addRunnerPeriod = async (runnerPeriod: IRunnerPeriod) => {
        return await runnerPeriodService
            .create(runnerPeriod)
            .then((runnerPeriodResponse) => {
                console.log('runnerPeriodResponse')
                dispatch(
                    runnerPeriodActions.addRunnerPeriodSuccess(
                        runnerPeriodResponse.data
                    )
                )
                return true
            })
            .catch((error) => {
                console.log(error)
                setformError({
                    message: 'failed successfully!',
                    success: false,
                    validationErrors: [...error],
                })
                return false
            })
    }

    const setRunnerPeriod = (runnerPeriod: IRunnerPeriod) => {
        dispatch(runnerPeriodActions.setActiveRunnerPeriod(runnerPeriod))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(runnerPeriodActions.setRunnerPeriodUpdateMode(updateMode))
    }

    const editRunnerPeriod = async (runnerPeriod: IRunnerPeriod) => {
        return await runnerPeriodService
            .update(runnerPeriod)
            .then((runnerPeriodResponse) => {
                dispatch(
                    runnerPeriodActions.editRunnerPeriodSuccess(
                        runnerPeriodResponse.data
                    )
                )
                setRunnerPeriod(runnerPeriodResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const archiveRunnerPeriod = async (runnerPeriod: IRunnerPeriod) => {
        return await runnerPeriodService
            .archive(runnerPeriod)
            .then((runnerPeriodResponse) => {
                dispatch(
                    runnerPeriodActions.editRunnerPeriodSuccess(
                        runnerPeriodResponse.data
                    )
                )
                setRunnerPeriod(runnerPeriodResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveRunnerPeriod = async (
        runnerPeriod: IRunnerPeriod,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addRunnerPeriod(runnerPeriod)
            : await editRunnerPeriod(runnerPeriod)
    }

    type historyProps = {
        project: string
        copYear: string
        region: string
    }

    const getHistoryCodes = useCallback(async (options: historyProps) => {
        const response = await runnerPeriodService.historyCodes(options)
        return response
    }, [])

    const getRunnerPeriods = useCallback((): IRunnerPeriod[] => {
        return runnerPeriods.filter((x) => !runnerCodes.includes(x.code))
    }, [runnerCodes, runnerPeriods])

    useEffect(() => {
        loadRunnerPeriods()
    }, [runnerPeriod, runnerPeriods, isLoading, initialFetch, loadRunnerPeriods])

    return {
        runnerPeriod,
        runnerPeriods:getRunnerPeriods(),
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addRunnerPeriod,
        editRunnerPeriod,
        saveRunnerPeriod,
        setRunnerPeriod,
        reloadRunnerPeriods,
        archiveRunnerPeriod,
        getHistoryCodes,
    }
}

export { useRunnerPeriod }
