import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import userBranchService from '../../../services/common/user-branch/user-branch.service'
import * as userBranchActions from '../../../redux/common/user-branch/user-branch.slice'
import {
    IUserBranch,
    IUserBranchDto,
} from '../../../models/common/user-branch.model'
import { rootState } from '../../../redux/root-reducer'
import { UpdateMode } from '../../../models/update-mode.enum'
import { useFormErrors } from '../form/form-error.hook'

const useUserBranch = () => {
    const userBranches = useSelector<rootState, IUserBranch[]>(
        (state) => state.userBranch.userBranches
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.userBranch.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.userBranch.initialFetch
    )
    const userBranch = useSelector<rootState, IUserBranch>(
        (state) => state.userBranch.userBranch
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.userBranch.updateMode
    )

    const dispatch = useDispatch()
    const { setformError } = useFormErrors()

    const loadUserBranches = useCallback(() => {
        if (initialFetch) {
            dispatch(userBranchActions.fetchUserBranchesAsync())
        }
    }, [dispatch, initialFetch])

    const addUserBranch = async (userBranch: IUserBranchDto) => {
        return await userBranchService
            .create(userBranch)
            .then((userBranchResponse) => {
                // dispatch(
                //     userBranchActions.addUserBranchSuccess(userBranchResponse.data)
                // )
                dispatch(
                    userBranchActions.fetchUserBranchesSuccess([
                        ...userBranches,
                        ...userBranchResponse.userBranches,
                    ])
                )
                return userBranchResponse.success
            })
            .catch((error) => {
                setformError({
                    message: 'Failed Successfully!',
                    success: false,
                    validationErrors: [...error],
                })
                return false
            })
    }

    const setUserBranch = (userBranch: IUserBranch) => {
        dispatch(userBranchActions.setActiveUserBranch(userBranch))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(userBranchActions.setUserBranchUpdateMode(updateMode))
    }

    const editUserBranch = async (userBranch: IUserBranchDto) => {
        return await userBranchService
            .update(userBranch)
            .then((userBranchResponse) => {
                // dispatch(
                //     userBranchActions.editUserBranchSuccess(userBranchResponse.data)
                // )
                // setUserBranch(userBranchResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveUserBranch = async (
        userBranch: IUserBranchDto,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addUserBranch(userBranch)
            : await editUserBranch(userBranch)
    }

    useEffect(() => {
        loadUserBranches()
    }, [userBranch, userBranches, isLoading, initialFetch, loadUserBranches])

    return {
        userBranch,
        userBranches,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addUserBranch,
        editUserBranch,
        saveUserBranch,
        setUserBranch,
    }
}

export { useUserBranch }
