import { apiRequests } from '../..'
import { IReligion, IReligionResponse } from '../../../models/common/religion.model'

const religionService = {
    list: (): Promise<IReligion[]> => apiRequests.get('/religions'),
    details: (code: string): Promise<IReligion> =>
        apiRequests.get(`/religions/${code}`),
    create: (religion: IReligion): Promise<IReligionResponse> =>
        apiRequests.post('/religions', religion),
    update: (religion: IReligion): Promise<IReligionResponse> =>
        apiRequests.put('/religions', religion),
}

export default religionService
