import { Alert } from 'antd'
import Idle from 'react-idle'
import { useAuthentication } from '../../../hooks/auth.hook'
import Countdown from 'antd/lib/statistic/Countdown'

const CheckIdleUser = () => {
    const { signOut } = useAuthentication()

    return (
        <Idle
            timeout={300_000}
            render={({ idle }: { idle: boolean }) => (
                <>
                    {idle && (
                        <Alert
                            message="You have been inactive for a while. Session ends in few minutes"
                            description={
                                <Countdown
                                    style={{ fontSize: '1rem' }}
                                    value={Date.now() + 1000 * 60 * 5}
                                    format="mm:ss"
                                    onFinish={signOut}
                                />
                            }
                            type="warning"
                            style={{ marginBottom: '1rem' }}
                        />
                    )}
                </>
            )}
        />
    )
}
export default CheckIdleUser
