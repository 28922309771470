import { PlusOutlined } from '@ant-design/icons'
import { Tabs } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import RoleClaimsTab from '../../../components/common/role-management/role-claims-tab.component'
import RoleForm from '../../../components/common/role-management/role-form.component'
import UserRoleTab from '../../../components/common/role-management/role-user-tab.component'
import { RolesTable } from '../../../components/common/role-management/roles.table.component'
import RoleUserTab from '../../../components/common/role-management/user-role-tab.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import { NoContent } from '../../../components/shared/no-content/no-content.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useRole } from '../../../hooks/common/role/role.hook'
import { emptyRole } from '../../../models/common/role.model'
import { UpdateMode } from '../../../models/update-mode.enum'

const { TabPane } = Tabs

const RolePage: React.FC = () => {
    const { t } = useTranslation()
    const { setUpdateMode, setRole, roles } = useRole()
    const { setContent, setShow, setTitle } = useContext<any>(AppModalContext)

    const handleShow = () => {
        setUpdateMode(UpdateMode.ADD)
        setTitle(t('common:role.roleMgtForm.title'))
        setContent(<RoleForm />)
        setRole({
            ...emptyRole,
        })
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs
                items={[
                    t('common:role.breadcrumb.roleMgt'),
                    t('common:role.breadcrumb.role'),
                ]}
            />
            {roles && roles.length > 0 ? (
                <>
                    <TitleBar
                        title={t('common:role.form.title')}
                        showButton={true}
                        buttonLabel={t('common:role.form.titleAdd')}
                        handleShow={handleShow}
                        icon={<PlusOutlined />}
                    />
                    <Tabs defaultActiveKey="1" type="line" size="small" style={{marginBottom:'5rem'}}>
                        <TabPane tab={t('common:role.tabPane.roles')} key="1">
                            <RolesTable />
                        </TabPane>

                        <TabPane tab={t('common:role.tabPane.RolePrv')} key="2">
                            {/* privileges table */}
                            <RoleClaimsTab />
                        </TabPane>
                        <TabPane tab={t('common:role.tabPane.userRolMgt')} key="3">
                            <UserRoleTab />
                        </TabPane>
                        <TabPane tab={t('common:role.tabPane.roleUserAs')} key="4">
                            <RoleUserTab />
                        </TabPane>
                    </Tabs>
                </>
            ) : (
                <NoContent
                    title="No Data Here"
                    showButton
                    handleClick={handleShow}
                />
            )}
        </>
    )
}

export default RolePage
