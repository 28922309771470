import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IMilestone {
    tenant: string
    runner: string
    region: string
    project: string
    activity: string
    site: string
    budgetNote: string
    target: number
    achievement: number
    budget: number
    createdOn: Date
}

export const emptyMilestone: IMilestone = {
    tenant: '',
    runner: '',
    region: '',
    project: '',
    activity: '',
    site: '',
    budgetNote: '',
    target: 0,
    achievement: 0,
    budget: 0,
    createdOn: new Date(),
}
export interface IMilestoneDashboard extends IMilestone {
    interventionCode: string
    interventionName: string
    reportId?: string;
}
export interface IMilestoneState extends IBaseState {
    readonly milestones: IMilestone[]
    readonly milestone: IMilestone
    readonly site: string
}

export interface IMilestoneResponse extends IResponseBase {
    data: IMilestone
}
