import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as componentModel from '../../models/budgeting/component.model'
import componentService from '../../services/budgeting/component.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: componentModel.IComponentState = {
    components: [],
    errors: '',
    component: componentModel.emptyComponent,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchComponentsAsync = createAsyncThunk<
    componentModel.IComponent[],
    void
>('component/fetchComponentsAsync', async (_, thunkApi) => {
    try {
        return await componentService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const componentSlice = createSlice({
    name: 'component',
    initialState,
    reducers: {
        fetchcomponentRequest: (state) => {
            state.isLoading = true
        },
        fetchcomponentSuccess: (
            state,
            action: PayloadAction<componentModel.IComponent[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.components = action.payload
        },
        fetchcomponentError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editComponentSuccess: (
            state,
            action: PayloadAction<componentModel.IComponent>
        ) => {
            state.components = state.components.map((component) => {
                return component.code === action.payload.code
                    ? action.payload
                    : component
            })
            state.updateMode = UpdateMode.NONE
        },
        addComponentSuccess: (
            state,
            action: PayloadAction<componentModel.IComponent>
        ) => {
            state.components = [...state.components, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveComponent: (
            state,
            action: PayloadAction<componentModel.IComponent>
        ) => {
            state.component = action.payload
        },
        setComponentUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchComponentsAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchComponentsAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.components = action.payload
        })
        builder.addCase(fetchComponentsAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchcomponentRequest,
    fetchcomponentSuccess,
    fetchcomponentError,
    editComponentSuccess,
    addComponentSuccess,
    setActiveComponent,
    setComponentUpdateMode,
} = componentSlice.actions

const reducer = componentSlice.reducer

export { reducer as componentReducer }
