import { Table } from 'antd'
import {
    costCategoryMockData,
    ICostCategoryMock,
} from '../../../mock-data/dashboards/cost-category.mock'
import ExcelExportWrapper from '../../reports/excel-export-wrapper.component'
import { NoContent } from '../../shared/no-content/no-content.component'
import { costCategoryTableColumns } from './cost-category-table.column'

const CostCategoryTable: React.FC = () => {
    return (
        <>
            {costCategoryMockData && costCategoryMockData.length ? (
                <ExcelExportWrapper
                    columns={costCategoryTableColumns}
                    data={costCategoryMockData}
                >
                    <Table<ICostCategoryMock>
                        dataSource={costCategoryMockData}
                        columns={costCategoryTableColumns}
                        size={'small'}
                        scroll={{ x: '500px' }}
                        rowKey={'code'}
                        pagination={false}
                    />
                </ExcelExportWrapper>
            ) : (
                <NoContent title="No data for Outlay vs target" showButton={false} />
            )}
        </>
    )
}

export default CostCategoryTable
