import { apiRequests } from '../'
import {
    IRunnerComponent,
    IRunnerComponentResponse,
} from '../../models/budgeting/runner-component.model'

const runnerComponentService = {
    list: (): Promise<IRunnerComponent[]> => apiRequests.get('/runnerComponents'),
    details: (code: string): Promise<IRunnerComponent> =>
        apiRequests.get(`/runnerComponents/${code}`),
    create: (runnerComponent: IRunnerComponent): Promise<IRunnerComponentResponse> =>
        apiRequests.post('/runnerComponents', runnerComponent),
    update: (runnerComponent: IRunnerComponent): Promise<IRunnerComponentResponse> =>
        apiRequests.put('/runnerComponents', runnerComponent),
}

export default runnerComponentService
