import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IUserCoordination {
    user: string
    coordination: string
    createdOn?: Date
}

export interface IUserCoordinationState extends IBaseState {
    readonly userCoordinations: IUserCoordination[]
    readonly userCoordination: IUserCoordination
}

export const emptyUserCoordination: IUserCoordination = {
    user: '',
    coordination: '',
    createdOn: new Date(),
}

export interface IUserCoordinationResponse extends IResponseBase {
    data: IUserCoordination
    userCoordinations: IUserCoordination[]
}