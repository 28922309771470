import { apiRequests } from '../'
import {
    IRunnerStatus,
    IRunnerStatusResponse,
} from '../../models/budgeting/runner-status.model'

const runnerStatusService = {
    list: (): Promise<IRunnerStatus[]> => apiRequests.get('/runnerStatuses'),
    details: (code: string): Promise<IRunnerStatus> =>
        apiRequests.get(`/runnerStatuses/${code}`),
    create: (runnerStatus: IRunnerStatus): Promise<IRunnerStatusResponse> =>
        apiRequests.post('/runnerStatuss', runnerStatus),
    update: (runnerStatus: IRunnerStatus): Promise<IRunnerStatusResponse> =>
        apiRequests.put('/runnerStatuses', runnerStatus),
}

export default runnerStatusService
