import { Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton, ResetButton, Select } from 'formik-antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useActivityPlan } from '../../../hooks/budgeting/activity-plan.hook'
import { useRunnerHistory } from '../../../hooks/budgeting/runner-history.hook'
import { useSite } from '../../../hooks/budgeting/site.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useConfiguration } from '../../../hooks/configuration.hook'
import { months } from '../../../mock-data/month/Month'
import { IActivity } from '../../../models/budgeting/activity.model'
import { IRunnerPeriod } from '../../../models/budgeting/runner-period.model'
import {
    FlexedDiv,
    FormLabels,
    SizedLabel,
    width100,
} from '../../profile/profile.styles'
import { useActivity } from '../../../hooks/budgeting/activity.hook'

const { TextArea } = Input
const FormItem = Form.Item

const ActivityHistoryFormFields: React.FC = () => {
    const { isSubmitting, setFieldValue } = useFormikContext<IActivity>()
    const { t } = useTranslation()
    const { activityPlans } = useActivityPlan()
    const { sites } = useSite()
    const { activity } = useActivity()

    const { configuration } = useConfiguration()
    const { runnerHistories } = useRunnerHistory()
    const { currentBranch } = useCurrentBranch()
    const { setValues } = useFormikContext<IActivity>()

    const [runnerHistoryState, setRunnerHistoryState] =
        useState<IRunnerPeriod[]>(runnerHistories)

    const handleMonthOnChange = useCallback(
        (month: any) => {
            let newArr: IRunnerPeriod[] = []

            if (runnerHistories && runnerHistories.length > 0) {
                runnerHistories.filter((r) => {
                    const str = r.code.slice(0, -2)
                    const [a, b, ...rest] = str.split('')

                    const monthCode = rest.toString().split(',').join('')
                    if (monthCode === month) {
                        return newArr.push(r)
                    }
                    return newArr
                })
            }
            const nwRunners = newArr.filter((a) => a.region === currentBranch.region)
            setRunnerHistoryState(nwRunners)
            setFieldValue('month', month.value)
        },
        [runnerHistories]
    )

    const handelSearch = (value: string) => {
        console.log(value)
    }

    // setValues(activity)
    useEffect(() => {
    }, [activity])
    return (
        <>
            <Form autoComplete="off">
                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:activity.form.description')}
                        </FormLabels>
                        <FormItem
                            name="description"
                            required={true}
                            style={{ marginBottom: 5 }}
                        >
                            <TextArea
                                name="description"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                {configuration.activityType === '02' ? (
                    <FlexedDiv>
                        <SizedLabel>
                            <FormLabels className="mb-0">
                                {t('budgeting:activity.form.month')}
                            </FormLabels>
                            <FormItem name="month" style={{ marginBottom: 5 }}>
                                <Select
                                    name="month"
                                    style={width100}
                                    disabled={isSubmitting}
                                    onChange={handleMonthOnChange}
                                >
                                    {months && months.length > 0
                                        ? months.map((month, index) => (
                                              <Select.Option
                                                  value={month.code}
                                                  key={month.code + index}
                                              >
                                                  {month.name}
                                              </Select.Option>
                                          ))
                                        : []}
                                </Select>
                            </FormItem>
                        </SizedLabel>

                        <SizedLabel>
                            <FormLabels className="mb-0">
                                {t('budgeting:activity.form.runner')}
                            </FormLabels>
                            <FormItem name="runner" style={{ marginBottom: 5 }}>
                                <Select
                                    name="runner"
                                    style={width100}
                                    disabled={isSubmitting}
                                >
                                    {runnerHistoryState &&
                                    runnerHistoryState.length > 0
                                        ? runnerHistoryState.map((r, index) => (
                                              <Select.Option
                                                  value={r.code}
                                                  key={r.code + index}
                                              >
                                                  {r.description}
                                              </Select.Option>
                                          ))
                                        : []}
                                </Select>
                            </FormItem>
                        </SizedLabel>
                    </FlexedDiv>
                ) : (
                    <></>
                )}
                <FlexedDiv>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:activity.form.activity')}
                        </FormLabels>
                        <FormItem name="activity" style={{ marginBottom: 5 }}>
                            <Select
                                name="activity"
                                style={width100}
                                disabled={isSubmitting}
                                showSearch
                                onSearch={handelSearch}
                                optionFilterProp="children"
                            >
                                {activityPlans && activityPlans.length > 0
                                    ? activityPlans.map((plan, index) => (
                                          <Select.Option
                                              value={plan.code}
                                              key={plan.code + index}
                                          >
                                              {plan.code}
                                          </Select.Option>
                                      ))
                                    : []}
                            </Select>
                        </FormItem>
                    </SizedLabel>
                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:activity.form.site')}
                        </FormLabels>
                        <FormItem name="site" style={{ marginBottom: 5 }}>
                            <Select
                                name="site"
                                style={width100}
                                disabled={isSubmitting}
                                showSearch
                            >
                                {sites && sites.length > 0
                                    ? sites.map((site, index) => (
                                          <Select.Option
                                              value={site.code}
                                              key={site.code + index}
                                          >
                                              {site.code}-{site.description}
                                          </Select.Option>
                                      ))
                                    : []}
                            </Select>
                        </FormItem>
                    </SizedLabel>

                    <SizedLabel>
                        <FormLabels className="mb-0">
                            {t('budgeting:activity.form.amount')}
                        </FormLabels>
                        <FormItem name="amount" style={{ marginBottom: 5 }}>
                            <Input
                                name="amount"
                                style={width100}
                                disabled={isSubmitting}
                            />
                        </FormItem>
                    </SizedLabel>
                </FlexedDiv>

                <Space size={'small'} style={{ marginTop: 10 }}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('budgeting:activity.form.submit')}
                    </SubmitButton>
                    <ResetButton disabled={isSubmitting}>
                        {t('budgeting:activity.form.reset')}
                    </ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default ActivityHistoryFormFields
