import { apiRequests } from '../..'
import { IDivision, IDivisionResponse } from '../../../models/common/division.model'

const divisionService = {
    list: (): Promise<IDivision[]> => apiRequests.get('/divisions'),
    details: (code: string): Promise<IDivision> =>
        apiRequests.get(`/divisions/${code}`),
    create: (division: IDivision): Promise<IDivisionResponse> =>
        apiRequests.post('/divisions', division),
    update: (division: IDivision): Promise<IDivisionResponse> =>
        apiRequests.put('/divisions', division),
}

export default divisionService
