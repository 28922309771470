import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { emptyBudgetBase, IBudgetBase } from './budget-base.model'

export interface IBudgetHistory extends IBudgetBase {}

export const emptyBudgetHistory: IBudgetHistory = emptyBudgetBase

export interface IBudgetHistoryState extends IBaseState {
    readonly budgetHistories: IBudgetHistory[]
    readonly budgetHistory: IBudgetHistory
}

export interface IBudgetHistoryResponse extends IResponseBase {
    data: IBudgetHistory
}
