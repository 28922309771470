import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as projectModel from '../../models/budgeting/project.model'
import projectService from '../../services/budgeting/project.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: projectModel.IProjectState = {
    projects: [],
    errors: '',
    project: projectModel.emptyProject,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchprojectAsync = createAsyncThunk<projectModel.IProject[], void>(
    'project/fetchprojectAsync',
    async (_, thunkApi) => {
        try {
            return await projectService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        fetchprojectRequest: (state) => {
            state.isLoading = true
        },
        fetchprojectSuccess: (
            state,
            action: PayloadAction<projectModel.IProject[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.projects = action.payload
        },
        fetchprojectError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editProjectSuccess: (
            state,
            action: PayloadAction<projectModel.IProject>
        ) => {
            state.projects = state.projects.map((project) => {
                return project.code === action.payload.code
                    ? action.payload
                    : project
            })
            state.updateMode = UpdateMode.NONE
        },
        addProjectSuccess: (state, action: PayloadAction<projectModel.IProject>) => {
            state.projects = [...state.projects, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveProject: (state, action: PayloadAction<projectModel.IProject>) => {
            state.project = action.payload
        },
        setProjectUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchprojectAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchprojectAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.projects = action.payload
        })
        builder.addCase(fetchprojectAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchprojectRequest,
    fetchprojectSuccess,
    fetchprojectError,
    editProjectSuccess,
    addProjectSuccess,
    setActiveProject,
    setProjectUpdateMode,
} = projectSlice.actions

const reducer = projectSlice.reducer

export { reducer as projectReducer }
