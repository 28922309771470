import { Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton, ResetButton, Select } from 'formik-antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBranch } from '../../../hooks/common/branch.hook'
import { IBranchStation } from '../../../models/common/branch-station.model'

const FormItem = Form.Item
const BranchStationFormFields: React.FC = () => {
    const { isSubmitting } = useFormikContext<IBranchStation>()
    const { t } = useTranslation()
    const { branches } = useBranch()
    return (
        <>
            <Form autoComplete="off">
                <p className="mb-0">{t('common:branchStation.branch')}</p>
                <FormItem name="branch" required={true}>
                    <Select name="branch">
                        {branches.map((b, index) => (
                            <Select.Option key={index} value={b.code}>
                                {b.branchName}
                            </Select.Option>
                        ))}
                    </Select>
                </FormItem>

                <p className="mb-0">{t('common:branchStation.station')}</p>
                <FormItem name="station" required={true}>
                    <Input name="station" disabled={isSubmitting} required={true} />
                </FormItem>

                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('common:branchStation.submit')}
                    </SubmitButton>
                    <ResetButton disabled={isSubmitting}>
                        {t('common:branchStation.reset')}
                    </ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default BranchStationFormFields
