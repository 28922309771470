import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IUserBranch {
    tenant: string;
    usrCode: string;
    branchCode: string;
    createdOn: Date;
}

export interface IUserBranchDto {
    tenant: string
    user: string
    branches: string[]
}


export interface IUserBranchState extends IBaseState {
    readonly userBranches: IUserBranch[]
    readonly userBranch: IUserBranch
}

export const emptyUserBranch: IUserBranch = {
    tenant: '',
    usrCode: '',
    branchCode: '',
    createdOn: new Date()
}

export interface IUserBranchResponse extends IResponseBase {
    data: IUserBranchDto,
    userBranches: IUserBranch[]
}
