import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as siteModel from '../../models/budgeting/site.model'
import siteService from '../../services/budgeting/site.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: siteModel.ISiteState = {
    sites: [],
    errors: '',
    site: siteModel.emptySite,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchSitesAsync = createAsyncThunk<siteModel.ISite[], string>(
    'site/fetchSitesAsync',
    async (region: string, thunkApi) => {
        try {
            return await siteService.list(region)
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        fetchsiteRequest: (state) => {
            state.isLoading = true
        },
        fetchsiteSuccess: (state, action: PayloadAction<siteModel.ISite[]>) => {
            state.isLoading = false
            state.initialFetch = false
            state.sites = action.payload
        },
        fetchsiteError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editSiteSuccess: (state, action: PayloadAction<siteModel.ISite>) => {
            state.sites = state.sites.map((site) => {
                return site.code === action.payload.code ? action.payload : site
            })
            state.updateMode = UpdateMode.NONE
        },
        addSiteSuccess: (state, action: PayloadAction<siteModel.ISite>) => {
            state.sites = [...state.sites, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveSite: (state, action: PayloadAction<siteModel.ISite>) => {
            state.site = action.payload
        },
        setSiteUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSitesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchSitesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.sites = action.payload
        })
        builder.addCase(fetchSitesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchsiteRequest,
    fetchsiteSuccess,
    fetchsiteError,
    editSiteSuccess,
    addSiteSuccess,
    setActiveSite,
    setSiteUpdateMode,
} = siteSlice.actions

const reducer = siteSlice.reducer

export { reducer as siteReducer }
