import { apiRequests } from '../'
import {
    IRunnerHistory,
    IRunnerHistoryResponse,
} from '../../models/budgeting/runner-history.model'

const runnerHistoryService = {
    list: (): Promise<IRunnerHistory[]> => apiRequests.get('/runnerHistories'),
    details: (code: string): Promise<IRunnerHistory> =>
        apiRequests.get(`/runnerHistories/${code}`),
    create: (runnerHistory: IRunnerHistory): Promise<IRunnerHistoryResponse> =>
        apiRequests.post('/runnerHistories', runnerHistory),
    update: (runnerHistory: IRunnerHistory): Promise<IRunnerHistoryResponse> =>
        apiRequests.put('/runnerHistories', runnerHistory),
}

export default runnerHistoryService
