import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as outlayDashActions from '../../redux/budgeting/outlay-dash.slice'
import { UpdateMode } from '../../models/update-mode.enum'
import { IOutlayCard } from '../../models/budgeting/outlay-card.model'
import { useCurrentBranch } from '../common/current-branch/current-branch.hook'
import { IOutlayTarget } from '../../models/budgeting/outlay-target.model'

const useOutlayDash = () => {
    const outlayDashs = useSelector<rootState, IOutlayTarget[]>(
        (state) => state.outlayDash.outlayDashs
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.outlayDash.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.outlayDash.initialFetch
    )
    const outlayDash = useSelector<rootState, IOutlayTarget>(
        (state) => state.outlayDash.outlayDash
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.outlayDash.updateMode
    )
    const { currentBranch } = useCurrentBranch()

    const dispatch = useDispatch()

    const loadOutlayDashs = useCallback(
        (region: string) => {
            if (initialFetch) {
                dispatch(outlayDashActions.fetchOutlayDashAsync(region))
            }
        },
        [dispatch, initialFetch]
    )

    const getTargets = useCallback((): IOutlayCard[] => {
        return outlayDashs.map((ol) => {
            const card: IOutlayCard = {
                outlay: ol.outlay,
                budgetCode: ol.budgetCode,
                indicator: ol.indicator,
                amount: ol.annualTarget,
            }
            return card
        })
    }, [outlayDashs])

    const getComponentTargets = useCallback(
        (component: String): IOutlayCard[] => {
            return outlayDashs
                .filter((x) => x.component === component)
                .map((ol) => {
                    const card: IOutlayCard = {
                        outlay: ol.outlay,
                        budgetCode: ol.budgetCode,
                        indicator: ol.indicator,
                        amount: ol.annualTarget,
                    }
                    return card
                })
        },
        [outlayDashs]
    )

    const getTotalTarget = useCallback((): number => {
        return outlayDashs
            .map((outlay) => outlay.annualTarget)
            .reduce((a, b) => a + b, 0)
    }, [outlayDashs])

    const getComponentTotalTarget = useCallback(
        (component: String): number => {
            return outlayDashs
                .filter((x) => x.component === component)
                .map((outlay) => outlay.annualTarget)
                .reduce((a, b) => a + b, 0)
        },
        [outlayDashs]
    )

    const getTotalAchievement = useCallback((): number => {
        return outlayDashs
            .map((outlay) => outlay.periodAchievement)
            .reduce((a, b) => a + b, 0)
    }, [outlayDashs])

    const getComponentTotalAchievement = useCallback(
        (component: String): number => {
            return outlayDashs
                .filter((x) => x.component === component)
                .map((outlay) => outlay.periodAchievement)
                .reduce((a, b) => a + b, 0)
        },
        [outlayDashs]
    )

    const getTotalBudget = useCallback((): number => {
        return outlayDashs
            .map((outlay) => outlay.componentBudget)
            .reduce((a, b) => a + b, 0)
    }, [outlayDashs])

    const getComponentTotalBudget = useCallback(
        (component: String): number => {
            return outlayDashs
                .filter((x) => x.component === component)
                .map((outlay) => outlay.componentBudget)
                .reduce((a, b) => a + b, 0)
        },
        [outlayDashs]
    )

    const getTotalExpenditure = useCallback((): number => {
        return outlayDashs
            .map((outlay) => outlay.periodExpenditure)
            .reduce((a, b) => a + b, 0)
    }, [outlayDashs])

    const getComponentTotalExpenditure = useCallback(
        (component: String): number => {
            return outlayDashs
                .filter((x) => x.component === component)
                .map((outlay) => outlay.periodExpenditure)
                .reduce((a, b) => a + b, 0)
        },
        [outlayDashs]
    )

    const getAchievements = useCallback((): IOutlayCard[] => {
        return outlayDashs.map((ol) => {
            const card: IOutlayCard = {
                outlay: ol.outlay,
                budgetCode: ol.budgetCode,
                indicator: ol.indicator,
                amount: ol.periodAchievement,
            }
            return card
        })
    }, [outlayDashs])

    const getComponentAchievements = useCallback(
        (component: String): IOutlayCard[] => {
            return outlayDashs
                .filter((x) => x.component === component)
                .map((ol) => {
                    const card: IOutlayCard = {
                        outlay: ol.outlay,
                        budgetCode: ol.budgetCode,
                        indicator: ol.indicator,
                        amount: ol.periodAchievement,
                    }
                    return card
                })
        },
        [outlayDashs]
    )

    const getExpenditures = useCallback((): IOutlayCard[] => {
        return outlayDashs.map((ol) => {
            const card: IOutlayCard = {
                outlay: ol.outlay,
                budgetCode: ol.budgetCode,
                indicator: ol.indicator,
                amount: ol.periodExpenditure,
            }
            return card
        })
    }, [outlayDashs])

    const getComponentExpenditures = useCallback(
        (component: String): IOutlayCard[] => {
            return outlayDashs
                .filter((x) => x.component === component)
                .map((ol) => {
                    const card: IOutlayCard = {
                        outlay: ol.outlay,
                        budgetCode: ol.budgetCode,
                        indicator: ol.indicator,
                        amount: ol.periodExpenditure,
                    }
                    return card
                })
        },
        [outlayDashs]
    )

    const reloadOutlayDash = useCallback(() => {
        dispatch(outlayDashActions.fetchOutlayDashAsync(currentBranch.region))
    }, [currentBranch.region, dispatch])

    const getBudget = useCallback((): IOutlayCard[] => {
        return outlayDashs.map((ol) => {
            const card: IOutlayCard = {
                outlay: ol.outlay,
                budgetCode: ol.budgetCode,
                indicator: ol.indicator,
                amount: ol.componentBudget,
            }
            return card
        })
    }, [outlayDashs])

    const getComponentBudget = useCallback(
        (component: String): IOutlayCard[] => {
            return outlayDashs
                .filter((x) => x.component === component)
                .map((ol) => {
                    const card: IOutlayCard = {
                        outlay: ol.outlay,
                        budgetCode: ol.budgetCode,
                        indicator: ol.indicator,
                        amount: ol.componentBudget,
                    }
                    return card
                })
        },
        [outlayDashs]
    )

    useEffect(() => {}, [
        outlayDash,
        outlayDashs,
        isLoading,
        initialFetch,
        loadOutlayDashs,
        reloadOutlayDash,
    ])

    return {
        outlayDashs,
        isLoading,
        initialFetch,
        updateMode,
        targets: getTargets(),
        targetsComponentOne: getComponentTargets('01'),
        targetsComponentTwo: getComponentTargets('02'),
        targetsComponentThree: getComponentTargets('03'),
        targetsComponentFour: getComponentTargets('04'),
        budgets: getBudget(),
        budgetsComponentOne: getComponentBudget('01'),
        budgetsComponentTwo: getComponentBudget('02'),
        budgetsComponentThree: getComponentBudget('03'),
        budgetsComponentFour: getComponentBudget('04'),
        expenditures: getExpenditures(),
        expendituresComponentOne: getComponentExpenditures('01'),
        expendituresComponentTwo: getComponentExpenditures('02'),
        expendituresComponentThree: getComponentExpenditures('03'),
        expendituresComponentFour: getComponentExpenditures('04'),
        achievements: getAchievements(),
        achievementsComponentOne: getComponentAchievements('01'),
        achievementsComponentTwo: getComponentAchievements('02'),
        achievementsComponentThree: getComponentAchievements('03'),
        achievementsComponentFour: getComponentAchievements('04'),
        totalTarget: getTotalTarget(),
        totalTargetComponentOne: getComponentTotalTarget('01'),
        totalTargetComponentTwo: getComponentTotalTarget('02'),
        totalTargetComponentThree: getComponentTotalTarget('03'),
        totalTargetComponentFour: getComponentTotalTarget('04'),
        totalAchievement: getTotalAchievement(),
        totalAchievementComponentOne: getComponentTotalAchievement('01'),
        totalAchievementComponentTwo: getComponentTotalAchievement('02'),
        totalAchievementComponentThree: getComponentTotalAchievement('03'),
        totalAchievementComponentFour: getComponentTotalAchievement('04'),
        totalBudget: getTotalBudget(),
        totalBudgetComponentOne: getComponentTotalBudget('01'),
        totalBudgetComponentTwo: getComponentTotalBudget('02'),
        totalBudgetComponentThree: getComponentTotalBudget('03'),
        totalBudgetComponentFour: getComponentTotalBudget('04'),
        totalExpenditure: getTotalExpenditure(),
        totalExpenditureComponentOne: getComponentTotalExpenditure('01'),
        totalExpenditureComponentTwo: getComponentTotalExpenditure('02'),
        totalExpenditureComponentThree: getComponentTotalExpenditure('03'),
        totalExpenditureComponentFour: getComponentTotalExpenditure('04'),
        reloadOutlayDash,
    }
}

export { useOutlayDash }
