import { UserManager } from 'oidc-client'
import { appConfig } from '../../../constants/constants'
import { storeUser, storeUserError } from '../../../redux/common/auth/auth.slice'
import store from '../../../redux/store'

const authConfig = {
    authority: appConfig.authUrl,
    client_id: appConfig.clientId,
    redirect_uri: appConfig.redirectUri,
    response_type: appConfig.responseType,
    scope: appConfig.scope,
    post_logout_redirect_uri: appConfig.postLogoutRedirectUri,
}

const userManager = new UserManager(authConfig)

export async function loadUserFromStorage() {
    try {
        let user = await userManager.getUser()
        if (!user) {
            return store.dispatch(storeUserError())
        }
        store.dispatch(storeUser(user))
    } catch (e) {
        console.error(`User not found: ${e}`)
        store.dispatch(storeUserError())
    }
}

export function signinRedirect() {
    return userManager.signinRedirect()
}

export function signinRedirectCallback() {
    return userManager.signinRedirectCallback()
}

export function signoutRedirectWithHint(id_token: string | null) {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirect({
        id_token_hint: id_token,
    })
}

export function signoutRedirect() {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirect()
}

export function signoutRedirectCallback() {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirectCallback()
}

export default userManager
