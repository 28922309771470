import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IDistrict {
    tenant: string
    copYear: string
    region: string
    code: string
    project: string
    description: string
    createdOn: Date
}

export const emptyDistrict: IDistrict = {
    tenant: '',
    copYear: '',
    region: '',
    code: '',
    project: '',
    description: '',
    createdOn: new Date(),
}

export interface IDistrictState extends IBaseState {
    readonly districts: IDistrict[]
    readonly district: IDistrict
}

export interface IDistrictResponse extends IResponseBase {
    data: IDistrict
}
