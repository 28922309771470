export const frDashboard = {
    costCategory: {
        refreshButton: 'Rafraîchir',
        printPreviewButton: 'Aperçu',
    },
    outlayTarget: {
        refreshButton: 'Rafraîchir',
        printPreviewButton: 'Aperçu',
    },
    intervention: {
        refreshButton: 'Rafraîchir',
        printPreviewButton: 'Aperçu',
    },
    milestoneProjection: {
        refreshButton: 'Rafraîchir',
        resetSelection: 'Réinitialiser',
        printPreviewButton: 'Aperçu',
    },
}
