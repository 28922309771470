import React, { useEffect, useState } from 'react'
import { Col, Spin } from 'antd'

const Wrapper: React.FC = ({ children }) => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <Spin spinning={loading} size="large">
            <div className="wrapper__container" style={{ minHeight: 'calc(100vh - 10.5rem)'}}>
                <Col span={24} xl={{ span: 24 }}>
                    {children}
                </Col>
            </div>
        </Spin>
    )
}

export default Wrapper
