import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { Form } from 'formik-antd'
import { Formik } from 'formik'
import React from 'react'
import FormStyles from '../../styles/Forms.module.css'
import { StyledInput } from './users-styles.component'
import { FiX } from 'react-icons/fi'
import { emptyUser, IUser } from '../../models/shared/user.model'

const UsersFilterForm: React.FunctionComponent = () => {
    return (
        <>
            <Formik<IUser>
                initialValues={emptyUser}
                onSubmit={(values, { resetForm, setSubmitting }) => {}}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <Form layout="vertical">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} md={16}>
                                <Form.Item
                                    name="username"
                                    className={FormStyles.FormGroup}
                                >
                                    <StyledInput
                                        prefix={
                                            <SearchOutlined className="site-form-item-icon" />
                                        }
                                        type="email"
                                        id="email_field"
                                        className="form-control"
                                        placeholder="Search for users"
                                        name="username"
                                        // onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={15} md={6}>
                                <Form.Item
                                    name="username"
                                    className={FormStyles.FormGroup}
                                >
                                    <StyledInput
                                        prefix={
                                            <SearchOutlined className="site-form-item-icon" />
                                        }
                                        type="email"
                                        id="email_field"
                                        name="email"
                                        className="form-control"
                                        placeholder="Search for users"
                                        // onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={9} md={2}>
                                <Button
                                    type="primary"
                                    style={{ height: '36px' }}
                                    icon={<FiX />}
                                    block
                                >
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default UsersFilterForm
