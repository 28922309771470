import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import SourceForm from '../../../components/common/source/source-form.component'
import SourceTable from '../../../components/common/source/source-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'

const SourcePage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)

    const handleShow = () => {
        setTitle('Create new source')
        setContent(<SourceForm />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'Sources']} />
            <TitleBar
                title="Sources"
                subTitle="View and manage sources"
                showButton
                handleShow={handleShow}
                buttonLabel="Add Source"
                icon={<FiPlus />}
            />
            <SourceTable />
        </>
    )
}

export default SourcePage
