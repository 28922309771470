import { Button, Modal, Space } from 'antd'
import { Formik } from 'formik'
import { Form, SubmitButton } from 'formik-antd'
import React, { useContext } from 'react'
import { emptyUser, IUser } from '../../models/shared/user.model'
import { UserContextType, UsersContext } from './users-context.provider'
import { StyledInput, StyleFormItem } from './users-styles.component'

const UsersFormModal: React.FunctionComponent = () => {
    const { showModal, setShowModal } = useContext<UserContextType>(UsersContext)

    return (
        <Modal
            okText="save"
            cancelText="cancel"
            visible={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            title="Create new user"
            footer={null}
        >
            <Formik<IUser>
                initialValues={emptyUser}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false)
                    setShowModal(false)
                }}
            >
                {({ isSubmitting }) => (
                    <Form layout="vertical">
                        <StyleFormItem name="userName" id="email" label="Username">
                            <StyledInput
                                type="userName"
                                placeholder="username"
                                name="userName"
                                id="userName"
                            />
                        </StyleFormItem>
                        <StyleFormItem name="email" id="email" label="Email address">
                            <StyledInput
                                type="email"
                                placeholder="email"
                                name="email"
                                id="email"
                            />
                        </StyleFormItem>
                        <StyleFormItem
                            name="password"
                            id="password"
                            label="Password"
                        >
                            <StyledInput
                                type="password"
                                placeholder="password"
                                name="password"
                                id="password"
                            />
                        </StyleFormItem>
                        <StyleFormItem
                            name="passwordConfirm"
                            id="passwordConfirm"
                            label="Confirm password"
                        >
                            <StyledInput
                                type="password"
                                placeholder="passwordConfirm"
                                name="passwordConfirm"
                                id="passwordConfirm"
                            />
                        </StyleFormItem>
                        <Space style={{ textAlign: 'right' }}>
                            <SubmitButton loading={isSubmitting} type="primary">
                                Submit
                            </SubmitButton>
                            <Button
                                style={{ borderRadius: '4px' }}
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </Button>
                        </Space>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default UsersFormModal
