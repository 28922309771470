import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ITicketStatus {
    tenant: string
    code: string
    description: string
    createdOn: Date
}

export interface ITicketStatusState extends IBaseState {
    readonly ticketStatuses: ITicketStatus[]
    readonly ticketStatus: ITicketStatus
}

export const emptyTicketStatus: ITicketStatus = {
    tenant: '',
    code: '',
    description: '',
    createdOn: new Date(),
}

export interface ITicketStatusResponse extends IResponseBase {
    data: ITicketStatus
}
