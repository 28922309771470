import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as runnerPeriodHistoryModel from '../../models/budgeting/runner-period-history.model'
import runnerPeriodHistoryService from '../../services/budgeting/runner-period-history.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: runnerPeriodHistoryModel.IRunnerPeriodHistoryState = {
    runnerPeriodHistories: [],
    errors: '',
    runnerPeriodHistory: runnerPeriodHistoryModel.emptyRunnerPeriodHistory,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchRunnerPeriodHistoriesAsync = createAsyncThunk<
    runnerPeriodHistoryModel.IRunnerPeriodHistory[],
    void
>('runnerPeriodHistory/fetchrunnerPeriodHistoriesAsync', async (_, thunkApi) => {
    try {
        return await runnerPeriodHistoryService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const runnerPeriodHistorySlice = createSlice({
    name: 'runnerPeriodHistory',
    initialState,
    reducers: {
        fetchRunnerPeriodHistoriesRequest: (state) => {
            state.isLoading = true
        },
        fetchRunnerPeriodHistoriesSuccess: (
            state,
            action: PayloadAction<runnerPeriodHistoryModel.IRunnerPeriodHistory[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerPeriodHistories = action.payload
        },
        fetchRunnerPeriodHistoriesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editRunnerPeriodHistorySuccess: (
            state,
            action: PayloadAction<runnerPeriodHistoryModel.IRunnerPeriodHistory>
        ) => {
            state.runnerPeriodHistories = state.runnerPeriodHistories.map(
                (runnerPeriodHistory) => {
                    return runnerPeriodHistory.code === action.payload.code
                        ? action.payload
                        : runnerPeriodHistory
                }
            )
            state.updateMode = UpdateMode.NONE
        },
        addRunnerPeriodHistorySuccess: (
            state,
            action: PayloadAction<runnerPeriodHistoryModel.IRunnerPeriodHistory>
        ) => {
            state.runnerPeriodHistories = [
                ...state.runnerPeriodHistories,
                action.payload,
            ]
            state.updateMode = UpdateMode.NONE
        },
        setActiveRunnerPeriodHistory: (
            state,
            action: PayloadAction<runnerPeriodHistoryModel.IRunnerPeriodHistory>
        ) => {
            state.runnerPeriodHistory = action.payload
        },
        setRunnerPeriodHistoryUpdateMode: (
            state,
            action: PayloadAction<UpdateMode>
        ) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRunnerPeriodHistoriesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(
            fetchRunnerPeriodHistoriesAsync.fulfilled,
            (state, action) => {
                state.isLoading = false
                state.initialFetch = false
                state.runnerPeriodHistories = action.payload
            }
        )
        builder.addCase(
            fetchRunnerPeriodHistoriesAsync.rejected,
            (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            }
        )
    },
})

export const {
    fetchRunnerPeriodHistoriesRequest,
    fetchRunnerPeriodHistoriesSuccess,
    fetchRunnerPeriodHistoriesError,
    editRunnerPeriodHistorySuccess,
    addRunnerPeriodHistorySuccess,
    setActiveRunnerPeriodHistory,
    setRunnerPeriodHistoryUpdateMode,
} = runnerPeriodHistorySlice.actions

const reducer = runnerPeriodHistorySlice.reducer

export { reducer as runnerPeriodHistoryReducer }
