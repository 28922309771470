export const enComponent = {
    tenant: 'Tenant',
    code: 'code',
    copYear: 'Component year',
    project: 'Project',
    description: 'Description',
    longDescription: 'Long Description',
    startDate: 'Start date',
    endDate: 'End date',
}
