import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    emptyServerStatus,
    IServerStatus,
    IServerStatusState,
} from '../../../models/common/server-status.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import serverStatusService from '../../../services/common/server-status/server-status.service'

export const initialState: IServerStatusState = {
    serverStatuses: [],
    errors: '',
    serverStatus: emptyServerStatus,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchServerStatusesAsync = createAsyncThunk(
    'serverStatus/fetchServerStatusesAsync',
    async () => {
        return await serverStatusService.list()
    }
)

export const serverStatusSlice = createSlice({
    name: 'serverStatus',
    initialState,
    reducers: {
        fetchServerStatusesRequest: (state) => {
            state.isLoading = true
        },
        fetchServerStatusesSuccess: (
            state,
            action: PayloadAction<IServerStatus[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.serverStatuses = action.payload
        },
        fetchServerStatusesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        addServerStatusSuccess: (state, action: PayloadAction<IServerStatus>) => {
            state.serverStatuses = [...state.serverStatuses, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        editServerStatusSuccess: (state, action: PayloadAction<IServerStatus>) => {
            state.serverStatuses = state.serverStatuses.map((serverStatus) => {
                return serverStatus.region === action.payload.region
                    ? action.payload
                    : serverStatus
            })
            state.updateMode = UpdateMode.NONE
        },
        setActiveServerStatus: (state, action: PayloadAction<IServerStatus>) => {
            state.serverStatus = action.payload
        },
        setServerStatusUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchServerStatusesAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchServerStatusesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.serverStatuses = action.payload
        })
        builder.addCase(fetchServerStatusesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchServerStatusesRequest,
    fetchServerStatusesSuccess,
    addServerStatusSuccess,
    editServerStatusSuccess,
    setActiveServerStatus,
    setServerStatusUpdateMode,
} = serverStatusSlice.actions

const reducer = serverStatusSlice.reducer

export { reducer as serverStatusReducer }
