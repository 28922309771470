import { ColumnsType } from 'antd/lib/table'
import { IDialogMessage } from '../../../models/common/dialog-message.model'

export const dialogMessagesTableColumns: ColumnsType<IDialogMessage> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Locale',
        dataIndex: 'locale',
        key: 'locale',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
