import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as districtActions from '../../redux/budgeting/district.slice'
import { IDistrict } from '../../models/budgeting/district.model'
import { UpdateMode } from '../../models/update-mode.enum'
import districtService from '../../services/budgeting/district.service'
import { useCurrentBranch } from '../common/current-branch/current-branch.hook'
import { useConfiguration } from '../configuration.hook'
import { emptySite, ISite } from '../../models/budgeting/site.model'

const useDistrict = () => {
    const { currentBranch } = useCurrentBranch()
    const { configuration } = useConfiguration()

    const districts = useSelector<rootState, IDistrict[]>(
        (state) => state.district.districts
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.district.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.district.initialFetch
    )
    const district = useSelector<rootState, IDistrict>(
        (state) => state.district.district
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.district.updateMode
    )

    const dispatch = useDispatch()

    const loadDistricts = useCallback(() => {
        if (initialFetch) {
            dispatch(districtActions.fetchdistrictAsync())
        }
    }, [dispatch, initialFetch])

    const getCommunitySites = useCallback(() => {
        const communitySites: ISite[] = districts
            .filter(
                (x) =>
                    x.region === currentBranch.region &&
                    x.project === configuration.project
            )
            .map((x) => {
                const site: ISite = {
                    ...emptySite,
                    code: x.code,
                    description: x.description,
                    copYear: x.copYear,
                    project: x.project,
                }
                return site
            })
        return communitySites
    }, [currentBranch.region, districts, configuration.project])

    const addDistrict = async (district: IDistrict) => {
        return await districtService
            .create(district)
            .then((districtResponse) => {
                if (districtResponse.success) {
                    dispatch(
                        districtActions.addDistrictSuccess(districtResponse.data)
                    )
                } else {
                    return districtResponse
                }
                return districtResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setDistrict = (district: IDistrict) => {
        dispatch(districtActions.setActiveDistrict(district))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(districtActions.setDistrictUpdateMode(updateMode))
    }

    const editDistrict = async (district: IDistrict) => {
        return await districtService
            .update(district)
            .then((districtResponse) => {
                dispatch(districtActions.editDistrictSuccess(districtResponse.data))
                setDistrict(districtResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveDistrict = async (district: IDistrict, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addDistrict(district)
            : await editDistrict(district)
    }

    useEffect(() => {
        loadDistricts()
    }, [district, districts, isLoading, initialFetch, loadDistricts])

    return {
        district,
        districts,
        communitySites: getCommunitySites(),
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addDistrict,
        editDistrict,
        saveDistrict,
        setDistrict,
    }
}

export { useDistrict }
