import React, { useContext } from 'react'
import { FiEdit } from 'react-icons/fi'
import BackButton from '../../../components/common/back-button/back-button.component'
import SourceDetailComponent from '../../../components/common/source/source-detail.component'
import SourceForm from '../../../components/common/source/source-form.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useSource } from '../../../hooks/common/source/source.hook'
import { UpdateMode } from '../../../models/update-mode.enum'

const SourceDetailsPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { setUpdateMode } = useSource()

    const handleShow = () => {
        setTitle('Edit Source')
        setUpdateMode(UpdateMode.EDIT)
        setContent(<SourceForm />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'Sources', 'Details']} />
            <TitleBar
                title="Source"
                subTitle="View and manage sources"
                showButton
                handleShow={handleShow}
                buttonLabel="Edit Source"
                icon={<FiEdit />}
            />

            <BackButton page="Source" />

            {/* details */}
            <SourceDetailComponent />
        </>
    )
}

export default SourceDetailsPage
