import { apiRequests } from '../'
import {
    IMilestone,
    IMilestoneDashboard,
    IMilestoneResponse,
} from '../../models/budgeting/milestone.model'

const milestoneService = {
    list: (): Promise<IMilestone[]> => apiRequests.get('/milestones'),
    details: (code: string): Promise<IMilestone> =>
        apiRequests.get(`/milestones/${code}`),
    createProjection: (milestone: IMilestone): Promise<IMilestoneResponse> =>
        apiRequests.post('/milestones/projection', milestone),
    createAchievement: (milestone: IMilestone): Promise<IMilestoneResponse> =>
        apiRequests.post('/milestones/achievement', milestone),
    milestonePost: ({
        runner,
        site,
    }: {
        runner: string
        site: string
    }): Promise<IMilestoneDashboard[]> =>
        apiRequests.post('/milestones/dashboard', { runner, site }),
    milestoneReport: ({
        runner,
        site,
        region
    }: {
        runner: string
        site: string
        region: string
    }): Promise<string> => {
        return apiRequests.get(
            `/milestones/dashboard/report?runner=${runner}&site=${site}&region=${region}`
        )
    },
}

export default milestoneService
