import React, { useContext } from 'react'
import { FiEdit } from 'react-icons/fi'
import BackButton from '../../../components/common/back-button/back-button.component'
import BranchDetailComponent from '../../../components/common/branch/branch-detail.component'
import BranchForm from '../../../components/common/branch/branch-form.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { UpdateMode } from '../../../models/update-mode.enum'

const BranchDetailsPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)

    const handleShow = () => {
        setTitle('Edit Branch')
        setContent(<BranchForm updateMode={UpdateMode.EDIT} />)
        setWidth('60rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'Branches', 'Details']} />
            <TitleBar
                title="Branch"
                subTitle="View and manage branches"
                showButton
                handleShow={handleShow}
                buttonLabel="Edit Branch"
                icon={<FiEdit />}
            />

            <BackButton page="Branch" />

            {/* details */}
            <BranchDetailComponent />
        </>
    )
}

export default BranchDetailsPage
