import React, { createContext, useEffect, useState } from 'react'
import { Layout, Badge, Avatar, Dropdown, Space, Drawer } from 'antd'
import {
    BellOutlined,
    TranslationOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import AppHeaderProfileMenus from '../../components/common/app-header/app-header-profile-menus.component'
import AppHeaderNotificationMenus from '../../components/common/app-header/app-header-notification-menus'
import AppHeaderLanguageMenus from './app-header-language-menus'
import { useAuthentication } from '../../hooks/auth.hook'
import './app-header.scss'
import Sidebar from '../sidebar/sidbar.component'

export const HeaderContext = createContext({})

const AppHeader: React.FC = () => {
    const { Header } = Layout
    const [show, setShow] = useState(false)
    const [showMobileNavbar, setShowMobileNavbar] = useState(false)
    const [showSearch, setShowSearch] = useState(false)

    useEffect(() => {}, [showSearch, showMobileNavbar, show])
    const { auth } = useAuthentication()

    const getNameInitials = (nameString: string) => {
        const fullName: any[] = nameString.split(' ')
        const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0)
        return initials.toUpperCase()
    }

    return (
        <HeaderContext.Provider
            value={{
                showMobileNavbar,
                showSearch,
                setShowMobileNavbar,
                setShowSearch,
            }}
        >
            <Drawer
                title="Topics"
                placement="left"
                headerStyle={{ display: 'none' }}
                onClose={() => setShow(false)}
                visible={show}
                className="sm-responsive-drawer"
            >
                <Sidebar collapsed={false} />
            </Drawer>

            {/* mobile sidebar drawer */}
            <Header
                className="site-layout-background app-header"
                style={{ background: '#12638F' }}
            >
                <div>
                    <MenuFoldOutlined
                        onClick={() => setShow(true)}
                        className="sidebar__toggler"
                    />
                    <Link to="/" className="app-header__title">
                        <img
                            src="/logo.svg"
                            alt="logo"
                            style={{ width: '3rem', height: 'auto' }}
                        />
                        DAMAFIN
                    </Link>
                </div>

                {/* other things */}
                <Space>
                    {/* notifications */}
                    <Dropdown
                        trigger={['click']}
                        overlay={<AppHeaderNotificationMenus />}
                        placement="bottomRight"
                    >
                        <Badge dot count={1} className="app-header__link">
                            <BellOutlined className="app-header__icon" />
                        </Badge>
                    </Dropdown>

                    {/* language */}
                    <Dropdown
                        trigger={['click']}
                        overlay={<AppHeaderLanguageMenus />}
                    >
                        <TranslationOutlined className="app-header__icon" />
                    </Dropdown>

                    {/* avatar */}
                    <Dropdown
                        trigger={['click']}
                        overlay={<AppHeaderProfileMenus />}
                        placement="bottom"
                        className="user__profile"
                    >
                        <Avatar
                            style={{
                                color: '#f56A',
                                backgroundColor: '#fde3cf',
                                fontWeight: 'bold',
                            }}
                        >
                            {getNameInitials(
                                auth.user && auth.user.profile.user_fullname
                            )}
                        </Avatar>
                    </Dropdown>
                </Space>
            </Header>
        </HeaderContext.Provider>
    )
}

export default AppHeader
