export const frRunnerPeriod = {
    breadcrumb: {
        runnerPeriod: 'Périodes du coureur',
        list: 'Liste',
    },
    mainPage: {
        title: 'Période du coureur',
        subTitle:
            'Affichez et gérez les périodes de coureur pour contrôler les coureurs auxquels les régions ont accès.',
        buttonLabel: 'Ajouter une période de coureur',
    },

    detailPage: {
        title: 'Période du coureur',
        subTitle: 'Afficher et gérer la période du coureur',
        buttonLabel: 'Modifier la période du coureur',
        modalTitle: 'Modifier la période du coureur',
    },

    form: {
        code: 'code',
        description: 'La description',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        component: "Composant",
        submit: 'Soumettre',
        reset: 'Réinitialiser',
    },
}
