import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IDialogMessage {
    code: string
    lid: string
    description: string
    createdOn: string
}

export interface IDialogMessageState extends IBaseState {
    readonly dialogMessages: IDialogMessage[]
    readonly dialogMessage: IDialogMessage
}

export const emptyDialogMessage: IDialogMessage = {
    code: '',
    lid: '',
    description: '',
    createdOn: '',
}
export interface IDialogMessageResponse extends IResponseBase {
    data: IDialogMessage
}
