import { apiRequests } from '../'
import {
    IRunnerPeriodHistory,
    IRunnerPeriodHistoryResponse,
} from '../../models/budgeting/runner-period-history.model'

const runnerPeriodHistoryService = {
    list: (): Promise<IRunnerPeriodHistory[]> =>
        apiRequests.get('/runnerPeriodHistories'),
    details: (code: string): Promise<IRunnerPeriodHistory> =>
        apiRequests.get(`/runnerPeriodHistories/${code}`),
    create: (
        runnerPeriodHistory: IRunnerPeriodHistory
    ): Promise<IRunnerPeriodHistoryResponse> =>
        apiRequests.post('/runnerPeriodHistories', runnerPeriodHistory),
    update: (
        runnerPeriodHistory: IRunnerPeriodHistory
    ): Promise<IRunnerPeriodHistoryResponse> =>
        apiRequests.put('/runnerPeriodHistories', runnerPeriodHistory),
}

export default runnerPeriodHistoryService
