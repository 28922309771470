import { Alert } from 'antd'
import { Formik } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useActivity } from '../../../hooks/budgeting/activity.hook'
import { useRunner } from '../../../hooks/budgeting/runner.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useCurrentUser } from '../../../hooks/common/current-user/current-user.hook'
import { useFormErrors } from '../../../hooks/common/form/form-error.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import { useConfiguration } from '../../../hooks/configuration.hook'
import { useActiveTab } from '../../../hooks/shared/active-tab/active-tab.hook'
import { emptyActivity, IActivity } from '../../../models/budgeting/activity.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import ActivityHistoryFormFields from './activity-history-form-fields.component'
import activityHistoryService from '../../../services/budgeting/activity-history.service'
import { useBudgetCode } from '../../../hooks/budgeting/budget-code.hook'
import { useActivityHistory } from '../../../hooks/budgeting/activity-history.hook'

interface IProp {
    updateMode: UpdateMode
    isCoordinationArea?: boolean
}
const ActivityHistoryForm: React.FC<IProp> = ({
    updateMode,
    isCoordinationArea = false,
}) => {
    const { toastSuccess, toastError } = useToast()
    const { activity, setActivity } = useActivity()
    const { runner } = useRunner()
    const { currentBranch } = useCurrentBranch()
    const { updateActivityHistory } = useActivityHistory()
    const { configuration } = useConfiguration()

    const { formError } = useFormErrors()
    const [hasSubmitted, setSubmitted] = useState(false)
    const { setShow } = useContext<any>(AppModalContext)

    const onClose = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSubmitted(false)
        },
        []
    )

    useEffect(() => {
        // setActivity(activity)
    }, [hasSubmitted, activity])
    return (
        <div>
            {hasSubmitted && (
                <Alert
                    message="Error Messages"
                    description={
                        <>
                            {formError.validationErrors &&
                            formError.validationErrors.length > 0 ? (
                                formError.validationErrors.map((v, index) => (
                                    <span key={index} style={{ display: 'block' }}>
                                        {v}
                                    </span>
                                ))
                            ) : (
                                <span>
                                    {JSON.stringify(
                                        formError.validationErrors,
                                        null,
                                        2
                                    )}
                                </span>
                            )}
                        </>
                    }
                    type="error"
                    closable
                    onClose={onClose}
                    style={{ marginBottom: 10 }}
                />
            )}
            <Formik<IActivity>
                initialValues={activity || emptyActivity}
                onSubmit={async (values, { setSubmitting, resetForm,  }) => {
                    const runnerCode =
                        configuration.activityType === '01'
                            ? runner.code
                            : values.runner
                    const { userCode, ...rest } = values

                    const obj: IActivity = {
                        ...rest,
                        runner: runnerCode,
                        component: runner.component,
                        project: configuration.project,
                        branch: currentBranch.code,
                        activityType: configuration.activityType,
                        tranCode: configuration.tranCode,
                        copYear: runner.copYear,
                        region: currentBranch.region,
                        userCode,
                    }

                    setSubmitted(false)

                    const feedback = await updateActivityHistory(obj)
                    if (feedback) {
                        toastSuccess(
                            updateMode === UpdateMode.ADD ? 'M0001' : 'M0002'
                        )
                        setShow(false)
                        resetForm()
                    } else {
                        toastError('M0003')
                        setShow(true)
                        setSubmitted(true)
                    }
                    setSubmitting(false)
                }}
            >
                <>
                    <ActivityHistoryFormFields />
                </>
            </Formik>
        </div>
    )
}
export default ActivityHistoryForm
