import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IClaim {
    code: string
    parent: string
    claimType: string
    claimCode: string
    description: string
    slug: string
    createdOn: Date
}

export interface IClaimState extends IBaseState {
    readonly claims: IClaim[]
    readonly claim: IClaim
}

export const emptyClaim: IClaim = {
    code: '',
    parent: '',
    claimType: '',
    claimCode: '',
    description: '',
    slug: '',
    createdOn: new Date(),
}

export interface IClaimResponse extends IResponseBase {
    data: IClaim
}
