import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IStationReference {
    tenant: string
    code: string
    description: string
    createdOn: Date
}

export interface IStationReferenceState extends IBaseState {
    readonly stationReferences: IStationReference[]
    readonly stationReference: IStationReference
}

export const emptyStationReference: IStationReference = {
    tenant: '',
    code: '',
    description: '',
    createdOn: new Date(),
}

export interface IStationReferenceResponse extends IResponseBase {
    data: IStationReference
}
