import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ICopYearBudgetCode {
    tenant: string
    copYear: string
    project: string
    budgetCode: string
}

export const emptyCopYearBudgetCode: ICopYearBudgetCode = {
    tenant: '',
    copYear: '',
    project: '',
    budgetCode: '',
}

export interface ICopYearBudgetCodeState extends IBaseState {
    readonly copYearBudgetCodes: ICopYearBudgetCode[]
    readonly copYearBudgetCode: ICopYearBudgetCode
}

export interface ICopYearBudgetCodeResponse extends IResponseBase {
    data: ICopYearBudgetCode
}
