import React from 'react'
import { sidebarData } from '../../../layout/sidebar/sidebar.data'

type Props = {
  parentCode: string;
}

const ClaimsParentRenderer: React.FunctionComponent<Props> = ({ parentCode }) => {
  const parentLink = sidebarData.find(item => item.code === parentCode);
  return (
    <>
      {parentLink ? parentLink.description : ''}
    </>
  )
}

export default ClaimsParentRenderer
