import { ColumnsType } from 'antd/lib/table'
import { IRole } from '../../../models/common/role.model'
import RoleClaimsRenderer from '../claim/role-claim.component'

export const rolesTableColumns: ColumnsType<IRole> = [
    {
        title: 'SN',
        dataIndex: 'code',
        key: 'code',
        width: '5rem',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '15rem',
    },
    {
        title: 'Claim',
        dataIndex: 'claims',
        key: 'claims',
        render: (_, record) => <RoleClaimsRenderer roleCode={record.code} />,
        ellipsis: true,
    },
]
