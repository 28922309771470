import { Card, Col, Row, Switch } from 'antd'
import React from 'react'

export const MySetting: React.FC = () => {
    const onChange = (checked: boolean) => {}

    return (
        <>
            <Row
                style={{ justifyContent: 'flex-start', alignItems: 'center' }}
                gutter={[16, 16]}
            >
                <Col span={24}>
                    <Card size="small" style={{ marginTop: '.5rem' }}>
                        <h5>Notifications</h5>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                        >
                            <Switch defaultChecked onChange={onChange} />
                            <p style={{ marginBottom: 0, marginLeft: 15 }}>
                                Email alerts for account activity are enabled
                            </p>
                        </div>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card size="small" style={{ marginTop: '.5rem' }}>
                        <h5>Type-to-Confirm</h5>
                        <p style={{ marginBottom: 0 }}>
                            For some products and services, the type-to-confirm
                            setting requires entering the label before deletion.
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                        >
                            <Switch defaultChecked onChange={onChange} />
                            <p style={{ marginBottom: 0, marginLeft: 15 }}>
                                Type-to-confirm is enabled
                            </p>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
