import { apiRequests } from '..'
import { ITarget, ITargetResponse } from '../../models/budgeting/target.model'

const targetService = {
    list: (): Promise<ITarget[]> => apiRequests.get('/targets'),
    details: (code: string): Promise<ITarget> => apiRequests.get(`/targets/${code}`),
    create: (target: ITarget): Promise<ITargetResponse> =>
        apiRequests.post('/targets', target),
    update: (target: ITarget): Promise<ITargetResponse> =>
        apiRequests.put('/targets', target),
}

export default targetService
