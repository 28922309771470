import { Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthentication } from '../../../hooks/auth.hook'
import { IUser } from '../../../models/shared/user.model'
import { ChangeProfilePicture } from './change-profile.component'

const FormItem = Form.Item
const DiplayFormFields: React.FC = () => {
    const { isSubmitting } = useFormikContext<IUser>()
    const { t } = useTranslation()
    const { auth } = useAuthentication()

    return (
        <>
            <ChangeProfilePicture />
            <Form autoComplete="off">
                <p className="mb-0">{t('user:usrName')}</p>
                <FormItem name="usrName" required={true}>
                    <Input
                        name="usrName"
                        disabled={isSubmitting}
                        required={true}
                        value={`${auth?.user?.profile.user_fullname}`}
                    />
                </FormItem>

                <p className="mb-0">{t('user:email')}</p>
                <FormItem name="email" required={true}>
                    <Input
                        name="email"
                        disabled={isSubmitting}
                        required={true}
                        value={`${auth?.user?.profile.email}`}
                    />
                </FormItem>

                <p className="mb-0">{t('user:telephone')}</p>
                <FormItem name="telephone" required={true}>
                    <Input
                        name="telephone"
                        disabled={isSubmitting}
                        required={true}
                        value={`${auth?.user?.profile.phone_number}`}
                    />
                </FormItem>
                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('user:update')}
                    </SubmitButton>
                </Space>
            </Form>
        </>
    )
}

export default DiplayFormFields
