export interface IActivitySummary {
    batch: string
    branch: string
    runner: string
    transDate: Date
    userCode: string
    usrName?: string
    amount: number
    number: number
    quantity: number
    region: string
    description: string
    project: string
    activityType: string
    transactionCode: string
    transactionCodeDescription?: string
}

export const emptyActivitySummary: IActivitySummary = {
    batch: '',
    branch: '',
    region: '',
    runner: '',
    transDate: new Date(),
    userCode: '',
    amount: 0,
    number: 0,
    quantity: 0,
    description: '',
    project: '',
    activityType: '',
    transactionCode: '',
}
