export const enDepartment = {
    description: 'Description',
    code: 'Code',
    segment: 'Segment',
    tenant: 'Tenant',
    createdOn: 'Created On',

    submit: 'Submit',
    reset: 'Reset',
}
