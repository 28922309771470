import React from 'react'
import { Table } from 'antd'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'
import { useDivision } from '../../../hooks/common/division/division.hook'
import { IDivision } from '../../../models/common/division.model'
import { divisionsTableColumns } from './division-column.component'

const DivisionTable = () => {
    const { divisions, setDivision } = useDivision()
    const router = useHistory()
    // const route = use
    const handleRowClick = (division: IDivision) => {
        setDivision(division)
        router.push(`/configurations/divisions/${slugify(division.code)}`)
    }

    return (
        <>
            {divisions && divisions.length ? (
                <Table<IDivision>
                    dataSource={divisions}
                    columns={divisionsTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    onRow={(record: IDivision) => {
                        return {
                            onClick: (e) => {
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                <NoContent title="No data for division" />
            )}
        </>
    )
}

export default DivisionTable
