import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as roleActions from '../../../redux/common/role/role.slice'
import { IRole } from '../../../models/common/role.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import roleService from '../../../services/common/role/role.service'

const useRole = () => {
    const roles = useSelector<rootState, IRole[]>((state) => state.role.roles)
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.role.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.role.initialFetch
    )
    const role = useSelector<rootState, IRole>((state) => state.role.role)
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.role.updateMode
    )

    const dispatch = useDispatch()

    const loadRoles = useCallback(() => {
        if (initialFetch) {
            dispatch(roleActions.fetchRolesAsync())
        }
    }, [dispatch, initialFetch])

    const addRole = async (role: IRole) => {
        return await roleService
            .create(role)
            .then((roleResponse) => {
                dispatch(roleActions.addRoleSuccess(roleResponse.data))
                return true
            })
            .catch((error) => {
                // dispatch(roleActions.)
                return false
            })
    }

    const setRole = (role: IRole) => {
        dispatch(roleActions.setActiveRole(role))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(roleActions.setRoleUpdateMode(updateMode))
    }

    const editRole = async (role: IRole) => {
        return await roleService
            .update(role)
            .then((roleResponse) => {
                dispatch(roleActions.editRoleSuccess(roleResponse.data))
                setRole(roleResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveRole = async (role: IRole, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addRole(role)
            : await editRole(role)
    }

    useEffect(() => {
        loadRoles()
    }, [role, roles, isLoading, initialFetch, loadRoles])

    return {
        role,
        roles,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addRole,
        editRole,
        saveRole,
        setRole,
    }
}

export { useRole }
