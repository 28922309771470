import { useCallback, useState } from 'react'
import { useModificationsContext } from '../../contexts/modifications/modifications.context'
import { IActivitySummary } from '../../models/budgeting/activity-summary.model'
import { IActivity } from '../../models/budgeting/activity.model'
import { emptyUser } from '../../models/shared/user.model'
import activityHistoryService from '../../services/budgeting/activity-history.service'
import { useUser } from '../shared/user/user.hook'
import { useDispatch } from 'react-redux'
import { useActivity } from './activity.hook'
import * as activityActions from '../../redux/budgeting/activity.slice'
import { useFormErrors } from '../common/form/form-error.hook'
import { useCurrentBranch } from '../common/current-branch/current-branch.hook'

const useActivityHistory = () => {
    const { users } = useUser()
    const { modificationsData } = useModificationsContext()
    const [batchLines, setBatchLines] = useState<IActivity[]>([])
    const dispatch = useDispatch()
    const { setActivity } = useActivity()
    const { setformError } = useFormErrors()
    const { currentBranch } = useCurrentBranch()

    const getActivityHistorySummary = () => {
        if (modificationsData) {
            return modificationsData
                .filter((m: any) => m.region === currentBranch.region)
                .map((item: IActivitySummary) => {
                    if (Array.isArray(users) && users.length > 0) {
                        return {
                            ...item,
                            usrName:
                                users.find((user) => user.code === item.userCode)
                                    ?.usrName || '',
                        }
                    } else {
                        return emptyUser
                    }
                })
        }
    }

    const getTotalAmount = () =>
        modificationsData
            .map((item: IActivitySummary) => item.amount)
            .reduce((a: number, b: number) => a + b, 0)

    const getBatchLines = useCallback(async (batchCode: string) => {
        const data = await activityHistoryService.batchLines(batchCode)
        setBatchLines(data)
    }, [])
    const updateActivityHistory = async (activityHistory: IActivity) => {
        return await activityHistoryService
            .update(activityHistory)
            .then((response) => {
                dispatch(activityActions.editActivitySuccess(response.data))
                setActivity(response.data)
                return true
            })
            .catch((error) => {
                setformError({
                    message: 'Failed',
                    success: false,
                    validationErrors: error,
                })
                return false
            })
    }

    return {
        activityHistorySummary: getActivityHistorySummary(),
        totalAmount: getTotalAmount(),
        getBatchLines,
        batchLines,
        updateActivityHistory,
    }
}

export { useActivityHistory }
