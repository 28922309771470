import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IIntervention {
    tenant: string
    code: string
    copYear: string
    description: string
    budgetCode: string
    project: string
    percentage: number
    createdOn: Date
}

export const emptyIntervention: IIntervention = {
    tenant: '',
    code: '',
    copYear: '',
    description: '',
    budgetCode: '',
    project: '',
    percentage: 0,
    createdOn: new Date(),
}

export interface IInterventionState extends IBaseState {
    readonly interventions: IIntervention[]
    readonly intervention: IIntervention
}

export interface IInterventionResponse extends IResponseBase {
    data: IIntervention
}
