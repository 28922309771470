import { apiRequests } from '../'
import {
    ICopYearCostCategory,
    ICopYearCostCategoryResponse,
} from '../../models/budgeting/cop-year-cost-category.model'

const copYearCostCategoryService = {
    list: (): Promise<ICopYearCostCategory[]> =>
        apiRequests.get('/copYearCostCategories'),
    details: (code: string): Promise<ICopYearCostCategory> =>
        apiRequests.get(`/copYearCostCategories/${code}`),
    create: (
        copyearcostcategory: ICopYearCostCategory
    ): Promise<ICopYearCostCategoryResponse> =>
        apiRequests.post('/copYearCostCategories', copyearcostcategory),
    update: (
        copyearcostcategory: ICopYearCostCategory
    ): Promise<ICopYearCostCategoryResponse> =>
        apiRequests.put('/copYearCostCategories', copyearcostcategory),
}

export default copYearCostCategoryService
