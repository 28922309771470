import { apiRequests } from '..'
import { IBudgetAnalysisOptions } from '../../models/reports/budget-analysis-options.model'
import { format } from '../../utils/format'

export const budgetAnalysisService = {
    getBudgetAnalysisData: (
        options: IBudgetAnalysisOptions,
        path: string
    ): Promise<any> => apiRequests.post(`/budgetAnalysis/${path}`, options),

    getBudgetAnalysisCostCategory: (options: IBudgetAnalysisOptions): Promise<any> =>
        apiRequests.get(
            `/budgetAnalysis/costCategory/report?tenant=${options.tenant}&project=${
                options.project
            }&copYear=04&component=${options.component}&region=${
                options.region
            }&startDate=${format.isodate(
                options.startDate
            )}&endDate=${format.isodate(options.endDate)}&user=${
                options.user
            }&activityType=${options.activityType}&reportType=01&tranCode=${
                options.tranCode
            }`
        ),
    getBudgetAnalysisIntervention: (options: IBudgetAnalysisOptions): Promise<any> =>
        apiRequests.get(
            `/budgetAnalysis/intervention/report?tenant=${options.tenant}&project=${
                options.project
            }&copYear=04&component=${options.component}&region=${
                options.region
            }&startDate=${format.isodate(
                options.startDate
            )}&endDate=${format.isodate(options.endDate)}&user=${
                options.user
            }&activityType=${options.activityType}&reportType=01&tranCode=${
                options.tranCode
            }`
        ),

    getBudgetAnalysisSite: (options: IBudgetAnalysisOptions): Promise<any> =>
        apiRequests.get(
            `/budgetAnalysis/site/report?tenant=${options.tenant}&project=${
                options.project
            }&copYear=04&component=${options.component}&region=${
                options.region
            }&startDate=${format.isodate(
                options.startDate
            )}&endDate=${format.isodate(options.endDate)}&user=${
                options.user
            }&activityType=${options.activityType}&reportType=01&tranCode=${
                options.tranCode
            }`
        ),
}
