import WelcomePage from '../../../pages/welcome.page'
import SignoutOidc from '../../../pages/signout-oidc/signout-oidc.page'
import SigninOidcPage from '../../../pages/signin-oidc/signin-oidc.page'
import { IRoute } from '../../../models/common/route.model'
import AccessDeniedPage from '../../../pages/access-denied/access-denied.page'
import ProfilePage from '../../../pages/profile/profile.page'

export const authRoutes: IRoute[] = [
    /**
     * Auth routes
     */
    {
        path: '/welcome',
        private: false,
        exact: false,
        component: WelcomePage,
    },
    {
        path: '/access-denied',
        private: false,
        exact: false,
        component: AccessDeniedPage,
    },
    {
        path: '/signout-oidc',
        private: false,
        exact: false,
        component: SignoutOidc,
    },
    {
        path: '/signin-oidc',
        private: false,
        exact: false,
        component: SigninOidcPage,
    },
    {
        path: '/profile/:tab',
        private: true,
        exact: true,
        component: ProfilePage,
    },
]
