import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { emptyRunnerPeriodBase, IRunnerPeriodBase } from './runner-period-base.model'

export interface IRunnerPeriodHistory extends IRunnerPeriodBase {}

export interface IRunnerPeriodHistoryState extends IBaseState {
    readonly runnerPeriodHistories: IRunnerPeriodHistory[]
    readonly runnerPeriodHistory: IRunnerPeriodHistory
}

export const emptyRunnerPeriodHistory: IRunnerPeriodHistory = emptyRunnerPeriodBase

export interface IRunnerPeriodHistoryResponse extends IResponseBase {
    data: IRunnerPeriodHistory
}
