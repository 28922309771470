import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    emptyConfiguration,
    IConfiguration,
    IConfigurationState,
} from '../models/configuration.model'

export const initialState: IConfigurationState = {
    configuration: emptyConfiguration,
}

export const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        setActiveConfiguration: (state, action: PayloadAction<IConfiguration>) => {
            state.configuration = action.payload
        },
        setActiveTab: (state, action: PayloadAction<string>) => {
            state.configuration.activeTab = action.payload
        },
        setDashboardName: (state, action: PayloadAction<string>) => {
            state.configuration.dashboardName = action.payload
        },
        setDisplayDashboardName: (state, action: PayloadAction<boolean>) => {
            state.configuration.showDashboardName = action.payload
        },
    },
})

export const { setActiveConfiguration, setActiveTab } = configurationSlice.actions

const reducer = configurationSlice.reducer

export { reducer as configurationReducer }
