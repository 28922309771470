import { ColumnsType } from 'antd/lib/table'
import { useSite } from '../../../hooks/budgeting/site.hook'
// import { IMilestoneProjectionMock } from '../../../mock-data/dashboards/milestone-projection.mock'
import { IMilestoneDashboard } from '../../../models/budgeting/milestone.model'

export const useMilestoneProjectionTableColumns = () => {
    const {sites} = useSite();
    const getSiteName = (code: string) => {
        return sites.find(s => s.code === code)?.description
    }
    const columns: ColumnsType<IMilestoneDashboard> = [
        {
            title: 'Site',
            dataIndex: 'site',
            key: 'site',
            width: '8rem',
            filtered: true,
            render: (_, record) => getSiteName(record.site)
        },
        {
            title: 'Activity',
            dataIndex: 'activity',
            key: 'activity',
            width: '4rem',
            filtered: true,
        },
        {
            title: 'Budget note',
            dataIndex: 'budgetNote',
            key: 'budgetNote',
            width: '9rem',
            filtered: true,
        },
        {
            title: 'Target',
            dataIndex: 'target',
            key: 'target',
            width: '4rem',
            align: 'right',
            filtered: true,
        },
        {
            title: 'Achievement',
            dataIndex: 'achievement',
            key: 'achievement',
            width: '4rem',
            align: 'right',
            filtered: true,
        },
        {
            title: 'Budget',
            dataIndex: 'budget',
            key: 'budget',
            width: '4rem',
            align: 'right',
            filtered: true,
        },
    ]
    return {
        columns,
    }
}
