import { useContext, useEffect } from 'react'
import { Table } from 'antd'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'
import { useRunner } from '../../../hooks/budgeting/runner.hook'
import { emptyRunner, IRunner } from '../../../models/budgeting/runner.model'
import { runnerTableColumns } from './runner-colum.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import RunnerForm from './runner-form.component'
import { UpdateMode } from '../../../models/update-mode.enum'

interface IProps {
    showButton?: boolean
}

const RunnerTable: React.FC<IProps> = ({ showButton = false }) => {
    const { setRunner, runners } = useRunner()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const router = useHistory()
    // const route = use
    const handleRowClick = (Runner: IRunner) => {
        setRunner(Runner)
        router.push(`/runners/${slugify(Runner.description)}`)
    }

    const handleShow = () => {
        setRunner(emptyRunner)
        setTitle('Add New Runner')
        setContent(<RunnerForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    useEffect(() => {}, [runners])

    return (
        <>
            {runners && runners.length ? (
                // <ExcelExportWrapper columns={runnerTableColumns} data={runners} fileName="runners">
                <Table<IRunner>
                    dataSource={runners}
                    columns={runnerTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    scroll={{ x: '500px' }}
                    onRow={(record: IRunner) => {
                        return {
                            onClick: (_) => {
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                // </ExcelExportWrapper>
                //</ExcelExportWrapper>
                <NoContent
                    title="No data for Runner"
                    showButton={showButton}
                    handleClick={handleShow}
                    buttonLabel="Add New Runner"
                />
            )}
        </>
    )
}

export default RunnerTable
