import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as userBranchModel from '../../../models/common/user-branch.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import userBranchService from '../../../services/common/user-branch/user-branch.service'

export const initialState: userBranchModel.IUserBranchState = {
    userBranches: [],
    errors: '',
    userBranch: userBranchModel.emptyUserBranch,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchUserBranchesAsync = createAsyncThunk<userBranchModel.IUserBranch[], void>(
    'userBranch/fetchUserBranchesAsync',
    async (_, thunkApi) => {
        try {
            return await userBranchService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const userBranchSlice = createSlice({
    name: 'userBranch',
    initialState,
    reducers: {
        fetchUserBranchesRequest: (state) => {
            state.isLoading = true
        },
        fetchUserBranchesSuccess: (
            state,
            action: PayloadAction<userBranchModel.IUserBranch[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.userBranches = action.payload
        },
        fetchUserBranchesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        // editUserBranchSuccess: (state, action: PayloadAction<userBranchModel.IUserBranchDto>) => {
        //     const obj: userBranchModel.IUserBranch[] = action.payload.branches.map(b => {
        //         return {
        //             ...userBranchModel.emptyUserBranch,
        //             tenant: action.payload.tenant,
        //             usrCode: action.payload.user,
        //             branchCode: b,
        //         }
        //     })
        //     state.userBranches = state.userBranches.map((userBranch) => {
        //         return userBranch.usrCode === action.payload.user ? action.payload : userBranch
        //     })
        //     state.updateMode = UpdateMode.NONE
        // },
        addUserBranchSuccess: (state, action: PayloadAction<userBranchModel.IUserBranchDto>) => {
            const obj: userBranchModel.IUserBranch[] = action.payload.branches.map(b => {
                return {
                    ...userBranchModel.emptyUserBranch,
                    tenant: action.payload.tenant,
                    usrCode: action.payload.user,
                    branchCode: b,
                }
            })
            state.userBranches = [...state.userBranches, ...obj ]
            state.updateMode = UpdateMode.NONE
        },
        setActiveUserBranch: (state, action: PayloadAction<userBranchModel.IUserBranch>) => {
            state.userBranch = action.payload
        },
        setUserBranchUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserBranchesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchUserBranchesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.userBranches = action.payload
        })
        builder.addCase(fetchUserBranchesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchUserBranchesRequest,
    fetchUserBranchesSuccess,
    fetchUserBranchesError,
    // editUserBranchSuccess,
    addUserBranchSuccess,
    setActiveUserBranch,
    setUserBranchUpdateMode,
} = userBranchSlice.actions

const reducer = userBranchSlice.reducer

export { reducer as userBranchReducer }
