import * as React from 'react'
import { Link } from 'react-router-dom'
import { LoginOutlined } from '@ant-design/icons'
import { signinRedirect } from '../../services/shared/auth/auth.service'

export const NavigationMenu: React.FC = () => {
    return (
        <ul className="navbar">
            {/* <li className="navbar__item">
                <Link to={'/home'} className="navItem__link" data-item="home">
                    Home
                </Link>
            </li>
            <li className="navbar__item">
                <Link to={'/about'} className="navItem__link" data-item="about">
                    About
                </Link>
            </li>
            <li className="navbar__item">
                <Link to={'/help'} className="navItem__link" data-item="help">
                    Help
                </Link>
            </li>
            <li className="navbar__item">
                <Link to={'/blog'} className="navItem__link" data-item="blog">
                    Blog
                </Link>
            </li>
            <li className="navbar__item">
                <Link to={'/contact'} className="navItem__link" data-item="contact">
                    Contact
                </Link>
            </li> */}
            <li className="navbar__item">
                <Link
                    to={'#'}
                    className="navItem__link"
                    data-item="login"
                    onClick={() => signinRedirect()}
                >
                    <span style={{paddingRight:'0.3rem'}}>
                        <LoginOutlined />
                    </span>
                    Login
                </Link>
            </li>
        </ul>
    )
}
