import React, { useRef } from 'react'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import ReportFilterComponent from '../report-filter.form.component'

const SiteCdvErTemplateReport: React.FC = () => {
    const ref = useRef()
    return (
        <div>
            <TitleBar title="Site CDV ER Template report" />
            <ReportFilterComponent formRef={ref} />
        </div>
    )
}
export default SiteCdvErTemplateReport
