import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ISource {
    tenant: string
    code: string
    description: string
}

export interface ISourceState extends IBaseState {
    readonly sources: ISource[]
    readonly source: ISource
}

export const emptySource: ISource = {
    tenant: '',
    code: '',
    description: '',
}
export interface ISourceResponse extends IResponseBase {
    data: ISource
}
