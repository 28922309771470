import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as dialogMessageModel from '../../../models/common/dialog-message.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import dialogMessageService from '../../../services/common/dialog-message/dialog-message.service'

export const initialState: dialogMessageModel.IDialogMessageState = {
    dialogMessages: [],
    errors: '',
    dialogMessage: dialogMessageModel.emptyDialogMessage,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchDialogMessagesAsync = createAsyncThunk(
    'branch/fetchDialogMessagesAsync',
    async () => {
        return await dialogMessageService.list()
    }
)

export const dialogMessageSlice = createSlice({
    name: 'dialogMessage',
    initialState,
    reducers: {
        fetchDialogMessagesRequest: (state) => {
            state.isLoading = true
        },
        fetchDialogMessagesSuccess: (
            state,
            action: PayloadAction<dialogMessageModel.IDialogMessage[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.dialogMessages = action.payload
        },
        fetchDialogMessagesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editDialogMessageSuccess: (
            state,
            action: PayloadAction<dialogMessageModel.IDialogMessage>
        ) => {
            state.dialogMessages = state.dialogMessages.map((contactrelation) => {
                return contactrelation.code === action.payload.code
                    ? action.payload
                    : contactrelation
            })
            state.updateMode = UpdateMode.NONE
        },
        addDialogMessageSuccess: (
            state,
            action: PayloadAction<dialogMessageModel.IDialogMessage>
        ) => {
            state.dialogMessages = [...state.dialogMessages, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveDialogMessage: (
            state,
            action: PayloadAction<dialogMessageModel.IDialogMessage>
        ) => {
            state.dialogMessage = action.payload
        },
        setDialogMessageUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDialogMessagesAsync.pending, (_state, _action) => {
            // fetchDialogMessagesRequest();
            _state.isLoading = true
        })
        builder.addCase(fetchDialogMessagesAsync.fulfilled, (_state, action) => {
            // fetchDialogMessagesSuccess(action.payload);
            _state.isLoading = false
            _state.initialFetch = false
            _state.dialogMessages = action.payload
        })
        builder.addCase(fetchDialogMessagesAsync.rejected, (_state, action) => {
            // fetchDialogMessagesError(action.payload as string);
            _state.isLoading = false
            // state.errors = action.payload;
        })
    },
})

export const {
    fetchDialogMessagesRequest,
    fetchDialogMessagesSuccess,
    fetchDialogMessagesError,
    editDialogMessageSuccess,
    addDialogMessageSuccess,
    setActiveDialogMessage,
    setDialogMessageUpdateMode,
} = dialogMessageSlice.actions

const reducer = dialogMessageSlice.reducer

export { reducer as dialogMessageReducer }
