import { applyMiddleware, createStore } from 'redux'

import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import loggerMiddleware from 'redux-logger'
import { persistedReducer } from './root-reducer'

const initialState = {}

const middlewares: [any] = [thunkMiddleware]

// const middlewareEnhancer = applyMiddleware(...middlewares)

// const enhancers = [middlewareEnhancer]
// const composedEnhancers = composeWithDevTools(...enhancers)

// const store = configureStore({
//     reducer: persistedReducer,
//     middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware),
//     devTools: process.env.NODE_ENV !== 'production',
//     preloadedState: initialState,
//     enhancers: [composedEnhancers],
//   })

// export default store

if (process.env.NODE_ENV === 'development') {
    middlewares.push(loggerMiddleware)
}

const middlewareEnhancer = applyMiddleware(...middlewares)

const enhancers = [middlewareEnhancer]
const composedEnhancers = composeWithDevTools(...enhancers)

const store = createStore(persistedReducer, initialState, composedEnhancers)

export default store
