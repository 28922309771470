import { rootState } from '../../../redux/root-reducer'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const useSessionBatch = () => {
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.sessionBatch.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.sessionBatch.initialFetch
    )

    useEffect(() => {}, [isLoading, initialFetch])

    return {
        isLoading,
        initialFetch,
    }
}

export { useSessionBatch }
