import React, { useEffect, useState } from 'react'
import useDebounce from '../../../hooks/shared/debounce/debounce.hook'
import { Input } from 'antd'
import { useTablePagination } from '../../../hooks/shared/pagination/pagination.hook'
import Icon from '@ant-design/icons'
import './search-input.scss'

export interface ISearchProps {
    handleChangeSearchQuery: (searchQuery: string) => void
    handleSearch: (searchQuery: string) => void
    placeHolder: string
    debounceDelay: number
    isSearching: boolean
}

const SearchInput: React.FC<ISearchProps> = ({
    handleChangeSearchQuery,
    handleSearch,
    placeHolder,
    debounceDelay,
    isSearching = false,
}) => {
    const [searchQuery, setSearchQuery] = useState<string | undefined>()
    const debouncedSearchQuery = useDebounce(searchQuery, debounceDelay)
    const { searchQuery: searchPhrase, setSearchQuery: setSearchPhrase } =
        useTablePagination()

    const { Search } = Input

    useEffect(() => {
        if (debouncedSearchQuery !== undefined) {
            handleChangeSearchQuery(debouncedSearchQuery)
        }
    }, [debouncedSearchQuery, handleChangeSearchQuery])

    const suffix = isSearching ? <Icon type="smile" /> : <span />

    return (
        <Search
            className="search_component"
            placeholder={placeHolder}
            onSearch={(value) => handleSearch(value)}
            onChange={(event) => {
                setSearchQuery(event.target.value)
                setSearchPhrase(event.target.value)
            }}
            style={{ marginRight: 5, outline: 0 }}
            defaultValue={searchPhrase || ''}
            disabled={isSearching}
            loading={isSearching}
            suffix={suffix}
        />
    )
}

export default SearchInput
