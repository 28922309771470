import { Spin, Tag, Typography } from 'antd'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { useTranslation } from 'react-i18next'
import { useMilestone } from '../../../hooks/budgeting/milestone.hook'
import MilestoneAchievementTable from '../../../components/budgeting/milestone-achievement/milestone-achievement-table.component'
import {
    FlexedDiv,
    SizedLabel,
    width100,
} from '../../../components/profile/profile.styles'
import { useRunner } from '../../../hooks/budgeting/runner.hook'
import { SelectSiteComponent } from '../../../components/common/select-site/select-site.component'
import {
    emptyConfiguration,
    IConfiguration,
} from '../../../models/configuration.model'
import { useConfiguration } from '../../../hooks/configuration.hook'
import { useTablePagination } from '../../../hooks/shared/pagination/pagination.hook'
import SearchInput from '../../../components/shared/search-input/search-input.component'
import {
    emptyMilestone,
    IMilestone,
} from '../../../models/budgeting/milestone.model'
import search from '../../../utils/search'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useRunnerHistory } from '../../../hooks/budgeting/runner-history.hook'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import MilestoneAchievementFormUpdate from '../../../components/budgeting/milestone-achievement/update/milestone-achievement-form-update.component'

const { Title } = Typography

const MilestoneAchievementPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { t } = useTranslation()
    const { searchQuery, setSearchQuery } = useTablePagination()
    const [query, setQuery] = useState<string>(searchQuery)

    const { isLoading, initialFetch, getMilestoneAchievements, site, setMilestone } =
        useMilestone()
    const { runner } = useRunner()
    const { getRunnerHistory } = useRunnerHistory()
    const [showComponent, setShowComponent] = useState(false)
    const { setConfiguration } = useConfiguration()
    const { currentBranch } = useCurrentBranch()

    const handleChangeSearchQuery = (searchQuery: string) => {
        setQuery(searchQuery)
    }

    const handleSearch = (searchQuery: string) => {
        setSearchQuery(searchQuery)
    }

    const milestones = getMilestoneAchievements(site)
    const filteredMilestones: IMilestone[] =
        milestones.length > 0
            ? milestones.filter((milestn) =>
                  search(
                      milestn,
                      ['activity', 'budgetNote', 'site', 'budget'],
                      query,
                      false
                  )
              )
            : []

    const configuration = useMemo((): IConfiguration => {
        return {
            ...emptyConfiguration,
            activityType: '01',
            project: currentBranch.project,
        }
    }, [currentBranch.project])

    const handleShow = () => {
        setTitle(t('budgeting:milestoneAchievement.form.title'))
        setMilestone(emptyMilestone)
        setContent(<MilestoneAchievementFormUpdate />)
        setWidth('40rem')
        setShow(true)
    }
    useEffect(() => {
        setConfiguration(configuration)
    }, [site, milestones, setConfiguration, currentBranch.project, configuration])

    return (
        <>
            <PageBreadCrumbs
                items={[
                    t(
                        'budgeting:milestoneAchievement.breadcrumb.milstoneArchivement'
                    ),
                    t('budgeting:milestoneAchievement.breadcrumb.list'),
                ]}
            />
            <TitleBar
                title={t('budgeting:milestoneAchievement.mainPage.title')}
                subTitle={t('budgeting:milestoneAchievement.mainPage.subTitle')}
                showButton
                buttonLabel={t(
                    'budgeting:milestoneAchievement.mainPage.buttonLabel'
                )}
                handleShow={handleShow}
            />
            <FlexedDiv>
                <FlexedDiv>
                    <SizedLabel>
                        <Tag
                            color="blue"
                            style={{
                                borderRadius: 3,
                                marginBottom: 5,
                                ...width100,
                                padding: `4px 5px`,
                            }}
                        >
                            <Title
                                level={5}
                                style={{ marginBottom: 0, fontSize: 14 }}
                            >{`${runner.milestoneProjection} - ${
                                getRunnerHistory(runner.milestoneProjection)
                                    ?.description
                            }`}</Title>
                        </Tag>
                    </SizedLabel>

                    <SizedLabel>
                        <SelectSiteComponent setShow={setShowComponent} />
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv style={{ alignSelf: 'stretch' }}>
                    <SizedLabel>
                        <SearchInput
                            isSearching={false}
                            handleChangeSearchQuery={(query) =>
                                handleChangeSearchQuery(query)
                            }
                            placeHolder="Search for an activity..."
                            handleSearch={(query) => handleSearch(query)}
                            debounceDelay={500}
                        />
                    </SizedLabel>
                </FlexedDiv>
            </FlexedDiv>
            {isLoading && initialFetch ? (
                <Spin size="large" />
            ) : (
                <>
                    {showComponent ? (
                        <MilestoneAchievementTable milestones={filteredMilestones} />
                    ) : (
                        <div
                            style={{
                                height: 300,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        ></div>
                    )}
                </>
            )}
        </>
    )
}

export default MilestoneAchievementPage
