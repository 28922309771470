import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as dateGenerationActions from '../../../redux/common/date-generation/date-generation.slice'
import { useCurrentBranch } from '../current-branch/current-branch.hook'
import {
    emptyDateGeneration,
    IDateGeneration,
} from '../../../models/common/date-generation.model'
import dateGenerationService from '../../../services/common/date-generation/date-generation.service'
import { UpdateMode } from '../../../models/update-mode.enum'

const useDateGeneration = () => {
    const { currentBranch } = useCurrentBranch()
    const dateGenerations = useSelector<rootState, IDateGeneration[]>(
        (state) => state.dateGeneration.dateGenerations
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.dateGeneration.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.dateGeneration.initialFetch
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.dateGeneration.updateMode
    )

    const dispatch = useDispatch()

    const loadDateGenerations = useCallback(() => {
        if (isLoading) {
            return
        }
        if (initialFetch) {
            dispatch(dateGenerationActions.fetchDateGenerationsRequest())
        }
    }, [dispatch, initialFetch, isLoading])

    const addDateGeneration = async (dateGeneration: IDateGeneration) => {
        return await dateGenerationService
            .create(dateGeneration)
            .then((dateGenerationResponse) => {
                dispatch(
                    dateGenerationActions.addDateGenerationSuccess(
                        dateGenerationResponse.data
                    )
                )
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const setDateGeneration = (dateGeneration: IDateGeneration) => {
        dispatch(dateGenerationActions.setActiveDateGeneration(dateGeneration))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(dateGenerationActions.setDateGenerationUpdateMode(updateMode))
    }

    const editDateGeneration = async (dateGeneration: IDateGeneration) => {
        return await dateGenerationService
            .update(dateGeneration)
            .then((dateGenerationResponse) => {
                dispatch(
                    dateGenerationActions.editDateGenerationSuccess(
                        dateGenerationResponse.data
                    )
                )
                setDateGeneration(dateGenerationResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveDateGeneration = async (
        dateGeneration: IDateGeneration,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addDateGeneration(dateGeneration)
            : await editDateGeneration(dateGeneration)
    }

    const getActiveBranchDateGeneration = useCallback((): IDateGeneration => {
        if (!dateGenerations) {
            return {
                ...emptyDateGeneration,
            }
        }
        const branchDateGeneration = dateGenerations.find(
            (x) => x.branch === currentBranch.code
        )

        if (branchDateGeneration) {
            return branchDateGeneration
        }
        return {
            ...emptyDateGeneration,
        }
    }, [currentBranch, dateGenerations])

    useEffect(() => {
        loadDateGenerations()
    }, [
        dateGenerations,
        isLoading,
        initialFetch,
        loadDateGenerations,
        getActiveBranchDateGeneration,
    ])

    return {
        dateGeneration: getActiveBranchDateGeneration(),
        dateGenerations,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addDateGeneration,
        editDateGeneration,
        saveDateGeneration,
        setDateGeneration,
    }
}

export { useDateGeneration }
