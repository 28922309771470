import { Table } from 'antd'
import { useDailyData } from '../../../hooks/budgeting/daily-data.hook'
import { IDailyData } from '../../../models/budgeting/daily-data.model'

import { NoContent } from '../../shared/no-content/no-content.component'
import { useDailyDataColumns } from './daily-data-table.column'

const DailyReportTable: React.FC = () => {
    const { dailyDatas } = useDailyData()
    const { dailyReportTableColumns } = useDailyDataColumns()
    return (
        <>
            {dailyDatas && dailyDatas.length > 0 ? (
                <Table<IDailyData>
                    dataSource={dailyDatas}
                    columns={dailyReportTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    scroll={{ x: '500px' }}
                    pagination={false}
                />
            ) : (
                <NoContent
                    title="No data for milestone projection"
                    showButton={false}
                />
            )}
        </>
    )
}

export default DailyReportTable
