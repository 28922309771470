import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as runnerStatusActions from '../../redux/budgeting/runner-status.slice'
import { IRunnerStatus } from '../../models/budgeting/runner-status.model'
import { UpdateMode } from '../../models/update-mode.enum'
import runnerStatuseservice from '../../services/budgeting/runner-status.service'

const useRunnerStatus = () => {
    const runnerStatuses = useSelector<rootState, IRunnerStatus[]>(
        (state) => state.runnerStatus.runnerStatuses
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.runnerStatus.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.runnerStatus.initialFetch
    )
    const runnerStatus = useSelector<rootState, IRunnerStatus>(
        (state) => state.runnerStatus.runnerStatus
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.runnerStatus.updateMode
    )

    const dispatch = useDispatch()

    const loadRunnerStatuses = useCallback(() => {
        if (initialFetch) {
            dispatch(runnerStatusActions.fetchRunnerStatusesAsync())
        }
    }, [dispatch, initialFetch])

    const addRunnerStatus = async (runnerStatus: IRunnerStatus) => {
        return await runnerStatuseservice
            .create(runnerStatus)
            .then((runnerStatusResponse) => {
                if (runnerStatusResponse.success) {
                    dispatch(
                        runnerStatusActions.addRunnerStatusSuccess(
                            runnerStatusResponse.data
                        )
                    )
                } else {
                    return runnerStatusResponse
                }
                return runnerStatusResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setRunnerStatus = (runnerStatus: IRunnerStatus) => {
        dispatch(runnerStatusActions.setActiveRunnerStatus(runnerStatus))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(runnerStatusActions.setRunnerStatusUpdateMode(updateMode))
    }

    const editRunnerStatus = async (runnerStatus: IRunnerStatus) => {
        return await runnerStatuseservice
            .update(runnerStatus)
            .then((runnerStatusResponse) => {
                dispatch(
                    runnerStatusActions.editRunnerStatusSuccess(
                        runnerStatusResponse.data
                    )
                )
                setRunnerStatus(runnerStatusResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveRunnerStatus = async (
        runnerStatus: IRunnerStatus,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addRunnerStatus(runnerStatus)
            : await editRunnerStatus(runnerStatus)
    }

    useEffect(() => {
        loadRunnerStatuses()
    }, [runnerStatus, runnerStatuses, isLoading, initialFetch, loadRunnerStatuses])

    return {
        runnerStatus,
        runnerStatuses,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addRunnerStatus,
        editRunnerStatus,
        saveRunnerStatus,
        setRunnerStatus,
    }
}

export { useRunnerStatus }
