import React, { useEffect } from 'react'
import { signoutRedirectCallback } from '../../services/shared/auth/auth.service'
import { useHistory } from 'react-router-dom'

const SignoutOidcPage: React.FC = () => {
    const history = useHistory()
    useEffect(() => {
        async function signoutAsync() {
            await signoutRedirectCallback()
            history.push('/')
        }
        signoutAsync()
    }, [history])

    return <div>Redirecting...</div>
}

export default SignoutOidcPage
