import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ISurgeActivity {
    tenant: string
    code: string
    createdOn: Date
}

export const emptySurgeActivity: ISurgeActivity = {
    tenant: '',
    code: '',
    createdOn: new Date(),
}

export interface ISurgeActivityState extends IBaseState {
    readonly surgeActivities: ISurgeActivity[]
    readonly surgeActivity: ISurgeActivity
}

export interface ISurgeActivityResponse extends IResponseBase {
    data: ISurgeActivity
}
