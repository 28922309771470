import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import SubDivisionForm from '../../../components/common/sub-division/sub-division-form.component'
import SubDivisionTable from '../../../components/common/sub-division/sub-division-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useSubDivision } from '../../../hooks/common/sub-division/sub-division.hook'
import { emptySubDivision } from '../../../models/common/sub-division.model'
import { UpdateMode } from '../../../models/update-mode.enum'

const SubDivisionPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)

    const { setSubDivision } = useSubDivision()

    const handleShow = () => {
        setTitle('Create new sub division')
        setSubDivision(emptySubDivision)
        setContent(<SubDivisionForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'SubDivisions']} />
            <TitleBar
                title="Sub Divisions"
                subTitle="View and manage sub divisions"
                showButton
                handleShow={handleShow}
                buttonLabel="Add Sub Division"
                icon={<FiPlus />}
            />
            <SubDivisionTable />
        </>
    )
}

export default SubDivisionPage
