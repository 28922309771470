export const enAnnualTarget = {
    tenant: 'Tenant',
    outlay: 'Outlay',
    copYear: 'Component year',
    project: 'Project',
    component: 'Component',
    region: 'Region',
    target: 'Target',
    createdOn: 'Created on',
}
