import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as copYearInterventionModel from '../../models/budgeting/cop-year-intervention.model'
import copYearInterventionservice from '../../services/budgeting/cop-year-intervention.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: copYearInterventionModel.ICopYearInterventionState = {
    copYearInterventions: [],
    errors: '',
    copYearIntervention: copYearInterventionModel.emptyCopYearIntervention,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchcopYearInterventionsAsync = createAsyncThunk<
    copYearInterventionModel.ICopYearIntervention[],
    void
>('copYearIntervention/fetchcopYearInterventionsAsync', async (_, thunkApi) => {
    try {
        return await copYearInterventionservice.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const copYearInterventionslice = createSlice({
    name: 'copYearIntervention',
    initialState,
    reducers: {
        fetchcopYearInterventionsRequest: (state) => {
            state.isLoading = true
        },
        fetchcopYearInterventionsSuccess: (
            state,
            action: PayloadAction<copYearInterventionModel.ICopYearIntervention[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.copYearInterventions = action.payload
        },
        fetchcopYearInterventionsError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editCopYearInterventionsuccess: (
            state,
            action: PayloadAction<copYearInterventionModel.ICopYearIntervention>
        ) => {
            state.copYearInterventions = state.copYearInterventions.map(
                (copYearIntervention) => {
                    return copYearIntervention.intervention ===
                        action.payload.intervention
                        ? action.payload
                        : copYearIntervention
                }
            )
            state.updateMode = UpdateMode.NONE
        },
        addCopYearInterventionsuccess: (
            state,
            action: PayloadAction<copYearInterventionModel.ICopYearIntervention>
        ) => {
            state.copYearInterventions = [
                ...state.copYearInterventions,
                action.payload,
            ]
            state.updateMode = UpdateMode.NONE
        },
        setActiveCopYearIntervention: (
            state,
            action: PayloadAction<copYearInterventionModel.ICopYearIntervention>
        ) => {
            state.copYearIntervention = action.payload
        },
        setCopYearInterventionUpdateMode: (
            state,
            action: PayloadAction<UpdateMode>
        ) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchcopYearInterventionsAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(
            fetchcopYearInterventionsAsync.fulfilled,
            (state, action) => {
                state.isLoading = false
                state.initialFetch = false
                state.copYearInterventions = action.payload
            }
        )
        builder.addCase(fetchcopYearInterventionsAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchcopYearInterventionsRequest,
    fetchcopYearInterventionsSuccess,
    fetchcopYearInterventionsError,
    editCopYearInterventionsuccess,
    addCopYearInterventionsuccess,
    setActiveCopYearIntervention,
    setCopYearInterventionUpdateMode,
} = copYearInterventionslice.actions

const reducer = copYearInterventionslice.reducer

export { reducer as copYearInterventionReducer }
