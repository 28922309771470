import { useContext, useEffect, useRef } from 'react'
import { Button, Space, Table, Tag } from 'antd'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { useActivity } from '../../../hooks/budgeting/activity.hook'
import { emptyActivity } from '../../../models/budgeting/activity.model'
import { activityTableColumns } from './activity-column.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import ActivityForm from './activity-form.component'
import { UpdateMode } from '../../../models/update-mode.enum'
import { IActivitySummary } from '../../../models/budgeting/activity-summary.model'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { ActivityEntryHiddenTable } from '../activity-summary/activity-entry-hidden.component'
import { Excel } from 'antd-table-saveas-excel'
import { activityExcelTableColumns } from './activity-excel-column.component'

interface IProps {
    showButton?: boolean
    isSurge?: boolean
}

const ActivityTable: React.FC<IProps> = ({
    showButton = false,
    isSurge = false,
}) => {
    const {
        setActivity,
        activitySummaryTabbed,
        setActivitySummary,
        activities,
        totalAmount,
        getMapedExcelActivities,
    } = useActivity()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const router = useHistory()
    // const route = use
    const handleRowClick = (summary: IActivitySummary) => {
        setActivitySummary(summary)
        const activity = activities.find((x) => x.batch === summary.batch)
        if (activity) {
            setActivity(activity)
        }
        if (isSurge) {
            router.push(`/surge-activities/${summary.batch}`)
        } else {
            router.push(`/site-activities/${summary.batch}`)
        }
    }

    const handleShow = () => {
        setActivity(emptyActivity)
        setTitle('Add New Activity')
        setContent(<ActivityForm isSurge={isSurge} updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    const recordRef = useRef<any>()

    const excelExport = () => {
        const excelData = getMapedExcelActivities(activities)
        const excel = new Excel()
        excel
            .addSheet('sheet 1')
            .addColumns(activityExcelTableColumns as any)
            .addDataSource(excelData, {
                str2Percent: true,
            })
            .saveAs('Activities.xlsx')
    }

    useEffect(() => {}, [activitySummaryTabbed])
    return (
        <div ref={recordRef}>
            {activitySummaryTabbed && activitySummaryTabbed.length ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Tag color="purple">
                            <h6 style={{ marginBottom: 0 }}>
                                Total Amount: {totalAmount}
                            </h6>
                        </Tag>
                        <Space>
                            <Button onClick={excelExport}>Export to excel</Button>
                        </Space>
                    </div>

                    <Table<IActivitySummary>
                        dataSource={activitySummaryTabbed}
                        columns={activityTableColumns}
                        className="activity__table activity__table--infinite"
                        id="activity__table"
                        size={'small'}
                        rowKey={`description`}
                        scroll={{ x: '500' }}
                        onRow={(record: IActivitySummary) => {
                            return {
                                onClick: (e) => {
                                    recordRef.current = e.target
                                    const target = recordRef.current.closest(
                                        '.ant-table-row-expand-icon-cell'
                                    )
                                    if (Object.is(target, null)) {
                                        handleRowClick(record)
                                    }
                                },
                            }
                        }}
                        expandable={{
                            expandedRowRender: (record) => (
                                <ActivityEntryHiddenTable data={record} />
                            ),
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <DownOutlined
                                        className="expand__icon"
                                        style={{
                                            fontSize: '1rem',
                                            color: '#858585',
                                        }}
                                        onClick={(e) => onExpand(record, e)}
                                    />
                                ) : (
                                    <RightOutlined
                                        className="expand__icon"
                                        style={{
                                            fontSize: '1rem',
                                            color: '#858585',
                                        }}
                                        onClick={(e) => onExpand(record, e)}
                                    />
                                ),
                        }}
                    />
                </>
            ) : (
                <NoContent
                    title="You have not entered any activities for the crurent runner. Click on the button below to start capturing your activities."
                    showButton={showButton}
                    handleClick={handleShow}
                    buttonLabel="Add New Activity"
                />
            )}
        </div>
    )
}

export default ActivityTable
