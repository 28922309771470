import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as stationreferenceModel from '../../../models/common/station-reference.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import stationreferenceService from '../../../services/common/station-reference/station-reference-service'

export const initialState: stationreferenceModel.IStationReferenceState = {
    stationReferences: [],
    errors: '',
    stationReference: stationreferenceModel.emptyStationReference,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchStationReferencesAsync = createAsyncThunk(
    'stationreference/fetchStationReferencesAsync',
    async () => {
        return await stationreferenceService.list()
    }
)

export const stationreferenceSlice = createSlice({
    name: 'stationreference',
    initialState,
    reducers: {
        fetchStationReferencesRequest: (state) => {
            state.isLoading = true
        },
        fetchStationReferencesSuccess: (
            state,
            action: PayloadAction<stationreferenceModel.IStationReference[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.stationReferences = action.payload
        },
        fetchStationReferencesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editStationReferenceSuccess: (
            state,
            action: PayloadAction<stationreferenceModel.IStationReference>
        ) => {
            state.stationReferences = state.stationReferences.map(
                (stationReference) => {
                    return stationReference.code === action.payload.code
                        ? action.payload
                        : stationReference
                }
            )
            state.updateMode = UpdateMode.NONE
        },
        addStationReferenceSuccess: (
            state,
            action: PayloadAction<stationreferenceModel.IStationReference>
        ) => {
            state.stationReferences = [...state.stationReferences, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveStationReference: (
            state,
            action: PayloadAction<stationreferenceModel.IStationReference>
        ) => {
            state.stationReference = action.payload
        },
        setStationReferenceUpdateMode: (
            state,
            action: PayloadAction<UpdateMode>
        ) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStationReferencesAsync.pending, (_state, _action) => {
            // fetchStationReferencesRequest();
            _state.isLoading = true
        })
        builder.addCase(fetchStationReferencesAsync.fulfilled, (_state, action) => {
            // fetchStationReferencesSuccess(action.payload);
            _state.isLoading = false
            _state.initialFetch = false
            _state.stationReferences = action.payload
        })
        builder.addCase(fetchStationReferencesAsync.rejected, (_state, action) => {
            // fetchStationReferencesError(action.payload as string);
            _state.isLoading = false
            // state.errors = action.payload;
        })
    },
})

export const {
    fetchStationReferencesRequest,
    fetchStationReferencesSuccess,
    fetchStationReferencesError,
    editStationReferenceSuccess,
    addStationReferenceSuccess,
    setActiveStationReference,
    setStationReferenceUpdateMode,
} = stationreferenceSlice.actions

const reducer = stationreferenceSlice.reducer

export { reducer as stationReferenceReducer }
