import BranchStationDetailsPage from '../../../pages/common/branch-station/branch-station-detail.page'
import BranchStationPage from '../../../pages/common/branch-station/branch-station.page'
import DivisionDetailsPage from '../../../pages/common/division/division-detail.page'
import DivisionPage from '../../../pages/common/division/division.page'
import BranchDetailsPage from '../../../pages/common/branch/branch-detail.page'
import BranchPage from '../../../pages/common/branch/branch.page'
import SourceDetailsPage from '../../../pages/common/source/source-detail.page'
import SourcePage from '../../../pages/common/source/source.page'
import StationReferenceDetailsPage from '../../../pages/common/station-reference/station-reference-detail.page'
import StationReferencePage from '../../../pages/common/station-reference/station-reference.page'
import SubDivisionDetailsPage from '../../../pages/common/sub-division/sub-division-detail.page'
import SubDivisionPage from '../../../pages/common/sub-division/sub-division.page'
import LocationPage from '../../../pages/common/location/location.page'
import LocationDetailsPage from '../../../pages/common/location/location-detail.page'
import ServicePage from '../../../pages/common/service/service.page'
import ServiceDetailsPage from '../../../pages/common/service/service-details.page'
import DialogMessageDetailsPage from '../../../pages/common/dialog-message/dialog-message-detail.page'
import DialogMessagesPage from '../../../pages/common/dialog-message/dialog-message.page'
import UsersPage from '../../../pages/manage/users/users.page'
import { IRoute } from '../../../models/common/route.model'

export const configurationRoutes: IRoute[] = [
    /**
     * configuration
     */
    {
        path: '/configurations/divisions',
        private: true,
        exact: true,
        component: DivisionPage,
    },
    {
        path: '/configurations/divisions/:code',
        private: true,
        exact: true,
        component: DivisionDetailsPage,
    },
    {
        path: '/configurations/branches',
        private: true,
        exact: true,
        component: BranchPage,
    },
    {
        path: '/configurations/branches/:code',
        private: true,
        exact: true,
        component: BranchDetailsPage,
    },
    {
        path: '/configurations/locations',
        private: true,
        exact: true,
        component: LocationPage,
    },
    {
        path: '/configurations/locations/:code',
        private: true,
        exact: true,
        component: LocationDetailsPage,
    },
    {
        path: '/configurations/sources',
        private: true,
        exact: true,
        component: SourcePage,
    },
    {
        path: '/configurations/sources/:code',
        private: true,
        exact: true,
        component: SourceDetailsPage,
    },
    {
        path: '/configurations/station-references',
        private: true,
        exact: true,
        component: StationReferencePage,
    },
    {
        path: '/configurations/station-references/:code',
        private: true,
        exact: true,
        component: StationReferenceDetailsPage,
    },
    {
        path: '/configurations/branch-stations',
        private: true,
        exact: true,
        component: BranchStationPage,
    },
    {
        path: '/configurations/branch-stations/:code',
        private: true,
        exact: true,
        component: BranchStationDetailsPage,
    },
    {
        path: '/configurations/subdivisions',
        private: true,
        exact: true,
        component: SubDivisionPage,
    },
    {
        path: '/configurations/subdivisions/:code',
        private: true,
        exact: true,
        component: SubDivisionDetailsPage,
    },
    {
        path: '/configurations/village',
        private: true,
        exact: true,
        component: UsersPage,
    },
    {
        path: '/configurations/services',
        private: true,
        exact: true,
        component: ServicePage,
    },
    {
        path: '/configurations/services/:code',
        private: true,
        exact: true,
        component: ServiceDetailsPage,
    },
    {
        path: '/configurations/dialog-messages',
        private: true,
        exact: true,
        component: DialogMessagesPage,
    },
    {
        path: '/configurations/dialog-messages/:code',
        private: true,
        exact: true,
        component: DialogMessageDetailsPage,
    },

]
