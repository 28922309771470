import { apiRequests } from '../'
import { IBudget, IBudgetResponse } from '../../models/budgeting/budget.model'

const budgetService = {
    list: (): Promise<IBudget[]> => apiRequests.get('/budgets'),
    details: (code: string): Promise<IBudget> => apiRequests.get(`/budgets/${code}`),
    create: (budget: IBudget): Promise<IBudgetResponse> =>
        apiRequests.post('/budgets', budget),
    update: (budget: IBudget): Promise<IBudgetResponse> =>
        apiRequests.put('/budgets', budget),
}

export default budgetService
