import { ColumnsType } from 'antd/lib/table'
import { IUser } from '../../../models/shared/user.model'
import UserRolesRenderer from './user-role-renderer.component'

export const userTableColumns: ColumnsType<IUser> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '5rem',
    },
    {
        title: 'Username',
        dataIndex: 'usrName',
        key: 'usrName',
        ellipsis: true,
        width: '15rem',
    },
    {
        title: 'Roles',
        dataIndex: 'roles',
        key: 'roles',
        render: (_, record) => <UserRolesRenderer userCode={record.code} />,
    },
]
