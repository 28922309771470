import { IBaseState } from '../base-state.model'
import { IBranch } from './branch.model'
import { IResponseBase } from '../response.base'

export interface ICurrentBranch extends IBranch {
    tenant: string
    code: string
    description: string
    branchShortName: string
    staCode: string
    telephone: string
    address: string
    postBox: string
    city: string
    region: string
    eMail: string
    website: string
    slogan: string
    branchName: string
    branchType: string
    bursaryStation: string
    branchTown: string
    project: string
    createdOn: Date
}

export interface ICurrentBranchState extends IBaseState {
    readonly currentBranches: ICurrentBranch[]
    readonly currentBranch: ICurrentBranch
}

export const emptyCurrentBranch: ICurrentBranch = {
    tenant: '',
    code: '',
    description: '',
    branchShortName: '',
    staCode: '',
    telephone: '',
    address: '',
    postBox: '',
    city: '',
    region: '',
    eMail: '',
    website: '',
    slogan: '',
    branchName: '',
    branchType: '',
    bursaryStation: '',
    branchTown: '',
    project: '',
    createdOn: new Date(),
}
export interface ICurrentBranchResponse extends IResponseBase {
    data: ICurrentBranch
}
