export const frBreadCrumb = {
    home: 'Maison',
    dashboard: 'Tableau de bord',

    cardHeader: {
        target: 'Cibles',
        archievement: 'Réalisations',
        budget: 'Budget',
        expenditure: 'Dépenses',
    },

    chartData: {
        title: "Budget d'étape vs dépenses",
        month: 'mois',
        amount: 'Montant',
    },
}
