import { IBaseState } from '../base-state.model'
import { IUserBranch } from './user-branch.model'
import { IResponseBase } from '../response.base'

export interface ICurrentUser {
    code: string
    name: string
    email: string
    telephone: string
    branch: string
    subject: string
    locale: string
    imageUrl?: string
    tenant: string
    userBranches: IUserBranch[]
}

export interface ICurrentUserState extends IBaseState {
    readonly currentUsers: ICurrentUser[]
    readonly currentUser: ICurrentUser
}

export const emptyCurrentUser: ICurrentUser = {
    code: '',
    name: '',
    email: '',
    telephone: '',
    branch: '',
    subject: '',
    imageUrl: '',
    tenant: '',
    locale: '',
    userBranches: [],
}
export interface ICurrentUserResponse extends IResponseBase {
    data: ICurrentUser
}
