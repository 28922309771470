import { UserOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import React from 'react'
import { IUser } from '../../models/shared/user.model'
import {
    StyledUserAvatarContent,
    StyleUserTableAvatar,
} from './users-styles.component'

type Props = {
    user: IUser
}

const UsersTableAvatarRenderer: React.FunctionComponent<Props> = ({ user }) => {
    return (
        <StyleUserTableAvatar>
            <Avatar size="large" icon={<UserOutlined />} />
            <StyledUserAvatarContent>
                <p>{user.usrName}</p>
                {/* <p>{user.email}</p> */}
            </StyledUserAvatarContent>
        </StyleUserTableAvatar>
    )
}

export default UsersTableAvatarRenderer
