import React from 'react'
import { loadUserFromStorage } from '../../../services/shared/auth/auth.service'
import './with-loading.css'

function LoadingMessage() {
    return (
        <div className="splash-screen" style={{ marginTop: '17rem' }}>
            {/* <div className="splash-screen" style={{ marginTop: '17rem' }}>
            please wait a moment while the application loads...
            <div className="loading-dot">.</div>
        </div> */}
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

interface Props {
    loading: boolean
}

function withLoading<T>(ChildComponent: React.ComponentType<any>) {
    return class extends React.Component<{}, Props> {
        constructor(props: any) {
            super(props)
            this.state = {
                loading: true,
            }
        }

        async componentDidMount() {
            try {
                await loadUserFromStorage()
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1500)
            } catch (err) {
                console.log(err)
                this.setState({
                    loading: false,
                })
            }
        }

        render() {
            // while checking user session, show "loading" message
            if (this.state.loading) return LoadingMessage()

            // otherwise, show the desired route
            return <ChildComponent {...(this.props as T)} />
        }
    }
}

export default withLoading
