import React, { useEffect } from 'react'
import styles from './info-bar.module.scss'
import { Typography } from 'antd'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useDateGeneration } from '../../../hooks/common/date-generation/date-generation.hook'
import { format } from '../../../utils/format'
import { BiStation } from 'react-icons/bi'
import { BsCalendar2DateFill } from 'react-icons/bs'
import { SiHomeassistant } from 'react-icons/si'
import styled from 'styled-components'
import { useRunnerComponent } from '../../../hooks/budgeting/runner-component.hook'
import { useRunner } from '../../../hooks/budgeting/runner.hook'
import { useWeek } from '../../../hooks/budgeting/week.hook'
import { useProject } from '../../../hooks/budgeting/project.hook'
import { GoProject } from 'react-icons/go'

const { Text } = Typography
const InfoBar: React.FC = () => {
    const { currentBranch } = useCurrentBranch()
    const { dateGeneration } = useDateGeneration()
    const { runnerComponent } = useRunnerComponent()
    const { runner } = useRunner()
    const { runnerWeek } = useWeek()
    const { projects } = useProject()

    const projectDescription =
        projects && projects.length > 0
            ? projects.find((p) => p.code === currentBranch.project)?.description
            : []

    useEffect(() => {}, [currentBranch, dateGeneration])

    return (
        <>
            <div className={styles.infoBar} style={{ margin: '50px 0 0 0' }}>
                <StyledParagraph>
                    <GoProject size={16} />
                    <Text>{projectDescription}</Text>
                </StyledParagraph>
                <StyledParagraph>
                    <SiHomeassistant size={16} />
                    <Text>
                        {runnerComponent.componentLongDescription ?? ''}
                        {runnerWeek
                            ? ` , WEEK ${format.twoChar(
                                  runnerWeek.weekSerialAdjusted.toString()
                              )}`
                            : ''}
                    </Text>
                </StyledParagraph>
                <StyledParagraph>
                    <BsCalendar2DateFill size={16} />
                    <Text>{currentBranch.branchName}</Text>
                </StyledParagraph>
                <StyledParagraph>
                    <BiStation size={16} />
                    <Text>
                        {' '}
                        {format.date(runner.startDate)} -{' '}
                        {format.date(runner.endDate)}
                    </Text>
                </StyledParagraph>
            </div>
        </>
    )
}

export default InfoBar

const StyledParagraph = styled.p`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #333;

    span.ant-typography {
        margin-left: 0.3rem;
    }
`
