import { ColumnsType } from 'antd/lib/table'
import { IActivitySummary } from '../../../models/budgeting/activity-summary.model'
import { format } from '../../../utils/format'

export const activityTableColumns: ColumnsType<IActivitySummary> = [
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
        width: '6rem',
        filtered: true,
    },
    {
        title: 'User Name',
        dataIndex: 'usrName',
        key: 'usrName',
        width: '15rem',
    },
    {
        title: 'Activity Type',
        width: '10rem',
        dataIndex: 'transactionCodeDescription',
        key: 'transactionCodeDescription',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        ellipsis: true,
    },
    {
        title: 'Number',
        dataIndex: 'number',
        key: 'number',
        width: '5rem',
        align: 'right',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: '10rem',
        align: 'right',
        render: (_, record) => format.number(record.amount),
    },
]
