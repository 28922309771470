import { useCallback, useContext, useEffect, useRef } from 'react'
import { Table } from 'antd'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { useActivity } from '../../../hooks/budgeting/activity.hook'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { UpdateMode } from '../../../models/update-mode.enum'
import ActivityForm from '../activity/activity-form.component'
import { activitySummaryTableColumns } from './activity-summary-table.columns'
import { IActivity } from '../../../models/budgeting/activity.model'
import { emptyActivitySummary } from '../../../models/budgeting/activity-summary.model'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import ActivitySummaryDetails from './activity-summary-detail.component'

interface IProps {
    showButton?: boolean
    isSurge?: boolean
}

const ActivitySummaryTable: React.FC<IProps> = ({
    showButton = false,
    isSurge = false,
}) => {
    const { setActivity, activities, activitySummary, getActivityBatch } =
        useActivity()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const router = useHistory()
    const recordRef = useRef<any>()

    const handleRowClick = (activity: IActivity) => {
        console.log('activity: ', activity)
        setActivity(activity)
        if (isSurge) {
            router.push(`/surge-activities/${activity.batch}/${activity.batchLine}`)
        } else {
            router.push(`/site-activities/${activity.batch}/${activity.batchLine}`)
        }
    }

    const getFirstActivitySummaryInBatch = useCallback(() => {
        if (activitySummary) {
            if (activities && activities.length) {
                const journal = activities.find(
                    (x) => x.batch === activitySummary.batch
                )
                if (journal) {
                    return journal
                }
                return emptyActivitySummary
            }
            return emptyActivitySummary
        }
        return emptyActivitySummary
    }, [activitySummary, activities])

    const handleShow = () => {
        setWidth('40rem')
        setShow(true)
        setContent(<ActivityForm isSurge={isSurge} updateMode={UpdateMode.ADD} />)
        setTitle('Add New Activity')

        const initialModel: any = {
            ...getFirstActivitySummaryInBatch(),
            amount: 0,
            description: '',
            site: '',
            activity: '',
            activityName: '',
            batchLine: '00000',
        }

        setActivity(initialModel)
    }

    useEffect(() => {
        const data = getActivityBatch(activitySummary.batch)
        if (data.length === 0) {
            router.goBack(-2)
        }
    }, [activitySummary.batch, getActivityBatch, router])

    return (
        <div ref={recordRef}>
            {activities && activities.length ? (
                <Table<IActivity>
                    dataSource={getActivityBatch(activitySummary.batch)}
                    columns={activitySummaryTableColumns}
                    size={'small'}
                    rowKey={'batchLine'}
                    scroll={{ x: '500' }}
                    onRow={(record: IActivity) => {
                        return {
                            onClick: (e) => {
                                recordRef.current = e.target
                                const target = recordRef.current.closest(
                                    '.ant-table-row-expand-icon-cell'
                                )
                                if (Object.is(target, null)) {
                                    handleRowClick(record)
                                }
                            },
                        }
                    }}
                    className="activity__table activity__table--infinite"
                    id="activity__table"
                    expandable={{
                        expandedRowRender: (record, index) => (
                            <div key={index}>
                                <ActivitySummaryDetails
                                    isSurge={isSurge}
                                    showEdit
                                    activity={record}
                                />
                            </div>
                        ),
                        expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                                <DownOutlined
                                    className="expand__icon"
                                    style={{ fontSize: '1rem', color: '#858585' }}
                                    onClick={(e) => onExpand(record, e)}
                                />
                            ) : (
                                <RightOutlined
                                    className="expand__icon"
                                    style={{ fontSize: '1rem', color: '#858585' }}
                                    onClick={(e) => onExpand(record, e)}
                                />
                            ),
                    }}
                />
            ) : (
                <NoContent
                    title="You have not entered any activities for the current runner. Click on the button below to start capturing your activities."
                    showButton={showButton}
                    handleClick={handleShow}
                    buttonLabel="Add New Activity"
                />
            )}
        </div>
    )
}

export default ActivitySummaryTable
