import { appRoutes } from '../../../constants/app-route'
import { IRoute } from '../../../models/common/route.model'
import SurgeActivityBatchPage from '../../../pages/budgeting/surge-activity/surge-activity-batch.page'
import SurgeActivityBatchDetailsPage from '../../../pages/budgeting/surge-activity/surge-activity-summary-detail.page'
import SurgeActivityPage from '../../../pages/budgeting/surge-activity/surge-activity.page'

export const surgeActivityRoutes: IRoute[] = [
    /**
     * Auth routes
     */
    {
        path: appRoutes.SURGE_ACTIVITY.SURGE_ACTIVITY_BATCH_SUMMARY,
        private: true,
        exact: true,
        component: SurgeActivityPage,
    },
    {
        path: appRoutes.SURGE_ACTIVITY.SURGE_ACTIVITY_BATCH,
        private: true,
        exact: true,
        component: SurgeActivityBatchPage,
    },
    /**
     * Activity Batch
     */
    {
        path: appRoutes.SURGE_ACTIVITY.SURGE_ACTIVITY_BATCH_DETAILS,
        private: true,
        exact: true,
        component: SurgeActivityBatchDetailsPage,
    },
]
