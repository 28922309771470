import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import {
    emptyRunnerComponentBase,
    IRunnerComponentBase,
} from './runner-component-base.model'

export interface IRunnerComponent extends IRunnerComponentBase {}

export interface IRunnerComponentState extends IBaseState {
    readonly runnerComponents: IRunnerComponent[]
    readonly runnerComponent: IRunnerComponent
}

export const emptyRunnerComponent: IRunnerComponent = emptyRunnerComponentBase

export interface IRunnerComponentResponse extends IResponseBase {
    data: IRunnerComponent
}
