import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IRegion {
    code: string
    zone: string
    initial: string
    description: string
}

export const emptyRegion: IRegion = {
    code: '',
    zone: '',
    initial: '',
    description: '',
}

export interface IRegionState extends IBaseState {
    readonly regions: IRegion[]
    readonly region: IRegion
}

export interface IRegionResponse extends IResponseBase {
    data: IRegion
}
