import { apiRequests } from '..'
import { IStrategy, IStrategyResponse } from '../../models/budgeting/strategy.model'

const strategyService = {
    list: (): Promise<IStrategy[]> => apiRequests.get('/strategies'),
    details: (code: string): Promise<IStrategy> =>
        apiRequests.get(`/strategies/${code}`),
    create: (strategy: IStrategy): Promise<IStrategyResponse> =>
        apiRequests.post('/strategies', strategy),
    update: (strategy: IStrategy): Promise<IStrategyResponse> =>
        apiRequests.put('/strategies', strategy),
}

export default strategyService
