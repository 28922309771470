import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IClaimType {
    code: string
    description: string
    slug?: string
    createdOn?: Date
}

export interface IClaimTypeState extends IBaseState {
    readonly claimTypes: IClaimType[]
    readonly claimType: IClaimType
}

export const emptyClaimType: IClaimType = {
    code: '',
    description: '',
    slug: '',
    createdOn: new Date(),
}

export interface IClaimTypeResponse extends IResponseBase {
    data: IClaimType
}
