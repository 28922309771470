import React from 'react'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import CostedWorkPlanFilterForm from '../../../components/reports/costed-work-flan-filter-form.component'

const SiteCostedWorkPlanReport: React.FC = () => {
    return (
        <div>
            <TitleBar title="Budget analysis" subTitle="by site" />
            <CostedWorkPlanFilterForm />
        </div>
    )
}
export default SiteCostedWorkPlanReport
