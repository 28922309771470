import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as subDivisionActions from '../../../redux/common/sub-division/sub-division.slice'
import { ISubDivision } from '../../../models/common/sub-division.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import subDivisionService from '../../../services/common/sub-division/sub-division.service'

const useSubDivision = () => {
    const subDivisions = useSelector<rootState, ISubDivision[]>(
        (state) => state.subDivision.subDivisions
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.subDivision.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.subDivision.initialFetch
    )
    const subDivision = useSelector<rootState, ISubDivision>(
        (state) => state.subDivision.subDivision
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.subDivision.updateMode
    )

    const dispatch = useDispatch()

    const loadSubDivisions = useCallback(() => {
        if (initialFetch) {
            dispatch(subDivisionActions.fetchSubDivisionsAsync())
        }
    }, [dispatch, initialFetch])

    const addSubDivision = async (subDivision: ISubDivision) => {
        return await subDivisionService
            .create(subDivision)
            .then((subDivisionResponse) => {
                dispatch(
                    subDivisionActions.addSubDivisionSuccess(
                        subDivisionResponse.data
                    )
                )
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const setSubDivision = (subDivision: ISubDivision) => {
        dispatch(subDivisionActions.setActiveSubDivision(subDivision))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(subDivisionActions.setSubDivisionUpdateMode(updateMode))
    }

    const editSubDivision = async (subDivision: ISubDivision) => {
        return await subDivisionService
            .update(subDivision)
            .then((subDivisionResponse) => {
                dispatch(
                    subDivisionActions.editSubDivisionSuccess(
                        subDivisionResponse.data
                    )
                )
                setSubDivision(subDivisionResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveSubDivision = async (
        subDivision: ISubDivision,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addSubDivision(subDivision)
            : await editSubDivision(subDivision)
    }

    useEffect(() => {
        loadSubDivisions()
    }, [subDivision, subDivisions, isLoading, initialFetch, loadSubDivisions])

    return {
        subDivision,
        subDivisions,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addSubDivision,
        editSubDivision,
        saveSubDivision,
        setSubDivision,
    }
}

export { useSubDivision }
