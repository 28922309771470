import { ColumnsType } from 'antd/lib/table'
import { IBranchStation } from '../../../models/common/branch-station.model'
import BranchNameRenderer from './branch-name-renderer.component'

export const branchStationsTableColumns: ColumnsType<IBranchStation> = [
    {
        title: 'Branch',
        dataIndex: 'branch',
        key: 'branch',
        width: '16rem',
        filtered: true,
        render: (_, record) => (
            <>
                <BranchNameRenderer branchStation={record} />
            </>
        ),
    },
    {
        title: 'Station',
        dataIndex: 'station',
        key: 'station',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
]
