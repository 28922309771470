import React from 'react'
import styles from './info-bar.module.scss'
import { Skeleton } from 'antd'
import styled from 'styled-components'

const InfoBarSkeleton: React.FC = () => {
    return (
        <>
            <div className={styles.infoBar} style={{ marginTop: 2 }}>
                <StyledParagraph>
                    <Skeleton.Button active={true} />
                    <Skeleton.Input active={true} />
                </StyledParagraph>
                <StyledParagraph>
                    <Skeleton.Button active={true} />
                    <Skeleton.Input active={true} />
                </StyledParagraph>
                <StyledParagraph>
                    <Skeleton.Button active={true} />
                    <Skeleton.Input active={true} />
                </StyledParagraph>
            </div>
        </>
    )
}

export default InfoBarSkeleton

const StyledParagraph = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #333;

    span.ant-typography {
        margin-left: 0.3rem;
    }
`
