import { apiRequests } from '..'
import { IRegion, IRegionResponse } from '../../models/budgeting/region.model'

const regionService = {
    list: (): Promise<IRegion[]> => apiRequests.get('/regions'),
    details: (code: string): Promise<IRegion> => apiRequests.get(`/regions/${code}`),
    create: (region: IRegion): Promise<IRegionResponse> =>
        apiRequests.post('/regions', region),
    update: (region: IRegion): Promise<IRegionResponse> =>
        apiRequests.put('/regions', region),
}

export default regionService
