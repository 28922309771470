import { ISideBarLink } from '../sidebar.data'
import { BiCategoryAlt } from 'react-icons/bi'
import { TbReport, TbReportAnalytics } from 'react-icons/tb'
import { AiOutlineInteraction, AiOutlineFundProjectionScreen } from 'react-icons/ai'

export const dashboardSubmenus: ISideBarLink[] = [
    {
        app: '01',
        code: 'B00',
        description: 'outlay vs target',
        route: '/dashboard/outlay-vs-target',
        dropDown: false,
        dropDownMenus: [],
        parent: '',
        icon: <BiCategoryAlt />,
    },
    {
        app: '01',
        parent: '',
        code: 'B01',
        description: 'Cost category',
        route: '/dashboard/cost-category',
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReportAnalytics />,
    },
    {
        app: '01',
        parent: '',
        code: 'B02',
        description: 'Interventions',
        route: '/dashboard/intervention',
        dropDown: false,
        dropDownMenus: [],
        icon: <AiOutlineInteraction />,
    },
    {
        app: '01',
        parent: '',
        code: 'B03',
        description: 'Milestone Projections',
        route: '/dashboard/milestone-projection',
        dropDown: false,
        dropDownMenus: [],
        icon: <AiOutlineFundProjectionScreen />,
    },
    {
        app: '01',
        parent: '',
        code: 'B04',
        description: 'Daily report',
        route: '/dashboard/daily-report',
        dropDown: false,
        dropDownMenus: [],
        icon: <TbReport />,
    },
]
