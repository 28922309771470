import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as annualTargetModel from '../../models/budgeting/annual-target.model'
import annualTargetService from '../../services/budgeting/annual-target.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: annualTargetModel.IAnnualTargetState = {
    annualTargets: [],
    errors: '',
    annualTarget: annualTargetModel.emptyAnnualTarget,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchAnnualTargetAsync = createAsyncThunk<
    annualTargetModel.IAnnualTarget[],
    void
>('annualTarget/fetchAnnualTargetAsync', async (_, thunkApi) => {
    try {
        return await annualTargetService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const annualTargetSlice = createSlice({
    name: 'annualTarget',
    initialState,
    reducers: {
        fetchAnnualTargetRequest: (state) => {
            state.isLoading = true
        },
        fetchAnnualTargetSuccess: (
            state,
            action: PayloadAction<annualTargetModel.IAnnualTarget[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.annualTargets = action.payload
        },
        fetchAnnualTargetError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editAnnualTargetSuccess: (
            state,
            action: PayloadAction<annualTargetModel.IAnnualTarget>
        ) => {
            state.annualTargets = state.annualTargets.map((annualTarget) => {
                return annualTarget.copYear === action.payload.copYear
                    ? action.payload
                    : annualTarget
            })
            state.updateMode = UpdateMode.NONE
        },
        addAnnualTargetSuccess: (
            state,
            action: PayloadAction<annualTargetModel.IAnnualTarget>
        ) => {
            state.annualTargets = [...state.annualTargets, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveAnnualTarget: (
            state,
            action: PayloadAction<annualTargetModel.IAnnualTarget>
        ) => {
            state.annualTarget = action.payload
        },
        setAnnualTargetUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAnnualTargetAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchAnnualTargetAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.annualTargets = action.payload
        })
        builder.addCase(fetchAnnualTargetAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchAnnualTargetRequest,
    fetchAnnualTargetSuccess,
    fetchAnnualTargetError,
    editAnnualTargetSuccess,
    addAnnualTargetSuccess,
    setActiveAnnualTarget,
    setAnnualTargetUpdateMode,
} = annualTargetSlice.actions

const reducer = annualTargetSlice.reducer

export { reducer as annualTargetReducer }
