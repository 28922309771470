import { apiRequests } from '../'
import {
    IRunnerComponentHistory,
    IRunnerComponentHistoryResponse,
} from '../../models/budgeting/runner-component-history.model'

const runnerComponentHistoryService = {
    list: (): Promise<IRunnerComponentHistory[]> =>
        apiRequests.get('/runnerComponentHistories'),
    details: (code: string): Promise<IRunnerComponentHistory> =>
        apiRequests.get(`/runnerComponentHistories/${code}`),
    create: (
        runnerComponentHistory: IRunnerComponentHistory
    ): Promise<IRunnerComponentHistoryResponse> =>
        apiRequests.post('/runnerComponentHistories', runnerComponentHistory),
    update: (
        runnerComponentHistory: IRunnerComponentHistory
    ): Promise<IRunnerComponentHistoryResponse> =>
        apiRequests.put('/runnerComponentHistories', runnerComponentHistory),
}

export default runnerComponentHistoryService
