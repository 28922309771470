import React from 'react'
import UserCoordination from '../../../components/common/user-coordination/user-coordination.component'

export const UserCoordinationPage: React.FC = () => {
  return (
    <>
        <UserCoordination />
    </>
  )
}
