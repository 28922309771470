import React from 'react'
import { Table } from 'antd'
import { ITicket } from '../../../models/common/ticket.model'
import { useTicket } from '../../../hooks/common/ticket/ticket.hook'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'
import { appRoutes } from '../../../constants/app-route'
import { useTicketColumns } from './ticket-column.component'

const TicketTable = () => {
    const { tickets, setTicket } = useTicket()
    const {ticketsTableColumns} = useTicketColumns();
    const router = useHistory()
    // const route = use
    const handleRowClick = (ticket: ITicket) => {
        setTicket(ticket)
        router.push(`${appRoutes.FAQs.TICKET}/${slugify(ticket.description)}`)
    }
    return (
        <>
            {tickets && tickets.length ? (
                <Table<ITicket>
                    dataSource={tickets}
                    columns={ticketsTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    onRow={(record: ITicket) => {
                        return {
                            onClick: (e) => {
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                <NoContent title="No data for ticket" />
            )}
        </>
    )
}

export default TicketTable
