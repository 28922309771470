import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IDailyData {
    user: string;
    userCode: string;
    site: string;
    siteName: string;
    userName: string;
    previousReceipts: number;
    todayReceipts: number;
    totalReceipts: number;
    transDate: Date;
}

export const emptyDailyData: IDailyData = {
    user: '',
    userCode: '',
    site: '',
    siteName: '',
    userName: '',
    previousReceipts: 0,
    todayReceipts: 0,
    totalReceipts: 0,
    transDate: new Date()
}
export interface IDailyDataState extends IBaseState {
    readonly dailyDatas: IDailyData[]
    readonly dailyData: IDailyData
}

export interface IDailyDataResponse extends IResponseBase {
    data: IDailyData
}
