import { appRoutes } from '../../../constants/app-route'
import { IRoute } from '../../../models/common/route.model'
import TicketStatusDetailsPage from '../../../pages/common/ticket-status/ticket-status-detail.page'
import TicketStatusPage from '../../../pages/common/ticket-status/ticket-status.page'
import TicketDetailsPage from '../../../pages/common/ticket/ticket-detail.page'
import TicketPage from '../../../pages/common/ticket/ticket.page'

export const faqsRoutes: IRoute[] = [
    {
        path: appRoutes.FAQs.TICKET,
        private: true,
        exact: true,
        component: TicketPage,
    },

    {
        path: appRoutes.FAQs.TICKET_DETAILS,
        private: true,
        exact: true,
        component: TicketDetailsPage,
    },

    {
        path: appRoutes.FAQs.TICKET_STATUS,
        private: true,
        exact: true,
        component: TicketStatusPage,
    },

    {
        path: appRoutes.FAQs.TICKET_STATUS_DETAILS,
        private: true,
        exact: true,
        component: TicketStatusDetailsPage,
    },
]
