import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import BranchForm from '../../../components/common/branch/branch-form.component'
import BranchTable from '../../../components/common/branch/branch-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useBranch } from '../../../hooks/common/branch.hook'
import { emptyBranch } from '../../../models/common/branch.model'
import { UpdateMode } from '../../../models/update-mode.enum'

const BranchPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { setBranch } = useBranch()

    const handleShow = () => {
        setTitle('Create new branch')
        setBranch(emptyBranch)
        setContent(<BranchForm updateMode={UpdateMode.ADD} />)
        setWidth('60rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'Branches']} />
            <TitleBar
                title="Branches"
                subTitle="View and manage branches"
                showButton
                handleShow={handleShow}
                buttonLabel="Add Branch"
                icon={<FiPlus />}
            />
            <BranchTable />
        </>
    )
}

export default BranchPage
