import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { UpdateMode } from '../../models/update-mode.enum'
import { ITransactionCode } from '../../models/budgeting/transaction-code.model'
import * as transactionCodeActions from '../../redux/budgeting/transaction-code.slice'
import transactionCodeService from '../../services/budgeting/transaction-code.service'

const useTransactionCode = () => {
    const transactionCodes = useSelector<rootState, ITransactionCode[]>(
        (state) => state.transactionCode.transactionCodes
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.transactionCode.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.transactionCode.initialFetch
    )
    const transactionCode = useSelector<rootState, ITransactionCode>(
        (state) => state.transactionCode.transactionCode
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.transactionCode.updateMode
    )

    const dispatch = useDispatch()

    const reloadTransactionCodes = useCallback(() => {
        dispatch(transactionCodeActions.fetchTransactionCodesAsync())
    }, [dispatch])

    const loadTransactionCodes = useCallback(() => {
        if (initialFetch) {
            reloadTransactionCodes()
        }
    }, [initialFetch, reloadTransactionCodes])

    const addTransactionCode = async (transactionCode: ITransactionCode) => {
        return await transactionCodeService
            .create(transactionCode)
            .then((transactionCodeResponse) => {
                if (transactionCodeResponse.success) {
                    dispatch(
                        transactionCodeActions.addTransactionCodeSuccess(
                            transactionCodeResponse.data
                        )
                    )
                } else {
                    return transactionCodeResponse
                }
                return transactionCodeResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setTransactionCode = (transactionCode: ITransactionCode) => {
        dispatch(transactionCodeActions.setActiveTransactionCode(transactionCode))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(transactionCodeActions.setTransactionCodeUpdateMode(updateMode))
    }

    const editTransactionCode = async (transactionCode: ITransactionCode) => {
        return await transactionCodeService
            .update(transactionCode)
            .then((transactionCodeResponse) => {
                dispatch(
                    transactionCodeActions.editTransactionCodeSuccess(
                        transactionCodeResponse.data
                    )
                )
                setTransactionCode(transactionCodeResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveTransactionCode = async (
        transactionCode: ITransactionCode,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addTransactionCode(transactionCode)
            : await editTransactionCode(transactionCode)
    }

    useEffect(() => {
        loadTransactionCodes()
    }, [
        transactionCode,
        transactionCodes,
        isLoading,
        initialFetch,
        loadTransactionCodes,
    ])

    return {
        transactionCode,
        transactionCodes,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addTransactionCode,
        editTransactionCode,
        saveTransactionCode,
        setTransactionCode,
        reloadTransactionCodes,
    }
}

export { useTransactionCode }
