export const enSite = {
    tenant: 'Tenant',
    code: 'Code',
    project: 'Project',
    copYear: 'Component year',
    region: 'Region',
    description: 'Description',
    district: 'District',
    siteType: 'Site type',
    siteTier: 'Site tier',
    createdOn: 'Created on',
}
