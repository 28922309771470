import { Card, Tabs } from 'antd'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import DisplayForm from '../../components/profile/display/display-form.component'
import AuthForm from '../../components/profile/login-authentication/auth-form.component'
import { MySetting } from '../../components/profile/my-setting/my-setting.component'
import PageBreadCrumbs from '../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { useCurrentUser } from '../../hooks/common/current-user/current-user.hook'
import { useActiveTab } from '../../hooks/shared/active-tab/active-tab.hook'

interface RouteProps {
    tab: string
}
const ProfilePage: React.FC = () => {
    const { currentUser } = useCurrentUser()
    const { activeTab, setActiveTab } = useActiveTab()
    const history = useHistory()
    const { tab } = useParams<RouteProps>()
    const handleOnChange = (e: any) => {
        setActiveTab(e)
    }

    useEffect(() => {
        if (tab !== activeTab.isActive) {
            history.push(`/profile/tab?=${activeTab.isActive}`)
        }
    }, [activeTab.isActive, tab, history])

    return (
        <>
            <PageBreadCrumbs items={['My Profile', '']} />
            <Tabs
                style={{ paddingBottom: 10 }}
                defaultActiveKey={activeTab.isActive}
                activeKey={activeTab.isActive}
                onChange={handleOnChange}
                size="small"
                onTabClick={handleOnChange}
            >
                <Tabs.TabPane tab="Display" key="display">
                    <Card size="small" style={{ marginTop: '.5rem' }}>
                        <DisplayForm currentUser={currentUser} />
                    </Card>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Login & Authentication" key="auth">
                    <AuthForm currentUser={currentUser} />
                </Tabs.TabPane>

                <Tabs.TabPane tab="My Settings" key="settings">
                    <MySetting />
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}

export default ProfilePage
