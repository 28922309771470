export const enActivity = {
    breadcrumb: {
        HIVActivities: 'HIV activities',
        siteActivities: 'Site Activities',
        BOProgram: {
            BOActivity: 'Program Management BO Activity Batch Summary',
            list: 'list',
        },
        DHSProgram: {
            DHSActivity: 'Program Management DHS Activity Batch Summary',
            list: 'list',
        },
        MvanProgram: {
            MvanActivity: 'Program Management MVAN Activity Batch Summary',
            list: 'list',
        },
        labActivity: {
            lab: 'Laboratory',
            labActivities: 'Laboratory Activities',
        },
    },

    mainPage: {
        title: 'Activity Batch Summary',
        subTitle: 'View and manage activity batch summary',
        buttonLabel: 'Add Activity Batch',
    },

    tabs: {
        siteActivity: 'Site Activity',
        coordinationAreaActivity: 'Coordination Area Activity',
        regionalActivities: 'Regional Activities',
    },

    detailPage: {
        title: 'Activity Batch',
        subTitle: 'View and manage Activity Batch',
        buttonLabel: 'Add Activity',
        breadcrumb: {
            mainActivities: 'Main Activities',
            siteActivities: 'Site Activities',
            batch: 'Batch',
        },
    },
    form: {
        Batch: 'Batch',
        userName: 'User Name',
        description: 'Description',
        ActivityType: 'Activity Type',
        addActivity: 'Add Activity',
        activity: 'Activity',
        site: 'Site',
        month: 'Month',
        runner: 'Runner',
        activityBatch: 'Add new activity Batch',
        amount: 'Amount',
        submit: 'Submit',
        reset: 'Reset',
    },

    activitySummaryDetailPage: {
        title: 'Activity Batchline',
        subTitle: 'View and manage batchline',
        buttonLabel: 'Edit Activity',
        breadcrumb: {
            mainActivities: 'Main Activities',
            siteActivities: 'site Activities',
            batchDetails: 'Batch Detail',
        },
    },
}
