import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IMonthName {
    code: string
    locale: string
    description: string
    createdOn: string
}

export interface IMonthNameState extends IBaseState {
    readonly monthNames: IMonthName[]
    readonly monthName: IMonthName
}

export const emtpyMonthName: IMonthName = {
    code: '',
    locale: 'en',
    description: '',
    createdOn: '',
}
export interface IMonthNameResponse extends IResponseBase {
    data: IMonthName
}
