import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResponseBase } from '../../../models/response.base'

export const initialState: IResponseBase = {
    success: true,
    message: '',
    validationErrors: [''],
}

export const validationErrorSlice = createSlice({
    name: 'validationError',
    initialState,
    reducers: {
        fetchValidationErrorRequest: (state) => {
            state.success = true
        },
        fetchValidationErrorSuccess: (state) => {
            state.success = true
        },
        fetchValidationErrorError: (state, action: PayloadAction<IResponseBase>) => {
            state.success = false
            state.message = action.payload.message
            state.validationErrors = [...action.payload.validationErrors]
        },
        setActiveValidationError: (state, action: PayloadAction<IResponseBase>) => {
            state = action.payload
        },
    },
})

export const {
    fetchValidationErrorRequest,
    fetchValidationErrorSuccess,
    fetchValidationErrorError,
    setActiveValidationError,
} = validationErrorSlice.actions

const reducer = validationErrorSlice.reducer

export { reducer as validationErrorReducer }
