import { ISideBarLink } from '../sidebar.data'
import { BiCategoryAlt } from 'react-icons/bi'
import { appRoutes } from '../../../constants/app-route'

export const runnerSubmenus: ISideBarLink[] = [
    {
        app: '01',
        code: 'E01',
        description: 'Runners',
        route: appRoutes.RUNNER.RUNNERS,
        dropDown: false,
        dropDownMenus: [],
        parent: '',
        icon: <BiCategoryAlt />,
    },
    {
        app: '01',
        code: 'E00',
        description: 'Runner Periods',
        route: appRoutes.RUNNER.RUNNER_PERIOD,
        dropDown: false,
        dropDownMenus: [],
        parent: '',
        icon: <BiCategoryAlt />,
    },
]
