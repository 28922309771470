import { Spin, Tag } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { useTranslation } from 'react-i18next'
import MilestoneProjectionTable from '../../../components/budgeting/milestone-projection/milestone-project-table.component'
import { useMilestone } from '../../../hooks/budgeting/milestone.hook'
import { Typography } from 'antd'
import { useRunner } from '../../../hooks/budgeting/runner.hook'
import { SelectSiteComponent } from '../../../components/common/select-site/select-site.component'
import {
    FlexedDiv,
    SizedLabel,
    width100,
} from '../../../components/profile/profile.styles'
import { useTablePagination } from '../../../hooks/shared/pagination/pagination.hook'
import SearchInput from '../../../components/shared/search-input/search-input.component'
import { IMilestone } from '../../../models/budgeting/milestone.model'
import search from '../../../utils/search'
import {
    emptyConfiguration,
    IConfiguration,
} from '../../../models/configuration.model'
import { useConfiguration } from '../../../hooks/configuration.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'

const { Title } = Typography

const MilestoneProjectPage: React.FC = () => {
    const { t } = useTranslation()
    const { searchQuery, setSearchQuery } = useTablePagination()
    const [query, setQuery] = useState<string>(searchQuery)

    const { isLoading, initialFetch, getMilestoneProjections, site } = useMilestone()
    const { runner } = useRunner()
    const [show, setShow] = useState(false)
    const { setConfiguration } = useConfiguration()
    const { currentBranch } = useCurrentBranch()

    const handleChangeSearchQuery = (searchQuery: string) => {
        setQuery(searchQuery)
    }

    const handleSearch = (searchQuery: string) => {
        setSearchQuery(searchQuery)
    }

    const milestones = getMilestoneProjections(site)

    const filteredMilestones: IMilestone[] =
        milestones.length > 0
            ? milestones.filter((milestone) =>
                  search(
                      milestone,
                      ['activity', 'budgetNote', 'site', 'budget'],
                      query,
                      false
                  )
              )
            : []

    const configuration = useMemo((): IConfiguration => {
        return {
            ...emptyConfiguration,
            activityType: '01',
            project: currentBranch.project,
        }
    }, [currentBranch.project])

    useEffect(() => {
        setConfiguration(configuration)
    }, [site, milestones, setConfiguration, currentBranch.project, configuration])
 
    return (
        <>
            <PageBreadCrumbs
                items={[
                    t(
                        'budgeting:milestoneProjection.breadcrumb.milestoneProjection'
                    ),
                    t('budgeting:milestoneProjection.breadcrumb.list'),
                ]}
            />
            <TitleBar
                title={t('budgeting:milestoneProjection.mainPage.title')}
                subTitle={t('budgeting:milestoneProjection.mainPage.subTitle')}
            />
            <FlexedDiv>
                <FlexedDiv>
                    <SizedLabel>
                        <Tag
                            color="blue"
                            style={{
                                borderRadius: 3,
                                marginBottom: 5,
                                ...width100,
                                padding: `4px 5px`,
                            }}
                        >
                            <Title
                                level={5}
                                style={{ marginBottom: 0, fontSize: 14 }}
                            >{`${runner.code} - ${runner.description}`}</Title>
                        </Tag>
                    </SizedLabel>

                    <SizedLabel>
                        <SelectSiteComponent setShow={setShow} />
                    </SizedLabel>
                </FlexedDiv>

                <FlexedDiv style={{ alignSelf: 'stretch' }}>
                    <SizedLabel>
                        <SearchInput
                            isSearching={false}
                            handleChangeSearchQuery={(query) =>
                                handleChangeSearchQuery(query)
                            }
                            placeHolder="Search for an activity..."
                            handleSearch={(query) => handleSearch(query)}
                            debounceDelay={500}
                        />
                    </SizedLabel>
                </FlexedDiv>
            </FlexedDiv>
            {isLoading && initialFetch ? (
                <Spin size="large" />
            ) : (
                <>
                    {show ? (
                        <MilestoneProjectionTable milestones={filteredMilestones} />
                    ) : (
                        <div
                            style={{
                                height: 300,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {/* <div>
                                <SpinnerComponent />
                            </div> */}
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default MilestoneProjectPage
