export const frUser = {
    formikValidation: {
        usrName: "Le Nom d'utilisateur est requise !",
    },
    form: {
        title: 'utilisateurs',
        titleAdd: 'Ajouter un utilisateur',
        titleEdit: 'Modifier le utilisateur',
        usrName: "Nom d'utilisateur",
        usrSite: 'Site utilisateur',
        mgtUsrSite: 'Gérer les sites des utilisateurs',
        mgtSiteBtn: "Gérer le site",
        editBtn: 'Éditer',
        detail: 'Détails',
        saveBtn: "sauvegarder",
        cancelBtn: "Annuler",
        code: "Code",
        role: "Rôle",
    },
    userForm: {
        code: "Code",
        name: "Nom",
        email: "E-mail",
        title: "Créer un nouvel utilisateur",
    }
}
