import { Col, Row, Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton, ResetButton } from 'formik-antd'
import React from 'react'
import { ITicket } from '../../../models/common/ticket.model'

const FormItem = Form.Item
const TicketFormFields: React.FC = () => {
    const { isSubmitting } = useFormikContext<ITicket>()
    return (
        <>
            <Form autoComplete="off">
                <Row gutter={16}>
                </Row>
                <Row>
                    <Col className="gutter-row" md={24}>
                        <p className="mb-0">Description</p>
                        <FormItem name="description" required={true}>
                            <Input
                                name="description"
                                disabled={isSubmitting}
                                required={true}
                                placeholder="Description "
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>Submit</SubmitButton>
                    <ResetButton disabled={isSubmitting}>Reset</ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default TicketFormFields
