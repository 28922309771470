export const enActivityPlan = {
    tenant: 'Tenant',
    code: 'Code',
    copYear: 'Component year',
    component: 'Domponent',
    project: 'Project',
    description: 'Description',
    intervention: 'Intervention',
    costCategory: 'Cost category',
    approach: 'Approach',
    objective: 'Objective',
    budgetCode: 'Budget code',
    budgetBalance: 'Budget balance',
    activityType: 'Activity type',
    createdOn: 'Created on',
}
