import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IUserRole {
    tenant: string
    roleCode: string
    usrCode: string
    status: string
}

export interface IUserRoleDto {
   tenant: string
   user: string
   roles: string[]
}

export interface IRoleUserDto {
    tenant: string;
    users: string;
    role: string;
 }
 

export interface IUserRoleState extends IBaseState {
    readonly userRoles: IUserRole[]
    readonly userRole: IUserRole
}

export const emptyUserRole: IUserRole = {
    tenant: '',
    roleCode: '',
    usrCode: '',
    status: '',
}

export interface IUserRoleResponse extends IResponseBase {
    data: IUserRole,
    userRoles: IUserRole[]
}
