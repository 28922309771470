export const frTarget = {
    tenant: 'Locataire',
    outlay: 'Dépense',
    copYear: 'Année composante',
    project: 'Projet',
    component: 'Composant',
    region: 'Région',
    target: 'Cible',
    createdOn: 'Créé le',
}
