import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Transfer } from 'antd'
import { Formik } from 'formik'
import { Form, FormItem, Select, SubmitButton } from 'formik-antd'
import { useTranslation } from 'react-i18next'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useUser } from '../../../hooks/shared/user/user.hook'
import { IUser } from '../../../models/shared/user.model'
import { useUserCoordination } from '../../../hooks/budgeting/user-coordination.hook'
import { TransferDirection } from 'antd/lib/transfer'
import {
    IUserCoordination,
    emptyUserCoordination,
} from '../../../models/budgeting/user-coordination.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import { useToast } from '../../../hooks/common/toast/toast.hook'

const { Option } = Select

interface RecordType {
    tenant: string
    code: string
    usrName: string
    email: string
    photoUrl: string
    locale: string
}
const UserCoordination: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { toastError, toastSuccess } = useToast()
    const { users } = useUser()
    const { userCoordinations, saveUserCoordination, userCoordination, addUserCoordination } =
        useUserCoordination()
    const [targetKeys, setTargetKeys] = useState<string[]>([])
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])
    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([])

    const onChange = (arr: string[]) => {
        console.log(arr)
        setTargetKeys([...arr])
    }

    const onSelectChange = (
        sourceSelectedKeys: string[],
        targetSelectedKeys: string[]
    ) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
    }

    const handleChange = useCallback(
        (val: string, option: any) => {
            const matches = userCoordinations.filter((coord) => coord.user === val)
            const userCodes = matches.map((coord) => coord.coordination)
            setTargetKeys(userCodes)
            console.log(userCodes, val)
        },
        [users, userCoordinations]
    )

    const onSearch = useCallback((input: string, option) => {
        return option?.children.props.children
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
    }, [])

    const filterOption = (inputValue: string, option: RecordType) =>
        option.usrName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1

    const handleSearch = (dir: TransferDirection, value: string) => {
        if (value.toLowerCase() !== '' || value.toLowerCase() !== null) {
            const foundUsers = users.filter((user) =>
                user.usrName.toLowerCase().includes(value.toLowerCase())
            )
            setFilteredUsers(foundUsers)
        } else {
            setFilteredUsers([])
        }
    }

    useEffect(() => {}, [userCoordinations])

    return (
        <>
            <Formik
                onSubmit={async (values, { setSubmitting }) => {
                    const obj: IUserCoordination = {
                        ...values,
                        coordination: targetKeys.join(','),
                        user: values.user,
                    }
                    console.log('data: ', obj)

                    const feedback = await addUserCoordination(obj)

                    if (feedback) {
                        toastSuccess('M0002')
                    } else {
                        toastError('M0003')
                    }
                    setSubmitting(false)
                }}
                initialValues={userCoordination || emptyUserCoordination}
            >
                {({ isSubmitting, handleSubmit }) => (
                    <Form autoComplete="off" onFinish={handleSubmit}>
                        <Card size="small" title="Assign users to selected a user">
                            <div className="mb-lg">
                                <label className="form-control mb-lg">
                                    <p className="mb-0">Users</p>
                                    <FormItem name="user" required={true}>
                                        <Select
                                            name="user"
                                            disabled={isSubmitting}
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                onSearch(input, option)
                                            }
                                            onChange={handleChange}
                                        >
                                            {users.map((user, index) => (
                                                <Option
                                                    value={user.code}
                                                    key={index}
                                                >
                                                    <p className="mb-0">
                                                        {user.usrName}
                                                    </p>
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                </label>
                            </div>

                            <div style={{ marginTop: 10 }}>
                                <Transfer
                                    rowKey={(record) => record.code}
                                    dataSource={
                                        filteredUsers && filteredUsers.length > 0
                                            ? filteredUsers
                                            : users
                                    }
                                    showSearch
                                    listStyle={{
                                        height: 300,
                                    }}
                                    operations={['add', 'remove']}
                                    operationStyle={{
                                        color: '#fff',
                                        backgroundColor: '#fff',
                                    }}
                                    titles={['All Users', 'Selected Users']}
                                    filterOption={filterOption}
                                    targetKeys={targetKeys}
                                    selectedKeys={selectedKeys}
                                    onChange={onChange}
                                    onSearch={handleSearch}
                                    onSelectChange={onSelectChange}
                                    render={(item) =>
                                        `${item.usrName + ' (' + item.code})`
                                    }
                                />
                            </div>
                            <Space style={{ marginTop: 10 }}>
                                <SubmitButton loading={isSubmitting}>
                                    {t('commone:save')}
                                </SubmitButton>
                            </Space>
                        </Card>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default UserCoordination
