import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as siteActions from '../../redux/budgeting/site.slice'
import { ISite } from '../../models/budgeting/site.model'
import { UpdateMode } from '../../models/update-mode.enum'
import siteService from '../../services/budgeting/site.service'
import { useCurrentBranch } from '../common/current-branch/current-branch.hook'
import { useDistrict } from './district.hook'
import { useProjectSite } from './project-site.hook'

const useSite = () => {
    const { currentBranch } = useCurrentBranch()
    const { districts } = useDistrict()
    const sites = useSelector<rootState, ISite[]>((state) => state.site.sites)
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.site.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.site.initialFetch
    )
    const site = useSelector<rootState, ISite>((state) => state.site.site)
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.site.updateMode
    )
    const { projectSites } = useProjectSite()

    const dispatch = useDispatch()

    const loadSites = useCallback(() => {
        if (initialFetch) {
            dispatch(siteActions.fetchSitesAsync(currentBranch.region))
        }
    }, [currentBranch.region, dispatch, initialFetch])

    const getProjectSites = useCallback(() => {
        const projetSiteCodes = projectSites.map((x) => x.site)
        return sites.filter((x) => projetSiteCodes.includes(x.code))
    }, [projectSites, sites])

    const getRegionSites = useCallback(() => {
        const regionDistricts = districts
            .filter((x) => x.region === currentBranch.region)
            .map((x) => x.code)

        const projectSites = getProjectSites()

        const regionSites = projectSites.filter((x) =>
            regionDistricts.includes(x.district)
        )

        return regionSites
    }, [currentBranch.region, districts, getProjectSites])

    const addSite = async (site: ISite) => {
        return await siteService
            .create(site)
            .then((siteResponse) => {
                if (siteResponse.success) {
                    dispatch(siteActions.addSiteSuccess(siteResponse.data))
                } else {
                    return siteResponse
                }
                return siteResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setSite = (site: ISite) => {
        dispatch(siteActions.setActiveSite(site))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(siteActions.setSiteUpdateMode(updateMode))
    }

    const editSite = async (site: ISite) => {
        return await siteService
            .update(site)
            .then((siteResponse) => {
                dispatch(siteActions.editSiteSuccess(siteResponse.data))
                setSite(siteResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveSite = async (site: ISite, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addSite(site)
            : await editSite(site)
    }

    useEffect(() => {
        loadSites()
    }, [site, sites, isLoading, initialFetch, loadSites])

    return {
        site,
        sites: getProjectSites(),
        regionSites: getRegionSites(),
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addSite,
        editSite,
        saveSite,
        setSite,
    }
}

export { useSite }
