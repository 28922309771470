import { rootState } from '../../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import currentUserService from '../../../services/common/current-user/current-user.service'
import { ICurrentUser } from '../../../models/common/current-user.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import * as currentUserActions from '../../../redux/common/current-user/current-user.slice'
import { useAuthentication } from '../../auth.hook'
import { IUserMenu } from '../../../models/common/user-menu.model'

const useCurrentUser = () => {
    const userMenus = useSelector<rootState, IUserMenu[]>(
        (state) => state.userMenu.userMenus
    )
    const currentUsers = useSelector<rootState, ICurrentUser[]>(
        (state) => state.currentUser.currentUsers
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.currentUser.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.currentUser.initialFetch
    )
    const currentUser = useSelector<rootState, ICurrentUser>(
        (state) => state.currentUser.currentUser
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.currentUser.updateMode
    )
    const { auth } = useAuthentication()

    const dispatch = useDispatch()

    const loadCurrentUsers = useCallback(() => {
        if (isLoading) {
            return
        }
        if (initialFetch) {
            dispatch(currentUserActions.fetchCurrentUserRequest())
        }
    }, [dispatch, initialFetch, isLoading])

    const addCurrentUser = async (currentUser: ICurrentUser) => {
        return await currentUserService
            .create(currentUser)
            .then((currentUser) => {
                dispatch(currentUserActions.addCurrentUserSuccess(currentUser.data))
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const setCurrentUser = (currentUser: ICurrentUser) => {
        dispatch(currentUserActions.setActiveCurrentUser(currentUser))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(currentUserActions.setCurrentUserUpdateMode(updateMode))
    }

    const editCurrentUser = async (currentUser: ICurrentUser) => {
        return await currentUserService
            .update(currentUser)
            .then((currentUser) => {
                dispatch(currentUserActions.editCurrentUserSuccess(currentUser.data))
                setCurrentUser(currentUser.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveCurrentUser = async (
        currentUser: ICurrentUser,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addCurrentUser(currentUser)
            : await editCurrentUser(currentUser)
    }

    const getCurrentUser = useCallback((): ICurrentUser => {
        const currentUser: ICurrentUser = {
            name: auth?.user?.profile.name!,
            email: auth?.user?.profile.user_email,
            telephone: auth?.user?.profile.user_telephone!,
            locale: auth?.user?.profile.user_locale!,
            code: auth?.user?.profile.user_map!,
            branch: '',
            subject: auth?.user?.profile.sub!,
            tenant: auth?.user?.profile.organization!,
            userBranches: [],
        }
        return currentUser
    }, [
        auth?.user?.profile.name,
        auth?.user?.profile.organization,
        auth?.user?.profile.sub,
        auth?.user?.profile.user_email,
        auth?.user?.profile.user_locale,
        auth?.user?.profile.user_map,
        auth?.user?.profile.user_telephone,
    ])

    useEffect(() => {
        loadCurrentUsers()
    }, [currentUser, currentUsers, isLoading, initialFetch, loadCurrentUsers])

    return {
        userMenus,
        currentUser: getCurrentUser(),
        currentUsers,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addCurrentUser,
        editCurrentUser,
        saveCurrentUser,
        setCurrentUser,
    }
}

export { useCurrentUser }
