import { Formik } from 'formik'
import React from 'react'
import { useLocation } from '../../../hooks/common/location/location.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import { emptyLocation, ILocation } from '../../../models/common/location.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import LocationFormFields from './location-form-field.component'

const LocationForm: React.FC = () => {
    const { toastSuccess, toastError } = useToast()
    const { saveLocation, location } = useLocation()
    return (
        <div>
            <Formik<ILocation>
                initialValues={location || emptyLocation}
                onSubmit={async (values, { setSubmitting }) => {
                    const feedback = await saveLocation(values, UpdateMode.ADD)
                    if (feedback) {
                        toastSuccess('M0002')
                    } else {
                        toastError('M0003')
                    }
                    setSubmitting(false)
                }}
            >
                <>
                    <LocationFormFields />
                </>
            </Formik>
        </div>
    )
}
export default LocationForm
