import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IComponent {
    tenant: string
    code: string
    copYear: string
    project: string
    description: string
    longDescription: string
    startDate: Date
    endDate: Date
}

export const emptyComponent: IComponent = {
    tenant: '',
    code: '',
    copYear: '',
    project: '',
    description: '',
    longDescription: '',
    startDate: new Date(),
    endDate: new Date(),
}

export interface IComponentState extends IBaseState {
    readonly components: IComponent[]
    readonly component: IComponent
}

export interface IComponentResponse extends IResponseBase {
    data: IComponent
}
