import { apiRequests } from '../..'
import { IUserBranch, IUserBranchDto, IUserBranchResponse } from '../../../models/common/user-branch.model'

const userBranchService = {
    list: (): Promise<IUserBranch[]> => apiRequests.get('/userBranches'),
    details: (code: string): Promise<IUserBranch> =>
        apiRequests.get(`/userBranches/${code}`),
    create: (userBranch: IUserBranchDto): Promise<IUserBranchResponse> =>
        apiRequests.post('/userBranches', userBranch),
    update: (userBranch: IUserBranchDto): Promise<IUserBranchResponse> =>
        apiRequests.put('/userBranches', userBranch),
}

export default userBranchService
