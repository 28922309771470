export const frIntervention = {
    tenant: 'Locataire',
    code: 'Code',
    copYear: 'Année composante',
    description: 'La description',
    budgetCode: 'Code budgétaire',
    project: 'Projet',
    percentage: 'Pourcentage',
    createdOn: 'Créé sur',
}
