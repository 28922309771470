import { Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IUser } from '../../../models/shared/user.model'

const FormItem = Form.Item
const ResetPasswordFormFields: React.FC = () => {
    const { isSubmitting } = useFormikContext<IUser>()
    const { t } = useTranslation()

    return (
        <>
            <div
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    background: '#fafafa',
                    padding: 5,
                }}
            >
                <p style={{ marginBottom: 0 }}>
                    <strong>Reset Password</strong>
                </p>
                <small>
                    Reset your password by entering current password first, then new
                    password. <br />
                    Your password must respect the validation rules
                </small>
            </div>
            <Form autoComplete="off">
                <p className="mb-0">{t('user:currentPassword')}</p>
                <FormItem name="currentPassword" required={true}>
                    <Input
                        name="currentPassword"
                        disabled={isSubmitting}
                        required={true}
                    />
                </FormItem>

                <p className="mb-0">{t('user:newPassword')}</p>
                <FormItem name="newPassword" required={true}>
                    <Input
                        name="newPassword"
                        disabled={isSubmitting}
                        required={true}
                    />
                </FormItem>

                <p className="mb-0">{t('user:confirmPassword')}</p>
                <FormItem name="confirmPassword" required={true}>
                    <Input
                        name="confirmPassword"
                        disabled={isSubmitting}
                        required={true}
                    />
                </FormItem>
                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('user:update')}
                    </SubmitButton>
                </Space>
            </Form>
        </>
    )
}

export default ResetPasswordFormFields
