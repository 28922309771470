import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as branchModel from '../../../models/common/branch.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import branchService from '../../../services/common/branch/branch.service'

export const initialState: branchModel.IBranchState = {
    branches: [],
    errors: '',
    branch: branchModel.emptyBranch,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchBranchesAsync = createAsyncThunk<branchModel.IBranch[], void>(
    'branch/fetchBranchesAsync',
    async (_, thunkApi) => {
        try {
            return await branchService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const branchSlice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        fetchBranchesRequest: (state) => {
            state.isLoading = true
        },
        fetchBranchesSuccess: (
            state,
            action: PayloadAction<branchModel.IBranch[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.branches = action.payload
        },
        fetchBranchesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editBranchSuccess: (state, action: PayloadAction<branchModel.IBranch>) => {
            state.branches = state.branches.map((branch) => {
                return branch.code === action.payload.code ? action.payload : branch
            })
            state.updateMode = UpdateMode.NONE
        },
        addBranchSuccess: (state, action: PayloadAction<branchModel.IBranch>) => {
            state.branches = [...state.branches, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveBranch: (state, action: PayloadAction<branchModel.IBranch>) => {
            state.branch = action.payload
        },
        setBranchUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBranchesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchBranchesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.branches = action.payload
        })
        builder.addCase(fetchBranchesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchBranchesRequest,
    fetchBranchesSuccess,
    fetchBranchesError,
    editBranchSuccess,
    addBranchSuccess,
    setActiveBranch,
    setBranchUpdateMode,
} = branchSlice.actions

const reducer = branchSlice.reducer

export { reducer as branchReducer }
