import { Alert } from 'antd'
import { Formik } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useRunnerPeriod } from '../../../hooks/budgeting/runner-period.hook'
import { useFormErrors } from '../../../hooks/common/form/form-error.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import {
    emptyRunnerPeriod,
    IRunnerPeriod,
} from '../../../models/budgeting/runner-period.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import RunnerPeriodFormFields from './runner-period-form-fields.component'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useRunner } from '../../../hooks/budgeting/runner.hook'
interface IProp {
    updateMode: UpdateMode
}
const RunnerPeriodForm: React.FC<IProp> = ({ updateMode }) => {
    const { toastSuccess, toastError } = useToast()
    const { saveRunnerPeriod, runnerPeriod } = useRunnerPeriod()
    const { currentBranch } = useCurrentBranch()
    const { runner } = useRunner()

    const { formError } = useFormErrors()
    const [hasSubmitted, setSubmitted] = useState(false)
    const { setShow } = useContext<any>(AppModalContext)

    const onClose = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSubmitted(false)
        },
        []
    )
    console.log("runner: ", runner)
    useEffect(() => {}, [hasSubmitted])
    return (
        <div>
            {hasSubmitted && (
                <Alert
                    message="Error Messages"
                    description={
                        <>
                            {formError.validationErrors &&
                            formError.validationErrors.length > 0 ? (
                                formError.validationErrors.map((v, index) => (
                                    <span key={index} style={{ display: 'block' }}>
                                        {v}
                                    </span>
                                ))
                            ) : (
                                <span>
                                    {JSON.stringify(
                                        formError.validationErrors,
                                        null,
                                        2
                                    )}
                                </span>
                            )}
                        </>
                    }
                    type="error"
                    closable
                    onClose={onClose}
                    style={{ marginBottom: 10 }}
                />
            )}
            <Formik<IRunnerPeriod>
                initialValues={runnerPeriod || emptyRunnerPeriod}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const obj: IRunnerPeriod = {
                        ...values,
                        tenant: currentBranch.tenant,
                        region: currentBranch.region,
                        component: values.component,
                        milestoneProjection: runner.milestoneProjection.slice(2),
                        copYear: runner.copYear,
                        project: currentBranch.project
                    }
                    setSubmitted(false)
                    const feedback = await saveRunnerPeriod(obj, updateMode)
                    if (feedback) {
                        toastSuccess('M0002')
                        setShow(false)
                        resetForm()
                    } else {
                        toastError('M0003')
                        setShow(true)
                        setSubmitted(true)
                    }
                    setSubmitting(false)
                }}
            >
                <>
                    <RunnerPeriodFormFields />
                </>
            </Formik>
        </div>
    )
}
export default RunnerPeriodForm
