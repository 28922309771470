import {
    IMonthName,
    IMonthNameResponse,
} from '../../../models/common/month-name.model'
import { apiRequests } from '../../../services'

const monthNameService = {
    list: (): Promise<IMonthName[]> => apiRequests.get('/monthNames'),
    details: (code: string): Promise<IMonthName> =>
        apiRequests.get(`/monthNames/${code}`),
    create: (monthName: IMonthName): Promise<IMonthNameResponse> =>
        apiRequests.post('/monthNames', monthName),
    update: (monthName: IMonthName): Promise<IMonthNameResponse> =>
        apiRequests.put('/monthNames', monthName),
}

export default monthNameService
