import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as projectSiteActions from '../../redux/budgeting/project-site.slice'
import { IProjectSite } from '../../models/budgeting/project-site.model'
import { UpdateMode } from '../../models/update-mode.enum'
import projectSiteService from '../../services/budgeting/project-site.service'
import { useCurrentBranch } from '../common/current-branch/current-branch.hook'

const useProjectSite = () => {
    const projectSites = useSelector<rootState, IProjectSite[]>(
        (state) => state.projectSite.projectSites
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.projectSite.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.projectSite.initialFetch
    )
    const projectSite = useSelector<rootState, IProjectSite>(
        (state) => state.projectSite.projectSite
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.projectSite.updateMode
    )

    const { currentBranch } = useCurrentBranch()

    const dispatch = useDispatch()

    const loadProjectSites = useCallback(() => {
        if (initialFetch) {
            dispatch(projectSiteActions.fetchProjectSitesAsync())
        }
    }, [dispatch, initialFetch])

    const addProjectSite = async (projectSite: IProjectSite) => {
        return await projectSiteService
            .create(projectSite)
            .then((projectSiteResponse) => {
                if (projectSiteResponse.success) {
                    dispatch(
                        projectSiteActions.addProjectSiteSuccess(
                            projectSiteResponse.data
                        )
                    )
                } else {
                    return projectSiteResponse
                }
                return projectSiteResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setProjectSite = (projectSite: IProjectSite) => {
        dispatch(projectSiteActions.setActiveProjectSite(projectSite))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(projectSiteActions.setProjectSiteUpdateMode(updateMode))
    }

    const editProjectSite = async (projectSite: IProjectSite) => {
        return await projectSiteService
            .update(projectSite)
            .then((projectSiteResponse) => {
                dispatch(
                    projectSiteActions.editProjectSiteSuccess(
                        projectSiteResponse.data
                    )
                )
                setProjectSite(projectSiteResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveProjectSite = async (
        projectSite: IProjectSite,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addProjectSite(projectSite)
            : await editProjectSite(projectSite)
    }

    const getProjectSites = useCallback(() => {
        return projectSites.filter((x) => x.project === currentBranch.project)
    }, [currentBranch.project, projectSites])

    useEffect(() => {
        loadProjectSites()
    }, [projectSite, projectSites, isLoading, initialFetch, loadProjectSites])

    return {
        projectSite,
        projectSites: getProjectSites(),
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addProjectSite,
        editProjectSite,
        saveProjectSite,
        setProjectSite,
    }
}

export { useProjectSite }
