import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as surgeActivityActions from '../../redux/budgeting/surge-activity.slice'
import { ISurgeActivity } from '../../models/budgeting/surge-activity.model'
import { UpdateMode } from '../../models/update-mode.enum'
import surgeActivityService from '../../services/budgeting/surge-activity.service'

const useSurgeActivity = () => {
    const surgeActivities = useSelector<rootState, ISurgeActivity[]>(
        (state) => state.surgeActivity.surgeActivities
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.surgeActivity.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.surgeActivity.initialFetch
    )
    const surgeActivity = useSelector<rootState, ISurgeActivity>(
        (state) => state.surgeActivity.surgeActivity
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.surgeActivity.updateMode
    )

    const dispatch = useDispatch()

    const loadSurgeActivities = useCallback(() => {
        if (initialFetch) {
            dispatch(surgeActivityActions.fetchSurgeActivitiesAsync())
        }
    }, [dispatch, initialFetch])

    const addSurgeActivity = async (surgeActivity: ISurgeActivity) => {
        return await surgeActivityService
            .create(surgeActivity)
            .then((surgeActivityResponse) => {
                if (surgeActivityResponse.success) {
                    dispatch(
                        surgeActivityActions.addSurgeActivitySuccess(
                            surgeActivityResponse.data
                        )
                    )
                } else {
                    return surgeActivityResponse
                }
                return surgeActivityResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setSurgeActivity = (surgeActivity: ISurgeActivity) => {
        dispatch(surgeActivityActions.setActiveSurgeActivity(surgeActivity))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(surgeActivityActions.setSurgeActivityUpdateMode(updateMode))
    }

    const editSurgeActivity = async (surgeActivity: ISurgeActivity) => {
        return await surgeActivityService
            .update(surgeActivity)
            .then((surgeActivityResponse) => {
                dispatch(
                    surgeActivityActions.editSurgeActivitySuccess(
                        surgeActivityResponse.data
                    )
                )
                setSurgeActivity(surgeActivityResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveSurgeActivity = async (
        surgeActivity: ISurgeActivity,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addSurgeActivity(surgeActivity)
            : await editSurgeActivity(surgeActivity)
    }

    const getSurgeActivityCodes = useCallback(() => {
        const surgeActivityCodes = surgeActivities.map((x) => x.code)
        // console.log('surgeActivityCodes',surgeActivityCodes)
        return surgeActivityCodes
    }, [surgeActivities])

    useEffect(() => {
        loadSurgeActivities()
    }, [
        surgeActivity,
        surgeActivities,
        isLoading,
        initialFetch,
        loadSurgeActivities,
    ])

    return {
        surgeActivity,
        surgeActivities,
        surgeActivityCodes: getSurgeActivityCodes(),
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addSurgeActivity,
        editSurgeActivity,
        saveSurgeActivity,
        setSurgeActivity,
    }
}

export { useSurgeActivity }
