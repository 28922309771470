import React from 'react'
import { FiArchive } from 'react-icons/fi'
import RunnerPeriodDetailComponent from '../../../components/budgeting/runner-period/runner-period-detail.component'
import BackButton from '../../../components/common/back-button/back-button.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { UpdateMode } from '../../../models/update-mode.enum'
import { useTranslation } from 'react-i18next'
import { Button, message } from 'antd'
import { AiFillCloseSquare } from 'react-icons/ai'
import { useRunnerPeriod } from '../../../hooks/budgeting/runner-period.hook'
import { RUNNER_STATUS } from '../../../constants/configurations.constant'
import useRoleAction from '../../../constants/role-action-hook'

const RunnerPeriodDetailsPage: React.FC = () => {
    const { t } = useTranslation()
    const { ROLE_ACTIONS } = useRoleAction()
    const { runnerPeriod, saveRunnerPeriod, archiveRunnerPeriod, setRunnerPeriod } =
        useRunnerPeriod()

    const archiveRunnerPeriodHandler = async () => {
        const updatedRunnerPeriod = {
            ...runnerPeriod,
            status: RUNNER_STATUS.ARCHIVED,
        }
        const feedback = await archiveRunnerPeriod(updatedRunnerPeriod)
        if (feedback) {
            message.success('Runner Period archive successfully')
            setRunnerPeriod(updatedRunnerPeriod)
        } else {
            message.error('Error: could not archive runner period')
        }
    }

    const closeRunnerPeriod = async () => {
        const updatedRunnerPeriod = { ...runnerPeriod, status: RUNNER_STATUS.CLOSED }
        const feedback = await saveRunnerPeriod(updatedRunnerPeriod, UpdateMode.EDIT)
        if (feedback) {
            message.success('Runner Period closed successfully')
            setRunnerPeriod(updatedRunnerPeriod)
        } else {
            message.error('Error: could not close runner period')
        }
    }

    return (
        <>
            <PageBreadCrumbs
                items={[
                    t('budgeting:runnerPeriod.breadcrumb.runnerPeriod'),
                    t('budgeting:runnerPeriod.breadcrumb.list'),
                ]}
            />
            <TitleBar
                title={t('budgeting:runnerPeriod.mainPage.title')}
                subTitle={t('budgeting:runnerPeriod.detailPage.subTitle')}
                showButton={ROLE_ACTIONS.RUNNER_PERIOD.EDIT}
                handleShow={archiveRunnerPeriodHandler}
                buttonLabel={t('budgeting:runnerPeriod.detailPage.buttonLabel')}
                icon={<FiArchive />}
                showExtra={ROLE_ACTIONS.RUNNER_PERIOD.EDIT}
                extra={
                    runnerPeriod.status === RUNNER_STATUS.CLOSED ? (
                        <></>
                    ) : (
                        <div className="custom__component">
                            <Button
                                danger
                                type="default"
                                icon={<AiFillCloseSquare />}
                                onClick={closeRunnerPeriod}
                            >
                                <span style={{ marginLeft: 5 }}>Close</span>
                            </Button>
                        </div>
                    )
                }
            />

            <BackButton page="Runner Period" />

            {/* details */}
            <RunnerPeriodDetailComponent />
        </>
    )
}

export default RunnerPeriodDetailsPage
