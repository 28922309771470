import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as weekActions from '../../redux/budgeting/week.slice'
import { emptyWeek, IWeek } from '../../models/budgeting/week.model'
import { useRunner } from './runner.hook'

const useWeek = () => {
    const { runner } = useRunner()
    const weeks = useSelector<rootState, IWeek[]>((state) => state.week.weeks)
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.week.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.week.initialFetch
    )
    const week = useSelector<rootState, IWeek>((state) => state.week.week)
    const dispatch = useDispatch()

    const loadWeeks = useCallback(() => {
        if (initialFetch) {
            dispatch(weekActions.fetchWeeksAsync(runner.copYear))
        }
    }, [dispatch, initialFetch, runner.copYear])

    const getRunnerWeek = useCallback((): IWeek => {
        const week = weeks.find(
            (x) =>
                x.weekStartDate <= runner.startDate &&
                x.weekEndDate >= runner.startDate
        )

        if (week) {
            return week
        }

        return {
            ...emptyWeek,
        }
    }, [runner.startDate, weeks])

    useEffect(() => {
        loadWeeks()
    }, [week, weeks, isLoading, initialFetch, loadWeeks])

    return {
        week,
        runnerWeek: getRunnerWeek(),
        weeks,
        isLoading,
        initialFetch,
    }
}

export { useWeek }
