export const frComponent = {
    tenant: 'Locataire',
    code: 'code',
    copYear: 'Année composante',
    project: 'Projet',
    description: 'La description',
    longDescription: 'longue description',
    startDate: 'Date de début',
    endDate: 'Date de fin',
}
