import React, { useEffect, useRef } from 'react'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import ReportWrapper from '../../../components/reports/report-wrapper.component'
// import ReportFilterComponent from '../report-filter.form.component'
import { useTranslation } from 'react-i18next'
import { useBudgetAnalysisContext } from '../../../contexts/reports/report.context'
import { Table } from 'antd'
import { BUDGET_ANALYSIS } from '../../../constants/configurations.constant'
import { budgetExecutionTableColumns } from '../../../components/reports/budget-execution-table.column'
import { IBudgetExecution } from '../../../models/reports/budget-execution.model'
import BudgetExecutionFilterForm from '../../../components/reports/budget-execution-filter.form.component'

const SiteBudgetExecutionReport: React.FC = () => {
    const { t } = useTranslation()
    const ref = useRef()
    const {
        hasReportData,
        reportData,
        setBudgetAnalysisType,
        setHasReportData,
        setHasPdf,
    } = useBudgetAnalysisContext()

    setBudgetAnalysisType(BUDGET_ANALYSIS.COST_CATEGORY)

    useEffect(() => {
        setHasReportData(false)
        setHasPdf(false)
    }, [])
    return (
        <div>
            <TitleBar
                title={t('common:siteReport.budgetExecutionPage.title')} //"Site budget analysis by cost category report"
            />
            <BudgetExecutionFilterForm formRef={ref} />
            <ReportWrapper />
            {hasReportData && (
                <div style={{ marginTop: '1rem' }}>
                    {/* <ExcelExportWrapper columns={budgetAnalysisTableColumns} data={reportData} fileName="budget analysis by cost category">*/}
                    <Table<IBudgetExecution>
                        dataSource={reportData}
                        columns={budgetExecutionTableColumns}
                        size={'small'}
                        rowKey={'code'}
                        scroll={{ x: '500' }}
                    />
                    {/* </ExcelExportWrapper> */}
                </div>
            )}
        </div>
    )
}
export default SiteBudgetExecutionReport
