import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IZone {
    code: string
    description: string
    createdOn: Date
}

export const emptyZone: IZone = {
    code: '',
    description: '',
    createdOn: new Date(),
}

export interface IZoneState extends IBaseState {
    readonly zones: IZone[]
    readonly zone: IZone
}

export interface IZoneResponse extends IResponseBase {
    data: IZone
}
