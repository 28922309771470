import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import BranchStationForm from '../../../components/common/branch-station/branch-station-form.component'
import BranchStationTable from '../../../components/common/branch-station/branch-station-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useBranchStation } from '../../../hooks/common/branch-station/branch-station.hook'
import { emptyBranchStation } from '../../../models/common/branch-station.model'
import { UpdateMode } from '../../../models/update-mode.enum'

const BranchStationPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { setBranchStation } = useBranchStation()

    const handleShow = () => {
        setTitle('Create new branch station')
        setBranchStation(emptyBranchStation)
        setContent(<BranchStationForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'BranchStations']} />
            <TitleBar
                title="Branch Stations"
                subTitle="View and manage branch stations"
                showButton
                handleShow={handleShow}
                buttonLabel="Add Branch Station"
                icon={<FiPlus />}
            />
            <BranchStationTable />
        </>
    )
}

export default BranchStationPage
