import { apiRequests } from '..'
import {
    IUserCoordination,
    IUserCoordinationResponse,
} from '../../models/budgeting/user-coordination.model'

const userCoordinationService = {
    list: (): Promise<IUserCoordination[]> => apiRequests.get('/userCoordinations'),
    details: (code: string): Promise<IUserCoordination> =>
        apiRequests.get(`/userCoordinations/${code}`),
    create: (
        userCoordination: IUserCoordination
    ): Promise<IUserCoordinationResponse> =>
        apiRequests.post('/userCoordinations', userCoordination),
    update: (
        userCoordination: IUserCoordination
    ): Promise<IUserCoordinationResponse> =>
        apiRequests.put('/userCoordinations', userCoordination),
}

export default userCoordinationService
