import { Space, Tag } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'

import { IRoleClaim } from '../../../models/common/role-claim.model'
import roleClaimService from '../../../services/common/role-claim/role-claim.service'

type Props = {
    roleCode: string
}

const RoleClaimsRenderer: React.FunctionComponent<Props> = ({ roleCode }) => {
    const [selectedRoleClaims, setSelectedRoleClaims] = useState<Array<IRoleClaim>>(
        []
    )

    const getRandomColors = () => {
        const colors = [
            'pink',
            'red',
            'yellow',
            'orange',
            'cyan',
            'green',
            'blue',
            'purple',
            'geekblue',
            'magenta',
            'volcano',
            'gold',
            'lime',
        ]
        const randomColorPos = Math.floor(Math.random() * (colors.length - 1))
        return colors[randomColorPos]
    }

    const reloadRoleClaims = useCallback(async () => {
        const roleClaims = await roleClaimService.getClaimsForRole(roleCode)
        setSelectedRoleClaims(roleClaims)
    }, [])

    useEffect(() => {
        reloadRoleClaims()
    }, [])

    return (
        <Space size="small" wrap>
            {selectedRoleClaims.map((item) => (
                <Tag color={getRandomColors()}>{item.code}</Tag>
            ))}
        </Space>
    )
}

export default RoleClaimsRenderer
