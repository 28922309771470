import { apiRequests } from '../'
import { IRunner, IRunnerResponse } from '../../models/budgeting/runner.model'

const runnerService = {
    list: (): Promise<IRunner[]> => apiRequests.get('/runners'),
    details: (code: string): Promise<IRunner> => apiRequests.get(`/runners/${code}`),
    create: (runner: IRunner): Promise<IRunnerResponse> =>
        apiRequests.post('/runners', runner),
    update: (runner: IRunner): Promise<IRunnerResponse> =>
        apiRequests.put('/runners', runner),
    archive: (runner: IRunner): Promise<IRunnerResponse> =>
        apiRequests.post('/runners/archive', runner),
}

export default runnerService
