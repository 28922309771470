import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { rootState } from '../../../redux/root-reducer'
import { IResponseBase } from '../../../models/response.base'
import * as formErrorActions from '../../../redux/common/form/form-error.slice'
const useFormErrors = () => {
    const formError = useSelector<rootState, IResponseBase>(
        (state) => state.formError
    )

    const dispatch = useDispatch()
    const setformError = useCallback(
        (formErrorObj: IResponseBase) => {
            dispatch(formErrorActions.setFormError(formErrorObj))
        },
        [dispatch]
    )

    return {
        formError,
        setformError,
    }
}

export { useFormErrors }
