import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IQuarter {
    tenant: string
    code: string
    copYear: string
    description: string
    startDate: Date
    endDate: Date
    status: string
    createdOn: Date
}

export const emptyQuarter: IQuarter = {
    tenant: '',
    code: '',
    copYear: '',
    description: '',
    startDate: new Date(),
    endDate: new Date(),
    status: '',
    createdOn: new Date(),
}

export interface IQuarterState extends IBaseState {
    readonly quarters: IQuarter[]
    readonly quarter: IQuarter
}

export interface IQuarterResponse extends IResponseBase {
    data: IQuarter
}
