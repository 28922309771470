export const frActivityPlan = {
    tenant: 'Locataire',
    code: 'Code',
    copYear: 'Année composante',
    component: 'Composante',
    project: 'Projet',
    description: 'La description',
    intervention: 'Intervention',
    costCategory: 'Catégorie de coût',
    approach: 'Approcher',
    objective: 'Objectif',
    budgetCode: 'Code budgétaire',
    budgetBalance: 'Solde budgétaire',
    activityType: "Type d'activité",
    createdOn: 'Créé sur',
}
