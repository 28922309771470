import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as copYearBudgetCodeModel from '../../models/budgeting/cop-year-budget-code.model'
import copYearBudgetCodeService from '../../services/budgeting/cop-year-budget-code.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: copYearBudgetCodeModel.ICopYearBudgetCodeState = {
    copYearBudgetCodes: [],
    errors: '',
    copYearBudgetCode: copYearBudgetCodeModel.emptyCopYearBudgetCode,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchcopYearBudgetCodesAsync = createAsyncThunk<
    copYearBudgetCodeModel.ICopYearBudgetCode[],
    void
>('copYearBudgetCode/fetchcopYearBudgetCodesAsync', async (_, thunkApi) => {
    try {
        return await copYearBudgetCodeService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const copYearBudgetCodeSlice = createSlice({
    name: 'copYearBudgetCode',
    initialState,
    reducers: {
        fetchcopYearBudgetCodesRequest: (state) => {
            state.isLoading = true
        },
        fetchcopYearBudgetCodesSuccess: (
            state,
            action: PayloadAction<copYearBudgetCodeModel.ICopYearBudgetCode[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.copYearBudgetCodes = action.payload
        },
        fetchcopYearBudgetCodesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editCopYearBudgetCodeSuccess: (
            state,
            action: PayloadAction<copYearBudgetCodeModel.ICopYearBudgetCode>
        ) => {
            state.copYearBudgetCodes = state.copYearBudgetCodes.map(
                (copYearBudgetCode) => {
                    return copYearBudgetCode.budgetCode === action.payload.budgetCode
                        ? action.payload
                        : copYearBudgetCode
                }
            )
            state.updateMode = UpdateMode.NONE
        },
        addCopYearBudgetCodeSuccess: (
            state,
            action: PayloadAction<copYearBudgetCodeModel.ICopYearBudgetCode>
        ) => {
            state.copYearBudgetCodes = [...state.copYearBudgetCodes, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveCopYearBudgetCode: (
            state,
            action: PayloadAction<copYearBudgetCodeModel.ICopYearBudgetCode>
        ) => {
            state.copYearBudgetCode = action.payload
        },
        setCopYearBudgetCodeUpdateMode: (
            state,
            action: PayloadAction<UpdateMode>
        ) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchcopYearBudgetCodesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchcopYearBudgetCodesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.copYearBudgetCodes = action.payload
        })
        builder.addCase(fetchcopYearBudgetCodesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchcopYearBudgetCodesRequest,
    fetchcopYearBudgetCodesSuccess,
    fetchcopYearBudgetCodesError,
    editCopYearBudgetCodeSuccess,
    addCopYearBudgetCodeSuccess,
    setActiveCopYearBudgetCode,
    setCopYearBudgetCodeUpdateMode,
} = copYearBudgetCodeSlice.actions

const reducer = copYearBudgetCodeSlice.reducer

export { reducer as copYearBudgetCodeReducer }
