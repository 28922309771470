import Card from 'antd/lib/card/Card'
import React from 'react'
import { useBudgetAnalysisContext } from '../../contexts/reports/report.context'

const ReportWrapper: React.FC = ({ children }) => {
    const { hasPdf, reportFile } = useBudgetAnalysisContext()

    // const url = appConfig.apiUrl + '/generated-reports/' + reportFile
    const url = 'data:application/pdf;base64,' + reportFile
    return (
        <div>
            {hasPdf ? (
                <Card size="small" style={{ marginTop: '1rem' }}>
                    <iframe
                        src={url}
                        typeof="application/pdf"
                        height="800px"
                        width="100%"
                        title="Report title"
                    />
                </Card>
            ) : (
                <> {children}</>
            )}
        </div>
    )
}

export default ReportWrapper
