import { apiRequests } from '../'
import {
    IRunnerPeriodStatus,
    IRunnerPeriodStatusResponse,
} from '../../models/budgeting/runner-period-status.model'

const runnerPeriodStatusService = {
    list: (): Promise<IRunnerPeriodStatus[]> =>
        apiRequests.get('/runnerPeriodStatuses'),
    details: (code: string): Promise<IRunnerPeriodStatus> =>
        apiRequests.get(`/runnerPeriodStatuses/${code}`),
    create: (
        runnerPeriodStatus: IRunnerPeriodStatus
    ): Promise<IRunnerPeriodStatusResponse> =>
        apiRequests.post('/runnerPeriodStatuses', runnerPeriodStatus),
    update: (
        runnerPeriodStatus: IRunnerPeriodStatus
    ): Promise<IRunnerPeriodStatusResponse> =>
        apiRequests.put('/runnerPeriodStatuses', runnerPeriodStatus),
}

export default runnerPeriodStatusService
