export enum activeTabActionTypes {
    SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
}

export interface ActiveTabSetActiveTabAction {
    type: typeof activeTabActionTypes.SET_ACTIVE_TAB
    payload: string
}

export type activeTabActionsCreators = ActiveTabSetActiveTabAction
