import { useDispatch, useSelector } from 'react-redux'
import { rootState } from '../../../redux/root-reducer'
import * as languageActions from '../../../redux/shared/lang/lang.actions'
import i18n from '../../../i18n'

const useLanguage = () => {
    const languageKeys: any = {
        en: '01',
        fr: '02',
    }
    const getLanguageKey = useSelector<rootState, string>(
        (state) => state.language.key
    )

    const dispatch = useDispatch()
    const changeLanguage = (languageKey: string) => {
        dispatch(languageActions.changeLanguage(languageKey))
        i18n.changeLanguage(languageKey, () => {})
    }

    const getLanguageKeyFromCode = (langCode: string) => {
        return Object.values(languageKeys)[Number.parseInt(langCode) - 1]
    }

    const getLanguageCodeFromKey = (langKey: string) => {
        return Object.keys(languageKeys)[Number.parseInt(langKey) - 1]
    }

    //
    const getKeyByValue = (value: string) => {
        return Object.keys(languageKeys).find((key) => languageKeys[key] === value)
    }

    return {
        languageKey: getLanguageKey,
        changeLanguage,
        getLanguageKeyFromCode,
        getLanguageCodeFromKey,
        getKeyByValue,
    }
}

export { useLanguage }
