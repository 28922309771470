import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IRunnerStatus {
    code: string
    description: string
    createdOn: Date
}

export const emptyRunnerStatus: IRunnerStatus = {
    code: '',
    description: '',
    createdOn: new Date(),
}

export interface IRunnerStatusState extends IBaseState {
    readonly runnerStatuses: IRunnerStatus[]
    readonly runnerStatus: IRunnerStatus
}

export interface IRunnerStatusResponse extends IResponseBase {
    data: IRunnerStatus
}
