import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as userMenuModel from '../../../models/common/user-menu.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import userMenuService from '../../../services/common/user-menu/user-menu.service'

export const initialState: userMenuModel.IUserMenuState = {
    userMenus: [],
    errors: '',
    userMenu: userMenuModel.emptyUserMenu,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchUserMenusAsync = createAsyncThunk(
    'userMenu/fetchUserMenusAsync',
    async () => {
        return await userMenuService.list()
    }
)

export const userMenuSlice = createSlice({
    name: 'userMenu',
    initialState,
    reducers: {
        fetchUserMenusRequest: (state) => {
            state.isLoading = true
        },
        fetchUserMenusSuccess: (
            state,
            action: PayloadAction<userMenuModel.IUserMenu[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.userMenus = action.payload
        },
        fetchUserMenusError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserMenusAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchUserMenusAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.userMenus = action.payload
        })
        builder.addCase(fetchUserMenusAsync.rejected, (state, action) => {
            state.isLoading = false
        })
    },
})

export const { fetchUserMenusRequest, fetchUserMenusSuccess, fetchUserMenusError } =
    userMenuSlice.actions

const reducer = userMenuSlice.reducer

export { reducer as userMenuReducer }
