import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as runnerPeriodModel from '../../models/budgeting/runner-period.model'
import runnerPeriodservice from '../../services/budgeting/runner-period.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: runnerPeriodModel.IRunnerPeriodState = {
    runnerPeriods: [],
    errors: '',
    runnerPeriod: runnerPeriodModel.emptyRunnerPeriod,
    isLoading: false,
    initialFetch: true,
    runnerHistoryCodes:[],
    updateMode: UpdateMode.NONE,
}

export const fetchRunnerPeriodsAsync = createAsyncThunk<
    runnerPeriodModel.IRunnerPeriod[],
    void
>('runnerPeriod/fetchRunnerPeriodsAsync', async (_, thunkApi) => {
    try {
        return await runnerPeriodservice.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const runnerPeriodslice = createSlice({
    name: 'runnerPeriod',
    initialState,
    reducers: {
        fetchRunnerPeriodsRequest: (state) => {
            state.isLoading = true
        },
        fetchRunnerPeriodsSuccess: (
            state,
            action: PayloadAction<runnerPeriodModel.IRunnerPeriod[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerPeriods = action.payload
        },
        fetchRunnerPeriodsError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editRunnerPeriodSuccess: (
            state,
            action: PayloadAction<runnerPeriodModel.IRunnerPeriod>
        ) => {
            state.runnerPeriods = state.runnerPeriods.map((runnerPeriod) => {
                return runnerPeriod.code === action.payload.code
                    ? action.payload
                    : runnerPeriod
            })
            state.updateMode = UpdateMode.NONE
        },
        addRunnerPeriodSuccess: (
            state,
            action: PayloadAction<runnerPeriodModel.IRunnerPeriod>
        ) => {
            state.runnerPeriods = [...state.runnerPeriods, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveRunnerPeriod: (
            state,
            action: PayloadAction<runnerPeriodModel.IRunnerPeriod>
        ) => {
            state.runnerPeriod = action.payload
        },
        setRunnerHistoryCodes: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.runnerHistoryCodes = action.payload
        },
        setRunnerPeriodUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRunnerPeriodsAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchRunnerPeriodsAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerPeriods = action.payload
        })
        builder.addCase(fetchRunnerPeriodsAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchRunnerPeriodsRequest,
    fetchRunnerPeriodsSuccess,
    fetchRunnerPeriodsError,
    editRunnerPeriodSuccess,
    addRunnerPeriodSuccess,
    setActiveRunnerPeriod,
    setRunnerPeriodUpdateMode,
    setRunnerHistoryCodes,
} = runnerPeriodslice.actions

const reducer = runnerPeriodslice.reducer

export { reducer as runnerPeriodReducer }
