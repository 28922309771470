import { Col, Row, Space } from 'antd'
import { useFormikContext } from 'formik'
import { Form, Input, SubmitButton, ResetButton } from 'formik-antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IDivision } from '../../../models/common/division.model'
import SearchableDropDown from '../../shared/seachable-dropdown/searchable-dropdown.component'

const FormItem = Form.Item
const DivisionFormFields: React.FC = () => {
    const { isSubmitting, initialValues, setFieldValue } =
        useFormikContext<IDivision>()
    const regions = [
        {
            code: '1',
            description: 'North West',
        },
        {
            code: '2',
            description: 'South West',
        },
        {
            code: '3',
            description: 'Center',
        },
        {
            code: '4',
            description: 'Litoral',
        },
    ]
    const { t } = useTranslation()

    return (
        <>
            <Form autoComplete="off">
                <p className="mb-0">{t('common:division.description')}</p>
                <FormItem name="description" required={true}>
                    <Input
                        name="description"
                        disabled={isSubmitting}
                        required={true}
                    />
                </FormItem>
                <Row>
                    <Col span={4}>
                        <p className="mb-0">{t('common:division.regionCode')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="regionCode" required={true}>
                            <SearchableDropDown
                                items={regions}
                                disabled={isSubmitting}
                                required={true}
                                value={initialValues.regionCode}
                                parentCallBack={(value: string) =>
                                    setFieldValue('regionCode', value)
                                }
                            />
                        </FormItem>
                    </Col>

                    <Col span={4}>
                        <p className="mb-0">{t('common:division.capital')}</p>
                    </Col>
                    <Col span={8}>
                        <FormItem name="capital" required={true}>
                            <Input
                                name="capital"
                                disabled={isSubmitting}
                                required={true}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Space size={'small'}>
                    <SubmitButton disabled={isSubmitting}>
                        {t('common:division.submit')}
                    </SubmitButton>
                    <ResetButton disabled={isSubmitting}>
                        {t('common:division.reset')}
                    </ResetButton>
                </Space>
            </Form>
        </>
    )
}

export default DivisionFormFields
