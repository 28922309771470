import { IBudgetExecutionOptions } from '../../models/reports/budget-execution-options.model'
import { budgetExecutionService } from '../../services/reports/budget-execution.service'

export const useBudgetExecution = () => {
    const getBudgetExecutionData = async (options: IBudgetExecutionOptions) =>
        await budgetExecutionService.getBudgetExecutionData(options)

    const getBudgetExecutionReport = async (options: IBudgetExecutionOptions) =>
        await budgetExecutionService.getBugdetExecutionReport(options)

    return {
        getBudgetExecutionData,
        getBudgetExecutionReport,
    }
}
