import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import {
    emptyRunnerComponent,
    IRunnerComponent,
} from '../../models/budgeting/runner-component.model'
import * as runnerComponentActions from '../../redux/budgeting/runner-component.slice'
import { useRunner } from './runner.hook'
import { useComponent } from './component.hook'
import { useProject } from './project.hook'
import { useCurrentBranch } from '../common/current-branch/current-branch.hook'
import { UpdateMode } from '../../models/update-mode.enum'
import runnerComponentService from '../../services/budgeting/runner-component.service'

const useRunnerComponent = () => {
    const { components } = useComponent()
    const { projects } = useProject()
    const { currentBranch } = useCurrentBranch()
    const { runner } = useRunner()

    const runnerComponents = useSelector<rootState, IRunnerComponent[]>(
        (state) => state.runnerComponent.runnerComponents
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.runnerComponent.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.runnerComponent.isLoading
    )
    const runnerComponent = useSelector<rootState, IRunnerComponent>(
        (state) => state.runnerComponent.runnerComponent
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.runnerComponent.updateMode
    )

    const dispatch = useDispatch()

    const loadRunnerComponents = useCallback(() => {
        if (initialFetch) {
            dispatch(runnerComponentActions.fetchRunnerComponentsAsync())
        }
    }, [dispatch, initialFetch])

    const addRunnerComponent = async (runnerComponent: IRunnerComponent) => {
        return await runnerComponentService
            .create(runnerComponent)
            .then((runnerComponent) => {
                dispatch(
                    runnerComponentActions.addRunnerComponentSuccess(
                        runnerComponent.data
                    )
                )
                return true
            })
            .catch(() => {
                return false
            })
    }

    const setRunnerComponent = (runnerComponent: IRunnerComponent) => {
        dispatch(runnerComponentActions.setActiveRunnerComponent(runnerComponent))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(runnerComponentActions.setRunnerComponentUpdateMode(updateMode))
    }

    const editRunnerComponent = async (runnerComponent: IRunnerComponent) => {
        return await runnerComponentService
            .update(runnerComponent)
            .then((runnerComponent) => {
                dispatch(
                    runnerComponentActions.editRunnerComponentSuccess(
                        runnerComponent.data
                    )
                )
                setRunnerComponent(runnerComponent.data)
                return true
            })
            .catch(() => {
                return false
            })
    }

    const saveRunnerComponent = async (
        runnerComponent: IRunnerComponent,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addRunnerComponent(runnerComponent)
            : await editRunnerComponent(runnerComponent)
    }

    const getRunnerComponent = useCallback(
        (runnerComponent: IRunnerComponent): IRunnerComponent => {
            const component = components.find(
                (x) => x.code === runnerComponent.component
            )
            const componentDescription = component ? component.description : ''
            const componentLongDescription = component
                ? component.longDescription
                : ''

            const project = projects.find((x) => x.code === runnerComponent.project)
            const projectDescription = project ? project.description : ''

            return {
                ...runnerComponent,
                componentDescription,
                componentLongDescription,
                projectDescription,
            }
        },
        [components, projects]
    )

    const getProjectRunnerComponent = useCallback((): IRunnerComponent => {
        const component = runnerComponents.find(
            (x) => x.project === currentBranch.project && x.runner === runner.code
        )

        if (component) {
            return getRunnerComponent(component)
        }

        return emptyRunnerComponent
    }, [runnerComponents, currentBranch.project, getRunnerComponent, runner.code])

    const getRunnerComponents = useCallback(() => {
        return runnerComponents.map((runnerComponent) =>
            getRunnerComponent(runnerComponent)
        )
    }, [runnerComponents, getRunnerComponent])

    useEffect(() => {
        loadRunnerComponents()
    }, [
        runnerComponent,
        runnerComponents,
        isLoading,
        initialFetch,
        loadRunnerComponents,
    ])

    return {
        runnerComponent: getProjectRunnerComponent(),
        runnerComponents,
        runnerComponentDetails: getRunnerComponents(),
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addRunnerComponent,
        editRunnerComponent,
        saveRunnerComponent,
        setRunnerComponent,
    }
}

export { useRunnerComponent }
