export interface ICostCategoryMock {
    code: string | number
    name: string
    budgetCode: string
    componentBudget: string
    componentExpenditure: string
    monthBudget: string
    monthExpenditure: string
    weekBudget: string
    weekExpenditure: string
    weekActual: string
    weekVariance: string
    percentage: string
}

export const getRandomAmount = (min: number, max: number) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    const result = Math.floor(Math.random() * (max - min) + min)
    //   return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'XAF' }).format(result);
    return new Intl.NumberFormat().format(result)
}

const costCategoryMockData: Array<ICostCategoryMock> = []

for (let i = 1; i < 20; i++) {
    costCategoryMockData.push({
        code: i < 10 ? `0${i}` : i,
        name: 'Random name',
        budgetCode: '',
        componentBudget: getRandomAmount(100, 10_000),
        componentExpenditure: getRandomAmount(5000, 50_000),
        monthBudget: getRandomAmount(50_000, 800_000),
        monthExpenditure: getRandomAmount(100_000, 5_000_000),
        weekBudget: getRandomAmount(80_000, 3_000_000),
        weekExpenditure: getRandomAmount(200_000, 2_000_000),
        weekActual: getRandomAmount(200_000, 3_000_000),
        weekVariance: getRandomAmount(180_000, 2_000_000),
        percentage: getRandomAmount(8, 100),
    })
}

export { costCategoryMockData }
