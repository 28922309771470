import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as CostCategoryActions from '../../redux/budgeting/cost-category.slice'
import { ICostCategory } from '../../models/budgeting/cost-category.model'
import { UpdateMode } from '../../models/update-mode.enum'
import costCategorieservice from '../../services/budgeting/cost-category.service'

const useCostCategory = () => {
    const costCategories = useSelector<rootState, ICostCategory[]>(
        (state) => state.costCategory.costCategories
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.costCategory.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.costCategory.initialFetch
    )
    const costCategory = useSelector<rootState, ICostCategory>(
        (state) => state.costCategory.costCategory
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.costCategory.updateMode
    )

    const dispatch = useDispatch()

    const loadCostCategories = useCallback(() => {
        if (initialFetch) {
            dispatch(CostCategoryActions.fetchCostCategoriesAsync())
        }
    }, [dispatch, initialFetch])

    const addCostCategory = async (CostCategory: ICostCategory) => {
        return await costCategorieservice
            .create(CostCategory)
            .then((CostCategoryResponse) => {
                if (CostCategoryResponse.success) {
                    dispatch(
                        CostCategoryActions.addCostCategoriesuccess(
                            CostCategoryResponse.data
                        )
                    )
                } else {
                    return CostCategoryResponse
                }
                return CostCategoryResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setCostCategory = (CostCategory: ICostCategory) => {
        dispatch(CostCategoryActions.setActiveCostCategory(CostCategory))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(CostCategoryActions.setCostCategoryUpdateMode(updateMode))
    }

    const editCostCategory = async (CostCategory: ICostCategory) => {
        return await costCategorieservice
            .update(CostCategory)
            .then((CostCategoryResponse) => {
                dispatch(
                    CostCategoryActions.editCostCategoriesuccess(
                        CostCategoryResponse.data
                    )
                )
                setCostCategory(CostCategoryResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveCostCategory = async (
        CostCategory: ICostCategory,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addCostCategory(CostCategory)
            : await editCostCategory(CostCategory)
    }

    useEffect(() => {
        loadCostCategories()
    }, [costCategory, costCategories, isLoading, initialFetch, loadCostCategories])

    return {
        costCategory,
        costCategories,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addCostCategory,
        editCostCategory,
        saveCostCategory,
        setCostCategory,
    }
}

export { useCostCategory }
