import { ColumnsType } from 'antd/lib/table'
import { IActivity } from '../../../models/budgeting/activity.model'
import { format } from '../../../utils/format'

export const activityExcelTableColumns: ColumnsType<IActivity> = [
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Batch Line',
        dataIndex: 'batchLine',
        key: 'batchLine',
    },
    {
        title: 'Runner',
        dataIndex: 'runner',
        key: 'runner', // requires mapping to description
    },
    {
        title: 'Component',
        dataIndex: 'component',
        key: 'component', // requires mapping to description
    },
    {
        title: 'User Name',
        dataIndex: 'userCode',
        key: 'userCode', 
    },
    {
        title: 'Cost Category',
        dataIndex: 'costCategory',
        key: 'costCategory', // requires mapping to description
    },
    {
        title: 'Intervention',
        dataIndex: 'intervention',
        key: 'intervention',
    },
    {
        title: 'Budget code',
        dataIndex: 'budgetCode',
        key: 'budgetCode',
    },
    {
        title: 'Activity',
        dataIndex: 'activity',
        key: 'activity',
    },
    {
        title: 'Activity Type',
        dataIndex: 'activityType',
        key: 'activityType',
        render: (_, record) => (record.activityType === '01' ? 'Site' : 'Program'),
    },
    {
        title: 'Site',
        dataIndex: 'site',
        key: 'site',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (_, record) => format.number(record.amount)
    },
    {
        title: 'Transaction date',
        dataIndex: 'transDate',
        key: 'transDate', // requires mapping to description
        render: (_, record) => format.date(record.transDate),
    },
    {
        title: 'Project',
        dataIndex: 'project',
        key: 'project', // requires mapping to description
    },
    {
        title: 'Cop Year',
        dataIndex: 'copYear',
        key: 'copYear', // requires mapping to description
    },
    {
        title: 'Region',
        dataIndex: 'region',
        key: 'region', // requires mapping to description
    },
]
