import { apiRequests } from '../..'
import { IRole, IRoleResponse } from '../../../models/common/role.model'

const roleService = {
    list: (): Promise<IRole[]> => apiRequests.get('/roles'),
    details: (code: string): Promise<IRole> => apiRequests.get(`/roles/${code}`),
    create: (role: IRole): Promise<IRoleResponse> =>
        apiRequests.post('/roles', role),
    update: (role: IRole): Promise<IRoleResponse> => apiRequests.put('/roles', role),
}

export default roleService
