import { Formik } from 'formik'
import React, { useContext } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useBranchStation } from '../../../hooks/common/branch-station/branch-station.hook'
import { useToast } from '../../../hooks/common/toast/toast.hook'
import {
    emptyBranchStation,
    IBranchStation,
} from '../../../models/common/branch-station.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import BranchStationFormFields from './branch-station-form-field.component'

interface IProp {
    updateMode: UpdateMode
}
const BranchForm: React.FC<IProp> = ({ updateMode }) => {
    const { toastSuccess, toastError } = useToast()
    const { saveBranchStation, branchStation, branchStationSchema } =
        useBranchStation()
    const { setShow } = useContext<any>(AppModalContext)

    function notify(arg0: { validationErrors: any; message: any }) {
        throw new Error('Function not implemented.')
    }

    return (
        <div>
            <Formik<IBranchStation>
                initialValues={branchStation || emptyBranchStation}
                validationSchema={branchStationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    const feedback = await saveBranchStation(values, UpdateMode.ADD)
                    if (feedback.success) {
                        toastSuccess('M0002')
                        setShow(false)
                    } else {
                        if (
                            feedback.validationErrors &&
                            feedback.validationErrors.length > 0
                        ) {
                            notify({
                                validationErrors: feedback.validationErrors,
                                message: feedback.message,
                            })
                        } else {
                            toastError('M0003')
                        }
                        setShow(true)
                    }
                    setSubmitting(false)
                }}
            >
                <>
                    <BranchStationFormFields />
                </>
            </Formik>
        </div>
    )
}
export default BranchForm
