import React from "react";
import { Table } from "antd";
import { useHistory } from "react-router-dom";
import { slugify } from "../../../utils/slug";
import { IClaim } from "../../../models/common/claim.model";
import { claimTableColumns } from "./claim.column";

type Props = {
    claims: IClaim[]
}

export const ClaimsTable: React.FC<Props> = ({claims}) => {
  const history = useHistory();

  const handleRowClick = (claim: IClaim) => {
    history.push(`/claims/${slugify(claim.description)}`);
  };

  return (
    <div>
      <Table<IClaim>
        dataSource={claims}
        columns={claimTableColumns}
        size={"small"}
        rowKey={"code"}
        onRow={(record: IClaim) => {
          return {
            onClick: (e) => {
              handleRowClick(record);
            },
          };
        }}
      />
    </div>
  );
};
