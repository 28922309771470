import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as runnerStatusModel from '../../models/budgeting/runner-status.model'
import runnerStatusService from '../../services/budgeting/runner-status.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: runnerStatusModel.IRunnerStatusState = {
    runnerStatuses: [],
    errors: '',
    runnerStatus: runnerStatusModel.emptyRunnerStatus,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchRunnerStatusesAsync = createAsyncThunk<
    runnerStatusModel.IRunnerStatus[],
    void
>('runnerStatus/fetchRunnerStatusesAsync', async (_, thunkApi) => {
    try {
        return await runnerStatusService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const runnerStatusSlice = createSlice({
    name: 'runnerStatus',
    initialState,
    reducers: {
        fetchRunnerStatussRequest: (state) => {
            state.isLoading = true
        },
        fetchRunnerStatussSuccess: (
            state,
            action: PayloadAction<runnerStatusModel.IRunnerStatus[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerStatuses = action.payload
        },
        fetchRunnerStatussError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editRunnerStatusSuccess: (
            state,
            action: PayloadAction<runnerStatusModel.IRunnerStatus>
        ) => {
            state.runnerStatuses = state.runnerStatuses.map((runnerStatus) => {
                return runnerStatus.code === action.payload.code
                    ? action.payload
                    : runnerStatus
            })
            state.updateMode = UpdateMode.NONE
        },
        addRunnerStatusSuccess: (
            state,
            action: PayloadAction<runnerStatusModel.IRunnerStatus>
        ) => {
            state.runnerStatuses = [...state.runnerStatuses, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveRunnerStatus: (
            state,
            action: PayloadAction<runnerStatusModel.IRunnerStatus>
        ) => {
            state.runnerStatus = action.payload
        },
        setRunnerStatusUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRunnerStatusesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchRunnerStatusesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerStatuses = action.payload
        })
        builder.addCase(fetchRunnerStatusesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchRunnerStatussRequest,
    fetchRunnerStatussSuccess,
    fetchRunnerStatussError,
    editRunnerStatusSuccess,
    addRunnerStatusSuccess,
    setActiveRunnerStatus,
    setRunnerStatusUpdateMode,
} = runnerStatusSlice.actions

const reducer = runnerStatusSlice.reducer

export { reducer as runnerStatusReducer }
