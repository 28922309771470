import { Card, Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useBranchStation } from '../../../hooks/common/branch-station/branch-station.hook'
import { useBranch } from '../../../hooks/common/branch.hook'

const BranchStationDetailComponent: React.FC = () => {
    const { branchStation } = useBranchStation()
    const { branches } = useBranch()
    const branchName = branches.find(
        (branch) => branch.code === branchStation.branch
    )?.branchName
    return (
        <Card bordered={false} size="small">
            <Row>
                <Col span={4}>
                    <StyledParagraph>Branch: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branchName}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Station: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branchStation.station}</StyledParagraph>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <StyledParagraph>Status: </StyledParagraph>
                </Col>
                <Col span={20}>
                    <StyledParagraph>{branchStation.status}</StyledParagraph>
                </Col>
            </Row>
        </Card>
    )
}

export default BranchStationDetailComponent

const StyledParagraph = styled.p`
    margin-bottom: 0;
`
