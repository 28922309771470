import { apiRequests } from '../'
import {
    IBudgetHistory,
    IBudgetHistoryResponse,
} from '../../models/budgeting/budget-history.model'

const budgetHistoryService = {
    list: (): Promise<IBudgetHistory[]> => apiRequests.get('/budgetHistories'),
    details: (history: string): Promise<IBudgetHistory> =>
        apiRequests.get(`/budgetHistories/${history}`),
    create: (budgetHistory: IBudgetHistory): Promise<IBudgetHistoryResponse> =>
        apiRequests.post('/budgetHistories', budgetHistory),
    update: (budgetHistory: IBudgetHistory): Promise<IBudgetHistoryResponse> =>
        apiRequests.put('/budgetHistories', budgetHistory),
}

export default budgetHistoryService
