import React from 'react'
import { Table } from 'antd'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'
import { userTableColumns } from './user.column'
import { useUser } from '../../../hooks/shared/user/user.hook'
import { IUser } from '../../../models/shared/user.model'

type  props= {
    resultUsers: IUser[]
}
export const UserTable: React.FC<props> = ({ resultUsers }) => {
    const {  setUser } = useUser()
    const history = useHistory()
    const handleRowClick = (user: IUser) => {
        setUser(user)
        history.push(`/users/${slugify(user.usrName)}`)
    }

    return (
        <div>
            <Table<IUser>
                dataSource={resultUsers}
                columns={userTableColumns}
                size={'small'}
                rowKey={'code'}
                onRow={(record: IUser) => {
                    return {
                        onClick: (e) => {
                            handleRowClick(record)
                        },
                    }
                }}
            />
        </div>
    )
}
