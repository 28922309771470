export const enBranchStation = {
    description: 'Description',
    code: 'Code',
    tenant: 'Tenant',
    branch: 'Branch',
    station: 'Station',
    status: 'Status',

    submit: 'Submit',
    reset: 'Reset',
}
