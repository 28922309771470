import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from 'oidc-client'
import { setAccessToken } from '../../../utils/axios-headers'

export interface IAuthState {
    readonly isLoadingUser: boolean
    readonly user: User | null
}

const initialState: IAuthState = {
    isLoadingUser: false,
    user: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loadingUser: (state) => {
            state.isLoadingUser = true
        },
        storeUser: (state, action: PayloadAction<User>) => {
            setAccessToken(action.payload.access_token)
            state.isLoadingUser = false
            state.user = action.payload
        },
        storeUserError: (state) => {
            setAccessToken('')
            state.isLoadingUser = false
            state.user = null
        },
        userExpired: (state) => {
            setAccessToken('')
            state.isLoadingUser = false
            state.user = null
        },
        userSignedOut: (state) => {
            setAccessToken('')
            state.isLoadingUser = false
            state.user = null
        },
    },
})

export const { loadingUser, storeUser, storeUserError, userExpired, userSignedOut } =
    authSlice.actions

const reducer = authSlice.reducer

export { reducer as authReducer }
