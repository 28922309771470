import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { emptyRunnerBase, IRunnerBase } from './runner-base.model'

export interface IRunner extends IRunnerBase {
    regionDescription?: string
    statusDescription?: string
}

export interface IRunnerState extends IBaseState {
    readonly runners: IRunner[]
    readonly runner: IRunner
}

export const emptyRunner: IRunner = emptyRunnerBase

export interface IRunnerResponse extends IResponseBase {
    data: IRunner
}

export interface IRunnerHistoryCodesState extends IBaseState {
    readonly codes: string[]
}
