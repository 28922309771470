import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISessionBatchState } from '../../../models/batch/session-batch.model'
import { UpdateMode } from '../../../models/update-mode.enum'

export const initialState: ISessionBatchState = {
    errors: '',
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const sessionBatchSlice = createSlice({
    name: 'sessionBatch',
    initialState,
    reducers: {
        fetchSessionBatchRequest: (state) => {
            state.isLoading = true
        },
        fetchSessionBatchSuccess: (state) => {
            state.isLoading = false
            state.initialFetch = false
        },
        fetchSessionBatchError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
    },
})

export const {
    fetchSessionBatchRequest,
    fetchSessionBatchSuccess,
    fetchSessionBatchError,
} = sessionBatchSlice.actions

const reducer = sessionBatchSlice.reducer

export { reducer as sessionBatchReducer }
