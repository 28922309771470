import React from 'react'
import { Table } from 'antd'
import { IBranchStation } from '../../../models/common/branch-station.model'
import { branchStationsTableColumns } from './branch-station-column.component'
import { useBranchStation } from '../../../hooks/common/branch-station/branch-station.hook'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { slugify } from '../../../utils/slug'

const BranchStationTable: React.FunctionComponent = () => {
    const { branchStations, setBranchStation } = useBranchStation()
    const router = useHistory()
    // const route = use
    const handleRowClick = (branchStation: IBranchStation) => {
        setBranchStation(branchStation)
        router.push(
            `/configurations/branch-stations/${slugify(branchStation.station)}`
        )
    }
    return (
        <>
            {branchStations && branchStations.length ? (
                <Table<IBranchStation>
                    dataSource={branchStations}
                    columns={branchStationsTableColumns}
                    size={'small'}
                    rowKey={'code'}
                    onRow={(record: IBranchStation) => {
                        return {
                            onClick: (e) => {
                                handleRowClick(record)
                            },
                        }
                    }}
                />
            ) : (
                <NoContent title="No data for branch" />
            )}
        </>
    )
}

export default BranchStationTable
