import React from 'react'
import { Avatar, Card } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import UsersContextProvider from '../../../components/users/users-context.provider'
import BackButton from '../../../components/common/back-button/back-button.component'
import EditUsersFormModal from '../../../components/users/edit-users-form-modal.component'
import {
    Header,
    StyledUserAvatarContent,
    StyleUserTableAvatar,
} from '../../../components/users/users-styles.component'
import UsersDetailsDropdown from '../../../components/users/users-details-dropdown.component'
import UserDetailTabs from '../../../components/users/user-tabs.component'
import { ICurrentUser } from '../../../models/common/current-user.model'

const user: ICurrentUser = {
    email: 'johndoe@test.com',
    code: '',
    name: 'JohnDor',
    telephone: '',
    branch: '',
    subject: '',
    locale: '',
    tenant: '',
    userBranches: [],
}

const UsersDetailsPage: React.FunctionComponent = () => {
    return (
        <>
            <UsersContextProvider>
                <BackButton page="users" />
                <EditUsersFormModal />
                <Card>
                    <Header>
                        <section>
                            <StyleUserTableAvatar>
                                <Avatar
                                    icon={<UserOutlined />}
                                    style={{
                                        width: '80px',
                                        height: '80px',
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        fontSize: '3.5rem',
                                    }}
                                />
                                <StyledUserAvatarContent>
                                    <p style={{ fontSize: '1.5rem' }}>{user.name}</p>
                                    <p>{user.email}</p>
                                </StyledUserAvatarContent>
                            </StyleUserTableAvatar>
                        </section>
                        <UsersDetailsDropdown />
                    </Header>
                    <UserDetailTabs />
                </Card>
            </UsersContextProvider>
        </>
    )
}

export default UsersDetailsPage
