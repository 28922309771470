import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'
import { emptyActivityBase, IActivityBase } from './activity-base.model'
import { IActivitySummary } from './activity-summary.model'

export interface IActivity extends IActivityBase {}

export interface IActivityState extends IBaseState {
    readonly activities: IActivity[]
    readonly activity: IActivity
    readonly activitySummary: IActivitySummary
    readonly activityHistories: IActivity[]
}

export const emptyActivity: IActivity = { ...emptyActivityBase }

export interface IActivityResponse extends IResponseBase {
    data: IActivity
}

export interface IFetchActivityParams {
    readonly Branch: string
    readonly region: string
    readonly activityType: string
}
