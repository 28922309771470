import { Formik } from 'formik'
import React, { useContext } from 'react'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useTicketStatus } from '../../../hooks/common/ticket-status/ticket-status.hook'
import { emptyTicketStatus, ITicketStatus } from '../../../models/common/ticket-status.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import TicketStatusFormFields from './ticket-status-form-field.component'
import { message } from 'antd'

interface IProp {
    updateMode: UpdateMode
}
const TicketStatusForm: React.FC<IProp> = ({ updateMode }) => {
    const { saveTicketStatus, ticketStatus } = useTicketStatus()
    const { setShow } = useContext<any>(AppModalContext)
    return (
        <div>
            <Formik<ITicketStatus>
                initialValues={ticketStatus || emptyTicketStatus}
                onSubmit={async (values, { setSubmitting }) => {
                    const obj: ITicketStatus = {
                        ...emptyTicketStatus,
                        ...values,
                        code: '01',
                    }
                    const feedback = await saveTicketStatus(obj, updateMode)
                    if (feedback) {
                        message.success('Ticket Status save succesfully')
                    } else {
                        message.error('Error saving ticket status')
                    }
                    setSubmitting(false)
                    setShow(false)
                }}
            >
                <>
                    <TicketStatusFormFields />
                </>
            </Formik>
        </div>
    )
}
export default TicketStatusForm
