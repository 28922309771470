export const frRole = {
    formikValidation: {
        description: 'Description is required !',
    },
    breadcrumb: {
        role: "Rôle",
        roleMgt: "Gestion des rôles",
        roleDetail: "Détail du rôle"
    },
    form: {
        title: 'Rôle',
        titleAdd: 'Ajouter un rôle',
        titleEdit: 'Modifier le rôle',
        description: 'La description',
        savebtn: "sauvegarder",
    },

    tabPane: {
        roles: "Rôles",
        RolePrv: "Privilèges de rôle",
        userRolMgt: "Attribution de rôle d'utilisateur",
        roleUserAs: "Affectation d'utilisateur de rôle"
    },
    RolePrv: {
        title: "Attribuer des rôles aux utilisateurs sélectionnés",
        subTitle: "Nom de rôle",
        allRol: "Tous les rôles",
        selRol: "Rôle d'utilisateur sélectionné",
        addBtn: "Ajouter",
        removeBtn: "Retirer",
        selUser: "Sélectionnez l'utilisateur",
        allUsers: "Tous les utilisateurs",
        selUsers: "Utilisateurs sélectionnés",
        assGrp: "Attribuer un rôle à un groupe d'utilisateurs"

    },
    claims: {
        breadcrumb: {
            setting: "Paramètre",
            claim: "Réclamations"
        },
        title: 'Réclamations',
        btnLabel: "Ajouter une réclamation"
    },
    roleMgtForm: {
        title: "Créer un nouveau rôle",
        saveBtn: "Sauvegarder",
        cancelBtn: "Annuler",
    }
}
