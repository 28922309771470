import { IRoute } from '../../../models/common/route.model'
import CostCategoryDashboardPage from '../../../pages/dashboard/cost-category-dashboard.page'
import IntervntionDashboardPage from '../../../pages/dashboard/intervention-dashboard.page'
import MilestonProjectionDashboardPage from '../../../pages/dashboard/milestone-projection-dashboard.page'
import DailyReportDashboardPage from '../../../pages/dashboard/daily-data-dashboard.page'
import OutlayVsTargetDashboardPage from '../../../pages/dashboard/outlay-target-dashboard.page'
import DashboardPage from '../../../pages/dashboard/index.page'

export const dashboardRoutes: IRoute[] = [
    /**
     * Dashboard routes
     */
    {
        path: '/dashboard/outlay-vs-target',
        private: true,
        exact: true,
        component: OutlayVsTargetDashboardPage,
    },
    {
        path: '/dashboard/cost-category',
        private: true,
        exact: true,
        component: CostCategoryDashboardPage,
    },
    {
        path: '/dashboard/intervention',
        private: true,
        exact: true,
        component: IntervntionDashboardPage,
    },
    {
        path: '/dashboard/milestone-projection',
        private: true,
        exact: true,
        component: MilestonProjectionDashboardPage, 
    },
    {
        path: '/dashboard/daily-report',
        private: true,
        exact: true,
        component: DailyReportDashboardPage,
    },
    {
        path: '/dashboard',
        private: true,
        exact: true,
        component: DashboardPage,
    },
]
