export const frActivityPlanSummary = {
    batch: 'Lot',
    branch: 'Bifurquer',
    runner: 'Coureuse',
    transDate: 'Date de la transaction',
    userCode: "Code d'utilisateur",
    usrName: "Nom d'utilisateur",
    amount: 'Montante',
    number: 'numbre',
    region: 'Région',
    description: 'La description',
    project: 'Projet',
    activityType: "Type d'activité",
    transactionCode: 'Code de transaction',
    transactionCodeDescription: 'description de la transaction',
}
