import React from 'react'
import UsersFormModal from '../../../components/users/user-form-modal.component'
import UsersTable from '../../../components/users/user-table.component'
import UsersContextProvider from '../../../components/users/users-context.provider'
import UsersFilterForm from '../../../components/users/users-filter-form.component'
import UserHeader from '../../../components/users/users-header.component'

const UsersPage: React.FC = () => {
    return (
        <>
            <UsersContextProvider>
                <UserHeader />
                <UsersFormModal />
                <UsersFilterForm />
                <UsersTable />
            </UsersContextProvider>
        </>
    )
}

export default UsersPage
