import { branchStationReducer } from './branch-station/branch-station.slice'
import { branchReducer } from './branch/branch.slice'
import { claimTypeReducer } from './claim-type/claim-type.slice'
import { currentBranchReducer } from './current-branch/current-branch.slice'
import { currentUserReducer } from './current-user/current-user.slice'
import { dateGenerationReducer } from './date-generation/date-generation.slice'
import { dialogMessageReducer } from './dialog-message/dialog-message.slice'
import { divisionReducer } from './division/division.slice'
import { formErrorReducer } from './form/form-error.slice'
import { locationReducer } from './location/location.slice'
import { maritalStatusReducer } from './marital-status/marital-status.slice'
import { monthNameReducer } from './month-name/month-name.slice'
import { religionReducer } from './religion/religion.slice'
import { roleReducer } from './role/role.slice'
import { serverStatusReducer } from './server-status/server-status.slice'
import { sideBarToggleReducer } from './side-bar-toggle/side-bar-toggle.reducer'
import { sourceReducer } from './source/source.slice'
import { stationReferenceReducer } from './station-reference/station-reference-slice'
import { subDivisionReducer } from './sub-division/sub-division.slice'
import { ticketStatusReducer } from './ticket-status/ticket-status.slice'
import { ticketReducer } from './ticket/ticket.slice'
import { userBranchReducer } from './user-branch/user-branch.slice'
import { userMenuReducer } from './user-menu/user-menu.slice'
import { userRoleReducer } from './user-role/user-role.slice'

const reducers = {
    sideBarToggle: sideBarToggleReducer,
    dialogMessage: dialogMessageReducer,
    dateGeneration: dateGenerationReducer,
    currentUser: currentUserReducer,
    currentBranch: currentBranchReducer,
    branch: branchReducer,
    branchStation: branchStationReducer,
    maritalStatus: maritalStatusReducer,
    serverStatus: serverStatusReducer,
    religion: religionReducer,
    subDivision: subDivisionReducer,
    location: locationReducer,
    division: divisionReducer,
    source: sourceReducer,
    stationReference: stationReferenceReducer,
    formError: formErrorReducer,
    userMenu: userMenuReducer,
    role: roleReducer,
    userRole: userRoleReducer,
    userBranch: userBranchReducer,
    // claim: claimReducer,
    // roleClaim: roleClaimReducer,
    claimType: claimTypeReducer,
    monthName: monthNameReducer,
    ticket: ticketReducer,
    ticketStatus: ticketStatusReducer,
}

export { reducers as commonReducers }
