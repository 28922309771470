import { Spin } from 'antd'
import React, { useContext, useEffect } from 'react'
import { FiPlus } from 'react-icons/fi'
import RunnerForm from '../../../components/budgeting/runner/runner-form.component'
import RunnerTable from '../../../components/budgeting/runner/runner-table.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useRunner } from '../../../hooks/budgeting/runner.hook'
import { emptyRunner } from '../../../models/budgeting/runner.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import { useTranslation } from 'react-i18next'
import useRoleAction from '../../../constants/role-action-hook'

const RunnerPage: React.FC = () => {
    const { t } = useTranslation()
    const { ROLE_ACTIONS } = useRoleAction()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)

    const { isLoading, initialFetch, setRunner, reloadRunners } = useRunner()

    const handleShow = () => {
        setTitle(t('budgeting:runner.form.creatTitle'))
        setRunner(emptyRunner)
        setContent(<RunnerForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    useEffect(() => {
        reloadRunners()
    }, [reloadRunners])

    return (
        <>
            <PageBreadCrumbs
                items={[
                    t('budgeting:runner.breadcrumb.runner'),
                    t('budgeting:runner.breadcrumb.list'),
                ]}
            />
            <TitleBar
                title={t('budgeting:runner.mainPage.title')}
                subTitle={t('budgeting:runner.mainPage.subTitle')}
                showButton={ROLE_ACTIONS.RUNNER.CREATE}
                handleShow={handleShow}
                buttonLabel={t('budgeting:runner.mainPage.buttonLabel')}
                icon={<FiPlus />}
            />
            {isLoading && initialFetch ? <Spin size="large" /> : <RunnerTable />}
        </>
    )
}

export default RunnerPage
