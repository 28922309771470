import { LogoutOutlined } from '@ant-design/icons'
import { Alert, Button, Divider, Menu, MenuProps, message, Space } from 'antd'
import React, { useContext, useState } from 'react'
import { FiCheck } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useAuthentication } from '../../../hooks/auth.hook'
import { useBranch } from '../../../hooks/common/branch.hook'
import { useCurrentBranch } from '../../../hooks/common/current-branch/current-branch.hook'
import { useActiveTab } from '../../../hooks/shared/active-tab/active-tab.hook'
import { IBranch } from '../../../models/common/branch.model'
import { emptyCurrentBranch } from '../../../models/common/current-branch.model'
import { fetchInitialDataBranchAsync } from '../../../redux/shared/batch/batch.action'
import './app-header-menus.style.scss'

const AppHeaderProfileMenus: React.FC = () => {
    const { signOut, auth } = useAuthentication()
    const [state, setState] = useState('start')
    const { setActiveTab } = useActiveTab()
    const { setBranch, branches } = useBranch()
    const { setCurrentBranch, currentBranch } = useCurrentBranch()
    const { setShow, setTitle, setContent, setWidth } =
        useContext<any>(AppModalContext)
    const history = useHistory()
    const dispatch = useDispatch()

    const userSites: any = []
    branches.forEach((branch) => {
        userSites.push({
            label: (
                <span>
                    {branch.branchName}{' '}
                    {currentBranch.code === branch.code ? (
                        <FiCheck style={{ marginLeft: '4px' }} />
                    ) : (
                        ''
                    )}
                </span>
            ),
            key: `switch-${branch.code}`,
        })
    })

    const handleClick = (e: any) => {
        if (e.key.startsWith('switch')) {
            const siteCode = e.key.split('-').at(1)
            const siteToSwitch = branches.find(
                (branch) => branch.code === siteCode
            ) as IBranch
            setTitle('Switch sites')
            setWidth('40rem')
            setContent(
                <Alert
                    message={`Are you sure you want to switch sites to ${siteToSwitch?.branchName}?`}
                    type="warning"
                    description={
                        <Space>
                            <Button onClick={() => setShow(false)}>Cancel</Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    history.push('/dashboard')
                                    switchSite(siteToSwitch)
                                }}
                            >
                                Yes, continue
                            </Button>
                        </Space>
                    }
                />
            )
            setShow(true)
        } else {
            setState(e.key)
        }
    }

    const switchSite = (branch: IBranch) => {
        dispatch(fetchInitialDataBranchAsync(branch.code))
        setBranch(branch)
        setCurrentBranch({
            ...emptyCurrentBranch,
            ...branch,
        })
        setShow(false)
        message.success('You have successfully switched to ' + branch.branchName)
    }

    const handleRoute = (tabName: string) => {
        setActiveTab(tabName)
        history.push(`/profile/tab?=${tabName}`)
    }

    const items: MenuProps['items'] = [
        {
            label: <strong>{auth.user && auth.user.profile.user_fullname}</strong>,
            key: 'profile_name',
        },
        {
            label: (
                <>
                    <p
                        style={{
                            letterSpacing: 1.875,
                            marginBottom: 0,
                            color: '#2D3239',
                            fontSize: '.75rem',
                        }}
                    >
                        {'My Profile'.toUpperCase()}
                    </p>
                    <Divider style={{ margin: 2 }} />
                </>
            ),
            key: 'profile_path',
            type: 'group',
            children: [
                {
                    label: (
                        <Link
                            to="/profile/tab?=display"
                            onClick={() => handleRoute('display')}
                        >
                            Display
                        </Link>
                    ),
                    key: 'display',
                },
                {
                    label: (
                        <Link
                            to="/profile/tab?=auth"
                            onClick={() => handleRoute('auth')}
                        >
                            Login & Authentication
                        </Link>
                    ),
                    key: 'login-and-authentication',
                },
                {
                    label: (
                        <Link
                            to="/profile/tab?=settings"
                            onClick={() => handleRoute('settings')}
                        >
                            My Settings
                        </Link>
                    ),
                    key: 'my-settings',
                },
                {
                    label: (
                        <>
                            <Link to="/welcome" onClick={() => signOut()}>
                                Logout
                            </Link>
                        </>
                    ),
                    key: 'logout',
                    icon: <LogoutOutlined />,
                },
            ],
        },
        {
            label: (
                <>
                    <p
                        style={{
                            letterSpacing: 1.875,
                            marginBottom: 0,
                            marginTop: 10,
                            color: '#2D3239',
                            fontSize: '.75rem',
                        }}
                    >
                        {'Account'.toUpperCase()}
                    </p>
                    <Divider style={{ margin: 2 }} />
                </>
            ),
            key: 'account',
            type: 'group',
            children: [
                {
                    label: (
                        <Link
                            to="/profile/tab?=account-settings"
                            onClick={() => signOut()}
                        >
                            Account Settings
                        </Link>
                    ),
                    key: 'account-setting',
                },
                {
                    label: (
                        <Link to="/profile/tab?=account-settings">Switch site</Link>
                    ),
                    children: userSites.sort((a: any, b: any) =>
                        a.label - b.label ? 1 : -1
                    ),
                    key: 'switch sites',
                },
            ],
        },
    ]

    return (
        <>
            <Menu
                className="app-header-menus"
                onClick={handleClick}
                selectedKeys={[state]}
                items={items}
                style={{ width: 250 }}
            />
        </>
    )
}

export default AppHeaderProfileMenus
