export const enRunner = {
    breadcrumb: {
        runner: 'Runners',
        list: 'List',
    },
    mainPage: {
        title: 'Runners',
        subTitle: 'View and manage runner',
        buttonLabel: 'Create Runner',
    },

    detailPage: {
        title: 'Runner detail',
        subTitle: 'Make changes to runner',
        buttonLabel: 'Edit Runner',
    },

    form: {
        code: 'code',
        creatTitle: 'Create new runner',
        editTitle: 'Edit runner',
        description: 'Description',
        startDate: 'Start Date',
        endDate: 'End Date',
        submit: 'Submit',
        reset: 'Reset',
    },
}
