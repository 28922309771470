export interface IRunnerComponentBase {
    tenant: string
    runner: string
    project: string
    component: string
    projectDescription?: string
    componentDescription?: string
    componentLongDescription?: string
    createdOn?: Date
}

export const emptyRunnerComponentBase: IRunnerComponentBase = {
    tenant: '',
    runner: '',
    project: '',
    component: '',
    createdOn: new Date(),
}
