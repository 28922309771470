import React, { useState } from 'react'

export type UserContextType = {
    showModal: boolean
    setShowModal: (value: boolean) => void
    editFormTarget: string
    setEditFormTarget: (value: string) => void
}

const initialUserContext: UserContextType = {
    showModal: false,
    setShowModal: () => null,
    editFormTarget: '',
    setEditFormTarget: () => null,
}

export const UsersContext = React.createContext(initialUserContext)

const UsersContextProvider: React.FunctionComponent = ({ children }) => {
    const [showModal, setShowModal] = useState(false)
    const [editFormTarget, setEditFormTarget] = useState('name')

    return (
        <UsersContext.Provider
            value={{
                showModal,
                setShowModal,
                editFormTarget,
                setEditFormTarget,
            }}
        >
            {children}
        </UsersContext.Provider>
    )
}

export default UsersContextProvider
