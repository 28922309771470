import { apiRequests } from '..'
import {
    ISurgeActivity,
    ISurgeActivityResponse,
} from '../../models/budgeting/surge-activity.model'

const surgeActivityService = {
    list: (): Promise<ISurgeActivity[]> =>
        apiRequests.get('/surgeActivities'),
    details: (code: string): Promise<ISurgeActivity> =>
        apiRequests.get(`/surgeActivities/${code}`),
    create: (surgeActivity: ISurgeActivity): Promise<ISurgeActivityResponse> =>
        apiRequests.post('/surgeActivities', surgeActivity),
    update: (surgeActivity: ISurgeActivity): Promise<ISurgeActivityResponse> =>
        apiRequests.put('/surgeActivities', surgeActivity),
    delete: (surgeActivity: ISurgeActivity): Promise<ISurgeActivityResponse> =>
        apiRequests.del('/surgeActivities', surgeActivity),
}

export default surgeActivityService
