import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ICopYearOutlay {
    tenant: string
    copYear: string
    project: string
    outlay: string
}

export const emptyCopYearOutlay: ICopYearOutlay = {
    tenant: '',
    copYear: '',
    project: '',
    outlay: '',
}

export interface ICopYearOutlayState extends IBaseState {
    readonly copYearOutlays: ICopYearOutlay[]
    readonly copYearOutlay: ICopYearOutlay
}

export interface ICopYearOutlayResponse extends IResponseBase {
    data: ICopYearOutlay
}
