import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as userCoordinationActions from '../../redux/budgeting/user-coordination.slice'
import { IUserCoordination } from '../../models/budgeting/user-coordination.model'
import { UpdateMode } from '../../models/update-mode.enum'
import userCoordinationService from '../../services/budgeting/user-coordination.service'

const useUserCoordination = () => {
    const userCoordinations = useSelector<rootState, IUserCoordination[]>(
        (state) => state.userCoordination.userCoordinations
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.userCoordination.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.userCoordination.initialFetch
    )
    const userCoordination = useSelector<rootState, IUserCoordination>(
        (state) => state.userCoordination.userCoordination
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.userCoordination.updateMode
    )

    const dispatch = useDispatch()

    const loadUserCoordinations = useCallback(() => {
        if (initialFetch) {
            dispatch(userCoordinationActions.fetchUserCoordinationAsync())
        }
    }, [dispatch, initialFetch])

    const addUserCoordination = async (userCoordination: IUserCoordination) => {
        return await userCoordinationService
            .create(userCoordination)
            .then((userCoordinationResponse) => {
                // dispatch(
                //     userCoordinationActions.addUserCoordinationSuccess(
                //         userCoordinationResponse.data
                //     )
                // )
                dispatch(
                    userCoordinationActions.fetchuserCoordinationSuccess([
                        ...userCoordinations,
                        ...userCoordinationResponse.userCoordinations,
                    ])
                )
                return userCoordinationResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setUserCoordination = (userCoordination: IUserCoordination) => {
        dispatch(userCoordinationActions.setActiveUserCoordination(userCoordination))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(userCoordinationActions.setUserCoordinationUpdateMode(updateMode))
    }

    const editUserCoordination = async (userCoordination: IUserCoordination) => {
        return await userCoordinationService
            .update(userCoordination)
            .then((userCoordinationResponse) => {
                dispatch(
                    userCoordinationActions.editUserCoordinationSuccess(
                        userCoordinationResponse.data
                    )
                )
                setUserCoordination(userCoordinationResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveUserCoordination = async (
        userCoordination: IUserCoordination,
        updateMode: UpdateMode
    ) => {
        return updateMode === UpdateMode.ADD
            ? await addUserCoordination(userCoordination)
            : await editUserCoordination(userCoordination)
    }

    useEffect(() => {
        loadUserCoordinations()
    }, [
        userCoordination,
        userCoordinations,
        isLoading,
        initialFetch,
        loadUserCoordinations,
    ])

    return {
        userCoordination,
        userCoordinations,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addUserCoordination,
        editUserCoordination,
        saveUserCoordination,
        setUserCoordination,
    }
}

export { useUserCoordination }
