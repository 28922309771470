import { apiRequests } from '../'
import {
    IIncludeRunner,
    IIncludeRunnerResponse,
} from '../../models/budgeting/include-runner.model'

const includeRunnerService = {
    list: (): Promise<IIncludeRunner[]> => apiRequests.get('/includeRunners'),
    details: (code: string): Promise<IIncludeRunner> =>
        apiRequests.get(`/includeRunners/${code}`),
    create: (includerunner: IIncludeRunner): Promise<IIncludeRunnerResponse> =>
        apiRequests.post('/includeRunners', includerunner),
    update: (includerunner: IIncludeRunner): Promise<IIncludeRunnerResponse> =>
        apiRequests.put('/includeRunners', includerunner),
}

export default includeRunnerService
