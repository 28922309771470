import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as ticketStatusModel from '../../../models/common/ticket-status.model'
import { UpdateMode } from '../../../models/update-mode.enum'
import ticketStatusService from '../../../services/common/ticket-status/ticket-status.service'

export const initialState: ticketStatusModel.ITicketStatusState = {
    ticketStatuses: [],
    errors: '',
    ticketStatus: ticketStatusModel.emptyTicketStatus,
    isLoading: false,
    isSearching: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchTicketStatusesAsync = createAsyncThunk<ticketStatusModel.ITicketStatus[], void>(
    'ticketStatus/fetchTicketStatusesAsync',
    async (_, thunkApi) => {
        try {
            return await ticketStatusService.list()
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.data })
        }
    }
)

export const ticketStatusSlice = createSlice({
    name: 'ticketStatus',
    initialState,
    reducers: {
        fetchTicketStatusesRequest: (state) => {
            state.isLoading = true
        },
        fetchTicketStatusesSuccess: (
            state,
            action: PayloadAction<ticketStatusModel.ITicketStatus[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.ticketStatuses = action.payload
        },
        fetchTicketStatusesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editTicketStatusSuccess: (
            state,
            action: PayloadAction<ticketStatusModel.ITicketStatus>
        ) => {
            state.ticketStatuses = state.ticketStatuses.map((ticketStatus) => {
                return ticketStatus.tenant === action.payload.tenant &&
                        ticketStatus.code === action.payload.code
                    ? action.payload
                    : ticketStatus
            })
            state.updateMode = UpdateMode.NONE
        },
        addTicketStatusSuccess: (
            state,
            action: PayloadAction<ticketStatusModel.ITicketStatus>
        ) => {
            state.ticketStatuses = [...state.ticketStatuses, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveTicketStatus: (
            state,
            action: PayloadAction<ticketStatusModel.ITicketStatus>
        ) => {
            state.ticketStatus = action.payload
        },
        setTicketStatusUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTicketStatusesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchTicketStatusesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.ticketStatuses = action.payload
        })
        builder.addCase(fetchTicketStatusesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchTicketStatusesRequest,
    fetchTicketStatusesSuccess,
    fetchTicketStatusesError,
    editTicketStatusSuccess,
    addTicketStatusSuccess,
    setActiveTicketStatus,
    setTicketStatusUpdateMode,
} = ticketStatusSlice.actions

const reducer = ticketStatusSlice.reducer

export { reducer as ticketStatusReducer }
