import { ArrowRightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useContext } from 'react'
import { FiPlus } from 'react-icons/fi'
import { UsersContext } from './users-context.provider'
import { Header, Title, Paragraph, StyledLink } from './users-styles.component'

const UserHeader: React.FunctionComponent = () => {
    const { setShowModal } = useContext<any>(UsersContext)

    return (
        <>
            <Header>
                <Title>Users</Title>
                <Button
                    className="d-flex align-items-center"
                    icon={<FiPlus />}
                    onClick={() => setShowModal(true)}
                >
                    Create user
                </Button>
            </Header>
            <Paragraph>Users page description</Paragraph>
            <StyledLink href="#">
                learn more <ArrowRightOutlined />
            </StyledLink>
        </>
    )
}

export default UserHeader
