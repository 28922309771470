import React, { useContext } from 'react'
import { FiEdit } from 'react-icons/fi'
import BackButton from '../../../components/common/back-button/back-button.component'
import LocationDetailComponent from '../../../components/common/location/location-detail.component'
import LocationForm from '../../../components/common/location/location-form.component'
import TitleBar from '../../../components/common/title-bar/title-bar.component'
import PageBreadCrumbs from '../../../components/shared/page-breadcrumb/page-breadcrumb.component'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import { useLocation } from '../../../hooks/common/location/location.hook'
import { UpdateMode } from '../../../models/update-mode.enum'

const LocationDetailsPage: React.FC = () => {
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const { setUpdateMode } = useLocation()

    const handleShow = () => {
        setTitle('Edit Location')
        setUpdateMode(UpdateMode.EDIT)
        setContent(<LocationForm />)
        setWidth('40rem')
        setShow(true)
    }

    return (
        <>
            <PageBreadCrumbs items={['Configurations', 'Locations', 'Details']} />
            <TitleBar
                title="Location"
                subTitle="View and manage locations"
                showButton
                handleShow={handleShow}
                buttonLabel="Edit Location"
                icon={<FiEdit />}
            />

            <BackButton page="Location" />

            {/* details */}
            <LocationDetailComponent />
        </>
    )
}

export default LocationDetailsPage
