import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface ISiteType {
    code: string
    description: string
    createdOn: Date
}

export const emptySiteType: ISiteType = {
    code: '',
    description: '',
    createdOn: new Date(),
}

export interface ISiteTypeState extends IBaseState {
    readonly siteTypes: ISiteType[]
    readonly siteType: ISiteType
}

export interface ISiteTypeResponse extends IResponseBase {
    data: ISiteType
}
