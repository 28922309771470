import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as copYearSiteModel from '../../models/budgeting/cop-year-site.model'
import copYearSiteservice from '../../services/budgeting/cop-year-site.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: copYearSiteModel.ICopYearSiteState = {
    copYearSites: [],
    errors: '',
    copYearSite: copYearSiteModel.emptyCopYearSite,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchcopYearSitesAsync = createAsyncThunk<
    copYearSiteModel.ICopYearSite[],
    void
>('copYearSite/fetchcopYearSitesAsync', async (_, thunkApi) => {
    try {
        return await copYearSiteservice.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const copYearSiteslice = createSlice({
    name: 'copYearSite',
    initialState,
    reducers: {
        fetchcopYearSitesRequest: (state) => {
            state.isLoading = true
        },
        fetchcopYearSitesSuccess: (
            state,
            action: PayloadAction<copYearSiteModel.ICopYearSite[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.copYearSites = action.payload
        },
        fetchcopYearSitesError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editCopYearSitesuccess: (
            state,
            action: PayloadAction<copYearSiteModel.ICopYearSite>
        ) => {
            state.copYearSites = state.copYearSites.map((copYearSite) => {
                return copYearSite.project === action.payload.project
                    ? action.payload
                    : copYearSite
            })
            state.updateMode = UpdateMode.NONE
        },
        addCopYearSitesuccess: (
            state,
            action: PayloadAction<copYearSiteModel.ICopYearSite>
        ) => {
            state.copYearSites = [...state.copYearSites, action.payload]
            state.updateMode = UpdateMode.NONE
        },
        setActiveCopYearSite: (
            state,
            action: PayloadAction<copYearSiteModel.ICopYearSite>
        ) => {
            state.copYearSite = action.payload
        },
        setCopYearSiteUpdateMode: (state, action: PayloadAction<UpdateMode>) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchcopYearSitesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchcopYearSitesAsync.fulfilled, (state, action) => {
            state.isLoading = false
            state.initialFetch = false
            state.copYearSites = action.payload
        })
        builder.addCase(fetchcopYearSitesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchcopYearSitesRequest,
    fetchcopYearSitesSuccess,
    fetchcopYearSitesError,
    editCopYearSitesuccess,
    addCopYearSitesuccess,
    setActiveCopYearSite,
    setCopYearSiteUpdateMode,
} = copYearSiteslice.actions

const reducer = copYearSiteslice.reducer

export { reducer as copYearSiteReducer }
