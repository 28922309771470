import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as componentActions from '../../redux/budgeting/component.slice'
import { IComponent } from '../../models/budgeting/component.model'
import { UpdateMode } from '../../models/update-mode.enum'
import componentService from '../../services/budgeting/component.service'

const useComponent = () => {
    const components = useSelector<rootState, IComponent[]>(
        (state) => state.component.components
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.component.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.component.initialFetch
    )
    const component = useSelector<rootState, IComponent>(
        (state) => state.component.component
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.component.updateMode
    )

    const dispatch = useDispatch()

    const loadComponents = useCallback(() => {
        if (initialFetch) {
            dispatch(componentActions.fetchComponentsAsync())
        }
    }, [dispatch, initialFetch])

    const addComponent = async (component: IComponent) => {
        return await componentService
            .create(component)
            .then((componentResponse) => {
                if (componentResponse.success) {
                    dispatch(
                        componentActions.addComponentSuccess(componentResponse.data)
                    )
                } else {
                    return componentResponse
                }
                return componentResponse.success
            })
            .catch((error) => {
                return error
            })
    }

    const setComponent = (component: IComponent) => {
        dispatch(componentActions.setActiveComponent(component))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(componentActions.setComponentUpdateMode(updateMode))
    }

    const editComponent = async (component: IComponent) => {
        return await componentService
            .update(component)
            .then((componentResponse) => {
                dispatch(
                    componentActions.editComponentSuccess(componentResponse.data)
                )
                setComponent(componentResponse.data)
                return true
            })
            .catch((error) => {
                return false
            })
    }

    const saveComponent = async (component: IComponent, updateMode: UpdateMode) => {
        return updateMode === UpdateMode.ADD
            ? await addComponent(component)
            : await editComponent(component)
    }

    useEffect(() => {
        loadComponents()
    }, [component, components, isLoading, initialFetch, loadComponents])

    return {
        component,
        components,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        addComponent,
        editComponent,
        saveComponent,
        setComponent,
    }
}

export { useComponent }
