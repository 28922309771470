import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as runnerPeriodStatusModel from '../../models/budgeting/runner-period-status.model'
import runnerPeriodStatusService from '../../services/budgeting/runner-period-status.service'
import { UpdateMode } from '../../models/update-mode.enum'

export const initialState: runnerPeriodStatusModel.IRunnerPeriodStatusState = {
    runnerPeriodStatuses: [],
    errors: '',
    runnerPeriodStatus: runnerPeriodStatusModel.emptyRunnerPeriodStatus,
    isLoading: false,
    initialFetch: true,
    updateMode: UpdateMode.NONE,
}

export const fetchRunnerPeriodStatusesAsync = createAsyncThunk<
    runnerPeriodStatusModel.IRunnerPeriodStatus[],
    void
>('runnerPeriodStatus/fetchrunnerPeriodStatusesAsync', async (_, thunkApi) => {
    try {
        return await runnerPeriodStatusService.list()
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.data })
    }
})

export const runnerPeriodStatusSlice = createSlice({
    name: 'runnerPeriodStatus',
    initialState,
    reducers: {
        fetchRunnerPeriodStatussRequest: (state) => {
            state.isLoading = true
        },
        fetchRunnerPeriodStatussSuccess: (
            state,
            action: PayloadAction<runnerPeriodStatusModel.IRunnerPeriodStatus[]>
        ) => {
            state.isLoading = false
            state.initialFetch = false
            state.runnerPeriodStatuses = action.payload
        },
        fetchRunnerPeriodStatussError: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.errors = action.payload
        },
        editRunnerPeriodStatusSuccess: (
            state,
            action: PayloadAction<runnerPeriodStatusModel.IRunnerPeriodStatus>
        ) => {
            state.runnerPeriodStatuses = state.runnerPeriodStatuses.map(
                (runnerPeriodStatus) => {
                    return runnerPeriodStatus.code === action.payload.code
                        ? action.payload
                        : runnerPeriodStatus
                }
            )
            state.updateMode = UpdateMode.NONE
        },
        addRunnerPeriodStatusSuccess: (
            state,
            action: PayloadAction<runnerPeriodStatusModel.IRunnerPeriodStatus>
        ) => {
            state.runnerPeriodStatuses = [
                ...state.runnerPeriodStatuses,
                action.payload,
            ]
            state.updateMode = UpdateMode.NONE
        },
        setActiveRunnerPeriodStatus: (
            state,
            action: PayloadAction<runnerPeriodStatusModel.IRunnerPeriodStatus>
        ) => {
            state.runnerPeriodStatus = action.payload
        },
        setRunnerPeriodStatusUpdateMode: (
            state,
            action: PayloadAction<UpdateMode>
        ) => {
            state.updateMode = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRunnerPeriodStatusesAsync.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(
            fetchRunnerPeriodStatusesAsync.fulfilled,
            (state, action) => {
                state.isLoading = false
                state.initialFetch = false
                state.runnerPeriodStatuses = action.payload
            }
        )
        builder.addCase(fetchRunnerPeriodStatusesAsync.rejected, (state, action) => {
            state.isLoading = false
            state.errors = action.payload
        })
    },
})

export const {
    fetchRunnerPeriodStatussRequest,
    fetchRunnerPeriodStatussSuccess,
    fetchRunnerPeriodStatussError,
    editRunnerPeriodStatusSuccess,
    addRunnerPeriodStatusSuccess,
    setActiveRunnerPeriodStatus,
    setRunnerPeriodStatusUpdateMode,
} = runnerPeriodStatusSlice.actions

const reducer = runnerPeriodStatusSlice.reducer

export { reducer as runnerPeriodStatusReducer }
