import { ColumnsType } from 'antd/lib/table'
import { ACTIVITY_TYPE_RECORD } from '../../../constants/configurations.constant'
import { IActivitySummary } from '../../../models/budgeting/activity-summary.model'
import { format } from '../../../utils/format'

export const activityHistoryTableColumns: ColumnsType<IActivitySummary> = [
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
        width: '6rem',
        filtered: true,
    },
    {
        title: 'User Name',
        dataIndex: 'usrName',
        key: 'usrName',
        width: '15rem',
    },
    {
        title: 'Activity Type',
        width: '10rem',
        dataIndex: 'activityType',
        key: 'activityType',
        render: (_, record) => ACTIVITY_TYPE_RECORD[record.activityType]
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        ellipsis: true,
    },
    {
        title: 'Number',
        dataIndex: 'number',
        key: 'number',
        align: 'right',
        width: '6rem',
        render: (_, record) => format.number(record.quantity)
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: '10rem',
        align: 'right',
        render: (_, record) => format.number(record.amount),
    },
]

