import { appRoutes } from '../../../constants/app-route'
import { IRoute } from '../../../models/common/route.model'
import LaboratoryActivityBatchPage from '../../../pages/budgeting/laboratory-activity/laboratory-activity-batch.page'
import LaboratoryActivityBatchDetailsPage from '../../../pages/budgeting/laboratory-activity/laboratory-activity-summary-detail.page'
import LaboratoryActivityPage from '../../../pages/budgeting/laboratory-activity/laboratory-activity.page'
import ProgramManagementBOLaboratoryActivityPage from '../../../pages/budgeting/program-management-laboratory-activity/program-management-laboratory-activity-bo.page.'
import ProgramManagementDHSLaboratoryActivityPage from '../../../pages/budgeting/program-management-laboratory-activity/program-management-laboratory-activity-dhs.page'
import ProgramManagementMVANLaboratoryActivityPage from '../../../pages/budgeting/program-management-laboratory-activity/program-management-laboratory-activity-mvan.page'

export const laboratoryActivityRoutes: IRoute[] = [
    /**
     * Auth routes
     */
    {
        path: appRoutes.LABORATORY_ACTIVITY.LABORATORY_ACTIVITY_BATCH_SUMMARY,
        private: true,
        exact: true,
        component: LaboratoryActivityPage,
    },
    {
        path: appRoutes.LABORATORY_ACTIVITY.LABORATORY_ACTIVITY_BATCH,
        private: true,
        exact: true,
        component: LaboratoryActivityBatchPage,
    },
    /**
     * Activity Batch
     */
    {
        path: appRoutes.LABORATORY_ACTIVITY.LABORATORY_ACTIVITY_BATCH_DETAILS,
        private: true,
        exact: true,
        component: LaboratoryActivityBatchDetailsPage,
    },
    {
        path: appRoutes.LABORATORY_ACTIVITY.LABORATORY_PROGRAM_MANAGEMENT_BO,
        private: true,
        exact: true,
        component: ProgramManagementBOLaboratoryActivityPage,
    },
    {
        path: appRoutes.LABORATORY_ACTIVITY.LABORATORY_PROGRAM_MANAGEMENT_DHS,
        private: true,
        exact: true,
        component: ProgramManagementDHSLaboratoryActivityPage,
    },
    {
        path: appRoutes.LABORATORY_ACTIVITY.LABORATORY_PROGRAM_MANAGEMENT_MVAN,
        private: true,
        exact: true,
        component: ProgramManagementMVANLaboratoryActivityPage,
    },
]
