export enum LanguageActionTypes {
    CHANGE = 'CHANGE_LANGUAGE',
    TOGGLE = 'TOGGLE_LANGUAGE',
}

interface ToggleLanguage {
    type: typeof LanguageActionTypes.TOGGLE
}

interface ChangeLanguage {
    type: typeof LanguageActionTypes.CHANGE
    payload: string
}

export interface ILanguage {
    key: string
    name: string
}

export type LanguageActionCreators = ToggleLanguage | ChangeLanguage
