import { rootState } from '../../redux/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import * as dailyDataActions from '../../redux/budgeting/daily-data.slice'
import { IDailyData } from '../../models/budgeting/daily-data.model'
import { UpdateMode } from '../../models/update-mode.enum'
import dailyDataService from '../../services/budgeting/daily-data.service'

const useDailyData = () => {
    const dailyDatas = useSelector<rootState, IDailyData[]>(
        (state) => state.dailyData.dailyDatas
    )
    const isLoading = useSelector<rootState, boolean>(
        (state) => state.dailyData.isLoading
    )
    const initialFetch = useSelector<rootState, boolean>(
        (state) => state.dailyData.initialFetch
    )
    const dailyData = useSelector<rootState, IDailyData>(
        (state) => state.dailyData.dailyData
    )
    const updateMode = useSelector<rootState, UpdateMode>(
        (state) => state.dailyData.updateMode
    )

    const dispatch = useDispatch()

    const setDailyData = (dailyData: IDailyData) => {
        dispatch(dailyDataActions.setActiveDailyData(dailyData))
    }

    const setUpdateMode = (updateMode: UpdateMode) => {
        dispatch(dailyDataActions.setDailyDataUpdateMode(updateMode))
    }

    const getDailyData = async ({
        currentUser,
        branch,
    }: {
        currentUser: string
        branch: string
    }) => {
        return await dailyDataService
            .post({
                currentUser,
                branch,
            })
            .then((response) => {
                dispatch(dailyDataActions.fetchDailyDataSuccess(response))
                return response
            })
            .catch((error) => {
                dispatch(dailyDataActions.fetchDailyDataError(error))
                return error
            })
    }

    return {
        dailyData,
        dailyDatas,
        isLoading,
        initialFetch,
        updateMode,
        setUpdateMode,
        setDailyData,
        getDailyData
    }
}

export { useDailyData }
