import { Button, Card, Col, Form, Row, Select, Space, Input } from 'antd'
import React, { useCallback, useState } from 'react'
import { useModificationsContext } from '../../contexts/modifications/modifications.context'
import { useProject } from '../../hooks/budgeting/project.hook'
import { useCurrentBranch } from '../../hooks/common/current-branch/current-branch.hook'
import activityHistoryService from '../../services/budgeting/activity-history.service'
import { useTranslation } from 'react-i18next'
import { useConfiguration } from '../../hooks/configuration.hook'
import { emptyConfiguration } from '../../models/configuration.model'
import { useTransactionCode } from '../../hooks/budgeting/transaction-code.hook'
import { useRunnerHistory } from '../../hooks/budgeting/runner-history.hook'
import { IRunnerHistory } from '../../models/budgeting/runner-history.model'
import { useMonthName } from '../../hooks/common/month-name/month-name.hook'

type Props = {
    modificationType: string
}

const ModificationsFilterForm: React.FC<Props> = ({ modificationType }) => {
    const [form] = Form.useForm()
    const { runnerHistories } = useRunnerHistory()
    const { setModificationsData } = useModificationsContext()
    const { setConfiguration } = useConfiguration()
    const { projects } = useProject()
    const { currentBranch } = useCurrentBranch()
    const { Option } = Select
    const { transactionCodes } = useTransactionCode()
    const { t } = useTranslation()
    const [runnerHistoriesObj, setRunnerHistoriesData] = useState<IRunnerHistory[]>(
        []
    )
    const [isSubmitting, setSubmitting] = useState(false)
    const { monthNames } = useMonthName()

    const reportTypeOptions = [
        { value: '01', label: 'Site' },
        { value: '02', label: 'Above Site' },
        { value: '03', label: 'Combined' },
    ]

    const projectName =
        projects && projects.length > 0
            ? projects.find((p) => p.code === currentBranch.project)?.description
            : 'No project'
    const onFinish = async (values: any) => {
        setSubmitting(true)
        const formValues = {
            ...values,
            tenant: currentBranch.tenant,
            modificationType,
            activityType: '01',
            project: currentBranch.project,
        }

        setConfiguration({
            ...emptyConfiguration,
            project: currentBranch.project,
            activityType: '01',
        })
        const data = await activityHistoryService.summary(formValues)
        setSubmitting(false)
        setModificationsData(data)
    }

    const handleMonthNameChange = useCallback((value: string) => {
        const filteredRunnerHistories: IRunnerHistory[] = runnerHistories.filter(
            (runner) => runner.region === currentBranch.region
        )
        const obj = filteredRunnerHistories.filter((filterRunnerHistory) => {
            const found = filterRunnerHistory.code.slice(2, 4).includes(value)
            if (!found) {
                form.setFieldsValue({ runner: undefined })
                return
            }
            return filterRunnerHistory
        })
        setRunnerHistoriesData([...obj])
    }, [runnerHistories, currentBranch.region])


    return (
        <>
            <Card size="small" style={{ marginBottom: '1rem' }}>
                <Form
                    name="modifcations-filter-form"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={[16, 16]}>
                        <Col md={6}>
                            <Form.Item
                                name="month"
                                required
                                label={'Month'}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Month is required',
                                    },
                                ]}
                            >
                                <Select onChange={handleMonthNameChange}>
                                    {monthNames.map((month, index) => (
                                        <Select.Option
                                            key={index}
                                            value={month.code}
                                        >
                                            {month.description}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={6}>
                            <Form.Item
                                name="runner"
                                required
                                label={t('budgeting:modifications.form.runner')}
                                labelCol={{ span: 24 }}
                            >
                                <Select
                                    // onChange={handleRunnerHistoryChange}
                                >
                                    {runnerHistoriesObj
                                        .filter(
                                            (runner) =>
                                                runner.region ===
                                                currentBranch.region
                                        )
                                        .map((runner, index) => (
                                            <Option key={index} value={runner.code}>
                                                {runner.description}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={6}>
                            <Form.Item
                                name="transactionCode"
                                required
                                label={'Transaction code'}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Transaction code is required',
                                    },
                                ]}
                            >
                                <Select>
                                    {transactionCodes.map((tranCode, index) => (
                                        <Option key={index} value={tranCode.code}>
                                            {tranCode.description}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={6}>
                            <Form.Item
                                name="siteType"
                                required
                                label={'Site type'}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Site type is required',
                                    },
                                ]}
                            >
                                <Select>
                                    {reportTypeOptions.map((reportType, index) => (
                                        <Option key={index} value={reportType.value}>
                                            {reportType.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Space>
                        <Button htmlType="submit" type="primary" disabled={isSubmitting}>
                            {t('budgeting:modifications.form.submit')}
                        </Button>
                        <Button htmlType="reset">
                            {t('budgeting:modifications.form.reset')}
                        </Button>
                    </Space>
                </Form>
            </Card>
        </>
    )
}
export default ModificationsFilterForm
