import { Action, combineReducers } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { commonReducers } from './common'
import { sharedReducers } from './shared'

import sessionStorage from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'
import { budgetingReducers } from './budgeting'
import { configurationReducer } from './configuration.slice'

export const rootReducer = combineReducers({
    ...sharedReducers,
    ...commonReducers,
    ...budgetingReducers,
    configuration: configurationReducer,
})

export const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    whitelist: [
        //shared
        'auth',
        'language',
        'sessionBatch',
        'activeTab',
        'validationError',
        'searchValue',
        'user',
        //activity
        'activity',
        'activityPlan',
        'budgetCode',
        'budgetHistory',
        'budgetCodeHistory',
        'budget',
        'component',
        'copYearBudgetCode',
        'copYearCostCategory',
        'copYearIntervention',
        'copYear',
        'costCategory',
        'district',
        'intervention',
        'milestone',
        'project',
        'quarter',
        'siteType',
        'site',
        'surgeActivity',
        'zone',
        'runner',
        'includeRunner',
        'regionRunnerPeriod',
        'runnerComponentHistory',
        'runnerComponent',
        'runnerHistory',
        'runnerStatus',
        'runnerPeriodComponent',
        'runnerPriodHistory',
        'runnerPeriodStatus',
        'runnerPeriod',
        'transactionCode',
        'userCoordination',
        'region',
        'outlay',
        'outlayDash',
        'milestoneDash',
        'runnerArchive',
        //common
        'sideBarToggle',
        'dialogMessage',
        'dateGeneration',
        'currentUser',
        'currentBranch',
        'branch',
        'branchStation',
        'maritalStatus',
        'serverStatus',
        'religion',
        'subDivision',
        'location',
        'division',
        'source',
        'stationReference',
        'formError',
        'userMenu',
    ],
    blacklist: [],
}

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export type rootState = ReturnType<typeof rootReducer>

export type appThunk = ThunkAction<void, rootState, unknown, Action<string>>
