export const enRole = {
    formikValidation: {
        description: 'Description is required !',
    },

    breadcrumb: {
        role: "Role",
        roleMgt: "Role Mangement",
        roleDetail: "Role Detail"
    },
    form: {
        title: 'Role',
        titleAdd: 'Add Role',
        titleEdit: 'Edit Role',
        description: 'Description',
        savebtn: "Save",
    },
    tabPane: {
        roles: "Roles",
        RolePrv: "Role Privileges",
        userRolMgt: "User Role Assignment",
        roleUserAs: "Role User Assignment"
    },
    RolePrv: {
        title: "Assign roles to selected users",
        subTitle: "Role Name",
        allRol: "All Roles",
        selRol: "Seleted User Role",
        addBtn: "Add",
        removeBtn: "Remove",
        selUser: "Select User",
        allUsers: "All Users",
        selUsers: "Selected Users",
        assGrp: "Assign a role to a group of users"

    },
    claims: {
        breadcrumb: {
            setting: "Setting",
            claim: "Claims"
        },
        title: 'Claims',
        btnLabel: "Add Claim"
    },

    roleMgtForm: {
        title: "Create New Role",
        saveBtn: "Save",
        cancelBtn: "Cancel",
    }


}
