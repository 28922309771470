import { ColumnsType } from 'antd/lib/table'
import { IBudgetExecution } from '../../models/reports/budget-execution.model'
import { format } from '../../utils/format'

export const budgetExecutionTableColumns: ColumnsType<IBudgetExecution> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Budgeted Amount',
        dataIndex: 'budgeted',
        key: 'budgeted',
        align: 'right',
        render: (_, record) => format.number(record.budgeted),
    },
    {
        title: 'Accumulated expenditure',
        dataIndex: 'accumulated',
        key: 'accumulated',
        align: 'right',
        render: (_, record) => format.number(record.accumulated),
    },
    {
        title: 'Actual period',
        dataIndex: 'actual',
        key: 'actual',
        align: 'right',
        render: (_, record) => format.number(record.actual),
    },
    {
        title: 'Variants',
        dataIndex: 'balance',
        key: 'balance',
        align: 'right',
        render: (_, record) => format.number(record.balance),
    },
]
