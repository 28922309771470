import styled, { CSSProperties } from 'styled-components'

const formControl: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

const width100: CSSProperties = {
    width: '100%',
}

const FlexedDiv = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100% !important;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }
`

const FormLabels = styled.p`
    margin-top: 0;
    margin-bottom: 0 !important;
`

const SizedLabel = styled.label`
    width: 100% !important;

    @media screen and (min-width: 768px) {
        width: 50%;
        margin-left: 5px;
    }
`
const SubmitModule = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;

    // @media screen and (max-width: 768px) {
    //     .btn {
    //         flex-direction: column-reverse !important;
    //         width: 100%;
    //         margin-bottom: .5rem;
    //     }
    // }

    @media screen and (min-width: 768px) {
        // flew-direction: row;
        padding: 0 3.5rem;
        justify-content: flex-end;
    }

    @media screen and (min-width: 991px) {
        padding: 0 6rem;
    }
`

export { formControl, width100, FlexedDiv, FormLabels, SizedLabel, SubmitModule }
