import { useCallback } from "react"
import { IRoleClaim } from "../../../models/common/role-claim.model"
import { UpdateMode } from "../../../models/update-mode.enum"
import roleClaimService from "../../../services/common/role-claim/role-claim.service"

const useRoleClaim = () => {
    const reloadRoleClaims = useCallback(async() => {
        try {
            const roleClaims = await roleClaimService.list()
            console.log('role claims')
            if(roleClaims) {
                return roleClaims
            }
            return false
        }catch(err) {
            console.log('role claims err: ', err)
        }
    }, [])

    const saveRoleClaim = useCallback(async (updateMode: UpdateMode, roleClaim: IRoleClaim) => {
        const feedback = await roleClaimService.save(roleClaim)
        console.log('feedback: ', feedback)
        return true
    }, [])

    return {
        reloadRoleClaims,
        saveRoleClaim
    }
}

export { useRoleClaim }
