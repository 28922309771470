import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IUser {
    tenant: string
    code: string
    usrName: string
    email: string
    photoUrl: string
    locale: string
}

export const emptyUser: IUser = {
    tenant: '',
    code: '',
    usrName: '',
    locale: '',
    photoUrl: '',
    email: '',
}

export interface IUserResponse extends IResponseBase {
    data: IUser
}

export interface IUserState extends IBaseState {
    user: IUser
    users: IUser[]
}
