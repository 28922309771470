import React, { useCallback, useEffect, useState } from 'react'
import { signinRedirectCallback } from '../../services/shared/auth/auth.service'
import { useHistory } from 'react-router-dom'
import Loading from '../../components/shared/loading/loading.component'
import { useDispatch } from 'react-redux'
import { fetchInitialDataAsync } from '../../redux/shared/batch/batch.action'

const SigninOidcPage: React.FunctionComponent = () => {
    const history = useHistory()

    const dispatch = useDispatch()

    const [showProgess] = useState(false)

    const signinAsync = useCallback(async () => {
        try {
            await signinRedirectCallback()
            setTimeout(() => {
                dispatch(fetchInitialDataAsync())
                history.push('/')
            }, 1500)
        } catch (err: any) {
            console.log(err)
            history.push('/access-denied')
        }
    }, [dispatch, history])

    useEffect(() => {
        signinAsync()
    }, [history, signinAsync])

    return (
        <>
            <div
                style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'grid',
                    placeItems: 'center',
                }}
            >
                <div>
                    <img
                        src="/cbclogo.gif"
                        alt="cbchs logo"
                        className="logo__backdrop"
                    />
                    {showProgess ? (
                        <Loading message="authentication callback in process" />
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default SigninOidcPage
