import { IBaseState } from '../base-state.model'
import { IResponseBase } from '../response.base'

export interface IRegionRunnerPeriod {
    tenant: string
    runner: string
    region: string
    createdOn: Date
}

export const emptyRegionRunnerPeriod: IRegionRunnerPeriod = {
    tenant: '',
    runner: '',
    region: '',
    createdOn: new Date(),
}

export interface IRegionRunnerPeriodState extends IBaseState {
    readonly regionRunnerPeriods: IRegionRunnerPeriod[]
    readonly regionRunnerPeriod: IRegionRunnerPeriod
}

export interface IRegionRunnerPeriodResponse extends IResponseBase {
    data: IRegionRunnerPeriod
}
