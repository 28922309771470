import { CaretDownOutlined, MailOutlined, StopOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import React from 'react'
import { MenuGroup, StyledMenu, StyledMenuItem } from './users-styles.component'

const UsersDetailsDropdown: React.FunctionComponent = () => {
    const menu = (
        <StyledMenu>
            <MenuGroup>
                <StyledMenuItem key="4" icon={<MailOutlined />}>
                    Send verification email
                </StyledMenuItem>
            </MenuGroup>

            <MenuGroup>
                <StyledMenuItem key="change email">Change email</StyledMenuItem>
                <StyledMenuItem key="change password">
                    Change password
                </StyledMenuItem>
            </MenuGroup>

            <StyledMenuItem key="2" icon={<StopOutlined />} color="red">
                Block
            </StyledMenuItem>
        </StyledMenu>
    )

    return (
        <>
            <Dropdown overlay={menu} trigger={['click']}>
                <Button icon={<CaretDownOutlined />}>Actions</Button>
            </Dropdown>
        </>
    )
}

export default UsersDetailsDropdown
