import { ColumnsType } from 'antd/lib/table'
import { useUser } from '../../../hooks/shared/user/user.hook'
import { IDailyData } from '../../../models/budgeting/daily-data.model'
import { format } from '../../../utils/format'

export const useDailyDataColumns = () => {
    const { users } = useUser()
    const dailyReportTableColumns: ColumnsType<IDailyData> = [
        {
            title: 'Username',
            dataIndex: 'user',
            key: 'user',
            width: '8rem',
            filtered: true,
            render: (_, row) => users.find((u) => u.code === row.user)?.usrName,
        },
        {
            title: 'Site Name',
            dataIndex: 'siteName',
            key: 'siteName',
            width: '8rem',
            filtered: true,
        },
        {
            title: 'Date',
            dataIndex: 'transDate',
            key: 'transDate',
            width: '4rem',
            filtered: true,
            render: (_, row) => format.date(row.transDate),
        },
        {
            title: 'Previous receipts',
            dataIndex: 'previousReceipts',
            key: 'previousReceipts',
            width: '4rem',
            filtered: true,
        },
        {
            title: "Today's receipts",
            dataIndex: 'todayReceipts',
            key: 'todayReceipts',
            width: '4rem',
            filtered: true,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            width: '4rem',
            filtered: true,
            render: (_, row) => row.previousReceipts + row.todayReceipts,
        },
    ]

    return {
        dailyReportTableColumns,
    }
}
