import { useContext, useEffect, useRef } from 'react'
import { Button, Space, Table, Tag } from 'antd'
import { NoContent } from '../../shared/no-content/no-content.component'
import { useHistory } from 'react-router-dom'
import { useActivity } from '../../../hooks/budgeting/activity.hook'
import { emptyActivity, IActivity } from '../../../models/budgeting/activity.model'
import { AppModalContext } from '../../../contexts/common/app-modal.context'
import ActivityForm from './activity-form.component'
import { UpdateMode } from '../../../models/update-mode.enum'
import { IActivitySummary } from '../../../models/budgeting/activity-summary.model'
import { Excel } from 'antd-table-saveas-excel'
import { activityExcelTableColumns } from './activity-excel-column.component'
import { useActivityHistory } from '../../../hooks/budgeting/activity-history.hook'
import { activityHistoryTableColumns } from '../activity-history-summary/activity-history-table.columns'
import { format } from '../../../utils/format'
interface IProps {
    showButton?: boolean
    activities: Array<IActivity>
}

const ActivityHistoryTable: React.FC<IProps> = ({
    activities,
    showButton = false,
}) => {
    const { setActivity, setActivitySummary, getMapedExcelActivities } =
        useActivity()
    const { activityHistorySummary, totalAmount } = useActivityHistory()
    const { setTitle, setContent, setShow, setWidth } =
        useContext<any>(AppModalContext)
    const router = useHistory()

    const handleRowClick = (summary: IActivitySummary) => {
        setActivitySummary(summary)
        const activity = activities.find((x) => x.batch === summary.batch)
        if (activity) {
            setActivity(activity)
        }
        router.push(`/regional-modifications/batch-summary/${summary.batch}`)
    }

    const handleShow = () => {
        setActivity(emptyActivity)
        setTitle('Add New Activity')
        setContent(<ActivityForm updateMode={UpdateMode.ADD} />)
        setWidth('40rem')
        setShow(true)
    }

    const recordRef = useRef<any>()

    const excelExport = () => {
        const excelData = getMapedExcelActivities(activityHistorySummary)
        const excel = new Excel()
        excel
            .addSheet('sheet 1')
            .addColumns(activityExcelTableColumns as any)
            .addDataSource(excelData, {
                str2Percent: true,
            })
            .saveAs('Activities.xlsx')
    }
    useEffect(() => {}, [activityHistorySummary])
    return (
        <div ref={recordRef}>
            {activityHistorySummary && activityHistorySummary.length ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Tag color="purple">
                            <h6 style={{ marginBottom: 0 }}>
                                Total Amount: {format.number(totalAmount)}
                            </h6>
                        </Tag>
                        <Space>
                            <Button onClick={excelExport}>Export to excel</Button>
                        </Space>
                    </div>

                    <Table<IActivitySummary>
                        dataSource={activityHistorySummary}
                        columns={activityHistoryTableColumns}
                        className="activity__table activity__table--infinite"
                        id="activity__table"
                        size={'small'}
                        rowKey={`description`}
                        scroll={{ x: '500' }}
                        onRow={(record: IActivitySummary) => {
                            return {
                                onClick: (e) => {
                                    recordRef.current = e.target
                                    const target = recordRef.current.closest(
                                        '.ant-table-row-expand-icon-cell'
                                    )
                                    if (Object.is(target, null)) {
                                        handleRowClick(record)
                                    }
                                },
                            }
                        }}
                    />
                </>
            ) : (
                <NoContent
                    title="You have not entered any activities for the crurent runner. Click on the button below to start capturing your activities."
                    showButton={showButton}
                    handleClick={handleShow}
                    buttonLabel="Add New Activity"
                />
            )}
        </div>
    )
}

export default ActivityHistoryTable
