import { ColumnsType } from 'antd/lib/table'
import { ILocation } from '../../../models/common/location.model'

export const locationsTableColumns: ColumnsType<ILocation> = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: '4rem',
        filtered: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Sub Division',
        dataIndex: 'subDivisionCode',
        key: 'subDivisionCode',
    },
    {
        title: 'Tenant',
        dataIndex: 'tenant',
        key: 'tenant',
    },
]
