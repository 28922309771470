import { apiRequests } from '../'
import { IRunnerArchiveOptions } from '../../models/budgeting/runner-archive.model'
import {
    IRunnerPeriod,
    IRunnerPeriodResponse,
} from '../../models/budgeting/runner-period.model'

const runnerPeriodService = {
    list: (): Promise<IRunnerPeriod[]> => apiRequests.get('/runnerPeriods'),
    historyCodes: (options: IRunnerArchiveOptions): Promise<string[]> =>
        apiRequests.get(
            `/runnerPeriods/historyCodes?region=${options.region}&copYear=${options.copYear}&project=${options.project}`
        ),
    details: (code: string): Promise<IRunnerPeriod> =>
        apiRequests.get(`/runnerPeriods/${code}`),
    create: (runnerPeriod: IRunnerPeriod): Promise<IRunnerPeriodResponse> =>
        apiRequests.post('/runnerPeriods', runnerPeriod),
    update: (runnerPeriod: IRunnerPeriod): Promise<IRunnerPeriodResponse> =>
        apiRequests.put('/runnerPeriods', runnerPeriod),
    archive: (runnerPeriod: IRunnerPeriod): Promise<IRunnerPeriodResponse> =>
        apiRequests.post('/runnerPeriods/archive', runnerPeriod),
}

export default runnerPeriodService
