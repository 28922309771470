import { apiRequests } from '../'
import {
    IRunnerPeriodComponent,
    IRunnerPeriodComponentResponse,
} from '../../models/budgeting/runner-period-component.model'

const runnerPeriodComponentService = {
    list: (): Promise<IRunnerPeriodComponent[]> =>
        apiRequests.get('/runnerPeriodComponents'),
    details: (code: string): Promise<IRunnerPeriodComponent> =>
        apiRequests.get(`/runnerPeriodComponents/${code}`),
    create: (
        runnerperiodComponent: IRunnerPeriodComponent
    ): Promise<IRunnerPeriodComponentResponse> =>
        apiRequests.post('/runnerPeriodComponents', runnerperiodComponent),
    update: (
        runnerperiodComponent: IRunnerPeriodComponent
    ): Promise<IRunnerPeriodComponentResponse> =>
        apiRequests.put('/runnerPeriodComponents', runnerperiodComponent),
}

export default runnerPeriodComponentService
