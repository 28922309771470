import React from 'react'
import PageBreadCrumbs from '../../components/shared/page-breadcrumb/page-breadcrumb.component'
// import { IdleTime } from "../../../layout/idle-time/idle-time.component";
import './home.page.scss'
import { useTranslation } from 'react-i18next'

const DashboardPage: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div className="home-page">
            <PageBreadCrumbs items={[t('common:breadCrumb.dashboard')]} />
        </div>
    )
}

export default DashboardPage
